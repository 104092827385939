<script lang="ts" setup>
import ZyroModal from '@/components/global/ZyroModal.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import {
	estimateCredits,
	purchaseCredits,
} from '@/api/BillingApi';
import { ref } from 'vue';
import PurchaseDetails from '@/components/builder-modals/modals/-partials/billing/PurchaseDetails.vue';
import {
	BillingEstimate,
	PurchaseCreditsPayload,
} from '@/types/billingTypes';
import { useStore } from 'vuex';
import {
	CLOSE_MODAL,
	GUI_NAMESPACE,
} from '@/store/builder/gui';

import { LOCAL_STORAGE_KEY_CREDITS_ORDER } from '@/constants';
import PurchaseItemList from '@/components/builder-modals/modals/-partials/billing/PurchaseItemList.vue';
import Icon from '@/components/global/Icon.vue';
import EventLogApi from '@/api/EventLogApi';

const { dispatch } = useStore();

const isPaymentLoading = ref(false);
const isEstimateLoading = ref(true);
const isEstimateError = ref(false);

const estimate = ref<BillingEstimate | null>(null);

const loadOrderEstimate = async () => {
	try {
		isEstimateError.value = false;
		const { data } = await estimateCredits();

		estimate.value = data['builder-credits100'];
	} catch {
		isEstimateError.value = true;
	} finally {
		isEstimateLoading.value = false;
	}
};

const closeModal = () => dispatch(`${GUI_NAMESPACE}/${CLOSE_MODAL}`);

const handleContinueToPayment = async () => {
	EventLogApi.logEvent({
		eventName: 'website_builder.ai_image_generator.continue_to_payment',
	});

	const currentUrl = window.location.href;

	const getSuccessUrl = () => {
		const url = new URL(currentUrl);

		url.searchParams.set('creditsPurchased', '1');

		return url.toString();
	};

	const getBackUrl = () => {
		const url = new URL(currentUrl);

		url.search = '';

		return url.toString();
	};

	const payload: PurchaseCreditsPayload = {
		backURL: currentUrl,
		cancelURL: currentUrl,
		confirmationURL: getSuccessUrl(),
		itemId: 'builder-credits100',
	};

	try {
		isPaymentLoading.value = true;
		const { data } = await purchaseCredits(payload);

		await dispatch('saving/saveWebsite', {
			saveWhenImpersonating: true,
		});

		localStorage.setItem(LOCAL_STORAGE_KEY_CREDITS_ORDER, data.token);

		window.history.replaceState({}, document.title, getBackUrl());
		await window.open(data.paymentLink, '_self');

		closeModal();
	} catch {
		dispatch('notifications/notify', {
			messageI18nKeyPath: 'builder.imageCreditsError',
		});

		isPaymentLoading.value = false;
	}
};

loadOrderEstimate();
</script>

<template>
	<Teleport to="body">
		<ZyroModal
			is-for-payments
			max-width="600px"
			height="auto"
			:show-close-button="false"
			:content-padding="'32px 40px 40px 40px'"
			@close-modal="() => {}"
		>
			<h2 class="text-heading-2">
				{{ $t('builder.creditsModalTitle') }}
			</h2>

			<p class="description text-body-2">
				{{ $t('builder.creditsModalDescription') }}
			</p>

			<div class="content">
				<div
					v-if="isEstimateError"
					class="estimate-error"
				>
					<Icon
						name="error"
						size="24px"
						class="estimate-error__icon"
					/>
					<span class="text-body-2">
						{{ $t('builder.imageCreditsError') }}
					</span>
				</div>

				<template v-else>
					<PurchaseItemList
						:is-loading="isEstimateLoading"
						:estimate="(estimate as BillingEstimate)"
					/>
					<PurchaseDetails
						:is-loading="isEstimateLoading"
						:estimate="(estimate as BillingEstimate)"
					/>
				</template>
			</div>

			<div class="action-buttons">
				<HostingerButton
					v-if="!isPaymentLoading"
					button-type="text"
					@click="closeModal"
				>
					{{ $t('common.cancel') }}
				</HostingerButton>
				<HostingerButton
					v-if="!isEstimateError"
					:is-disabled="isEstimateLoading"
					:is-loading="isPaymentLoading"
					@click="handleContinueToPayment"
				>
					{{ $t('common.continueToPayment') }}
				</HostingerButton>
			</div>
		</ZyroModal>
	</Teleport>
</template>

<style lang="scss" scoped>
.estimate-error {
	display: flex;
	align-items: center;
	gap: 8px;
	border-radius: 8px;
	background-color: var(--color-danger-light);
	padding: 16px;

	&__icon {
		color: var(--color-danger);
	}
}

.description {
	margin-top: 8px;
	color: var(--color-gray)
}

.content {
	margin: 24px 0;
}

.action-buttons {
	display: flex;
	justify-content: flex-end;
	gap: 16px;

	@media screen and (max-width: 460px) {
		flex-direction: column-reverse;

		> * {
			width: 100%;
		}
	}
}
</style>
