<template>
	<div class="form-card">
		<h3 class="text-bold-1 form-card__title">
			<span class="form-card__form-name">
				{{ formId }}
			</span>
			<ZyroSvgDeprecated
				name="bullet-point"
				class="form-card__title-bullet-point"
			/>
			<span class="form-card__submission-count"> {{ entryCount }} {{ $t('common.submissions') }}</span>
		</h3>

		<i18n-t
			tag="p"
			class="text-body-1 form-card__form-name"
			keypath="siteSettings.forms.cardDescription"
		>
			{{ formId }}
		</i18n-t>
		<HostingerButton
			v-qa="`sitesettings-${formId}-btn-viewlist`"
			button-type="outlined"
			class="form-card__button"
			@click="$emit('update', formId)"
		>
			{{ $t('siteSettings.forms.viewList') }}
		</HostingerButton>
	</div>
</template>

<script>
import HostingerButton from '@/components/global/HostingerButton.vue';
import ZyroSvgDeprecated from '@/components/global/ZyroSvgDeprecated.vue';

import { defineComponent } from 'vue';

export default defineComponent({
	components: {
		HostingerButton,
		ZyroSvgDeprecated,
	},
	props: {
		formId: {
			type: String,
			required: true,
		},
		entryCount: {
			type: Number,
			required: true,
		},
		activeFormId: {
			type: String,
			default: null,
		},
	},
	emits: ['update'],
});
</script>

<style lang="scss" scoped>
.form-card {
	flex: 0 0 60%;
	padding: 24px 32px;
	margin-bottom: 16px;
	background: $color-light;

	@media screen and (max-width: $media-mobile) {
		padding: 16px;
		margin-bottom: 8px;
	}

	&__form-name {
		word-break: break-all;
	}

	&__title {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-bottom: 24px;

		&-bullet-point {
			margin: 0 7px 0 10px;
			flex-shrink: 0;
		}
	}

	&__submission-count {
		color: $color-gray;
		white-space: nowrap;
	}

	&__button {
		display: block;
		margin-top: 28px;
		margin-left: auto;
	}
}
</style>
