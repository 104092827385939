// this is map of events that goes to amplitude and GTM
export default {
	getEvent(eventName) {
		// Converts String to Object path and returns the accessed event
		try {
			return eventName.split('.').reduce((object, item) => object[item], this);
		} catch {
			return null;
		}
	},
	// Hostinger integration event
	website_builder: {
		ecomm_payment_modal: {
			shown: {
				amplitude: 'website_builder.ecomm_payment_modal.shown',
			},
		},
		ecomm_payment: {
			enter: {
				amplitude: 'website_builder.ecomm_payment.enter',
			},
		},
		product_updates: {
			enter: {
				amplitude: 'website_builder.product_updates.enter',
			},
		},
		blog_generator: {
			enter: {
				amplitude: 'website_builder.blog_generator.enter',
			},
		},
		ai_heatmap: {
			enter: {
				amplitude: 'website_builder.ai_heatmap.enter',
			},
		},
		backups: {
			enter: {
				amplitude: 'website_builder.backups.enter',
			},
			restore: {
				amplitude: 'website_builder.backups.restore',
			},
		},
		pay_2_publish: {
			go_live: {
				amplitude: 'website_builder.pay_2_publish.go_live',
			},
		},
		qr_code: {
			download: {
				amplitude: 'website_builder.qr_code.download',
			},
			get: {
				amplitude: 'website_builder.qr_code.get',
			},
		},
		builder_multi_select: {
			selected: {
				amplitude: 'website_builder.builder_multi_select.selected',
			},
		},
		preview: {
			enter: {
				amplitude: 'website_builder.preview.enter',
			},
			edit: {
				amplitude: 'website_builder.preview.edit',
			},
			back_to_form: {
				amplitude: 'website_builder.preview.back_to_form',
			},
		},
		asset_manager: {
			enter: {
				amplitude: 'website_builder.asset_manager.enter',
			},
			image_search: {
				amplitude: 'website_builder.asset_manager.image_search',
			},
		},
		gamefication: {
			enter: {
				amplitude: 'website_builder.gamefication.enter',
			},
			rated: {
				amplitude: 'website_builder.gamefication.rated',
			},
			completed: {
				amplitude: 'website_builder.gamefication.completed',
			},
		},
		game_start: {
			enter: {
				amplitude: 'website_builder.game_start.enter',
			},
			completed: {
				amplitude: 'website_builder.game_start.completed',
			},
		},
		game_edit_heading: {
			enter: {
				amplitude: 'website_builder.game_edit_heading.enter',
			},
			completed: {
				amplitude: 'website_builder.game_edit_heading.completed',
			},
		},
		game_update_image: {
			enter: {
				amplitude: 'website_builder.game_update_image.enter',
			},
			completed: {
				amplitude: 'website_builder.game_update_image.completed',
			},
		},
		create_new: {
			enter: {
				amplitude: 'website_builder.create_new.enter',
			},
		},
		game_edit_paragraph_text: {
			enter: {
				amplitude: 'website_builder.game_edit_paragraph_text.enter',
			},
			completed: {
				amplitude: 'website_builder.game_edit_paragraph_text.completed',
			},
		},
		game_add_your_own_log: {
			enter: {
				amplitude: 'website_builder.game_add_your_own_log.enter',
			},
			completed: {
				amplitude: 'website_builder.game_add_your_own_log.completed',
			},
		},
		game_update_social_media: {
			enter: {
				amplitude: 'website_builder.game_update_social_media.enter',
			},
			completed: {
				amplitude: 'website_builder.game_update_social_media.completed',
			},
		},
		game_check_your_mobile: {
			enter: {
				amplitude: 'website_builder.game_check_your_mobile.enter',
			},
			completed: {
				amplitude: 'website_builder.game_check_your_mobile.completed',
			},
		},
		game_get_found_on_google: {
			enter: {
				amplitude: 'website_builder.game_get_found_on_google.enter',
			},
			completed: {
				amplitude: 'website_builder.game_get_found_on_google.completed',
			},
		},
		game_connect_domain: {
			enter: {
				amplitude: 'website_builder.game_connect_domain.enter',
			},
			completed: {
				amplitude: 'website_builder.game_connect_domain.completed',
			},
		},
		game_go_live: {
			enter: {
				amplitude: 'website_builder.game_go_live.enter',
			},
			completed: {
				amplitude: 'website_builder.game_go_live.completed',
			},
		},
		feedback: {
			enter: {
				amplitude: 'website_builder.feedback.enter',
			},
			sent: {
				amplitude: 'website_builder.feedback.sent',
			},
		},
		website_settings: {
			enter: {
				amplitude: 'website_builder.website_settings.enter',
			},
		},
		languages: {
			enter: {
				amplitude: 'website_builder.languages.enter',
			},
			add: {
				amplitude: 'website_builder.languages.add',
			},
		},
		ai_tools: {
			enter: {
				amplitude: 'website_builder.ai_tools.enter',
			},
		},
		blog: {
			enter: {
				amplitude: 'website_builder.blog.enter',
			},
		},
		ai_assist: {
			enter: {
				amplitude: 'website_builder.ai_assist.enter',
			},
			generate: {
				amplitude: 'website_builder.ai_assist.generate',
			},
			cancel: {
				amplitude: 'website_builder.ai_assist.cancel',
			},
			retry: {
				amplitude: 'website_builder.ai_assist.retry',
			},
			keep: {
				amplitude: 'website_builder.ai_assist.keep',
			},
			copy_text: {
				amplitude: 'website_builder.ai_assist.copy_text',
			},
		},
		global_styles: {
			enter: {
				amplitude: 'website_builder.global_styles.enter',
			},
		},
		global_styles_colors: {
			enter: {
				amplitude: 'website_builder.global_styles_colors.enter',
			},
			change: {
				amplitude: 'website_builder.global_styles_colors.change',
			},
		},
		global_styles_buttons: {
			enter: {
				amplitude: 'website_builder.global_styles_buttons.enter',
			},
		},
		global_styles_text: {
			enter: {
				amplitude: 'website_builder.global_styles_text.enter',
			},
		},
		global_styles_animations: {
			enter: {
				amplitude: 'website_builder.global_styles_animations.enter',
			},
		},
		pages_and_navigation: {
			enter: {
				amplitude: 'website_builder.pages_and_navigation.enter',
			},
		},
		add_page: {
			enter: {
				amplitude: 'website_builder.add_page.enter',
			},
			added: {
				amplitude: 'website_builder.add_page.added',
			},
			generate_page: {
				amplitude: 'website_builder.add_page.generate_page',
			},
			create_page: {
				amplitude: 'website_builder.add_page.create_page',
			},
			page_generated: {
				amplitude: 'website_builder.add_page.page_generated',
			},
		},
		add_element: {
			element_added: {
				amplitude: 'website_builder.add_element.element_added',
			},
			enter: {
				amplitude: 'website_builder.add_element.enter',
			},
			chatbot_coming_soon_send: {
				amplitude: 'website_builder.add_element.chatbot_coming_soon_send',
			},
		},
		builder: {
			back: {
				amplitude: 'website_builder.builder.back',
			},
			enter: {
				amplitude: 'website_builder.builder.enter',
			},
			saved: {
				amplitude: 'website_builder.builder.saved',
			},
			previewed: {
				amplitude: 'website_builder.builder.previewed',
			},
			website_published: {
				amplitude: 'website_builder.builder.website_published',
			},
			website_update: {
				amplitude: 'website_builder.builder.website_update',
			},
		},
		domain: {
			propagated: {
				amplitude: 'website_builder.domain.propagated',
			},
		},
		ai_builder: {
			enter: {
				amplitude: 'website_builder.ai_builder.enter',
			},
			create: {
				amplitude: 'website_builder.ai_builder.create',
			},
			created: {
				amplitude: 'website_builder.ai_builder.created',
			},
			back: {
				amplitude: 'website_builder.ai_builder.back',
			},
			builder_enter: {
				amplitude: 'website_builder.ai_builder.builder_enter',
			},
			preview_enter: {
				amplitude: 'website_builder.ai_builder.preview_enter',
			},
		},
		ai_builder_voice_description: {
			enter: {
				amplitude: 'website_builder.ai_builder_voice_description.enter',
			},
			manual_change: {
				amplitude: 'website_builder.ai_builder_voice_description.manual_change',
			},
		},
		ai_image_generator: {
			enter: {
				amplitude: 'website_builder.ai_image_generator.enter',
			},
			create: {
				amplitude: 'website_builder.ai_image_generator.create',
			},
			use: {
				amplitude: 'website_builder.ai_image_generator.use',
			},
			buy_more: {
				amplitude: 'website_builder.ai_image_generator.buy_more',
			},
			continue_to_payment: {
				amplitude: 'website_builder.ai_image_generator.continue_to_payment',
			},
			payment_successful: {
				amplitude: 'website_builder.ai_image_generator.payment_successful',
			},
			image_rating: {
				amplitude: 'website_builder.ai_image_generator.image_rating',
			},
		},
		text_box_threshold: {
			reached: {
				amplitude: 'website_builder.text_box_threshold.reached',
			},
		},
		home_page_text_threshold: {
			reached: {
				amplitude: 'website_builder.home_page_text_threshold.reached',
			},
		},
		help: {
			enter: {
				amplitude: 'website_builder.help.enter',
			},
		},
		help_articles: {
			enter: {
				amplitude: 'website_builder.help_articles.enter',
			},
			shown: {
				amplitude: 'website_builder.help_articles.shown',
			},
		},
		keyboard_shortcuts: {
			enter: {
				amplitude: 'website_builder.keyboard_shortcuts.enter',
			},
		},
		quick_tour: {
			enter: {
				amplitude: 'website_builder.quick_tour.enter',
			},
		},
		ecomm_bookings: {
			enter: {
				amplitude: 'website_builder.ecomm_bookings.enter',
			},
		},
		ecomm: {
			store_added: {
				amplitude: 'website_builder.ecomm.store_added',
			},
			enter: {
				amplitude: 'website_builder.ecomm.enter',
			},
			remove: {
				amplitude: 'website_builder.ecomm.remove',
			},
		},
		ecomm_product_page: {
			enter: {
				amplitude: 'website_builder.ecomm_product_page.enter',
			},
		},
		ecomm_page_settings: {
			enter: {
				amplitude: 'website_builder.ecomm_page_settings.enter',
			},
			saved: {
				amplitude: 'website_builder.ecomm_page_settings.saved',
			},
		},
		ecomm_products: {
			add: {
				amplitude: 'website_builder.ecomm_products.add',
			},
			edit: {
				amplitude: 'website_builder.ecomm_products.edit',
			},
		},
		website_settings_general_settings: {
			enter: {
				amplitude: 'website_builder.website_settings_general_settings.enter',
			},
		},
		website_settings_integrations: {
			enter: {
				amplitude: 'website_builder.website_settings_integrations.enter',
			},
		},
		website_settings_analytics: {
			enter: {
				amplitude: 'website_builder.website_settings_analytics.enter',
			},
			help_article_enter: {
				amplitude: 'website_builder.website_settings_analytics.help_article_enter',
			},
		},
		website_settings_seo: {
			enter: {
				amplitude: 'website_builder.website_settings_seo.enter',
			},
		},
		website_settings_form_submissions: {
			enter: {
				amplitude: 'website_builder.website_settings_form_submissions.enter',
			},
		},
		website_settings_export_wordpress: {
			enter: {
				amplitude: 'website_builder.website_settings_export_wordpress.enter',
			},
		},
		website_settings_media_library: {
			enter: {
				amplitude: 'website_builder.website_settings_media_library.enter',
			},
		},
		social_link: {
			copied: {
				amplitude: 'website_builder.social_link.copied',
			},
			created: {
				amplitude: 'website_builder.social_link.created',
			},
		},
		styles_animation_style: {
			selected: {
				amplitude: 'website_builder.styles_animation_style.selected',
			},
		},
		search: {
			typed: {
				amplitude: 'website_builder.search.typed',
			},
			enter: {
				amplitude: 'website_builder.search.enter',
			},
		},
		password_setup: {
			enter: {
				amplitude: 'website_builder.password_setup.enter',
			},
			enabled: {
				amplitude: 'website_builder.password_setup.enabled',
			},
			disabled: {
				amplitude: 'website_builder.password_setup.disabled',
			},
			saved: {
				amplitude: 'website_builder.password_setup.saved',
			},
		},
		seo_brand_name: {
			typed: {
				amplitude: 'website_builder.seo_brand_name.typed',
			},
		},
		seo_language: {
			selected: {
				amplitude: 'website_builder.seo_language.selected',
			},
		},
		seo_website_description: {
			typed: {
				amplitude: 'website_builder.seo_website_description.typed',
			},
		},
		seo_keywords: {
			chosen: {
				amplitude: 'website_builder.seo_keywords.chosen',
			},
		},
		seo_summary: {
			finished: {
				amplitude: 'website_builder.seo_summary.finished',
			},
		},
		published: {
			view_site: {
				amplitude: 'website_builder.published.view_site',
			},
		},
		connect_domain_manual: {
			enter: {
				amplitude: 'website_builder.connect_domain_manual.enter',
			},
		},
		add_video: {
			added: {
				amplitude: 'website_builder.add_video.added',
			},
		},
		start_blog: {
			enter: {
				amplitude: 'website_builder.start_blog.enter',
			},
		},
		blog_add_post: {
			create_content: {
				amplitude: 'website_builder.blog_add_post.create_content',
			},
			skip: {
				amplitude: 'website_builder.blog_add_post.skip',
			},
			enter: {
				amplitude: 'website_builder.blog_add_post.enter',
			},
		},
		add_section: {
			create_section: {
				amplitude: 'website_builder.add_section.create_section',
			},
			enter: {
				amplitude: 'website_builder.add_section.enter',
			},
			added: {
				amplitude: 'website_builder.add_section.added',
			},
			generate_section: {
				amplitude: 'website_builder.add_section.generate_section',
			},
		},
		template: {
			chosen: {
				amplitude: 'website_builder.template.chosen',
			},
			personalise: {
				amplitude: 'website_builder.template.personalise',
			},
			use_original: {
				amplitude: 'website_builder.template.use_original',
			},
			ecommerce_chosen: {
				amplitude: 'website_builder.template.ecommerce_chosen',
			},
			shown: {
				amplitude: 'website_builder.template.shown',
			},
			enter: {
				amplitude: 'website_builder.template.enter',
			},
		},
		chatbot: {
			enter: {
				amplitude: 'website_builder.chatbot.enter',
			},
			question_sent: {
				amplitude: 'website_builder.chatbot.question_sent',
			},
			answer_generated: {
				amplitude: 'website_builder.chatbot.answer_generated',
			},
		},
		embed_code: {
			edit: {
				amplitude: 'website_builder.embed_code.edit',
			},
		},
		feature_lock: {
			shown: {
				amplitude: 'website_builder.feature_lock.shown',
			},
			upgrade: {
				amplitude: 'website_builder.feature_lock.upgrade',
			},
		},
		private_mode: {
			switch: {
				amplitude: 'website_builder.private_mode.switch',
			},
		},
		section_settings: {
			enter: {
				amplitude: 'website_builder.section_settings.enter',
			},
			section_background_change: {
				amplitude: 'website_builder.section_settings.section_background_change',
			},
		},
	},
	websites: {
		connect_domain: {
			shown: {
				amplitude: 'websites.connect_domain.shown',
			},
			enter: {
				amplitude: 'websites.connect_domain.enter',
			},
		},
	},
	logo_maker: {
		logo_maker: {
			enter: {
				amplitude: 'logo_maker.logo_maker.enter',
			},
		},
	},
};
