<template>
	<GridButton
		ref="buttonRef"
		v-qa="'builder-gridelement-gridbutton'"
		:data-element-ref="props.elementId"
		:tag-name="tagName"
		class="layout-element__component layout-element__component--GridButton"
		:href="href"
		:target="target"
		:rel="rel"
		:type="type"
		:content="content"
		:font-size-mobile="fontSizeMobile"
		:font-size-desktop="fontSizeDesktop"
		:font-family="fontFamily"
		:font-weight="fontWeight"
		:border-width="borderWidth"
		:border-radius="borderRadius"
		:background-color="backgroundColor"
		:font-color="fontColor"
		:border-color="borderColor"
		:background-color-hover="backgroundColorHover"
		:font-color-hover="fontColorHover"
		:border-color-hover="borderColorHover"
		:mobile-element-width="mobileElementWidth"
		:mobile-element-height="mobileElementHeight"
		is-in-builder
		@click.prevent
		@drag.prevent
		@dragstart.prevent
	/>
	<ElementEditControls
		:target-ref="buttonRef?.$el"
		:element-id="props.elementId"
		:is-element-active="props.isActive"
		:enter-edit-mode-button-title="$t('builder.editButton.title')"
	>
		<template #edit-mode-popup>
			<EditButton @close="closeElementEditPopupHandler" />
		</template>
	</ElementEditControls>
</template>

<script setup>
import {
	computed,
	ref,
} from 'vue';

import ElementEditControls from '@/components/builder-controls/ElementEditControls.vue';
import GridButton from '@zyro-inc/site-modules/components/elements/button/GridButton.vue';
import EditButton from '@/components/builder-controls/edit-button/EditButton.vue';

import { useGridButton } from '@zyro-inc/site-modules/components/elements/button/useGridButton';
import { useElementEditPopup } from '@/use/useElementEditPopup';

const props = defineProps({
	data: {
		type: Object,
		required: true,
	},
	elementId: {
		type: String,
		required: true,
	},
	isActive: {
		type: Boolean,
		default: false,
	},
});

const {
	tagName,
	href,
	target,
	rel,
	type,
	content,
	fontSizeMobile,
	fontSizeDesktop,
	fontFamily,
	fontWeight,
	borderWidth,
	borderRadius,
	backgroundColor,
	fontColor,
	borderColor,
	backgroundColorHover,
	fontColorHover,
	borderColorHover,
	mobileElementWidth,
	mobileElementHeight,
} = useGridButton(props, {
	href: computed(() => props.data.href),
});

const buttonRef = ref(null);

const { closeElementEditPopupHandler } = useElementEditPopup({
	elementId: props.elementId,
});
</script>
