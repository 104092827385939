<template>
	<div
		ref="switcherRef"
		class="header-switcher"
	>
		<HostingerButton
			v-qa="`dropdown-button-select-${currentLocale}`"
			class="header-switcher__trigger"
			button-type="plain"
			@click="isOpen = !isOpen"
		>
			<div class="header-switcher__language">
				<Flag
					:src="currentLanguageData.src"
					:alt="currentLanguageData.alt"
				/>
				<span class="header-switcher__language-title text-body-2">
					{{ currentLanguageName }}
				</span>
			</div>
			<template
				v-if="isDropdownShown"
				#icon-right
			>
				<Icon
					name="expand_more"
					:direction="isOpen ? 'down' : 'up'"
				/>
			</template>
		</HostingerButton>

		<Popup
			v-if="isOpen && isDropdownShown"
			placement="bottom"
			auto-update
			:target-ref="$refs.switcherRef"
			@click-outside="isOpen = false"
		>
			<MultilingualList
				:show-flags="true"
				class="header-switcher__list"
				:languages="languageList"
				@language-click="handleLanguageClick"
			/>
		</Popup>
	</div>
</template>

<script setup>
import Icon from '@/components/global/Icon.vue';
import Popup from '@/components/global/Popup.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';

import { useStore } from 'vuex';
import Flag from '@zyro-inc/site-modules/components/Flag.vue';

import MultilingualList from '@/components/multilingual/MultilingualList.vue';
import { FLAG_CDN_PREFIX } from '@zyro-inc/site-modules/constants';
import {
	computed,
	ref,
} from 'vue';

const {
	state,
	getters,
	dispatch,
} = useStore();

const isOpen = ref(false);
const switcherRef = ref(null);
const currentLocale = computed(() => state.currentLocale);
const siteLanguagesArray = computed(() => getters.siteLanguagesSortedArray);

const currentLanguageData = computed(() => {
	const languageData = siteLanguagesArray.value.find(({ locale }) => locale === currentLocale.value);

	return {
		...languageData,
		src: languageData.country ? `${FLAG_CDN_PREFIX}/${languageData.flagPath}` : '/assets/icons/empty-flag.svg',
		alt: languageData.country ? `${languageData.locale} flag` : 'empty flag',
	};
});
const currentLanguageName = computed(() => currentLanguageData.value?.name);
const languageList = computed(() => siteLanguagesArray.value.filter(({ locale }) => locale !== currentLocale.value));
const isDropdownShown = computed(() => siteLanguagesArray.value.length > 1);

const handleLanguageClick = (language) => {
	dispatch('updateCurrentLocale', language.locale);
	isOpen.value = false;
};
</script>
<style lang="scss" scoped>
.header-switcher {
	$this: &;

	margin: 0 4px;
	position: relative;

	@media screen and (max-width: $media-mobile) {
		display: none;
	}

	&__language {
		position: relative;
		display: flex;
		align-items: center;
		height: 100%;
	}

	&__trigger {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		width: 100%;
		overflow: hidden;
	}

	&__language-title {
		overflow: hidden;
		margin-left: 8px;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&__list {
		width: 100%;
		padding: 4px;
		background-color: $color-light;
		box-shadow: 0 6px 14px rgb(0 0 0 / 10%);
	}
}

</style>
