<template>
	<div class="bar">
		<div
			v-for="(_color, index) in colors"
			:key="`segment-${index + 1}`"
			class="segment"
		>
			<TransitionGroup name="fill">
				<div
					v-if="index + 1 <= progress"
					class="segment-overlay"
					:style="getSegmentColor(index)"
				/>
			</TransitionGroup>
		</div>
	</div>
	<span
		v-if="hints.length"
		class="text-body-3 hint"
	>
		{{ hintText }}
	</span>
</template>

<script setup lang="ts">
import { computed } from 'vue';

interface Props {
	progress: number;
	colors: string[];
	hints?: string[];
	isProgressKept?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	progress: 0,
	colors: () => [
		'var(--color-danger)',
		'var(--color-warning)',
		'var(--color-success)',
	],
	hints: () => [],
	isProgressKept: false,
});

const getSegmentColor = (index: number) => {
	// isProgressKept is used to keep the progress bar filled with last color even if progress is bigger than maximum number of segments
	if (props.isProgressKept && props.progress > index + 1 && props.progress >= props.colors.length) {
		return {
			'background-color': [...props.colors].pop(),
		};
	}

	return {
		'background-color': props.colors[props.progress - 1],
	};
};

const hintText = computed(() => {
	if (props.progress < props.hints.length) {
		return props.hints[props.progress];
	}

	return [...props.hints].pop();
});

</script>

<style lang="scss" scoped>
.hint {
	padding-top: 4px;
	color: var(--color-gray);
}

.bar {
	display: flex;
	column-gap: 4px;
	height: 12px;
}

.segment {
	position: relative;
	flex-grow: 1;
	overflow: hidden;
	background-color: $color-gray-light;

	&:first-child {
		border-radius: 2px 0 0 2px;
	}

	&:last-child {
		border-radius: 0 2px 2px 0;
	}
}

.segment-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transition: background-color 0.3s ease;
	transform-origin: left;
}

.fill-enter-active,
.fill-leave-active {
	transition: transform 0.3s ease;
}

.fill-enter-from,
.fill-leave-to {
	transform: scaleX(0);
}
</style>
