<template>
	<Teleport
		v-if="isEditControlsVisible"
		to="body"
	>
		<!-- Edit popup -->
		<Popup
			v-if="isElementEditMode && targetRef"
			:target-ref="targetRef"
			:placement="editModePopupOptions.placement"
			:offset="editModePopupOptions.offset"
			:auto-update="editModePopupOptions.autoUpdate"
			:flip="editModePopupOptions.flip"
			:is-mobile-fixed-to-bottom="!isMobilePopupStickedToBottom"
			:is-mobile-sticked-to-bottom="isMobilePopupStickedToBottom"
			:portal-selector="SELECTOR_DATA_PORTAL_BUILDER_PREVIEW"
			ignore-target-position-change
			@click-outside="clickOutsideHandler"
		>
			<slot name="edit-mode-popup" />
		</Popup>

		<!-- Mobile screen resolution controls bar -->
		<Teleport
			v-else-if="isMobileScreen"
			:to="SELECTOR_DATA_PORTAL_BUILDER_PREVIEW_MOBILE_BOTTOM_BAR"
		>
			<ElementEditControlsBar
				:enter-element-edit-mode-button-title="enterEditModeButtonTitle"
				:element-id="props.elementId"
				@element-deleted="emit('element-deleted')"
			>
				<slot name="additional-edit-buttons" />
			</ElementEditControlsBar>
		</Teleport>
		<!-- Desktop screen resolution controls bar popup -->
		<Popup
			v-else
			:target-ref="nonGridElementRef || currentElementRef"
			:portal-selector="SELECTOR_DATA_PORTAL_BUILDER_PREVIEW"
			:placement="controlsPopupOptions.placement"
			:auto-update="controlsPopupOptions.autoUpdate"
			:flip="controlsPopupOptions.flip"
			:offset="controlsPopupOptions.offset"
		>
			<ElementEditControlsBar
				:enter-element-edit-mode-button-title="enterEditModeButtonTitle"
				:element-id="props.elementId"
				:is-main-controls-disabled="isMainControlsDisabled"
				@element-deleted="emit('element-deleted')"
			>
				<slot name="additional-edit-buttons" />
			</ElementEditControlsBar>
		</Popup>
	</Teleport>
</template>

<script setup>
import { useStore } from 'vuex';
import { computed } from 'vue';

import Popup from '@/components/global/Popup.vue';
import {
	SELECTOR_DATA_PORTAL_BUILDER_PREVIEW_MOBILE_BOTTOM_BAR,
	SELECTOR_DATA_PORTAL_BUILDER_PREVIEW,
} from '@/constants';

import ElementEditControlsBar from '@/components/builder-controls/ElementEditControlsBar.vue';
import { useElementEditPopup } from '@/use/useElementEditPopup';
import { useCurrentElementRef } from '@/use/useCurrentElementRef';

const props = defineProps({
	elementId: {
		type: String,
		required: true,
	},
	isElementActive: {
		type: Boolean,
		default: false,
	},
	targetRef: {
		type: [
			HTMLElement,
			null,
		],
		default: null,
	},
	enterEditModeButtonTitle: {
		type: String,
		default: '',
	},
	editModePopupOptions: {
		type: Object,
		default: () => ({
			placement: 'right-start',
			offset: 18,
			flip: false,
			autoUpdate: false,
		}),
	},
	controlsPopupOptions: {
		type: Object,
		default: () => ({
			placement: 'top-start',
			offset: 22,
			flip: false,
			autoUpdate: true,
		}),
	},
	isMobilePopupStickedToBottom: {
		type: Boolean,
		default: false,
	},
	isMainControlsDisabled: {
		type: Boolean,
		default: false,
	},
	isNonGridElementEditMode: {
		type: Boolean,
		default: false,
	},
	isNonGridElementControlsVisible: {
		type: Boolean,
		default: false,
	},
	nonGridElementRef: {
		type: HTMLElement,
		default: null,
	},
});

const emit = defineEmits(['element-deleted']);

const {
	state,
	getters,
} = useStore();
const { currentElementRef } = useCurrentElementRef();
const { closeElementEditPopupHandler } = useElementEditPopup({
	elementId: props.elementId,
});
const isElementEditMode = computed(() => props.isNonGridElementEditMode || state.isElementEditMode);
const currentElementId = computed(() => getters.currentElementId);
const isColorPickerOpen = computed(() => getters['gui/isColorPickerOpen']);
const isMobileScreen = computed(() => state.gui.isMobileScreen);
const isActive = computed(() => props.isElementActive);
const isEditControlsVisible = computed(
	() => props.isNonGridElementControlsVisible || (currentElementId.value === props.elementId && !isActive?.value),
);

const clickOutsideHandler = (event) => {
	if (isColorPickerOpen.value) {
		return;
	}

	// This prevents textbox from leaving edit mode when cursor leaves textbox area while selecting text
	if (event.button === 0) {
		return;
	}

	closeElementEditPopupHandler();
};
</script>
