import { computed } from 'vue';
import { useStore } from 'vuex';

export const usePageSeoSettings = () => {
	const {
		getters,
		state,
		dispatch,
	} = useStore();

	const currentPageId = computed(() => state.currentPageId);
	const sitePages = computed(() => getters.sitePages);

	const pageData = computed(() => sitePages.value[currentPageId.value]);
	const pageSlug = computed(() => pageData.value.slug);
	const pageMeta = computed(() => pageData.value.meta ?? {});
	const pageMetaTitle = computed(() => pageMeta.value.title ?? '');
	const pageMetaDescription = computed(() => pageMeta.value.description ?? '');
	const pageMetaKeywords = computed<string[]>(() => pageMeta.value.keywords ?? []);
	const pageMetaFocusKeyword = computed<string | null>(() => pageMeta.value.focusKeyword ?? null);
	const pageMetaNoIndex = computed(() => pageMeta.value.noindex ?? false);
	const pageName = computed(() => pageData.value.name);

	const mergePageMeta = (dataToMerge: Record<string, any>) => {
		dispatch('mergePageData', {
			pageId: currentPageId.value,
			pageData: {
				meta: {
					...dataToMerge,
				},
			},
		});
	};

	return {
		mergePageMeta,
		pageData,
		pageName,
		pageSlug,
		pageMetaTitle,
		pageMetaDescription,
		pageMetaKeywords,
		pageMetaFocusKeyword,
		pageMetaNoIndex,
	};
};
