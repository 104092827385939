import axios from '@/services/http/axios';
import {
	HResourceTypes,
	HostingResource,
} from '@/types/resourceTypes';

const HRESOURCES_API = `${import.meta.env.VITE_HRESOURCES_API_URL}/api`;

type ResourcesResponse = {
	data: HostingResource[];
	status: number,
	success: boolean,
};

export default {
	getUserResources: async ({ types }: { types: HResourceTypes[] } = {
		types: [],
	}): Promise<HostingResource[]> => {
		const { data } = await axios.get<ResourcesResponse>(`${HRESOURCES_API}/v2/resources`, {
			params: {
				...(types && {
					type: types,
				}),
			},
		});

		return data.data;
	},
};
