<template>
	<div class="edit-embed">
		<ZyroPopupCard
			type="editor"
			show-footer
			:title="$t('common.embedCode')"
			:save-btn-title="$t('common.embedCode')"
			max-width="560px"
			editor-popup-width="560px"
			@close="closeEditEmbed"
			@save="setEmbedContent"
		>
			<ZyroLabel class="edit-embed__input-label">
				{{ $t('builder.editEmbed.addYourCodeHere') }}
			</ZyroLabel>
			<ZyroCodeEditor v-model="embedContentValue" />
			<NpsRateFeature
				:feature-name="$t('builder.npsRateEmbed')"
				:type="NPS_TYPE_FEATURE_EMBED_CODE"
			/>
		</ZyroPopupCard>
	</div>
</template>

<script setup>
import ZyroLabel from '@/components/global/ZyroLabel.vue';
import ZyroPopupCard from '@/components/global/ZyroPopupCard.vue';

import { useStore } from 'vuex';

import ZyroCodeEditor from '@/components/reusable-components/ZyroCodeEditor.vue';
import NpsRateFeature from '@/components/ui/NpsRateFeature.vue';
import { NPS_TYPE_FEATURE_EMBED_CODE } from '@/constants';

import {
	ref,
	onMounted,
	computed,
} from 'vue';
import EventLogApi from '@/api/EventLogApi';
import { useNotifications } from '@/use/useNotifications';
import { useI18n } from 'vue-i18n';

const {
	getters,
	dispatch,
	state,
} = useStore();

const { notify } = useNotifications();

const { t } = useI18n();

const DANGEROUS_EMBED_KEYWORDS = ['WebAssembly'];

const emit = defineEmits(['close']);

const embedContentValue = ref(null);

const currentElementContent = computed(() => getters.currentElementContent);

onMounted(() => {
	embedContentValue.value = currentElementContent.value;
});

const hasDangerousKeywords = (embedValue) => DANGEROUS_EMBED_KEYWORDS.some((keyword) => embedValue.includes(keyword));

const isEmbedContentAllowed = (contentValue) => !hasDangerousKeywords(contentValue);

const closeEditEmbed = () => {
	emit('close');
};

const setEmbedContent = () => {
	if (state.user.user.isAccessManager) {
		notify({
			message: t('builder.embeddedCodeEditDisabled'),
		});

		closeEditEmbed();

		return;
	}

	if (!isEmbedContentAllowed(embedContentValue.value)) {
		closeEditEmbed();

		return;
	}

	dispatch('mergeCurrentElementData', {
		elementData: {
			content: embedContentValue.value,
		},
	});

	EventLogApi.logEvent({
		eventName: 'website_builder.embed_code.edit',
	});

	closeEditEmbed();
};

</script>
<style lang="scss" scoped>
.edit-embed {
	&__input-label {
		margin-bottom: 6px;
	}
}
</style>
