<script setup lang="ts">
import ZyroSelect from '@/components/global/ZyroSelect.vue';
import Icon from '@/components/global/Icon.vue';
import { useSeoStore } from '@/stores';
import { SeoDrawerTab } from '@/types/seoTypes';

const value = defineModel<SeoDrawerTab>('modelValue', {
	default: () => {},
});
const seoStore = useSeoStore();
</script>

<template>
	<div class="page-select">
		<ZyroSelect
			v-model="value"
			class="page-select__select"
			:options="seoStore.seoDrawerTabs"
			label-key="label"
			:filterable="false"
		>
			<template #option="{ option }">
				<div
					class="page-select__input"
					:class="{ 'page-select__input--selected': option.id === value.id }"
				>
					<span>
						{{ option.label }}
					</span>
					<Icon
						v-if="option.id === value.id"
						name="check"
						dimensions="20px"
					/>
				</div>
			</template>
			<template #selected-option="{ option }">
				<div class="page-select__input">
					<Icon
						name="sort"
						dimensions="20px"
					/>
					<span>
						{{ option.label }}
					</span>
				</div>
			</template>
		</ZyroSelect>
	</div>
</template>

<style scoped lang="scss">
.page-select {
	&__select {
		cursor: pointer;
		height: 40px;
		border-radius: 8px;
		color: var(--color-gray-dark);
}

	&__input {
		width: 100%;
		display: flex;
		align-items: center;
		gap: 8px;

		&--selected {
			justify-content: space-between;
		}
	}

	:deep() {
		.datalist {
			border: 1px solid transparent;
			transition: border-color 0.1s ease-in-out;

			&--opened {
				border-color: var(--color-gray);
			}
		}

		.v-select {
			.vs__selected.vs__selected {
				opacity: 1;
			}

			.vs__selected, .vs__search {
				margin: 0;
			}

			.vs__dropdown-menu {
				margin-top: 8px;
			}

			.vs__dropdown-toggle {
				justify-content: space-between;
				align-items: center;
			}

			input {
				pointer-events: none;
				caret-color: transparent;
			}
		}
	}
}
</style>
