<template>
	<Teleport to="body">
		<Popup
			:target-ref="targetRef"
			:portal-selector="SELECTOR_DATA_PORTAL_BUILDER_PREVIEW"
			placement="top-start"
			auto-update
			:flip="false"
			:offset="22"
		>
			<div class="crop-settings">
				<div class="crop-settings__slider">
					<ZyroRange
						:model-value="cropScale"
						:is-value-shown="false"
						min="1"
						units="%"
						step="0.01"
						max="2.5"
						is-with-icon-controls
						@update:model-value="updateCropScale"
					/>
				</div>
				<hr class="crop-settings__vertical-separator">
				<HostingerButton
					v-qa="'builder-elementedit-buttoncropdone'"
					button-type="plain"
					theme="highlight"
					:title="$t('common.crop')"
					@click="exitCropMode"
				>
					{{ $t('common.done') }}
				</HostingerButton>
			</div>
		</Popup>
	</Teleport>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

import Popup from '@/components/global/Popup.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import ZyroRange from '@/components/global/ZyroRange.vue';
import { useCropImage } from '@/components/layout-element/useCropImage';
import { SELECTOR_DATA_PORTAL_BUILDER_PREVIEW } from '@/constants';

defineProps({
	targetRef: {
		type: HTMLElement,
		required: true,
	},
});

const {
	dispatch,
	getters,
} = useStore();

const {
	exitCropMode,
	croppedImageId,
} = useCropImage();

const cropScale = computed(() => Number.parseFloat(getters.siteElements[croppedImageId.value].desktop.crop?.scale) || 1);

const updateCropScale = (scale) => {
	// TODO: Pass temp scale to composable instead of updating it all the time
	//  and only update on exit or when slider is released
	dispatch('mergeElementData', {
		elementId: croppedImageId.value,
		elementData: {
			desktop: {
				crop: {
					scale,
				},
			},
		},
	});
};
</script>

<style lang="scss" scoped>
.crop-settings {
	display: flex;
	align-items: center;
	background-color: $color-light;
	box-shadow: $box-shadow;
	padding: 2px;
	border-radius: 8px;

	&__vertical-separator {
		width: 1px;
		height: 20px;
		display: inline-block;
		background-color: $color-gray-border;
		margin: 0 4px;
		border: none;
	}
}
</style>
