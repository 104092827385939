import axios from '@/services/http/axios';

const ECOMMERCE_API = `${import.meta.env.VITE_ECOMMERCE_API_URL}/store`;

export const getSettings = async (storeId) => {
	const { data } = await axios.get(`${ECOMMERCE_API}/${storeId}/settings`);

	return data;
};

export const getStoreProducts = async (storeId, options = {}) => {
	const {
		sort,
		collectionId,
		isHidden,
	} = options;

	const searchParams = new URLSearchParams(sort);

	if (collectionId) {
		searchParams.set('collection_ids[]', collectionId);
	}

	if (isHidden) {
		searchParams.set('is_hidden', isHidden);
	}

	const { data } = await axios.get(`${ECOMMERCE_API}/${storeId}/products?${searchParams.toString()}`);

	return {
		products: data.products,
		count: data.count,
	};
};

export const getVariantsQuantity = async (storeId) => {
	const { data } = await axios.get(`${ECOMMERCE_API}/${storeId}/variants`, {
		params: {
			fields: 'inventory_quantity',
		},
	});

	return data?.variants;
};

export const getCategories = async (storeId) => {
	const { data } = await axios.get(`${ECOMMERCE_API}/${storeId}/collections`);

	return data.collections;
};
