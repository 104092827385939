import {
	computed,
	ref,
} from 'vue';
import { defaultPexelsVideos } from '@/use/defaultPexelsVideo';
import { useStore } from 'vuex';
import { defineStore } from 'pinia';
import PexelsApi from '@/api/PexelsApi';
import {
	PexelsVideo,
	PexelsVideoFile,
	SearchVideoProps,
	Video,
} from '@/types/pexelsTypes';
import {
	PEXELS_VIDEOS_PER_PAGE,
	PEXELS_VIDEO_QUALITIES,
} from '@/constants';
import { useNotifications } from '@/use/useNotifications';
import { useI18n } from 'vue-i18n';
import EventLogApi from '@/api/EventLogApi';
import { BACKGROUND_TYPES } from '@zyro-inc/site-modules/constants';

const mapPexelsVideos = (videos: PexelsVideo[]): Video[] => videos.map((pexelsVideo) => ({
	id: pexelsVideo.id,
	image: pexelsVideo.image,
	user: pexelsVideo.user,
	videoFiles: pexelsVideo?.video_files.map((videoFile: PexelsVideoFile) => ({
		id: videoFile.id,
		quality: videoFile.quality,
		width: videoFile.width,
		height: videoFile.height,
		link: videoFile.link,
	})),
}));

export const getHighestQualityPexelsVideo = (videoFiles: PexelsVideoFile[]): PexelsVideoFile => videoFiles
	.sort((a, b) => PEXELS_VIDEO_QUALITIES.indexOf(b.quality) - PEXELS_VIDEO_QUALITIES.indexOf(a.quality))[0];

export const usePexelsStore = defineStore('pexels', () => {
	const {
		state,
		dispatch,
	} = useStore();

	const { notify } = useNotifications();
	const { t } = useI18n();

	const videos = ref<Video[]>(mapPexelsVideos(defaultPexelsVideos));
	const searchTerm = ref('Nature');
	const currentPage = ref(1);
	const isLoadingPexelsResults = ref(false);
	const currentBlockId = computed(() => state.currentBlockId);
	const showEmptyDisclaimer = computed(() => !isLoadingPexelsResults.value && (!videos.value.length || !searchTerm.value.length));

	const searchVideo = async ({
		query,
		page,
	}: SearchVideoProps) => {
		isLoadingPexelsResults.value = true;

		if (!page) {
			currentPage.value = 1;
		}

		searchTerm.value = query.trim();

		if (typeof query !== 'string' || !query.trim().length) {
			videos.value = [];
			isLoadingPexelsResults.value = false;

			return;
		}

		try {
			const { videos: pexelsVideos } = await PexelsApi.searchVideos({
				query,
				perPage: PEXELS_VIDEOS_PER_PAGE,
				page: currentPage.value,
			});

			if (page && page !== 1) {
				videos.value = [
					...videos.value,
					...mapPexelsVideos(pexelsVideos),
				];

				isLoadingPexelsResults.value = false;

				return;
			}

			videos.value = mapPexelsVideos(pexelsVideos);
			isLoadingPexelsResults.value = false;
		} catch (error) {
			isLoadingPexelsResults.value = false;

			notify({
				message: t('builder.pexelsSearchFailed'),
			});

			throw new Error('Failed to search for videos');
		}
	};

	// Uses same query to fetch next page of images. Results are added to existing list
	const searchMoreVideos = async () => {
		currentPage.value += 1;

		await searchVideo({
			query: searchTerm.value,
			page: currentPage.value,
		});
	};

	const selectVideo = (videoId: number) => {
		const selectedVideo = videos.value.find((video) => video.id === videoId);

		if (!selectedVideo) {
			notify({
				message: t('builder.pexelsInvalidSelectedVideo'),
			});

			throw new Error(`Video with id ${videoId} not found`);
		}

		const videoThumbnailSrc = selectedVideo.image;
		const highestQualityVideoFile = getHighestQualityPexelsVideo(selectedVideo.videoFiles);
		const videoSrc = highestQualityVideoFile?.link;

		if (!videoSrc) {
			notify({
				message: t('builder.pexelsMissingVideoURL'),
			});

			throw new Error('Failed to get video resource URL');
		}

		dispatch('updateBlockData', {
			blockId: currentBlockId.value,
			blockData: {
				background: {
					current: 'video',
					video: {
						videoSrc,
						videoThumbnailSrc,
					},
				},
			},
			merge: true,
		});

		EventLogApi.logEvent({
			eventName: 'website_builder.section_settings.section_background_change',
			eventProperties: {
				type: BACKGROUND_TYPES.VIDEO,
			},
		});
	};

	const setDefaultPexelsVideos = () => {
		videos.value = mapPexelsVideos(defaultPexelsVideos);
		searchTerm.value = 'Nature';
	};

	return {
		searchVideo,
		searchMoreVideos,
		selectVideo,
		setDefaultPexelsVideos,
		videos,
		showEmptyDisclaimer,
	};
});
