<template>
	<BlockBlogList
		ref="blogListRef"
		class="block-blog-header"
		:data="data"
		:block-id="blockId"
		:posts="blogPosts"
		:class="{ customAnimationClass }"
		:blog-categories="blogCategories"
		:blog-reading-time-text="blogReadingTimeText"
		:[DATA_ATTRIBUTE_ANIMATION_STATE]="animationAttributeStateValue"
		@post-click="editPost"
	>
		<template #block-blog-list-empty-block>
			<BlockBlogListEmptyBlock
				:mode="emptyBlockMode"
				@add-post-button-click="addPost"
			/>
		</template>
		<template #block-blog-list-overlay="{ post }">
			<BlockBlogListItemOverlay @overlay-button-click="editPost(post)" />
		</template>
	</BlockBlogList>
	<BlockControls
		:block-id="blockId"
		:target-ref="blogListRef?.$el"
		:is-first-block="isFirstBlock"
		:is-last-block="isLastBlock"
		:transparent-header-height="transparentHeaderHeight"
	/>
	<BlockEditControls
		:block-id="blockId"
		:target-ref="blogListRef?.$el"
		is-duplicate-button-visible
		is-delete-button-visible
		is-reordering-button-visible
		is-visibility-button-visible
		:enter-edit-mode-button-title="t('builder.editBlockButton.editBlogList')"
	>
		<template #edit-mode-popup>
			<EditBlockBlogListTabs @close="closeBlockEditPopupHandler" />
		</template>
	</BlockEditControls>
</template>

<script setup>
import {
	computed,
	ref,
} from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

import BlockControls from '@/components/block/BlockControls.vue';
import BlockEditControls from '@/components/builder-controls/BlockEditControls.vue';
import BlockBlogListEmptyBlock from '@/components/blocks/BlockBlogListEmptyBlock.vue';
import BlockBlogListItemOverlay from '@/components/blocks/BlockBlogListItemOverlay.vue';
import BlockBlogList from '@zyro-inc/site-modules/components/blocks/blog/BlockBlogList.vue';
import EditBlockBlogListTabs from '@/components/builder-controls/edit-block-blog-list/EditBlockBlogListTabs.vue';
import {
	BLOG_POST_COVER_IMAGE_MAX_HEIGHT,
	BLOG_POST_COVER_IMAGE_MAX_WIDTH,
} from '@zyro-inc/site-modules/components/blocks/blog/constants';
import { mockPosts } from '@zyro-inc/site-modules/components/blocks/blog/mockPosts';
import { getOptimizedSrc } from '@zyro-inc/site-modules/utils/getSrcsets';
import { getRandomArrayItem } from '@/utils/array';
import { BLOG_PLACEHOLDERS } from '@/data';
import { DATA_ATTRIBUTE_ANIMATION_STATE } from '@zyro-inc/site-modules/constants';
import { useSiteEngineAnimations } from '@zyro-inc/site-modules/use/useSiteEngineAnimations';

const {
	state,
	getters,
	dispatch,
} = useStore();

const props = defineProps({
	blockId: {
		type: String,
		required: true,
	},
	data: {
		type: Object,
		required: true,
	},
	areDemoPostsShown: {
		type: Boolean,
		default: false,
	},
	isFirstBlock: {
		type: Boolean,
		default: false,
	},
	isLastBlock: {
		type: Boolean,
		default: false,
	},
	transparentHeaderHeight: {
		type: Number,
		default: 0,
	},
});
const { t } = useI18n();

const blogListRef = ref(null);

const {
	customAnimationClass,
	animationAttributeStateValue,
} = useSiteEngineAnimations({
	data: props.data,
});

const websiteId = computed(() => state.websiteId);
const currentPageId = computed(() => state.currentPageId);
const user = computed(() => state.user.user);
const publishedBlogPages = computed(() => getters.publishedBlogPages);
const blogPages = computed(() => getters.blogPages);
const blogCategories = computed(() => getters.blogCategories);
const blogReadingTimeText = computed(() => getters.blogReadingTimeText);

const emptyBlockMode = computed(() => (Object.keys(blogPages.value).length === 0 ? 'with-button' : 'no-posts'));
const posts = computed(() => (props.areDemoPostsShown ? mockPosts : publishedBlogPages.value));

const blogPosts = computed(() => Object.fromEntries(Object.entries(posts.value)
	.map(([id, post]) => {
		const coverImageSrc = getOptimizedSrc(post.coverImageOrigin, post.coverImagePath, websiteId.value, {
			width: BLOG_POST_COVER_IMAGE_MAX_WIDTH,
			height: BLOG_POST_COVER_IMAGE_MAX_HEIGHT,
		});

		return [
			id,
			{
				...post,
				coverImageSrc,
			},
		];
	})));

const addPost = () => {
	dispatch('addBlogPostPage', {
		postTitle: t('builder.blog.blogPost.title'),
		postDescription: t('builder.blog.blogPost.description'),
		postContent: t('builder.blog.blogPageElements.content'),
		postThumbnail: getRandomArrayItem(BLOG_PLACEHOLDERS),
	});

	if (user.value?.id) {
		window.hj('identify', user.value.id, {
			'builder.blog.create_new_post': true,
		});
	}
};

const editPost = (post) => {
	const pageId = Object.keys(publishedBlogPages.value).find(
		(key) => publishedBlogPages.value[key].slug === post.slug,
	);

	// Prevent "navigation duplicated" error (do not push to the same route)
	if (pageId === currentPageId.value) {
		return;
	}

	dispatch('updateCurrentPageId', pageId);
};

const closeBlockEditPopupHandler = () => {
	dispatch('leaveBlockEditMode');
};
</script>
