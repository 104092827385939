import axios from '@/services/http/axios';

const ECOMMERCE_API = `${import.meta.env.VITE_ECOMMERCE_API_URL}/admin/store`;

export const getSettings = async (storeId: string) => {
	const { data } = await axios.get<{
		status: {
			paymentsEnabled: boolean
		}
	}>(`${ECOMMERCE_API}/settings`, {
		headers: {
			'x-ecommerce-id': storeId,
		},
	});

	return data;
};
