<template>
	<BlockImageSlideshow
		ref="slideshowRef"
		:slides="slides"
		:is-loop-enabled="isLoopEnabled"
		:current-slide-index="currentSlideIndex"
		:slide-direction="slideDirection"
		:dot-color="dotColor"
		:is-navigation-arrows-visible="isNavigationArrowsVisible"
		:is-navigation-dots-visible="isNavigationDotsVisible"
		:arrow-color="arrowColor"
		:is-next-button-disabled="!canSlideToNext"
		:is-previous-button-disabled="!canSlideToPrevious"
		:desktop-min-height="desktopMinHeight"
		:mobile-min-height="mobileMinHeight"
		:website-id="websiteId"
		@transition-start="isTransitioning = true"
		@transition-end="isTransitioning = false"
		@mouseenter="clearAutoplayInterval"
		@mouseleave="resetAutoplayInterval"
		@next-slide="slideToNext"
		@previous-slide="slideToPrevious"
		@dot-click="slideTo"
	/>
	<BlockControls
		:block-id="blockId"
		:target-ref="slideshowRef?.$el"
		:is-first-block="isFirstBlock"
		:is-last-block="isLastBlock"
		:transparent-header-height="transparentHeaderHeight"
		is-resize-handle-visible
	/>

	<BlockEditControls
		:block-id="blockId"
		:target-ref="slideshowRef?.$el"
		:enter-edit-mode-button-title="t('builder.editBlockButton.editSection')"
		is-duplicate-button-visible
		is-delete-button-visible
		is-reordering-button-visible
		is-visibility-button-visible
	>
		<template #additional-edit-buttons>
			<template v-if="!isMobileScreen">
				<VerticalSeparator />
				<div
					v-qa="'builder-section-btn-editslideshow'"
					class="slideshow-controls"
				>
					<HostingerButton
						button-type="plain"
						theme="highlight"
						@click="previousSlideshowSlide"
					>
						<template #icon>
							<Icon name="chevron_left" />
						</template>
					</HostingerButton>
					<HostingerButton
						button-type="plain"
						theme="highlight"
						class="slideshow-controls__button-slideshow-manage"
						@click="isSlideshowModalShown = true"
					>
						<span class="text-body-2">{{ slideshowSlideName }}</span>
					</HostingerButton>
					<HostingerButton
						button-type="plain"
						theme="highlight"
						@click="nextSlideshowSlide"
					>
						<template #icon>
							<Icon name="chevron_right" />
						</template>
					</HostingerButton>
				</div>
			</template>

			<template v-else>
				<VerticalSeparator />
				<ControlsTooltip :title="$t('builder.editBlockButton.changeSlides')">
					<HostingerButton
						v-qa="'builder-section-btn-changeslides'"
						button-type="plain"
						theme="highlight"
						@click="isSlideshowModalShown = true"
					>
						<template #icon>
							<Icon name="add_photo_alternate" />
						</template>
					</HostingerButton>
				</ControlsTooltip>
			</template>
		</template>

		<template #edit-mode-popup>
			<EditBlockImageSlideshowSettingsTabs @close="closeBlockEditPopupHandler" />
		</template>
	</BlockEditControls>
	<Teleport
		v-if="isSlideshowModalShown"
		to="body"
	>
		<AssetManager
			is-slideshow
			:visible-categories="[ASSETS_CATEGORY_IMAGE]"
			:slideshow-id="blockId"
			@select-image="addSlidesToSlideshow([$event])"
			@select-images="addSlidesToSlideshow"
			@close="isSlideshowModalShown = false"
		/>
	</Teleport>
</template>

<script setup>
import {
	ref,
	computed,
	onMounted,
	onUnmounted,
	toRefs,
} from 'vue';
import { useStore } from 'vuex';

import { useI18n } from 'vue-i18n';

import AssetManager from '@/components/builder-modals/modals/AssetManager.vue';
import Icon from '@/components/global/Icon.vue';
import BlockEditControls from '@/components/builder-controls/BlockEditControls.vue';
import BlockControls from '@/components/block/BlockControls.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import ControlsTooltip from '@/components/ControlsTooltip.vue';
import VerticalSeparator from '@/components/global/VerticalSeparator.vue';
import BlockImageSlideshow from '@zyro-inc/site-modules/components/blocks/slideshow/BlockImageSlideshow.vue';
import EditBlockImageSlideshowSettingsTabs from '@/components/builder-controls/edit-block-image-slideshow/EditBlockImageSlideshowSettingsTabs.vue';
import { updateAiGeneratedImagePath } from '@/utils/urlValidators';

import { useImageSlideshow } from '@zyro-inc/site-modules/components/blocks/slideshow/useImageSlideshow';

import { ASSETS_CATEGORY_IMAGE } from '@/constants';

const props = defineProps({
	blockId: {
		type: String,
		required: true,
	},
	data: {
		type: Object,
		required: true,
	},
	isFirstBlock: {
		type: Boolean,
		default: false,
	},
	isLastBlock: {
		type: Boolean,
		default: false,
	},
	transparentHeaderHeight: {
		type: Number,
		default: 0,
	},
});

const { data } = toRefs(props);
const {
	dispatch,
	state,
} = useStore();

const { t } = useI18n();

const isSlideshowModalShown = ref(false);
const slideshowRef = ref(null);
const isTransitioning = ref(false);
const websiteId = ref(state.websiteId);

const isMobileScreen = computed(() => state.gui.isMobileScreen);
const slides = computed(() => data.value.slides);
const isLoopEnabled = computed(() => data.value.isLoopEnabled);
const isAutoplayEnabled = computed(() => data.value.isAutoplayEnabled);
const autoplayInterval = computed(() => data.value.autoplayInterval);
const dotColor = computed(() => data.value.settings.styles.navigationDotsColor);
const arrowColor = computed(() => data.value.settings.styles.navigationArrowsColor);
const isNavigationDotsVisible = computed(() => data.value.isNavigationDotsVisible);
const isNavigationArrowsVisible = computed(() => data.value.isNavigationArrowsVisible);
const desktopMinHeight = computed(() => data.value.desktop.minHeight);
const mobileMinHeight = computed(() => data.value.mobile.minHeight);

const {
	slideDirection,
	currentSlideIndex,
	slideshowRefs,
	canSlideToNext,
	canSlideToPrevious,
	slideToNext,
	slideToPrevious,
	slideTo,
	slideCount,
	resetAutoplayInterval,
	clearAutoplayInterval,
} = useImageSlideshow({
	isAutoplayEnabled,
	isLoopEnabled,
	autoplayInterval,
	isTransitioning,
	slides,
});

const addSlides = (newSlides) => {
	dispatch('updateBlockData', {
		blockId: props.blockId,
		blockData: {
			slides: [
				...slides.value,
				...newSlides,
			],
		},
		merge: true,
	});
};

const addSlidesToSlideshow = (newSlides) => {
	const preparedSlides = newSlides.map(({
		url,
		path,
		...rest
	}) => {
		const validPath = updateAiGeneratedImagePath(url, path);

		return {
			url,
			path: validPath,
			...rest,
		};
	});

	slideshowRefs.value[props.blockId].addSlides(preparedSlides);
};

const slideshowSlideName = computed(() => {
	if (slideshowRefs.value[props.blockId].slideCount === 0) {
		return t('common.noSlides');
	}

	return `${t('common.slide')} ${slideshowRefs.value[props.blockId].currentSlideNumber}`;
});

const previousSlideshowSlide = () => {
	const slideshow = slideshowRefs.value[props.blockId];

	if (slideshow.canSlideToPrevious) {
		slideshow.slideToPrevious();
	}
};

const nextSlideshowSlide = () => {
	const slideshow = slideshowRefs.value[props.blockId];

	if (slideshow.canSlideToNext) {
		slideshow.slideToNext();
	}
};

const closeBlockEditPopupHandler = () => {
	dispatch('leaveBlockEditMode');
};

onMounted(() => {
	// Builder specfic
	// Props & methods that we want to access in other scopes using useImageSlideshow() composable
	slideshowRefs.value[`${props.blockId}`] = {
		canSlideToNext,
		canSlideToPrevious,
		slideToNext,
		slideToPrevious,
		slideTo,
		slideCount,
		currentSlideNumber: computed(() => currentSlideIndex.value + 1),
		addSlides,
	};
});

onUnmounted(() => {
	delete slideshowRefs.value[`${props.blockId}`];
});

</script>

<style lang="scss" scoped>
.slideshow-controls {
		display: flex;
		background-color: $color-light;
		border-width: 2px;
		border-radius: 100px;
		transition-property:
			border-color,
			box-shadow,
			color;

	&__button-slideshow-manage {
		padding: 0 12px;
	}

}
</style>
