<template>
	<Integration
		:integration-key="INTEGRATION_KEY_FACEBOOK_MESSENGER"
		:is-footer-visible="v$.locale.$anyDirty"
		icon="messenger"
		class="integration-facebook-messenger"
		:name="$t('siteSettings.integrationFacebookMessengerName')"
		:input-label="$t('siteSettings.integrationFacebookMessengerLabel')"
		:input-placeholder="$t('siteSettings.integrationFacebookMessengerExample')"
		@reset="resetLocale"
		@submit="submitLocale"
	>
		<ZyroNotification type="warning">
			<template #message>
				<i18n-t
					tag="span"
					keypath="siteSettings.integrationFacebookMessengerDeprecation"
				>
					<a
						class="z-link"
						href="https://support.hostinger.com/en/articles/6454541-website-builder-how-to-connect-messenger-live-chat"
					>
						{{ $t('siteSettings.integrationFacebookMessengerDeprecationLink') }}
					</a>
				</i18n-t>
			</template>
		</ZyroNotification>

		<IntegrationInfoText
			v-if="!isHostingerBrand"
			translation-key="siteSettings.integrationFacebookMessengerStep1Message"
			:link-href="$t('siteSettings.integrationFacebookMessengerStep1LinkHref')"
			link-text="siteSettings.integrationFacebookMessengerStep1LinkText"
		/>

		<IntegrationInfoText
			v-if="!isHostingerBrand"
			translation-key="siteSettings.integrationFacebookMessengerStep2Message"
			:link-href="$t('siteSettings.integrationFacebookMessengerStep2LinkHref')"
			link-text="siteSettings.integrationFacebookMessengerStep2LinkText"
		/>

		<template #input-suffix>
			<ZyroSelect
				v-qa="'messenger-locale-select'"
				class="messenger-locale-select"
				:options="MESSENGER_LOCALES"
				label-key="title"
				:model-value="locale"
				@update:model-value="locale = $event, v$.locale.$touch()"
			/>
		</template>
	</Integration>
</template>

<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';

import {
	MESSENGER_LOCALES,
	DEFAULT_MESSENGER_LOCALE,
} from '@/components/site-settings/pages/integrations/facebookMessengerLocales';

import ZyroNotification from '@/components/site-settings/components/ZyroNotification.vue';
import Integration from '@/components/site-settings/pages/integrations/Integration.vue';
import IntegrationInfoText from '@/components/site-settings/pages/integrations/components/IntegrationInfoText.vue';
import { INTEGRATION_KEY_FACEBOOK_MESSENGER } from '@/constants';
import { isHostingerBrand } from '@/utils/isHostingerBrand';
import ZyroSelect from '@/components/global/ZyroSelect.vue';
import { useSiteStore } from '@/stores/siteStore';

export default defineComponent({
	components: {
		Integration,
		ZyroSelect,
		IntegrationInfoText,
		ZyroNotification,
	},

	setup() {
		const siteStore = useSiteStore();

		return {
			v$: useVuelidate(),
			isHostingerBrand,
			MESSENGER_LOCALES,
			INTEGRATION_KEY_FACEBOOK_MESSENGER,
			siteStore,
		};
	},

	validations() {
		return {
			locale: {},
		};
	},

	data() {
		return {
			locale: null,
		};
	},

	computed: {
		...mapGetters(['siteMeta']),
		currentMessengerLocaleFromMeta() {
			const activeLocale = MESSENGER_LOCALES.find(({ value }) => this.siteMeta.facebookMessengerLocale === value);

			return activeLocale || DEFAULT_MESSENGER_LOCALE;
		},
	},

	beforeMount() {
		this.locale = this.currentMessengerLocaleFromMeta || DEFAULT_MESSENGER_LOCALE;
	},

	methods: {
		submitLocale() {
			this.siteStore.setSiteMetaData({
				key: 'facebookMessengerLocale',
				value: this.locale.value,
			});
			this.v$.$reset();
		},
		resetLocale() {
			this.locale = this.currentMessengerLocaleFromMeta;
			this.v$.$reset();
		},
	},
});
</script>
<style lang="scss" scoped>
.messenger-locale-select {
	width: 100%;
	min-width: 150px;
	height: 54px;
	background-color: transparent;
	border: 1px solid $color-gray-border;
	border-radius: 8px;

	:deep(.vs__dropdown-menu) {
		margin-top: 8px;
		min-width: 150px;
		max-height: 140px;
		border-radius: 8px;
		border: 1px solid $color-gray-border;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 8%);
	}
}
</style>
