<script setup lang="ts">
import Icon from '@/components/global/Icon.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import ZyroSvgDeprecated from '@/components/global/ZyroSvgDeprecated.vue';

import EmptyPagesBlock from '@/components/builder-controls/multipage-drawer/EmptyPagesBlock.vue';
import AssetManager from '@/components/builder-modals/modals/AssetManager.vue';
import {
	ASSETS_CATEGORY_DOCUMENT,
	ASSETS_CATEGORY_IMAGE,
} from '@/constants';
import { getAssetNameFromUrl } from '@/use/useAssets';

import { useI18n } from 'vue-i18n';

import {
	ref,
	computed,
} from 'vue';

type Props = {
	href: string | null,
}

const props = withDefaults(defineProps<Props>(), {
	href: null,
});

const emit = defineEmits([
	'update:href',
	'update:target',
]);

const isAssetManagerOpen = ref(false);

const { t } = useI18n();

const selectDocumentButtonText = computed(() => (props.href ? t('builder.linkSettingsChangeFile') : t('builder.linkSettingsSelectFile')));

const closeAssetManager = () => {
	isAssetManagerOpen.value = false;
};

const selectImage = (event = {
	url: null,
}) => {
	emit('update:href', event.url);
	closeAssetManager();
};

const clearLink = () => {
	emit('update:href', null);
	emit('update:target', null);
};

</script>
<template>
	<div class="link-settings-file-download">
		<div
			v-if="href"
			class="link-settings-file-download__selected-file"
		>
			<ZyroSvgDeprecated
				name="document"
				class="link-settings-file-download__selected-file-document-icon"
			/>

			<p class="link-settings-file-download__selected-file-name text-body-2">
				{{ getAssetNameFromUrl(href, true) }}
			</p>

			<HostingerButton
				class="link-settings-file-download__selected-file-x-icon"
				button-type="plain"
				theme="highlight"
				@click="clearLink"
			>
				<template #icon>
					<Icon name="close" />
				</template>
			</HostingerButton>
		</div>

		<EmptyPagesBlock
			v-else
			class="link-settings-file-download__empty-pages-block"
			:subtitle="$t('builder.linkSettingsNoFileSelected')"
		/>

		<div class="link-settings-file-download__select-file-container">
			<HostingerButton
				v-qa="'button-select-file'"
				@click="isAssetManagerOpen = true"
			>
				{{ selectDocumentButtonText }}
			</HostingerButton>
		</div>

		<Teleport
			v-if="isAssetManagerOpen"
			to="body"
		>
			<AssetManager
				:visible-categories="[ASSETS_CATEGORY_DOCUMENT, ASSETS_CATEGORY_IMAGE]"
				@update-target="$emit('update:target', '_blank')"
				@select-image="selectImage"
				@close="closeAssetManager"
			/>
		</Teleport>
	</div>
</template>

<style lang="scss" scoped>
.link-settings-file-download {
	&__selected-file {
		display: flex;
		align-items: center;
		padding: 16px 3px 16px 16px;
		border-bottom: 1px solid $color-gray-light;
	}

	&__selected-file-document-icon {
		flex-shrink: 0;
		margin-right: 18px;
	}

	&__selected-file-name {
		flex-grow: 1;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: pre;
	}

	&__empty-pages-block {
		margin-right: 0;
		margin-left: 0;
		border-radius: 8px;
	}

	&__selected-file-x-icon {
		flex-shrink: 0;
	}

	&__select-file-container {
		display: flex;
		justify-content: center;
		margin-top: 16px;
		margin-bottom: 16px;
	}
}
</style>
