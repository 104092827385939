<script setup lang="ts">
import CollapsibleContainer from '@/components/CollapsibleContainer.vue';
import Icon from '@/components/global/Icon.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import ZyroFieldTextArea from '@/components/global/ZyroFieldTextArea.vue';
import ZyroPopupCard from '@/components/global/ZyroPopupCard.vue';
import NpsRateFeature from '@/components/ui/NpsRateFeature.vue';
import FeatureLockBanner from '@/components/FeatureLockBanner.vue';
import {
	AI_BLOG_DESCRIPTION_MAX_LENGTH,
	AI_IMAGE_GENERATE_DEFAULT_COUNT,
	MODAL_BUY_CREDITS,
	NPS_TYPE_FEATURE_AI_IMAGE_GENERATION,
	MODAL_UPGRADE_TO_BUSINESS_PLAN,
	ECOMMERCE_FUNNEL_LOCATIONS,
} from '@/constants';
import {
	onUnmounted,
	onMounted,
	computed,
	ref,
} from 'vue';
import {
	useAiImageGenerator,
	StylePresetType,
} from '@/use/useAiImageGenerator';
import AiImageGenerationImagePreview from '@/components/builder-controls/AiImageGenerationImagePreview.vue';
import AiImageGenerationStyleList from '@/components/builder-controls/AiImageGenerationStyleList.vue';
import { useStore } from 'vuex';
import EventLogApi from '@/api/EventLogApi';
import SegmentedProgressBar from '@/components/global/SegmentedProgressBar.vue';
import { useI18n } from 'vue-i18n';
import { useUserCredits } from '@/use/useUserCredits';
import {
	useAiToolsStore,
	useUserStore,
} from '@/stores';
import {
	GUI_NAMESPACE,
	OPEN_MODAL,
} from '@/store/builder/gui';

const MIN_DESCRIPTION_LENGTH = 20;

interface Props {
	shouldAddImageElement?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	shouldAddImageElement: false,
});

defineEmits<{
	close: [];
}>();

const {
	generateImages,
	isGeneratingImages,
	generatedImageSrcList,
	resetImageGenerator,
} = useAiImageGenerator();

const {
	state,
	dispatch,
} = useStore();

const aiToolsStore = useAiToolsStore();

const {
	t,
	locale,
} = useI18n();
const userStore = useUserStore();

const isCurrentEnLocale = computed(() => (locale.value || locale) === 'en');

const imagesToGenerate = ref(0);
const { userCredits } = useUserCredits();
const imageCountToGenerate = computed(() => {
	if (!userCredits.value || userCredits.value < 1) {
		return 0;
	}

	if (userCredits.value < AI_IMAGE_GENERATE_DEFAULT_COUNT) {
		return userCredits.value;
	}

	return AI_IMAGE_GENERATE_DEFAULT_COUNT;
});

const imageDescription = ref('');
const showExample = ref(false);
const stylePreset = ref<StylePresetType>('enhance');
const isImageGenerationDisabled = computed(() => isGeneratingImages.value || imageDescription.value.length < MIN_DESCRIPTION_LENGTH);
const isShowingGenerateTab = computed(() => !(isGeneratingImages.value || generatedImageSrcList.value.length));
const areCreditsVisible = computed(() => isShowingGenerateTab.value && !userStore.areFeaturesLocked);

const handleBuyCredits = () => {
	EventLogApi.logEvent({
		eventName: 'website_builder.ai_image_generator.buy_more',
	});

	dispatch(`${GUI_NAMESPACE}/${OPEN_MODAL}`, {
		name: MODAL_BUY_CREDITS,
	});
};

const toggleShowExample = () => {
	showExample.value = !showExample.value;
};

const handleStyleSelect = (newStyle: StylePresetType) => {
	stylePreset.value = newStyle;
};

const handleCreateImageButtonClick = () => {
	if (userStore.areFeaturesLocked) {
		dispatch(`gui/${OPEN_MODAL}`, {
			name: MODAL_UPGRADE_TO_BUSINESS_PLAN,
			settings: {
				location: ECOMMERCE_FUNNEL_LOCATIONS.AI_IMAGE_GENERATION_POPUP,
			},
		});

		return;
	}

	if (!userCredits.value || userCredits.value < 1) {
		handleBuyCredits();

		return;
	}

	imagesToGenerate.value = imageCountToGenerate.value;

	generateImages({
		description: imageDescription.value,
		stylePreset: stylePreset.value,
		shouldAddImageElement: props.shouldAddImageElement,
		imageCount: imageCountToGenerate.value,
	});

	EventLogApi.logEvent({
		eventName: 'website_builder.ai_image_generator.create',
		eventProperties: {
			location: state.currentElementId ? 'element' : 'section',
			description: imageDescription.value,
			image_style: stylePreset.value,
		},
	});
};

const handleGenerateAgain = () => {
	imagesToGenerate.value = 0;
	resetImageGenerator();

	handleCreateImageButtonClick();
};

const progress = computed(() => Math.floor((imageDescription.value.length / 10)));

const progressColors = computed(() => ([
	'var(--color-danger)',
	'var(--color-success)',
]));

const progressHints = computed(() => ([
	t('builder.aiImageValidationHint1'),
	t('builder.aiImageValidationHint2'),
	t('builder.aiImageValidationHint3'),
]));

onMounted(() => {
	if (aiToolsStore.getAiToolsPrompt) {
		imageDescription.value = aiToolsStore.getAiToolsPrompt;

		aiToolsStore.clearAiToolsPrompt();
	}
});

onUnmounted(() => {
	resetImageGenerator();
});

</script>

<template>
	<Teleport to="body">
		<ZyroPopupCard
			id="ai-image-generator-popup"
			is-select-allowed
			editor-popup-width="420px"
			max-width="500px"
			type="editor"
			:title="$t('builder.aiImageGenerationTitle')"
			class="image-generator"
			@close="$emit('close')"
		>
			<div
				v-if="isShowingGenerateTab"
				class="image-generator__popup-content"
			>
				<div class="image-generator__heading-container">
					<p class="text-bold-2">
						{{ $t('builder.aiImageGenerationImageDescription') }}
					</p>
					<p
						v-if="!isCurrentEnLocale"
						class="text-body-3 image-generator__description-subtitle"
					>
						{{ $t('builder.aiImageGenerationUseEnglish') }}
					</p>
				</div>
				<ZyroFieldTextArea
					v-model="imageDescription"
					class="image-generator__description-textarea"
					theme="hostinger"
					bold
					:placeholder="$t('builder.aiImageGenerationExampleDescription')"
					:min-height="120"
					is-resizable
					:maxlength="AI_BLOG_DESCRIPTION_MAX_LENGTH"
				/>
				<SegmentedProgressBar
					is-progress-kept
					:progress="progress"
					:colors="progressColors"
					:hints="progressHints"
				/>
				<div class="image-generator__example-button-container">
					<HostingerButton
						:title="$t('common.seeExamples')"
						class="image-generator__example-button"
						button-type="plain"
						@click="toggleShowExample"
					>
						<template #icon-left>
							<Icon
								name="lightbulb"
								is-filled
								dimensions="16px"
							/>
						</template>

						<span>{{ $t('common.seeExamples') }}</span>

						<template #icon-right>
							<Icon
								name="chevron_right"
								dimensions="16px"
								:direction="showExample ? 'right' : undefined"
							/>
						</template>
					</HostingerButton>
					<CollapsibleContainer
						:is-visible="showExample"
						max-height="200px"
					>
						<div class="image-generator__example-text text-body-3">
							<p>
								{{ $t('builder.aiImageGenerationExampleText') }}
							</p>
							<ul>
								<li>{{ $t('builder.aiImageGenerationExampleListItem0') }}</li>
								<li>{{ $t('builder.aiImageGenerationExampleListItem1') }}</li>
								<li>{{ $t('builder.aiImageGenerationExampleListItem2') }}</li>
							</ul>
						</div>
					</CollapsibleContainer>
				</div>
				<AiImageGenerationStyleList @select-style="handleStyleSelect" />
			</div>
			<AiImageGenerationImagePreview
				v-else
				:image-count-to-generate="imagesToGenerate"
				:should-add-image-element="shouldAddImageElement"
				:is-image-generation-disabled="isImageGenerationDisabled"
				:generated-image-src-list="generatedImageSrcList"
				:is-generating-images="isGeneratingImages"
				@generate-again="handleGenerateAgain"
				@close="$emit('close')"
				@back="resetImageGenerator"
			>
				<template #credit-info>
					<div class="credits text-body-3">
						<p
							v-if="userCredits && userCredits < 1"
							class="credits__count"
						>
							{{ $t('builder.aiImageGeneratorNoCredits') }}
						</p>
						<i18n-t
							v-else
							tag="p"
							class="credits__count"
							keypath="builder.aiImageGeneratorCreditsUsage"
						>
							<b>
								{{ imageCountToGenerate }}
							</b>
							<b>
								{{ userCredits }}
							</b>
						</i18n-t>
						<div
							class="credits__button"
							@click="handleBuyCredits"
						>
							{{ $t('common.buyMore') }}
						</div>
					</div>
				</template>
			</AiImageGenerationImagePreview>
			<div class="image-generator__popup-footer">
				<HostingerButton
					v-if="isShowingGenerateTab"
					class="image-generator__create-image-button"
					:is-disabled="isImageGenerationDisabled"
					@click="handleCreateImageButtonClick"
				>
					<template #icon>
						<Icon
							name="auto_awesome"
							is-filled
						/>
					</template>
					{{ $t('builder.aiImageGenerationCreateImages') }}
				</HostingerButton>
				<div
					v-if="areCreditsVisible"
					class="credits text-body-3"
				>
					<p
						v-if="userCredits && userCredits < 1"
						class="credits__count"
					>
						{{ $t('builder.aiImageGeneratorNoCredits') }}
					</p>
					<i18n-t
						v-else
						tag="p"
						class="credits__count"
						keypath="builder.aiImageGeneratorCreditsUsage"
					>
						<b>
							{{ imageCountToGenerate }}
						</b>
						<b>
							{{ userCredits }}
						</b>
					</i18n-t>
					<div
						class="credits__button"
						@click="handleBuyCredits"
					>
						{{ $t('common.buyMore') }}
					</div>
				</div>
				<p
					v-if="areCreditsVisible"
					class="text-body-3 image-generator__disclaimer"
				>
					{{ $t('builder.aiOutputsMightBeMisleading') }}
				</p>
				<FeatureLockBanner
					v-if="userStore.areFeaturesLocked"
					class="image-generator__feature-lock-banner"
				/>
				<NpsRateFeature
					:feature-name="$t('builder.aiImageGenerationTitle')"
					:type="NPS_TYPE_FEATURE_AI_IMAGE_GENERATION"
					class="image-generator__nps"
				/>
			</div>
		</ZyroPopupCard>
	</Teleport>
</template>

<style scoped lang="scss">
$popup-margin-md: 16px;

.image-generator {
  position: fixed;
  top: calc($header-height-editor + $popup-margin-md);
  bottom: $popup-margin-md;
  right: $popup-margin-md;
  overflow: hidden;
	display: flex;
	flex-direction: column;
	width: 360px;

	:deep(.bar) {
		width: calc(360px - 2 * $popup-margin-md - 1px);
	}

	:deep(.popup-card__head) {
		border: none;
		padding-bottom: 0;
	}

	:deep(.popup-card__content) {
		overflow-y: auto;
		display: flex;
		flex-direction: column;
		margin-right: -$popup-margin-md;
		height: 100%;
	}

	&__disclaimer {
		color: var(--color-gray);
		text-align: center;
		margin-bottom: 16px;
	}

	&__popup-content {
		overflow-y: auto;
	}

	&__popup-footer {
		padding-right: $popup-margin-md;
	}

	&__heading-container {
		margin-bottom: 8px;
	}

	&__description-subtitle {
		color: $color-gray;
	}

	&__description-textarea {
		width: calc(360px - 2 * $popup-margin-md - 1px);
		margin-bottom: 8px;

		:deep(textarea) {
			background-color: $color-gray-light;

			&::placeholder {
				color: $color-gray;
			}
		}
	}

	&__example-button-container {
		margin-bottom: $popup-margin-md;
	}

	&__example-button {
		color: $color-primary;
		border: none;
		margin: 4px 0;

		:deep(.hostinger-button__text) {
			margin: 0 4px;
			font-weight: 400;
		}

		&:focus, &:hover {
			color: $color-primary;
			background-color: $color-light;
			border: none;
		}
	}

	&__example-text {
		display: flex;
		flex-direction: column;
		gap: 8px;
		color: $color-gray;
		padding: 12px 16px;
		margin-right: 16px;
		border: 1px solid $color-gray-border;
		border-radius: 4px;

		& li {
			list-style-position: inside;
		}
	}

	&__create-image-button {
		margin: 16px 0 8px;
		width: calc(100% + 2px);
		height: 40px;
	}

	&__nps {
		width: 100%;
	}

	&__feature-lock-banner {
		margin: 8px 0 16px
	}

	@media screen and (max-width: $media-mobile) {
		top: unset;
		left: 0;
		bottom: 0;
		min-height: calc(50vh + $builder-preview-bottom-bar-height);
		width: 100%;

		:deep(.bar) {
			width: 100%;
		}

		&--loading {
			bottom: $builder-preview-bottom-bar-height !important;
		}

		// Slightly bigger tooltip than default so that style selection would be visible
		// otherwise style selection is behind the fold
		:deep(.popup-card) {
			min-height: calc(50vh + $builder-preview-bottom-bar-height);
		}

		:deep(.popup-card__content) {
			max-height: unset;
			margin-right: unset;
		}

		&__description-textarea {
			width: 100%;
		}

		&__create-image-button {
			width: 100%;
		}
	}
}

.credits {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 4px;
	margin-bottom: 4px;

	&__count {
		color: var(--color-gray);
	}

	&__button {
		color: var(--color-primary);
		cursor: pointer;
	}
}
</style>
