<template>
	<ZyroModal
		max-width="350px"
		max-height="640px"
		:title="itemId ? t('common.linkSettings') : t('common.addNewLink')"
		class="add-link-modal"
		@close-modal="dispatch('gui/CLOSE_MODAL')"
	>
		<ZyroFieldInput
			v-model="name"
			:label="t('builder.pageSettingsModal.textFields.nameInNavigation.label')"
			qa-selector="linksettingsmodal-input-name"
			:placeholder="t('common.newLink')"
		/>
		<LinkSettings
			:href="href"
			:target="target"
			:rel="rel"
			:type="type"
			:page-id="pageId"
			@update:href="href = $event"
			@update:target="target = $event"
			@update:rel="rel = $event"
			@update:type="type = $event"
			@update:page-id="pageId = $event"
		/>
		<ZyroFieldToggle
			id="linksettings-hidden-toggle"
			v-model="isHidden"
			v-qa="`linksettingsmodal-hidepage-toggle-${isHidden ? 'hidden' : 'visible'}`"
			:label="t('common.hidePage')"
		/>
		<template #footer>
			<HostingerButton
				v-qa="'linksettingsmodal-btn-close'"
				button-type="text"
				@click="dispatch('gui/CLOSE_MODAL')"
			>
				{{ t('common.cancel') }}
			</HostingerButton>
			<HostingerButton
				v-qa="'linksettingsmodal-btn-submit'"
				@click="submitForm"
			>
				{{ t('common.save') }}
			</HostingerButton>
		</template>
	</ZyroModal>
</template>

<script setup lang="ts">
import HostingerButton from '@/components/global/HostingerButton.vue';
import ZyroFieldInput from '@/components/global/ZyroFieldInput.vue';
import ZyroFieldToggle from '@/components/global/ZyroFieldToggle.vue';
import ZyroModal from '@/components/global/ZyroModal.vue';
import { useStore } from 'vuex';
import {
	LINK_TYPE,
	LinkType,
} from '@zyro-inc/site-modules/constants';
import { NAVIGATION_TYPE_LINK } from '@/constants';
import LinkSettings from '@/components/builder-controls/edit-button/LinkSettings.vue';
import {
	ref,
	computed,
} from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const {
	state,
	getters,
	dispatch,
} = useStore();

const itemId = computed(() => state.gui.activeModalSettings.itemId);
const item = computed(() => getters['navigation/getItemById'](itemId.value));
const name = ref(item.value?.name ?? t('common.newLink'));
const href = ref(item.value?.href ?? '');
const target = ref(item.value?.target ?? '_self');
const rel = ref(item.value?.rel ?? '');
const type = ref<LinkType>(item.value?.innerLinkType ?? LINK_TYPE.PAGE);
const pageId = ref(item.value?.innerLinkPageId ?? '');
const isHidden = ref(item.value?.isHidden ?? false);

const submitForm = () => {
	if (itemId.value) {
		if (item.value.isHidden !== isHidden.value) {
			dispatch('navigation/changeItemVisibility', {
				itemId: itemId.value,
				isHidden: isHidden.value,
			});
		}

		dispatch('navigation/setItemData', {
			data: {
				navItemId: itemId.value,
				name: name.value,
				href: href.value,
				target: target.value,
				rel: rel.value,
				innerLinkType: type.value,
				innerLinkPageId: pageId.value,
				linkType: NAVIGATION_TYPE_LINK,
			},
		});
	} else {
		dispatch('navigation/addItem', {
			item: {
				name: name.value,
				href: href.value,
				target: target.value,
				rel: rel.value,
				innerLinkType: type.value,
				innerLinkPageId: pageId.value,
				linkType: NAVIGATION_TYPE_LINK,
			},
			isHidden: isHidden.value,
		});
	}

	dispatch('gui/CLOSE_MODAL');
};
</script>
