// eslint-disable-next-line no-restricted-imports
import axios, {
	type AxiosError,
	type AxiosRequestConfig,
	type AxiosResponse,
	type InternalAxiosRequestConfig,
} from 'axios';
import {
	getNormalisedRequestConfigProperties,
	getNormalisedErrorResponseProperties,
} from '@/services/http/getNormalisedAxiosProperties';
import { nanoid } from 'nanoid';

import { HEADER_X_CORRELATION_ID } from '@/constants';
import {
	addAxiosErrorBreadcrumb,
	logAxiosError,
} from '@/services/errorLogger';
// eslint-disable-next-line import/no-cycle
import { useAuthStore } from '@/stores/authStore';
import { useSiteStore } from '@/stores/siteStore';

const axiosInstance = axios.create({
	timeout: 150000,
	headers: {
		common: {
			Accept: 'application/json, text/plain, */*',
			'Content-Type': 'application/json',
		},
	},
});

export const correlationInterceptor = (req: InternalAxiosRequestConfig) => {
	if (!req.headers) {
		return req;
	}

	// eslint-disable-next-line no-param-reassign
	req.headers[HEADER_X_CORRELATION_ID] = nanoid(36);

	return req;
};

export const hResourceInterceptor = (req: InternalAxiosRequestConfig) => {
	const siteStore = useSiteStore();

	if (siteStore.hResourceId) {
		// eslint-disable-next-line no-param-reassign
		req.headers.hResourceId = siteStore.hResourceId;
	}

	return req;
};

export const authTokenInterceptor = async (req: InternalAxiosRequestConfig) => {
	if (req.isPublic) {
		return req;
	}

	const authStore = useAuthStore();

	await authStore.checkIfTokenIsRefreshing();

	const token = authStore.getBearerToken();
	const authToken = authStore.getAuthToken();

	if (!authToken) {
		return req;
	}

	if (req.headers) {
		// eslint-disable-next-line no-param-reassign
		req.headers.Authorization = token;
	}

	return req;
};

axiosInstance.interceptors.request.use(authTokenInterceptor);
axiosInstance.interceptors.request.use(correlationInterceptor);

axiosInstance.interceptors.request.use(hResourceInterceptor);

const requestRetry = async (request: AxiosRequestConfig) => {
	const newRequest = {
		...request,
		isRetryNeeded: false,
	};

	await axiosInstance(newRequest);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const authTokenRefresh = (requestConfig?: InternalAxiosRequestConfig) => {
	const config = requestConfig || {} as InternalAxiosRequestConfig;
	const authStore = useAuthStore();
	const tokenFromRequest = config.headers.Authorization as string;

	authStore.refreshToken(tokenFromRequest);

	// eslint-disable-next-line no-underscore-dangle
	config._retry = true;

	return axiosInstance(requestConfig as InternalAxiosRequestConfig);
};

axiosInstance.interceptors.response.use((response: AxiosResponse) => response, async (error: AxiosError) => {
	addAxiosErrorBreadcrumb(error);
	const requestConfig = error?.config;

	const { serviceName } = getNormalisedRequestConfigProperties(error?.config);
	const {
		message,
		status,
	} = getNormalisedErrorResponseProperties(error.response);

	const isTokenExpired = status === 401
		&& (message === 'jwt expired'
		|| serviceName !== import.meta.env.VITE_BACKEND_API_URL);

	// eslint-disable-next-line no-underscore-dangle
	const isRefreshNeeded = isTokenExpired && !requestConfig?._retry;

	if (isRefreshNeeded) {
		return authTokenRefresh(requestConfig);
	}

	if (requestConfig?.isRetryNeeded) {
		await requestRetry(requestConfig);
	}

	logAxiosError(error);

	return Promise.reject(error);
});

const axiosWrapper = {
	get<T>(url: string, config?: AxiosRequestConfig) {
		return axiosInstance.get<T>(url, config);
	},
	post<T>(url: string, data?: any, config?: AxiosRequestConfig) {
		return axiosInstance.post<T>(url, data, config);
	},
	put: (url: string, data?: any, config?: AxiosRequestConfig) => axiosInstance.put(url, data, config),
	patch: (url: string, data?: any, config?: AxiosRequestConfig) => axiosInstance.patch(url, data, config),
	delete: (url: string, config?: AxiosRequestConfig) => axiosInstance.delete(url, config),
	request: (config: AxiosRequestConfig) => axiosInstance(config),
	isCancel: (error: any) => axios.isCancel(error),
	CancelToken: axios.CancelToken,
	interceptors: axiosInstance.interceptors,
	defaults: axiosInstance.defaults,
};

export default axiosWrapper;
