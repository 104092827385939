import { useStore } from 'vuex';
import { GAMIFICATION_TASK_CHANGE_LOGO } from '@/constants';
import { useGamification } from '@/use/useGamification';

export const useUpdateLogo = () => {
	const {
		getters,
		dispatch,
	} = useStore();

	const { completeAchievement } = useGamification();

	const updateLogo = ({
		origin,
		path,
		fullResolutionHeight,
		fullResolutionWidth,
	}) => {
		const logoAspectRatio = fullResolutionWidth / fullResolutionHeight;
		const desktopLogoWidth = Math.ceil(Number.parseInt(getters.headerBlock.settings.styles?.['logo-width'], 10) / logoAspectRatio);
		const mobileLogoWidth = Math.ceil(Number.parseInt(getters.headerBlock.settings.styles?.['m-logo-width'], 10) / logoAspectRatio);

		dispatch('updateBlockData', {
			blockId: 'header',
			blockData: {
				...(logoAspectRatio ? {
					logoAspectRatio,
				} : {}),
				desktop: {
					...(desktopLogoWidth ? {
						logoHeight: desktopLogoWidth,
					} : {}),
				},
				mobile: {
					...(mobileLogoWidth ? {
						logoHeight: mobileLogoWidth,
					} : {}),
				},
				settings: {
					logoImagePath: path,
					logoImageOrigin: origin,
					logoSvg: null,
				},
			},
			merge: true,
		});

		completeAchievement(GAMIFICATION_TASK_CHANGE_LOGO);
	};

	return {
		updateLogo,
	};
};
