<script setup lang="ts">
import ZyroFieldToggle from '@/components/global/ZyroFieldToggle.vue';

import { useStore } from 'vuex';

import PageSearchPreview from '@/components/builder-controls/page-settings/PageSearchPreview.vue';
import { getPagePathFromId } from '@zyro-inc/site-modules/utils/page/getPagePathFromId';

import {
	computed,
	ref,
	onMounted,
	nextTick,
} from 'vue';
import { useI18n } from 'vue-i18n';
import AiSeoAssistantSection from '@/components/AiSeoAssistantSection.vue';
import PageSettingsSeoKeywords from '@/components/PageSettingsSeoKeywords.vue';
import PageSettingsSeoSlug from '@/components/PageSettingsSeoSlug.vue';
import PageSettingsSeoTitle from '@/components/PageSettingsSeoTitle.vue';
import PageSettingsSeoDescription from '@/components/PageSettingsSeoDescription.vue';
import PageSettingsSeoSuggestions from '@/components/PageSettingsSeoSuggestions.vue';
import { usePageSeoSettings } from '@/use/usePageSeoSettings';

interface Props {
	shouldScrollIntoSlugField: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	shouldScrollIntoSlugField: false,
});

const {
	state,
	getters,
} = useStore();
const { t } = useI18n();
const {
	pageMetaTitle,
	pageMetaDescription,
	pageMetaNoIndex,
	pageName,
	mergePageMeta,
} = usePageSeoSettings();

const slugSettingsRef = ref<InstanceType<typeof PageSettingsSeoSlug> | null>(null);
const currentPageId = computed(() => state.currentPageId);
const currentLanguageData = computed(() => getters.currentLanguageData);
const siteMetaTitle = computed(() => currentLanguageData.value.metaTitle);
const siteUrl = computed(() => getters.siteUrl);
const isHomepage = computed(() => getters.homePageId === currentPageId.value);

// Search preview
const searchPreviewTitle = computed(() => `${pageMetaTitle.value || pageName.value}${siteMetaTitle.value ? ` | ${siteMetaTitle.value}` : ''}`);

const searchPreviewUrl = computed(() => {
	const url = getPagePathFromId({
		siteData: state.website,
		pageId: currentPageId.value,
		locale: state.currentLocale,
	}) || '';

	return `${siteUrl.value}${url}`;
});
const searchPreviewDescription = computed(() => pageMetaDescription.value || t('builder.pageSettingsModal.metaDescriptionPlaceholder'));

// Meta noindex
const shouldPageBeNoIndexed = computed(() => pageMetaNoIndex.value || false);
const updateShouldPageBeNoIndexed = (noindexValue: boolean) => {
	mergePageMeta({
		noindex: noindexValue,
	});
};

onMounted(async () => {
	if (props.shouldScrollIntoSlugField) {
		await nextTick();

		slugSettingsRef.value?.$el?.scrollIntoView({
			behavior: 'smooth',
		});
	}
});
</script>

<template>
	<div class="page-settings-seo-general">
		<AiSeoAssistantSection
			:page-id="currentPageId"
			:page-name="pageName"
		/>

		<PageSearchPreview
			class="page-settings-seo-general__search-preview"
			:title="searchPreviewTitle"
			:url="searchPreviewUrl"
			:description="searchPreviewDescription"
			is-full-width
		/>
		<p class="page-settings-seo-general__search-preview-disclaimer-text">
			{{ $t('builder.pageSettingsModal.googleSeoUpdateMessageV2') }}
		</p>

		<div class="page-settings-seo-general__item">
			<ZyroFieldToggle
				id="page-noindex"
				v-qa="'pagesettings-inputtoggle-noindex'"
				class="page-settings-seo-general__noindex-toggle"
				:model-value="shouldPageBeNoIndexed"
				:label="$t('siteSettings.seo.toggleFields.noindex.label')"
				@update:model-value="updateShouldPageBeNoIndexed"
			/>

			<PageSettingsSeoKeywords />
		</div>

		<div class="page-settings-seo-general__item">
			<PageSettingsSeoTitle />
		</div>

		<div class="page-settings-seo-general__item">
			<PageSettingsSeoDescription />
		</div>

		<div class="page-settings-seo-general__item">
			<PageSettingsSeoSlug
				v-if="!isHomepage"
				ref="slugSettingsRef"
			/>
		</div>

		<PageSettingsSeoSuggestions />
	</div>
</template>

<style lang="scss" scoped>
.page-settings-seo-general {
	&__item {
		&:not(:last-child) {
			margin-bottom: 32px;
		}
	}

	&__search-preview {
		margin-bottom: 8px;
	}

	&__search-preview-disclaimer-text {
		margin-bottom: 16px;
		font-size: 12px;
		font-weight: 400;
		line-height: 1.67;
		color: $color-gray;
	}

	&__char-counter {
		position: absolute;
		top: 8px;
		right: 0;
		color: $color-gray;
	}

	&__input-message {
		margin-top: 8px;
		font-size: 13px;
		color: $color-gray;
	}

	&__noindex-toggle {
		margin: 16px 0;
	}

	:deep() {
		.zyro-input__input,
		.zyro-textarea {
			border-radius: 8px;
		}

		.zyro-label {
			margin-top: 0;
		}
	}
}

.status-icon {
    flex-shrink: 0;
	color: $color-warning-dark;
	margin-right: 8px;

	&--completed {
		color: $color-success;
	}
}

.collapsible-header {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-weight: 400;
}

:deep() {
	.collapsible {
		border: 1px solid $color-gray-border;
		border-radius: 8px;
		overflow: hidden;

		&__header {
			padding: 12px 16px;
		}
	}

	.toggle {
		right: 0;
	}
}

ul {
	list-style: none;
}

.checker-list-item {
	display: flex;
	align-items: top;
}

.icon-wrapper {
	display: flex;
}
</style>
