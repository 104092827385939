export const HRESOURCE_TYPES = {
	FREE_DOMAIN: 'free_domain',
	WEBSITE_BUILDER: 'website_builder',
	HOSTING: 'hosting',
	VIRTUAL_MACHINE: 'virtual_machine',
	DOMAIN: 'domain',
	DOMAIN_TRANSFER: 'domain_transfer',
	SSL: 'ssl',
	GOOGLE_WORKSPACE: 'google_workspace',
	PRIORITY_SUPPORT: 'priority_support',
	SEO: 'seo',
	TITAN_MAIL: 'titan_mail',
	EMAIL: 'email',
	RANKING_COACH: 'ranking_coach',
	ZYRO: 'zyro',
	CPANEL_HOSTING: 'cpanel_hosting',
	CPANEL_RESELLER_HOSTING: 'cpanel_reseller_hosting',
} as const;
export type HResourceTypes = (typeof HRESOURCE_TYPES)[keyof typeof HRESOURCE_TYPES];

export const HRESOURCE_STATES = {
	CREATED: 'created',
	PENDING_SETUP: 'pending_setup',
	ACTIVATING: 'activating',
	ACTIVE: 'active',
	UPDATING: 'updating',
	SUSPENDING: 'suspending',
	SUSPENDED: 'suspended',
	UNSUSPENDING: 'unsuspending',
	DELETING: 'deleting',
	DELETED: 'deleted',
	COMPLETED: 'completed',
	CANCELED: 'canceled',
	FAILED: 'failed',
} as const;
export type HResourceStates = (typeof HRESOURCE_TYPES)[keyof typeof HRESOURCE_TYPES];

export type HostingResource = {
	id: number;
	customer_custom_id: string,
  idempotency_key: string,
  reference_id: string,
  related_resource_id: number,
	chargebee_subscription_id: string,
	type: HResourceTypes,
	state: HResourceStates,
	reason: string,
	title: string,
	config: Record<string, unknown>[],
	metadata: Record<string, unknown>[],
	expires_at: string,
	addons: {
		id: number,
		type: string,
		config: Record<string, unknown>[],
		state: HResourceStates,
	}[],
	items: {
		id: number,
		resource_id: number,
		type: string,
		state: HResourceStates,
		domain: string,
		config: Record<string, unknown>[],
		created_at: string,
		updated_at: string,
	}[],
	activated_at: string,
	suspended_at: string,
	created_at: string,
	updated_at: string,
};
