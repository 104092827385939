<template>
	<div class="blog-buttons">
		<HostingerButton
			v-qa="'builder-header-btn-postsettings'"
			button-type="plain"
			:title="$t('common.postSettings')"
			class="builder-header__separator margin-x-4"
			@click="openModal({
				name: 'BlogPostSettingsModal',
				settings: { blogPostId: currentPageId },
			})"
		>
			<template #icon-left>
				<Icon name="settings" />
			</template>
			{{ $t('common.postSettings') }}
		</HostingerButton>
		<div class="builder-header__separator" />
		<HostingerButton
			v-qa="`builder-header-btn-status-${blogPostStatusText}`"
			button-type="plain"
			class="margin-x-4"
			@click="handleStatusClick"
		>
			<template #icon-right>
				<Icon :name="iconName" />
			</template>
			{{ $t('common.status') }}: {{ blogPostStatusText }}
		</HostingerButton>
	</div>
</template>
<script setup>
import HostingerButton from '@/components/global/HostingerButton.vue';
import Icon from '@/components/global/Icon.vue';
import { useStore } from 'vuex';
import {
	GUI_NAMESPACE,
	OPEN_MODAL,
} from '@/store/builder/gui';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const {
	dispatch,
	state,
	getters,
} = useStore();
const { t } = useI18n();

const currentPageId = computed(() => state.currentPageId);
const currentPage = computed(() => getters.currentPage);
const isScheduledPost = computed(() => currentPage.value?.isScheduled ?? false);
const isDraftPost = computed(() => currentPage.value?.isDraft ?? false);
const blogPostStatusText = computed(() => {
	if (isScheduledPost.value) {
		return t('common.scheduled');
	}

	if (isDraftPost.value) {
		return t('common.draft');
	}

	return t('common.public');
});
const iconName = computed(() => {
	if (isScheduledPost.value) {
		return 'av_timer';
	}

	return isDraftPost.value ? 'visibility_off' : 'visibility';
});

const openModal = ({
	name,
	settings,
}) => {
	dispatch(`${GUI_NAMESPACE}/${OPEN_MODAL}`, {
		name,
		settings,
	});
};

const handleStatusClick = async () => {
	if (!currentPage.value?.isScheduled) {
		dispatch('blog/toggleBlogPostVisibility', currentPageId.value);
		// additionally save website on status changes to prevent nasty UI saving button jumps
		await dispatch('saving/saveWebsite');
	}
};
</script>
<style lang="scss" scoped>
@import './BuilderHeader';

.blog-buttons {
	display: flex;
	align-items: center;

	@media screen and (max-width: $media-mobile) {
		display: none;
	}
}

.margin-x-4 {
	margin: 0 4px;
}
</style>
