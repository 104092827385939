<template>
	<ZyroPopupCard
		type="editor"
		:title="$t('builder.editImage.popupTitle')"
		:tabs="tabs"
		:current-tab="currentTab"
		class="edit-image-popup"
		@update:current-tab="currentTab = $event"
		@close="$emit('close')"
	>
		<EditImageTabAction v-if="currentTab.id === ELEMENT_EDIT_POPUP_TAB_ID_ACTION" />
		<EditImageTabImage v-else-if="currentTab.id === ELEMENT_EDIT_POPUP_TAB_ID_IMAGE" />
		<EditImageTabStyles v-else-if="currentTab.id === ELEMENT_EDIT_POPUP_TAB_ID_STYLE" />
		<EditImageTabShape v-else-if="currentTab.id === ELEMENT_EDIT_POPUP_TAB_ID_SHAPE" />
		<EditTabAnimation v-else-if="currentTab.id === ELEMENT_EDIT_POPUP_TAB_ID_ANIMATION" />

		<NpsRateFeature
			:feature-name="$t('builder.npsRateImage')"
			:type="NPS_TYPE_FEATURE_IMAGE"
		/>
	</ZyroPopupCard>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import ZyroPopupCard from '@/components/global/ZyroPopupCard.vue';

import EditImageTabAction from '@/components/builder-controls/edit-image/EditImageTabAction.vue';
import EditImageTabImage from '@/components/builder-controls/edit-image/EditImageTabImage.vue';
import EditImageTabStyles from '@/components/builder-controls/edit-image/EditImageTabStyles.vue';
import EditImageTabShape from '@/components/builder-controls/edit-image/EditImageTabShape.vue';
import EditTabAnimation from '@/components/builder-controls/reusable-controls/element/EditTabAnimation.vue';

import NpsRateFeature from '@/components/ui/NpsRateFeature.vue';
import {
	NPS_TYPE_FEATURE_IMAGE,
	ELEMENT_EDIT_POPUP_TAB_ID_ACTION,
	ELEMENT_EDIT_POPUP_TAB_ID_IMAGE,
	ELEMENT_EDIT_POPUP_TAB_ID_SHAPE,
	ELEMENT_EDIT_POPUP_TAB_ID_STYLE,
	ELEMENT_EDIT_POPUP_TAB_ID_ANIMATION,
} from '@/constants';

import { useI18n } from 'vue-i18n';

defineEmits(['close']);

const { t } = useI18n();
const tabs = [
	{
		title: t('common.image'),
		id: ELEMENT_EDIT_POPUP_TAB_ID_IMAGE,
	},
	{
		title: t('common.style'),
		id: ELEMENT_EDIT_POPUP_TAB_ID_STYLE,
	},
	{
		title: t('common.action'),
		id: ELEMENT_EDIT_POPUP_TAB_ID_ACTION,
	},
	{
		title: t('common.shape'),
		id: ELEMENT_EDIT_POPUP_TAB_ID_SHAPE,
	},
	{
		title: t('common.animation'),
		id: ELEMENT_EDIT_POPUP_TAB_ID_ANIMATION,
	},
];

const currentTab = ref(tabs[0]);

</script>
<style lang="scss" scoped>
.edit-image-popup {
	:deep(.popup-card__title) {
		font-size: 20px;
	}
}
</style>
