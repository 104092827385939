<script setup>
import Icon from '@/components/global/Icon.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import Popup from '@/components/global/Popup.vue';

import FolderButtons from '@/components/builder-controls/multipage-drawer/popup-buttons/FolderButtons.vue';
import LinkButtons from '@/components/builder-controls/multipage-drawer/popup-buttons/LinkButtons.vue';
import PageButtons from '@/components/builder-controls/multipage-drawer/popup-buttons/PageButtons.vue';

import {
	NAVIGATION_TYPE_PAGE,
	NAVIGATION_TYPE_LINK,
	NAVIGATION_TYPE_FOLDER,
} from '@/constants';

import {
	ref,
	computed,
} from 'vue';

const POPUP_BUTTONS = {
	[NAVIGATION_TYPE_PAGE]: PageButtons,
	[NAVIGATION_TYPE_LINK]: LinkButtons,
	[NAVIGATION_TYPE_FOLDER]: FolderButtons,
};

const props = defineProps({
	item: {
		type: Object,
		required: true,
	},
});

defineEmits([
	'start-renaming',
	'open-page-settings-popup',
]);

const isPopupOpen = ref(false);
const settingsButtonRef = ref(null);

const buttonSetComponent = computed(() => POPUP_BUTTONS[props.item.linkType]);

const togglePopup = () => {
	isPopupOpen.value = !isPopupOpen.value;
};

const handleClickOutside = (event) => {
	if (!event.target?.closest('[role="dialog"]')) {
		isPopupOpen.value = false;
	}
};
</script>

<template>
	<HostingerButton
		ref="settingsButtonRef"
		v-qa="`sitemenu-btn-settings-${item.name || item.id}`"
		class="trigger-button"
		button-type="plain"
		theme="highlight"
		:title="$t('common.settings')"
		@click.stop="togglePopup"
	>
		<template #icon>
			<Icon
				name="settings"
				dimensions="20px"
			/>
		</template>
	</HostingerButton>
	<Popup
		v-if="isPopupOpen"
		placement="right-start"
		:target-ref="settingsButtonRef && settingsButtonRef?.$el"
		@click-outside="handleClickOutside"
	>
		<Component
			:is="buttonSetComponent"
			:item="item"
			class="popup-content"
			:toggle="togglePopup"
			@open-page-settings-popup="$emit('open-page-settings-popup', $event)"
			@start-renaming="$emit('start-renaming')"
		/>
	</Popup>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/components/Popup";

.popup-content {
	min-width: 208px;
}

.trigger-button {
	margin-right: 4px;
}
</style>
