import {
	addBreadcrumb,
	captureException,
} from '@sentry/vue';

import { getElementPositionFromDOM } from '@/utils/getElementPositionFromDom';
import { MOBILE_BLOCK_PADDING_X } from '@zyro-inc/site-modules/components/blocks/layout/constants';

import { getIsLayoutPositionValid } from '@/utils/getIsLayoutPositionValid';

import {
	fixLayoutMobilePosition,
	fixLayoutDesktopPosition,
} from '@/utils/fixLayoutPosition';

import {
	ELEMENT_POSITION_KEY_MOBILE,
	ELEMENT_POSITION_KEY_DESKTOP,
} from '@zyro-inc/site-modules/constants';

export const getValidatedLayoutPositions = ({
	elementId,
	element,
	blockId,
}) => {
	const validatedElementPositions = {
		[ELEMENT_POSITION_KEY_MOBILE]: element[ELEMENT_POSITION_KEY_MOBILE],
		[ELEMENT_POSITION_KEY_DESKTOP]: element[ELEMENT_POSITION_KEY_DESKTOP],
	};

	const isDesktopPositionValid = getIsLayoutPositionValid({
		position: element.desktop,
	});
	const isMobilePositionValid = getIsLayoutPositionValid({
		position: element.mobile,
	});

	if (!isDesktopPositionValid || !isMobilePositionValid) {
		const invalidPosition = isDesktopPositionValid ? element.mobile : element.desktop;
		const positionKey = isDesktopPositionValid ? 'mobile' : 'desktop';

		addBreadcrumb({
			category: 'LAYOUT_INVALID_POSITION',
			message: 'getValidatedLayoutPosition',
			data: {
				positionKey,
				position: invalidPosition,
				elementId,
			},
			level: 'debug',
			type: 'debug',
		});

		captureException(new Error(('Invalid element position'), {
			tags: {
				errorType: 'Invalid data.json',
			},
		}));
	}

	if (!isMobilePositionValid) {
		const positionInDom = getElementPositionFromDOM({
			elementId,
			blockId,
			leftOffset: MOBILE_BLOCK_PADDING_X,
		});

		validatedElementPositions[ELEMENT_POSITION_KEY_MOBILE] = fixLayoutMobilePosition({
			mobilePosition: validatedElementPositions[ELEMENT_POSITION_KEY_MOBILE],
			desktopPosition: validatedElementPositions[ELEMENT_POSITION_KEY_DESKTOP],
			positionInDom,
		});
	}

	if (!isDesktopPositionValid) {
		const positionInDom = getElementPositionFromDOM({
			elementId,
			blockId,
		});

		validatedElementPositions[ELEMENT_POSITION_KEY_DESKTOP] = fixLayoutDesktopPosition({
			desktopPosition: validatedElementPositions[ELEMENT_POSITION_KEY_DESKTOP],
			positionInDom,
		});
	}

	return validatedElementPositions;
};
