<template>
	<div class="add-element">
		<div
			v-for="(element,key) in filteredElements"
			:key="`add-${key}`"
			v-qa="`builder-addelements-element-${element.name}`"
			class="add-element__element"
			draggable="true"
			@click="handleElementClick(element, key)"
			@dragstart="onDragStart($event, key)"
			@dragend="onDragEnd"
		>
			<div class="add-element__icon">
				<Icon
					:name="element.icon"
					viewBox="0 0 65 48"
					is-custom
					color="gray"
				/>
			</div>
			<div
				class="text-body-2 add-element__name"
				v-text="element.name"
			/>
		</div>
	</div>
</template>

<script setup>
import Icon from '@/components/global/Icon.vue';

import { useStore } from 'vuex';
import {
	GUI_NAMESPACE,
	OPEN_MODAL,
	CLOSE_DRAWER,
} from '@/store/builder/gui';
import { MODAL_AI_CHATBOX_COMING_SOON } from '@/constants';

import {
	BLOCK_TYPE_LAYOUT,
	BLOCK_TYPE_NAVIGATION,
	ELEMENT_TYPE_ECOMMERCE_BUTTON,
} from '@zyro-inc/site-modules/constants';

import { getLayoutBlock } from '@/components/block/blocks';
import { useNotifications } from '@/use/useNotifications';
import { useSidebar } from '@/use/useSidebar';
import { generateRandomId } from '@/utils/generateRandomId';
import {
	useAddElement,
	ELEMENT_KEY_CHATBOT,
} from '@/use/useAddElement';
import { useBlockLayout } from '@zyro-inc/site-modules/components/blocks/layout/useBlockLayout';

import {
	computed,
	ref,
	nextTick,
} from 'vue';

const emit = defineEmits(['element-added']);

const {
	getters,
	state,
	dispatch,
} = useStore();
const {
	defaultElements,
	addLayoutElement,
} = useAddElement();

const { notify } = useNotifications();

const isEmptyPageDrag = ref(false);

const currentPageId = computed(() => state.currentPageId);
const currentBlockId = computed(() => state.currentBlockId);

const siteBlocks = computed(() => getters.siteBlocks);
const currentBlock = computed(() => getters.currentBlock);
const siteElements = computed(() => getters.siteElements);
const currentPage = computed(() => getters.currentPage);
const isCurrentPageEmpty = computed(() => getters.isCurrentPageEmpty);
const currentBlockType = computed(() => getters.currentBlockType);
const isEcommerceAvailableForUsers = computed(() => getters['ecommerce/isEcommerceAvailableForUsers']);
const isStoreTypeZyro = computed(() => getters['ecommerce/isStoreTypeZyro']);

const {
	layoutElements,
	blockElements,
} = useBlockLayout({
	blockData: currentBlock,
	siteElements,
	shouldBuildResponsive: false,
});

const { closeSidebar } = useSidebar();

const filteredElements = computed(() => Object.fromEntries(Object.entries(defaultElements.value).filter(
	([, elementValue]) => {
		if ((!isEcommerceAvailableForUsers.value && !isStoreTypeZyro.value)) {
			const isElementEcommerceButton = elementValue.content.type === ELEMENT_TYPE_ECOMMERCE_BUTTON;

			return !isElementEcommerceButton;
		}

		return true;
	},
)));

const openChatboxModal = () => {
	dispatch(`${GUI_NAMESPACE}/${OPEN_MODAL}`, {
		name: MODAL_AI_CHATBOX_COMING_SOON,
	});
};

const onDragStart = (event, key) => {
	if (key === ELEMENT_KEY_CHATBOT) {
		openChatboxModal();

		return;
	}

	if (isCurrentPageEmpty.value) {
		isEmptyPageDrag.value = true;

		dispatch('addBlock', {
			pageId: currentPageId.value,
			blockData: getLayoutBlock(),
		});
	}

	const {
		content,
		widthPixels,
		heightPixels,
	} = filteredElements.value[key];

	dispatch('updateAddElementData', {
		elementId: generateRandomId(),
		elementData: content,
		width: widthPixels,
		height: heightPixels,
	});
};

const handleEmptyPageBlock = () => {
	const blockId = currentPage.value.blocks[0];
	const hasElements = siteBlocks.value[blockId].components.length > 0;

	if (!hasElements) {
		dispatch('removeBlock', {
			blockId,
			rootBlock: false,
		});
	}
};

const onDragEnd = () => {
	if (isEmptyPageDrag.value) {
		handleEmptyPageBlock();
		isEmptyPageDrag.value = false;
	}
};

const handleElementClick = async (element, key) => {
	if (key === ELEMENT_KEY_CHATBOT) {
		openChatboxModal();

		return;
	}

	const isBlockLayout = currentBlockType.value === BLOCK_TYPE_LAYOUT;
	const isBlockHeader = currentBlockType.value === BLOCK_TYPE_NAVIGATION;
	const currentPageFirstBlockId = currentPage.value.blocks[0];
	const isCurrentPageFirstBlockTypeLayout = siteBlocks.value[currentPageFirstBlockId]?.type === BLOCK_TYPE_LAYOUT;

	if (isBlockHeader && isCurrentPageFirstBlockTypeLayout) {
		dispatch('updateCurrentBlockId', currentPageFirstBlockId);
	} else if (!isBlockLayout) {
		notify({
			messageI18nKeyPath: 'builder.failedToAddElementToBlockErrorMessage',
		});

		return;
	}

	const newElementId = generateRandomId();
	const elementToAdd = {
		...element.content,
		settings: {
			...element.content.settings,
			styles: {
				...(element.content.settings?.styles || {}),
			},
		},
	};

	const currentBlockSelected = document.querySelector(`[data-block-ref='${currentBlockId.value}'`);

	const newElementRawPosition = {
		newElementRawWidth: element.widthPixels,
		newElementRawHeight: element.heightPixels,
	};

	addLayoutElement({
		layoutElements: layoutElements.value,
		blockElements: blockElements.value,
		blockId: currentBlockId.value,
		blockToAddRef: currentBlockSelected,
		elementId: newElementId,
		newElementData: elementToAdd,
		newElementRawPosition,
	});

	await nextTick();

	dispatch('selectCurrentElement', {
		elementId: newElementId,
	});

	dispatch(`gui/${CLOSE_DRAWER}`);
	closeSidebar();

	emit('element-added');
};
</script>

<style lang="scss" scoped>
.add-element {
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	grid-gap: 16px;
	color: $color-gray;
	text-align: center;

	@media screen and (max-width: $media-mobile) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}

	&__info {
		display: block;
		padding: 12px 24px;
		margin-bottom: 12px;
		text-align: center;
		background: $color-gray-light;
	}

	&__icon {
		display: flex;
		width: 100%;
		padding: 16px;
		margin-bottom: 8px;
		border: 1px solid $color-gray-border;
		transition: 300ms background-color;
		border-radius: 12px;

		svg {
			width: 100%;
			height: 48px;
		}
	}

	&__element {
		cursor: move;

		&:hover {
			.add-element {
				&__icon {
					background: $color-gray-light;
				}
			}
		}
	}

	&__name {
		overflow-wrap: break-word;
	}
}
</style>
