<template>
	<ZyroSegmentControl
		:controls="headerStates"
		:active-control="currentHeaderState"
		@update:active-control="updateCurrentHeaderState"
	/>
	<template v-if="currentHeaderState.id === NORMAL_STATE">
		<ZyroFieldColorPicker
			v-qa="'editblocknavigation-tabstyle-backgroundcolor'"
			:is-open="isBackgroundColorPickerOpen"
			:color="backgroundColor"
			placement="left-start"
			class="navigation-style__item"
			:label="$t('builder.editBlockNavigation.tabStyle.headerBackground')"
			@update-color="handleBackgroundColorInput"
			@toggle="isBackgroundColorPickerOpen = !isBackgroundColorPickerOpen"
			@click-outside="isBackgroundColorPickerOpen = false"
		/>
		<ZyroFieldToggle
			v-if="!isMobileMode"
			id="isTransparentToggle"
			v-qa="`editblocknavigation-tabstyle-istransparent-${currentBlock.background.isTransparent}`"
			class="navigation-style__item"
			:label="$t('builder.editBlockNavigation.tabStyle.headerTransparent')"
			:model-value="currentBlock.background.isTransparent"
			@update:model-value="updateIsBackgroundEnabled"
		/>
		<ZyroSeparator class="navigation-style__separator" />
		<ZyroFieldFontSelect
			:current-font-family="currentNavigationFont"
			:is-open="isFontSelectOpen"
			@set-font-family="handleTextFontFamilyChange"
			@toggle="isFontSelectOpen = !isFontSelectOpen"
			@close="isFontSelectOpen = false"
		/>
		<ZyroFieldColorPicker
			v-qa="'editblocknavigation-tabstyle-headertextcolor-btn'"
			class="navigation-style__item"
			:color="navLinkTextColors.normal || siteStyles['nav-link'].color"
			:is-open="isTextColorPickerOpen"
			placement="left-start"
			:label="$t('builder.editBlockNavigation.tabStyle.textColor')"
			@update-color="handleTextColorInput"
			@toggle="isTextColorPickerOpen = !isTextColorPickerOpen"
			@click-outside="isTextColorPickerOpen = false"
		/>
	</template>

	<template v-else>
		<ZyroFieldColorPicker
			v-qa="'editblocknavigation-tabstyle-hovercolor-btn'"
			:color="navLinkTextColors.hover || siteStyles['nav-link']['color-hover'] || siteStyles['nav-link'].color"
			class="navigation-style__item"
			:is-open="isTextHoverColorPickerOpen"
			placement="left-start"
			:label="$t('builder.editBlockNavigation.tabStyle.hoverColor')"
			@update-color="handleTextColorHoverInput"
			@toggle="isTextHoverColorPickerOpen = !isTextHoverColorPickerOpen"
			@click-outside="isTextHoverColorPickerOpen = false"
		/>
	</template>

	<template v-if="!isAiBuilderMode">
		<ZyroSeparator class="navigation-style__separator" />
		<HostingerButton
			v-qa="'editblocknavigation-tabstyle-edittextstyles-btn'"
			button-type="outlined"
			class="navigation-style__edit-text-styles-button"
			@click="openLinkStylesDrawer"
		>
			{{ $t('builder.editBlockNavigation.tabStyle.editTextStyles') }}
		</HostingerButton>
	</template>
</template>

<script setup>
import tinycolor from 'tinycolor2';
import ZyroFieldColorPicker from '@/components/global/ZyroFieldColorPicker.vue';
import ZyroFieldFontSelect from '@/components/global/ZyroFieldFontSelect.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import { useBuilderMode } from '@/use/useBuilderMode';

import ZyroFieldToggle from '@/components/global/ZyroFieldToggle.vue';
import ZyroSeparator from '@/components/global/ZyroSeparator.vue';

import { useStore } from 'vuex';

import {
	COLOR_LIGHT,
	DRAWER_STYLES_TAB_ID_TYPOGRAPHY,
	DRAWER_USER_STYLES,
	USER_STYLES_SELECT_KEY_PARAGRAPH,
} from '@/constants';

import {
	computed,
	ref,
} from 'vue';
import {
	extractFontName,
	extractFontTypeFromVariable,
	websiteFontNames,
} from '@zyro-inc/site-modules/utils/font';

import { useI18n } from 'vue-i18n';
import ZyroSegmentControl from '@/components/global/ZyroSegmentControl.vue';
import { TYPOGRAPHY_NAV_LINK } from '@zyro-inc/site-modules/constants/globalStyles';

import { useDrawerTabs } from '@/components/builder-drawers/drawers/use/useDrawerTabs';
import {
	CHANGE_DRAWER_PAGE,
	TOGGLE_DRAWER,
} from '@/store/builder/gui';

const DEFAULT_BACKGROUND = {
	color: 'rgb(255, 255, 255)',
	current: 'color',
};

const {
	getters,
	state,
	dispatch,
} = useStore();

const { changeCurrentTab } = useDrawerTabs();
const { isAiBuilderMode } = useBuilderMode();

const { t } = useI18n();

const NORMAL_STATE = 'normal';
const HOVER_STATE = 'hover';

const COLOR_SHADE_OFFSET = 12;

const headerStates = [
	{
		id: NORMAL_STATE,
		title: t('builder.editButton.customButtonStyles.normal'),
	},
	{
		id: HOVER_STATE,
		title: t('builder.editButton.customButtonStyles.hover'),
	},
];

const currentBlock = computed(() => getters.currentBlock);
const currentBlockId = computed(() => state.currentBlockId);
const isMobileMode = computed(() => state['gui/isMobileMode']);
const siteStyles = computed(() => getters.siteStyles);
const siteFonts = computed(() => getters.siteFonts);
const navLinkTextColors = computed(() => ({
	normal: currentBlock.value.navLinkTextColor,
	hover: currentBlock.value.navLinkTextColorHover,
}));
const currentNavigationFont = computed(() => {
	const localFont = currentBlock.value?.fontFamily;
	const globalFontVar = extractFontName(siteStyles.value['nav-link']['font-family']);
	const globalFontType = extractFontTypeFromVariable(globalFontVar);
	const globalFont = websiteFontNames(siteFonts.value)[globalFontType];

	return localFont ?? globalFont;
});
const backgroundColor = computed(() => {
	const { background } = currentBlock.value;

	return ('current' in background) ? background.color : DEFAULT_BACKGROUND.color;
});

const isBackgroundColorPickerOpen = ref(false);
const isTextColorPickerOpen = ref(false);
const isTextHoverColorPickerOpen = ref(false);
const isFontSelectOpen = ref(false);
const currentHeaderState = ref(headerStates[0]);

const handleTextColorInput = (value) => {
	dispatch('setStyleProperty', {
		element: 'nav-link',
		property: 'color',
		value,
	});

	// Support for Global Colors
	dispatch('updateBlockData', {
		blockId: currentBlockId.value,
		blockData: {
			navLinkTextColor: value,
		},
		merge: true,
	});
};

const handleTextColorHoverInput = (value) => {
	dispatch('setStyleProperty', {
		element: 'nav-link',
		property: 'color-hover',
		value,
	});

	// Support for Global Colors
	dispatch('updateBlockData', {
		blockId: currentBlockId.value,
		blockData: {
			navLinkTextColorHover: value,
		},
		merge: true,
	});
};

const updateIsBackgroundEnabled = (newValue) => {
	dispatch('updateBlockData', {
		blockId: currentBlockId.value,
		blockData: {
			background: {
				isTransparent: newValue,
				current: 'color',
			},
		},
		merge: true,
	});
};

const handleTextFontFamilyChange = ({
	fontFamily,
	fontWeight,
}) => {
	dispatch('updateBlockData', {
		blockId: currentBlockId.value,
		blockData: {
			fontFamily,
			fontWeight: Number.parseInt(fontWeight, 10) || 400,
		},
		merge: true,
	});

	isFontSelectOpen.value = false;
};

const handleBackgroundColorInput = (color) => {
	const constrastColor = tinycolor(color).isDark() ? 'light' : 'dark';
	const contrastBackgroundColor = (constrastColor === COLOR_LIGHT
		? tinycolor(color).lighten(COLOR_SHADE_OFFSET)
		: tinycolor(color).darken(COLOR_SHADE_OFFSET)).toRgbString();

	dispatch('updateBlockData', {
		blockId: currentBlockId.value,
		blockData: {
			settings: {
				styles: {
					contrastBackgroundColor,
				},
			},
			background: {
				color,
				current: 'color',
			},
		},
		merge: true,
	});
};

const updateCurrentHeaderState = (newState) => {
	currentHeaderState.value = newState;
};

const openLinkStylesDrawer = () => {
	dispatch(`gui/${TOGGLE_DRAWER}`, DRAWER_USER_STYLES);

	changeCurrentTab({
		drawer: DRAWER_USER_STYLES,
		tabId: DRAWER_STYLES_TAB_ID_TYPOGRAPHY,
	});

	dispatch(`gui/${CHANGE_DRAWER_PAGE}`, {
		drawerKey: DRAWER_USER_STYLES,
		pageKey: USER_STYLES_SELECT_KEY_PARAGRAPH,
		options: {
			currentTypographyType: TYPOGRAPHY_NAV_LINK,
		},
	});
};
</script>

<style lang="scss" scoped>
	.navigation-style {
		&__item {
			padding: 8px 0;
		}

		&__separator {
			margin: 8px 0;
		}

		&__edit-text-styles-button {
			display: block;
			align-self: center;
			margin: 12px auto 0;
		}
	}
</style>
