import { useSiteStore } from '@/stores';
import {
	computed,
	ref,
} from 'vue';
import { useClipboardItems } from '@vueuse/core';
import { nanoid } from 'nanoid';
import { mergeObjects } from '@/utils/mergeObjects';
import {
	SiteBlock,
	SiteElement,
	SitePage,
	siteSchemaValidator,
} from '@hostinger/builder-schema-validator';
import { useNotifications } from '@/use/useNotifications';

function generateUniqueIdsForLanguageData({ languageData }:{
		languageData: {blocks: Record<string, SiteBlock>, elements: Record<string, SiteElement>}
	}) {
	// Generate new IDs for blocks
	const blockIdMapping = Object.fromEntries(
		Object.keys(languageData.blocks).map((key) => [
			key,
			nanoid(6),
		]),
	);

	// Generate new IDs for elements
	const elementIdMapping = Object.fromEntries(
		Object.keys(languageData.elements).map((key) => [
			key,
			nanoid(6),
		]),
	);

	// Update blocks with new IDs and adjust zindexes and components
	const updatedBlocks = Object.fromEntries(
		Object.entries(languageData.blocks).map(([blockId, blockData]) => {
			const newBlockId = blockIdMapping[blockId];

			return [
				newBlockId,
				{
					...blockData,
					zindexes: blockData.zindexes?.map((elementId) => elementIdMapping[elementId] || elementId) || [],
					components: blockData?.components?.map((elementId) => elementIdMapping[elementId] || elementId) || [],
				},
			];
		}),
	);

	// Update elements with new IDs
	const updatedElements = Object.fromEntries(
		Object.entries(languageData.elements).map(([key, value]) => {
			const newKey = elementIdMapping[key] || blockIdMapping[key] || key;

			return [
				newKey,
				value,
			];
		}),
	);

	// Return updated data
	return {
		blocks: updatedBlocks,
		elements: updatedElements,
	};
}

export const useClipboard = () => {
	const siteStore = useSiteStore();
	const { copy } = useClipboardItems();
	const { notify } = useNotifications();

	const stringMime = ref('text/plain');

	const siteBlocks = computed(() => siteStore.siteBlocks);

	/**
   * Construct languageData matching structure for block. Entire block data - block data with element data.
   */
	const getStringifiedEntireBlockData = ({ blockId }:{blockId: string}) => {
		const blockData = siteBlocks.value[blockId];
		const blockElementData = Object.fromEntries(Object.entries(siteStore.siteElements)
			.filter(([elementId]) => blockData.components?.includes(elementId)));

		const languageData = {
			blocks: {
				[blockId]: blockData,
			},
			elements: blockElementData,
		};

		return JSON.stringify(languageData);
	};

	const copyEntireBlockDataToClipboard = ({ blockId }:{blockId: string}) => {
		const stringifiedData = getStringifiedEntireBlockData({
			blockId,
		});

		const clipboardItem = new ClipboardItem({
			[stringMime.value]: new Blob([stringifiedData], {
				type: stringMime.value,
			}),
		});

		copy([clipboardItem]);
	};

	const pasteEntireBlockDataFromClipboard = async ({ selectedBlockId }:{selectedBlockId: string}) => {
		try {
			const clipboardItem = (await navigator.clipboard.read())[0];
			const blob = await clipboardItem.getType(stringMime.value);
			const parsedData = JSON.parse(await blob.text() || '{}');
			const updatedParsedData = generateUniqueIdsForLanguageData({
				languageData: parsedData,
			});

			const blockIds = Object.keys(updatedParsedData.blocks);
			const pageBlocks = computed(() => {
				if (!siteStore.currentPage) {
					throw new Error('Error occured while pasting block data, page does not exist');
				}

				const { blocks } = siteStore.currentPage as SitePage;

				if (!blocks) return blockIds;

				const index = blocks.indexOf(selectedBlockId);

				if (index === -1) return blocks;

				return blocks.toSpliced(index + 1, 0, ...blockIds);
			});

			const entireBlockData = {
				...updatedParsedData,
				// Updating current page with new block
				pages: {
					[siteStore.currentPageId]: {
						blocks: pageBlocks.value,
					},
				},
			};

			// Validate block data before merging into languageData
			siteSchemaValidator.validateSiteBlock(Object.values(entireBlockData.blocks)[0] as SiteBlock);

			const newLanguageData = mergeObjects(siteStore.currentSiteLanguage, entireBlockData);

			siteStore.setSiteLanguageData({
				locale: siteStore.currentLocale,
				data: newLanguageData,
			});
		} catch (error) {
			notify({
				message: 'Failed to paste block from JSON, check console for more details',
			});

			console.error('Error pasting block data from clipboard', error);
		}
	};

	return {
		copyEntireBlockDataToClipboard,
		pasteEntireBlockDataFromClipboard,
	};
};
