import {
	LOCAL_STORAGE_KEY_CURRENT_ROUTE_PATH,
	LOCAL_STORAGE_KEY_IS_FORCE_RELOAD_REQUIRED,
	LOCAL_STORAGE_KEY_LAST_FORCE_RELOAD_TIME,
} from '@/constants';
import axios from '@/services/http/axios';
import dayjs from 'dayjs';
/* eslint-disable import/no-cycle */
import { store } from '@/store/builder';

const LOAD_ERRORS = {
	FETCH_DYNAMIC_MODULE: 'Failed to fetch dynamically imported module',
	LOAD_DYNAMIC_MODULE: 'loading dynamically imported module',
};

const saveBuilderChanges = async () => {
	if (!store.getters['saving/canSave']) {
		return;
	}

	store.dispatch('unselectCurrentElement');

	await store.dispatch('saving/saveWebsite', {
		saveWhenImpersonating: true,
	});
};

const isLastForceReloadTimeExpired = () => {
	const lastReloadTime = localStorage.getItem(LOCAL_STORAGE_KEY_LAST_FORCE_RELOAD_TIME);

	// Force reload should happen only every hour, otherwise it will be too annoying for the user,
	return !lastReloadTime || dayjs().diff(dayjs(Number(lastReloadTime)), 'minute') > 60;
};

export const forceReload = async (currentPath: string) => {
	if (!isLastForceReloadTimeExpired()) {
		return;
	}

	await saveBuilderChanges();

	localStorage.setItem(LOCAL_STORAGE_KEY_LAST_FORCE_RELOAD_TIME, String(Date.now()));
	localStorage.setItem(LOCAL_STORAGE_KEY_IS_FORCE_RELOAD_REQUIRED, '0');
	window.location.replace(`${window.location.origin}${currentPath}`);
};

const checkChunkLoadError = () => {
	const originalConsole = console.error;

	console.error = async (error) => {
		originalConsole(error);

		const isChunkLoadError = Object.values(LOAD_ERRORS).some((errorText) => error.message?.includes(errorText));

		if (!isChunkLoadError) {
			return;
		}

		await forceReload(localStorage.getItem(LOCAL_STORAGE_KEY_CURRENT_ROUTE_PATH) as string);
	};
};

let appVersionCheckInterval: any = null;

const fetchAppVersion = async (currentAppVersion: string) => {
	localStorage.setItem(LOCAL_STORAGE_KEY_IS_FORCE_RELOAD_REQUIRED, '0');

	try {
		const { data } = await axios.get<{ appVersion: string }>(`${import.meta.env.VITE_BUILDER_URL}/appVersion.json`);

		if (data.appVersion !== currentAppVersion) {
			localStorage.setItem(LOCAL_STORAGE_KEY_IS_FORCE_RELOAD_REQUIRED, '1');
			clearInterval(appVersionCheckInterval);
		}
	} catch (error) {
		clearInterval(appVersionCheckInterval);
		console.error('Failed to fetch app version');
	}
};

const checkAppVersion = async () => {
	const currentAppVersion = import.meta.env.VITE_APP_VERSION;

	if (!currentAppVersion) {
		return;
	}

	const FIVE_MINUTE_INTERVAL = 5 * 60 * 1000;

	await fetchAppVersion(currentAppVersion);

	appVersionCheckInterval = setInterval(async () => fetchAppVersion(currentAppVersion), FIVE_MINUTE_INTERVAL);
};

export const initForceReloadCheck = () => {
	checkChunkLoadError();
	checkAppVersion();
};
