<template>
	<div
		:data-element-ref="elementId"
		class="layout-element__component layout-element__component--GridGallery"
	>
		<GridGallery
			v-if="hasGridImages"
			:key="renderKey"
			ref="galleryRef"
			:load-images="isGalleryInView"
			:grid-images="gridImages"
			:column-count="columnCount"
			:column-gap="columnGap"
			:mobile-column-count="mobileColumnCount"
			:mobile-column-gap="mobileColumnGap"
			:is-masonry-layout="isMasonryLayout"
			@image-load="handleGalleryImageLoad"
		/>
		<GridGalleryEmpty
			v-else
			ref="emptyGalleryRef"
		/>
	</div>
	<ElementEditControls
		:target-ref="editControlsTargetRef?.$el"
		:element-id="elementId"
		:is-element-active="isActive"
		:enter-edit-mode-button-title="$t('builder.editGallery.title')"
	>
		<template #additional-edit-buttons>
			<VerticalSeparator />
			<ControlsTooltip :title="$t('builder.editGallery.manageButton')">
				<HostingerButton
					v-qa="`gallery-button-managegallery`"
					button-type="plain"
					theme="highlight"
					:title="$t('builder.editGallery.manageButton')"
					@click="openGalleryManager"
				>
					<template #icon>
						<Icon name="add_photo_alternate" />
					</template>
				</HostingerButton>
			</ControlsTooltip>
		</template>
		<template #edit-mode-popup>
			<EditGallery @close="closeElementEditPopupHandler" />
		</template>
	</ElementEditControls>

	<Teleport
		v-if="isGalleryManagerVisible"
		to="body"
	>
		<AssetManager
			is-gallery
			:visible-categories="[ASSETS_CATEGORY_IMAGE]"
			:gallery-id="galleryId"
			@select-image="addImagesToGallery([$event])"
			@select-images="addImagesToGallery($event)"
			@close="isGalleryManagerVisible = false, showGalleryManager = false"
		/>
	</Teleport>
</template>

<script setup>
import {
	watch,
	computed,
	ref,
} from 'vue';
import { useStore } from 'vuex';

import Icon from '@/components/global/Icon.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import GridGallery from '@zyro-inc/site-modules/components/elements/gallery/GridGallery.vue';
import ElementEditControls from '@/components/builder-controls/ElementEditControls.vue';
import VerticalSeparator from '@/components/global/VerticalSeparator.vue';
import ControlsTooltip from '@/components/ControlsTooltip.vue';
import AssetManager from '@/components/builder-modals/modals/AssetManager.vue';
import EditGallery from '@/components/builder-controls/edit-gallery/EditGallery.vue';
import { useImageIntersectionObserver } from '@/use/useImageIntersectionObserver';
import { useGridGallery } from '@zyro-inc/site-modules/components/elements/gallery/useGridGallery';
import { getOptimizedSrc } from '@zyro-inc/site-modules/utils/getSrcsets';
import { useDeviceElementHeight } from '@/use/useDeviceElementHeight';
import { useEditGridGallery } from '@/components/builder-controls/edit-gallery/useEditGridGallery';
import { useElementEditPopup } from '@/use/useElementEditPopup';
import { updateAiGeneratedImagePath } from '@/utils/urlValidators';

import GridGalleryEmpty from '@/components/elements/GridGalleryEmpty.vue';

import { ASSETS_CATEGORY_IMAGE } from '@/constants';
import { isDesktopSafari } from '@/utils/browserIdentifiers';

// A reasonable value to prevent massive images in builder (1224 / 4 * 2 for retina)
const MAX_WIDTH = 612;

const props = defineProps({
	data: {
		type: Object,
		required: true,
	},
	elementId: {
		type: String,
		required: true,
	},
	isActive: {
		type: Boolean,
		default: false,
	},
});

const emit = defineEmits(['update-is-in-view']);

const galleryRef = ref(null);
const isGalleryManagerVisible = ref(false);
const emptyGalleryRef = ref(null);

const renderKey = ref(null);

const {
	addElement,
	elements,
} = useImageIntersectionObserver();

const {
	hasGridImages,
	columnCount,
	columnGap,
	isMasonryLayout,
	mobileColumnCount,
	mobileColumnGap,
} = useGridGallery(props);

// Is used for global check if gallery is opened.
// To prevent element deletion when gallery is opened after backspace click
const { showGalleryManager } = useEditGridGallery();

const {
	state,
	getters,
	dispatch,
} = useStore();

const { updateElementHeightOnDevices } = useDeviceElementHeight();
const { closeElementEditPopupHandler } = useElementEditPopup({
	elementId: props.elementId,
});

addElement(galleryRef);

const isGalleryInView = computed(() => !elements.value.includes(galleryRef.value?.$el));

const galleryId = computed(() => props.elementId);

const editControlsTargetRef = computed(() => (hasGridImages.value ? galleryRef.value : emptyGalleryRef.value));

const currenElementId = computed(() => getters.currentElementId);

const gridImages = computed(() => props.data.images.map((image) => {
	const src = getOptimizedSrc(image.origin, image.path, state.websiteId, {
		width: MAX_WIDTH,
		isLossless: true,
	});

	return {
		...image,
		src,
	};
}));

const handleGalleryImageLoad = () => {
	if (isGalleryManagerVisible.value) {
		updateElementHeightOnDevices({
			elementId: currenElementId.value,
		});
	}
};

const openGalleryManager = () => {
	isGalleryManagerVisible.value = true;
	showGalleryManager.value = true;
};

const addImagesToGallery = (images) => {
	const oldImages = props.data.images;

	const newImages = images.map(({
		url,
		path,
		origin,
		alt,
	}) => {
		const validPath = updateAiGeneratedImagePath(url, path);

		return {
			path: validPath,
			origin,
			alt: alt || '',
		};
	});

	dispatch('mergeElementData', {
		elementId: props.elementId,
		elementData: {
			images: [
				...oldImages,
				...newImages,
			],
		},
	});

	updateElementHeightOnDevices({
		elementId: props.elementId,
	});
};

watch(columnGap, () => {
	// Rerender on each change in safari or else it wont rerender changed gaps
	if (isDesktopSafari) {
		renderKey.value += 1;
	}
});

watch(isGalleryInView, () => {
	emit('update-is-in-view', isGalleryInView.value);
});

</script>
