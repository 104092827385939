<template>
	<BuilderSidebarActionButton
		ref="triggerButtonRef"
		v-qa="'builder-sidebar-btn-feedback'"
		:is-active="isActive"
		:title="title"
		icon="warning"
		is-icon-filled
		custom-icon-color="var(--color-warning-dark)"
		@action-click="$emit('action-click')"
	/>
	<Popup
		v-if="isActive"
		is-fixed
		:target-ref="triggerButtonRef?.$el"
		:padding="isMobileScreen ? 0 : 16"
		auto-update
		@click-outside="handleClose"
	>
		<ul class="sidebar-popup">
			<li>
				<p class="text-bold-2">
					{{ $t('builder.yourWebsiteNeedsChanges') }}
				</p>
				<i18n-t
					tag="p"
					class="sidebar-popup__text text-body-2"
					keypath="builder.checkoutSlugIsReservedDescription"
				>
					<span>
						{{ isSiteBeingPublished ? $t("builder.changeToPublishWebsite") : $t('builder.changeToUpdateWebsite') }}
					</span>
				</i18n-t>
				<HostingerButton @click="handleChangeClick">
					{{ $t('builder.changeUrl') }}
				</HostingerButton>
			</li>
		</ul>
	</Popup>
</template>

<script setup lang="ts">
import Popup from '@/components/global/Popup.vue';
import BuilderSidebarActionButton from '@/components/builder-view/sidebar/BuilderSidebarActionButton.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import { DRAWER_SEO } from '@/constants';
import { useStore } from 'vuex';
import {
	computed,
	ref,
} from 'vue';

interface Props {
	title: string;
	isActive: boolean;
	pageId: string;
}

const props = defineProps<Props>();

const emit = defineEmits([
	'toggle',
	'close-popup',
	'action-click',
]);
const {
	state,
	dispatch,
} = useStore();

const triggerButtonRef = ref<InstanceType<typeof BuilderSidebarActionButton> | null>(null);
const isMobileScreen = computed(() => state.gui.isMobileScreen);
const isSiteBeingPublished = computed(() => state.gui.isSiteBeingPublished);

const handleClose = () => {
	emit('close-popup');
};

const handleChangeClick = () => {
	dispatch('gui/OPEN_DRAWER', {
		id: DRAWER_SEO,
		settings: {
			pageId: props.pageId,
			isCheckoutSlugError: true,
		},
	});
	handleClose();
};

</script>

<style lang="scss" scoped>
@import "./BuilderSidebarPopup";

.sidebar-popup {
	list-style: none;
	max-width: 280px;
	padding: 16px;

	li {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}

&__text {
	margin-bottom: 8px;
}
}
</style>
