<script setup lang="ts">
import CheckerList from '@/components/global/CheckerList.vue';
import ZyroFieldInput from '@/components/global/ZyroFieldInput.vue';
import { useStore } from 'vuex';
import { MAX_LENGTH_PAGE_SLUG } from '@zyro-inc/builder/src/constants/index';
import { getPagePathFromId } from '@zyro-inc/site-modules/utils/page/getPagePathFromId';
import { slugifyPagePath } from '@/utils/urlValidators';
import {
	computed,
	ref,
	onMounted,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { usePageSeoSettings } from '@/use/usePageSeoSettings';
import { getFocusKeywordIncludedInPageSlug } from '@/utils/seoValidation';
import { useSiteStore } from '@/stores/siteStore';

const USER_SITE_PAGE_SLUG_ECOMMERCE_CHECKOUT = 'checkout';
const {
	state,
	getters,
	dispatch,
} = useStore();
const { t } = useI18n();
const {
	pageMetaFocusKeyword,
	pageSlug,
} = usePageSeoSettings();
const siteStore = useSiteStore();

const error = ref<string | null>(null);
const initialSlugValue = ref<string>(pageSlug.value);

const currentPageId = computed(() => state.currentPageId);
const pageSlugMessage = computed(() => {
	const path = getPagePathFromId({
		pageId: currentPageId.value,
		siteData: siteStore.site,
		locale: state.currentLocale,
	});

	return `${t('builder.pageSettingsModal.textFields.pageUrl.message')} ${getters.siteUrl}${path}`;
});
const isFocusKeywordIncludeInPageSug = computed(() => getFocusKeywordIncludedInPageSlug({
	pageSlug: pageSlug.value,
	focusKeyword: pageMetaFocusKeyword.value,
}));

const pageSeoCheckerList = computed(() => ([
	{
		id: 'seoPageFocusKeyword',
		isCompleted: isFocusKeywordIncludeInPageSug.value,
		text: {
			keyPath: 'builder.seoDrawer.seoChecker.seoCheckerPageSlugIncludeFocusKeyword',
			value: pageMetaFocusKeyword.value ? `"${pageMetaFocusKeyword.value}"` : '',
		},
	},
]));
const setCheckoutSlugError = () => {
	error.value = t('builder.ecommerceCheckoutSlugError');
};

const updatePageSlug = (newValue: string) => {
	const slug = slugifyPagePath(newValue).path;

	if (slug === USER_SITE_PAGE_SLUG_ECOMMERCE_CHECKOUT) {
		setCheckoutSlugError();
	} else {
		error.value = null;
	}

	dispatch('mergePageData', {
		pageId: currentPageId.value,
		pageData: {
			slug,
		},
	});
};

const handleBlur = () => {
	if (initialSlugValue.value === pageSlug.value) {
		return;
	}

	if (!error.value) {
		initialSlugValue.value = pageSlug.value;

		return;
	}

	error.value = null;

	dispatch('mergePageData', {
		pageId: currentPageId.value,
		pageData: {
			slug: initialSlugValue.value,
		},
	});
};

onMounted(() => {
	if (state.gui.activeDrawerSettings?.isCheckoutSlugError) {
		setCheckoutSlugError();
		dispatch('gui/setActiveDrawerSettings', {});
	}
});

</script>
<template>
	<div>
		<ZyroFieldInput
			id="page-url"
			:model-value="pageSlug"
			:label="t('builder.pageSettingsModal.textFields.pageUrl.label')"
			:message="pageSlugMessage"
			:maxlength="MAX_LENGTH_PAGE_SLUG"
			:error="error"
			@update:model-value="updatePageSlug"
			@input-blur="handleBlur"
		/>
		<CheckerList :checker-items="pageSeoCheckerList" />
	</div>
</template>

<style lang="scss" scoped>
.status-icon {
	flex-shrink: 0;
	color: $color-warning-dark;
	margin-right: 8px;

	&--completed {
		color: $color-success;
	}
}

.collapsible-header {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-weight: 400;
}

:deep(.collapsible) {
	border: 1px solid $color-gray-border;
	border-radius: 8px;
	overflow: hidden;
}

:deep(.collapsible__header) {
	padding: 12px 16px;
}

.checker-list-item {
	display: flex;
	align-items: top;
}

.icon-wrapper {
	display: flex;
}
</style>
