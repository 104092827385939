<script setup lang="ts">
import HostingerButton from '@/components/global/HostingerButton.vue';

import EditableItemsItemEditor from '@/components/reusable-components/editable-items-list/-partials/EditableItemsItemEditor.vue';
import SystemDialogModal from '@/components/builder-modals/modals/SystemDialogModal.vue';
import ZyroFieldInput from '@/components/global/ZyroFieldInput.vue';
import { useI18n } from 'vue-i18n';

import { useEditableItemInput } from '@/components/reusable-components/editable-items-list/useEditableItemInput';

type Props = {
	buttonText: string;
	placeholder: string;
	validateValue: (value: string) => boolean;
	isInputVisibleOnAddClick: boolean;
	isInputModal: boolean;
	modalTitle?: string;
	inputLabel?: string;
};

const props = withDefaults(defineProps<Props>(), {
	isInputVisibleOnAddClick: true,
	isInputModal: false,
});

const emit = defineEmits<{
	add: [value: string];
}>();

const { t } = useI18n();

const {
	handleInput,
	showInput,
	hideInput,
	submitValue,
	isInputShown,
	inputValue,
	error,
} = useEditableItemInput({
	...props,
	emit,
});
</script>

<template>
	<template v-if="isInputShown">
		<Teleport
			v-if="isInputModal"
			to="body"
		>
			<SystemDialogModal
				:title="modalTitle"
				:primary-button-text="t('common.cancel')"
				:secondary-button-text="t('common.add')"
				secondary-button-color="primary"
				width="400px"
				@close="hideInput"
				@click-primary="hideInput"
				@click-secondary="submitValue"
			>
				<ZyroFieldInput
					color="light"
					class="add-button-modal__input"
					:label="inputLabel"
					:error="error"
					:placeholder="placeholder"
					:model-value="inputValue"
					focus-on-mount
					qa-selector="edititems-item-input-newitem"
					@update:model-value="handleInput"
				/>
			</SystemDialogModal>
		</Teleport>
		<EditableItemsItemEditor
			v-else
			:model-value="inputValue"
			class="add-button-input"
			:placeholder="placeholder"
			:error="error"
			@update:model-value="handleInput"
			@submit="submitValue"
			@click-outside="hideInput"
		/>
	</template>

	<div
		v-else
		class="add-button-container"
	>
		<slot
			name="button"
			:show-input="showInput"
		>
			<HostingerButton
				v-qa="'edititems-list-btn-additem'"
				class="add-button"
				theme="primary"
				button-type="outlined"
				@click="isInputVisibleOnAddClick && showInput()"
			>
				{{ buttonText }}
			</HostingerButton>
		</slot>
	</div>
</template>

<style lang="scss" scoped>
// Position relative is needed if dropdown is passed as custom button
.add-button-container {
	position: relative;
	margin: 16px 0;
}

.add-button-modal {
	&__input {
		:deep(.zyro-input__input) {
			border-radius: 8px;
		}
	}
}
</style>
