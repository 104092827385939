import { useStore } from 'vuex';
import { Toast } from '@/types';

export const useNotifications = () => {
	const { dispatch } = useStore();

	const notify = (settings: Toast) => dispatch('notifications/notify', settings);

	return {
		notify,
	};
};
