// eslint-disable-next-line import/no-unresolved
import '@/assets/scss/global.scss';
import { createApp } from 'vue';
import '@hostinger/hcomponents/style.css';
import { createPinia } from 'pinia';

import {
	dataQADirective,
	QA_DIRECTIVE_NAME,
} from '@zyro-inc/site-modules/directives/dataQaDirective';
import App from '@/App.vue';
import i18n from '@/i18n/setup';
import router from '@/router';
import {
	initErrorLogger,
	setSentryTagIsFirst24HourUser,
} from '@/services/errorLogger';
import { store } from '@/store/builder';
import { setAuthToken } from '@/utils/auth';
import {
	JWT,
	AUTH_TOKEN,
} from '@/constants';
import { authorizeUser } from '@/api/hPanelApi';
import { initForceReloadCheck } from '@/services/forceReloadService';

const getJWTFromQuery = async () => {
	const url = new URL(window.location.href);
	const urlParams = url.searchParams;

	const jwtFromQuery = urlParams.get(JWT);
	const authTokenFromQuery = urlParams.get(AUTH_TOKEN);

	if (jwtFromQuery) {
		setAuthToken(jwtFromQuery);
		setSentryTagIsFirst24HourUser();

		return;
	}

	if (authTokenFromQuery) {
		const { data } = await authorizeUser({
			authToken: authTokenFromQuery,
		});

		setAuthToken(data.jwtToken);

		urlParams.delete(AUTH_TOKEN);
		urlParams.delete(JWT);
		window.location.assign(url);
	}
};

(async () => {
	if ('ResizeObserver' in window === false) {
		// Loads polyfill asynchronously, only if required.
		const observer = await import('@juggle/resize-observer');

		window.ResizeObserver = observer.ResizeObserver;
	}
})();

const pinia = createPinia();
const app = createApp(App);

initErrorLogger(app);
app.use(pinia);
app.use(i18n);
app.use(store);
app.directive(QA_DIRECTIVE_NAME, dataQADirective);

(async () => {
	await getJWTFromQuery();
	initForceReloadCheck();

	app.use(router);
	app.mount('#app');
})();
