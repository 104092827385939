import { HEADER_X_CORRELATION_ID } from '@/constants';
// eslint-disable-next-line import/no-cycle
import { useAuthStore } from '@/stores/authStore';
// eslint-disable-next-line no-restricted-imports
import axios from 'axios';
import { nanoid } from 'nanoid';
import {
	addAxiosErrorBreadcrumb,
	logAxiosError,
} from '@/services/errorLogger';

const axiosInstance = axios.create({
	timeout: 150000,
	headers: {
		common: {
			Accept: 'application/json, text/plain, */*',
			'Content-Type': 'application/json',
		},
	},
});

axiosInstance.interceptors.request.use(async (req) => {
	const tokenFromRequest = req.headers.Authorization;

	if (tokenFromRequest) {
		return req;
	}

	const authStore = useAuthStore();
	const token = authStore.getBearerToken();

	if (!token) {
		authStore.forceLogout();

		return req;
	}

	// eslint-disable-next-line no-param-reassign
	req.headers.Authorization = token;

	return req;
});

axiosInstance.interceptors.request.use((req) => {
	if (!req.headers) {
		return req;
	}

	// eslint-disable-next-line no-param-reassign
	req.headers[HEADER_X_CORRELATION_ID] = nanoid(36);

	return req;
});

axiosInstance.interceptors.response.use(
	(res) => res.data,
	(error) => {
		addAxiosErrorBreadcrumb(error);

		logAxiosError(error);

		return Promise.reject(error);
	},
);

export const authAxios = axiosInstance;
