import { ScreenSizeProperties } from '@/types';

type ElementPosition = ScreenSizeProperties & {
	blockWidth: number;
};

export const getElementPositionFromDOM = ({
	elementId,
	blockId,
	leftOffset = 0,
}: {
	elementId: string;
	blockId: string;
	leftOffset?: number;
}): ElementPosition | {} => {
	const element = document.querySelector(`[data-element-ref='${elementId}']`);
	const block = document.querySelector(`[data-block-ref='${blockId}']`);

	if (!element || !block) {
		return {} as ElementPosition;
	}

	const elementRect = element.getBoundingClientRect();
	const blockRect = block.getBoundingClientRect();

	const elementRectOffsetFromBlockRect: ElementPosition = {
		top: Math.round(elementRect.top - blockRect.top),
		left: Math.round(elementRect.left - blockRect.left - leftOffset),
		width: Math.round(elementRect.width),
		height: Math.round(elementRect.height),
		blockWidth: blockRect.width,
	};

	return elementRectOffsetFromBlockRect;
};
