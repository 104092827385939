<template>
	<ZyroPopupCard
		type="editor"
		:title="popupTitle"
		:tabs="availableTabs"
		:current-tab="currentTab"
		@update:current-tab="currentTab = $event"
		@close="emit('close')"
	>
		<EditButtonTabAction
			v-if="currentTab.id === 'general'"
			:element-id="currentElementId"
			@close="emit('close')"
		/>
		<EditButtonTabEcommerce
			v-else-if="currentTab.id === 'ecommerce'"
			@close="emit('close')"
		/>
		<EditButtonTabLayout
			v-else-if="currentTab.id === 'layout'"
			@close="emit('close')"
		/>
		<EditButtonTabStripe
			v-else-if="currentTab.id === 'stripe'"
			@close="emit('close')"
		/>
		<EditButtonTabStyle
			v-else-if="currentTab.id === 'style'"
			:element-id="currentElementId"
			@close="emit('close')"
		/>
		<EditTabAnimation
			v-else-if="currentTab.id === 'animation'"
			@close="emit('close')"
		/>
		<NpsRateFeature
			v-if="!isEcommerceButton"
			class="edit-button__nps"
			:feature-name="$t('builder.npsRateButton')"
			:type="NPS_TYPE_FEATURE_BUTTON"
		/>
	</ZyroPopupCard>
</template>

<script setup>
import ZyroPopupCard from '@/components/global/ZyroPopupCard.vue';

import { useStore } from 'vuex';

import EditButtonTabAction from '@/components/builder-controls/edit-button/EditButtonTabAction.vue';
import EditButtonTabEcommerce from '@/components/builder-controls/edit-button/EditButtonTabEcommerce.vue';
import EditButtonTabLayout from '@/components/builder-controls/edit-button/EditButtonTabLayout.vue';
import EditButtonTabStripe from '@/components/builder-controls/edit-button/EditButtonTabStripe.vue';
import EditButtonTabStyle from '@/components/builder-controls/edit-button/EditButtonTabStyle.vue';
import EditTabAnimation from '@/components/builder-controls/reusable-controls/element/EditTabAnimation.vue';

import NpsRateFeature from '@/components/ui/NpsRateFeature.vue';
import {
	ELEMENT_TYPE_STRIPE_BUTTON,
	ELEMENT_TYPE_ECOMMERCE_BUTTON,
	BLOCK_TYPE_LAYOUT,
} from '@zyro-inc/site-modules/constants';
import { NPS_TYPE_FEATURE_BUTTON } from '@/constants';

import {
	ref,
	computed,
} from 'vue';

import { useI18n } from 'vue-i18n';

const { getters } = useStore();
const { t } = useI18n();
const currentElementId = computed(() => getters.currentElementId);
const currentElementType = computed(() => getters.currentElementType);
const currentElementBlockType = computed(() => getters.currentElementBlockType);

const emit = defineEmits(['close']);

const tabs = [
	{
		title: 'Stripe',
		id: 'stripe',
	},
	{
		title: t('common.product'),
		id: 'ecommerce',
	},
	{
		title: t('common.general'),
		id: 'general',
	},
	{
		title: t('common.style'),
		id: 'style',
	},
	{
		title: t('common.layout'),
		id: 'layout',
	},
	{
		title: t('common.animation'),
		id: 'animation',
	},
];

const isStripeButton = computed(() => currentElementType.value === ELEMENT_TYPE_STRIPE_BUTTON);
const isEcommerceButton = computed(() => currentElementType.value === ELEMENT_TYPE_ECOMMERCE_BUTTON);
const isBlockLayoutBlock = computed(() => currentElementBlockType.value === BLOCK_TYPE_LAYOUT);

const enabledTabs = computed(() => {
	const defaultBlockTabs = isBlockLayoutBlock.value
		? [
			'general',
			'style',
			'animation',
		] : [
			'general',
			'style',
			'layout',
		];

	if (isStripeButton.value) {
		return [
			...defaultBlockTabs,
			'stripe',
		];
	}

	if (isEcommerceButton.value) {
		return [
			...defaultBlockTabs,
			'ecommerce',
		];
	}

	return defaultBlockTabs;
});
const availableTabs = computed(() => tabs.filter((tab) => enabledTabs.value.includes(tab.id)));

const currentTab = ref(availableTabs.value[0]);

const popupTitle = computed(() => {
	if (isStripeButton.value) {
		return t('common.stripeCheckout');
	}

	if (isEcommerceButton.value) {
		return t('builder.ecommerceAddToBagButton');
	}

	return t('builder.editButton.popupTitle');
});
</script>

<style lang="scss" scoped>
.edit-button {
	&__nps:not(.s) {
		margin-top: 0;
	}
}
</style>
