import axios from '@/services/http/axios';
import { WORDPRESS_EXPORT_STATUSES } from '@/constants';

const WORDPRESS_API = `${import.meta.env.VITE_BACKEND_API_URL}/wordpress`;

export type WordPressExportStatus = typeof WORDPRESS_EXPORT_STATUSES[keyof typeof WORDPRESS_EXPORT_STATUSES];

export const getIdentify = async ({
	siteId,
	domain,
}: {siteId: string, domain: string}): Promise<string> => {
	const { data } = await axios.get<{authorizationUrl: string}>(`${WORDPRESS_API}/identify`, {
		params: {
			siteId,
			domain,
		},
	});

	return data.authorizationUrl;
};

export const exportToWordPress = ({
	siteId,
	domain,
	username,
	password,
}: {siteId: string, domain: string, username: string, password: string}) => {
	const body = {
		domain,
		username,
		password,
	};

	return axios.post(`${WORDPRESS_API}/export/${siteId}`, body);
};

export const getWordPressExportStatus = ({ siteId }: {siteId: string}) => axios.get<{
	status: WordPressExportStatus,
	results: {
		errors: string[],
		success: string[],
	},
}>(`${WORDPRESS_API}/export/${siteId}/status`);
