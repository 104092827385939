import {
	ref,
	computed,
} from 'vue';
import { useI18n } from 'vue-i18n';
import {
	AI_BUILDER_CATEGORY_ID_STORE,
	AI_BUILDER_CATEGORY_ID_BLOG,
	AI_BUILDER_CATEGORY_ID_BUSINESS,
	AI_BUILDER_CATEGORY_ID_PORTFOLIO,
	AI_BUILDER_CATEGORY_ID_OTHER,
	AI_BUILDER_CATEGORY_ID_WEDDINGS,
	AI_BUILDER_CATEGORY_ID_LANDING_PAGE,
	AI_BUILDER_DESCRIPTION_WORD_COUNT_BAD_QUALITY,
	AI_BUILDER_DESCRIPTION_WORD_COUNT_MEDIUM_QUALITY,
	AI_BUILDER_CATEGORY_ID_RESTAURANT,
} from '@/constants';
import {
	ColorsTag,
	AiBuilderColorPalette,
} from '@/types/aiBuilderTypes';

const brandName = ref('');
const brandDescription = ref('');
const businessTypeId = ref('');
const currentStep = ref(0);
const isDescriptionUpdatedByVoice = ref(false);
const isDescriptionUpdatedManually = ref(false);
const isCollapsibleContainerOpen = ref(false);
const isPaletteListPopupOpen = ref(false);
const selectedColorsTag = ref<ColorsTag>('random');
const selectedHostingReferenceId = ref<string | undefined>(undefined);
const selectedColors = ref<AiBuilderColorPalette>({
	name: '',
	color1: '',
	color2: '',
	color3: '',
	light: '',
	dark: '',
	grey: '',
});

const setSelectedColors = (colors: AiBuilderColorPalette) => {
	selectedColors.value = colors;
};

export const validateFormInput = (input: string) => !!input.trim();

export const useAiBuilderForm = () => {
	const { t } = useI18n();

	const AI_BUILDER_CATEGORIES = {
		[AI_BUILDER_CATEGORY_ID_BUSINESS]: {
			label: t('builder.aiBuilderBusinessShowcase'),
			id: AI_BUILDER_CATEGORY_ID_BUSINESS,
			amplitudeName: 'Business',
			onboardingRedirectParamValue: 'business',
		},
		[AI_BUILDER_CATEGORY_ID_STORE]: {
			label: t('builder.addEcommerceStoreDrawer.title'),
			id: AI_BUILDER_CATEGORY_ID_STORE,
			amplitudeName: 'Online store',
			onboardingRedirectParamValue: 'online-store',
		},
		[AI_BUILDER_CATEGORY_ID_LANDING_PAGE]: {
			label: t('builder.landingPage'),
			id: AI_BUILDER_CATEGORY_ID_LANDING_PAGE,
			amplitudeName: 'Landing page',
			onboardingRedirectParamValue: 'landing-page',
		},
		[AI_BUILDER_CATEGORY_ID_BLOG]: {
			label: t('builder.blog.blogDrawer.title'),
			id: AI_BUILDER_CATEGORY_ID_BLOG,
			amplitudeName: 'Blog',
			onboardingRedirectParamValue: 'blog',
		},
		[AI_BUILDER_CATEGORY_ID_PORTFOLIO]: {
			label: t('common.portfolio'),
			id: AI_BUILDER_CATEGORY_ID_PORTFOLIO,
			amplitudeName: 'Portfolio',
			onboardingRedirectParamValue: 'portfolio',
		},
		[AI_BUILDER_CATEGORY_ID_RESTAURANT]: {
			label: t('common.restaurant'),
			id: AI_BUILDER_CATEGORY_ID_RESTAURANT,
			amplitudeName: 'Restaurant',
			onboardingRedirectParamValue: 'restaurant',
		},
		[AI_BUILDER_CATEGORY_ID_WEDDINGS]: {
			label: t('builder.weddings'),
			id: AI_BUILDER_CATEGORY_ID_WEDDINGS,
			amplitudeName: 'Weddings invitation',
			onboardingRedirectParamValue: 'weddings',
		},
		[AI_BUILDER_CATEGORY_ID_OTHER]: {
			label: t('siteSettings.domain.domainConnectionModal.otherProvider'),
			id: AI_BUILDER_CATEGORY_ID_OTHER,
			amplitudeName: 'Other',
			onboardingRedirectParamValue: 'other',
		},
	};

	const isValidBrandName = computed(() => validateFormInput(brandName.value));
	const isValidBrandDescription = computed(() => brandDescription.value.trim().split(' ').length >= AI_BUILDER_DESCRIPTION_WORD_COUNT_BAD_QUALITY);
	const isValidBusinessType = computed(() => !!businessTypeId.value && validateFormInput(businessTypeId.value));
	const isFormValid = computed(() => isValidBrandName.value && isValidBrandDescription.value && isValidBusinessType.value);
	const descriptionQualityConfig = computed(() => {
		if (!brandDescription.value.length) {
			return {
				progress: 0,
				label: t('builder.AIWebsiteBuilder.descriptionQualityLabelBad'),
			};
		}

		const descriptionWordCount = brandDescription.value.trim().split(' ').length;

		if (descriptionWordCount < AI_BUILDER_DESCRIPTION_WORD_COUNT_BAD_QUALITY) {
			return {
				progress: 1,
				label: t('builder.AIWebsiteBuilder.qualityLabelBad'),
			};
		}

		if (descriptionWordCount < AI_BUILDER_DESCRIPTION_WORD_COUNT_MEDIUM_QUALITY) {
			return {
				progress: 2,
				label: t('builder.AIWebsiteBuilder.qualityLabelMedium'),
			};
		}

		return {
			progress: 3,
			label: t('builder.AIWebsiteBuilder.descriptionQualityLabelGood'),
		};
	});

	const resetFormInputs = () => {
		brandName.value = '';
		brandDescription.value = '';
		businessTypeId.value = '';
	};

	return {
		AI_BUILDER_CATEGORIES,
		brandName,
		brandDescription,
		businessTypeId,
		isFormValid,
		isValidBrandName,
		isValidBrandDescription,
		isValidBusinessType,
		currentStep,
		isDescriptionUpdatedByVoice,
		isDescriptionUpdatedManually,
		descriptionQualityConfig,
		selectedColors,
		setSelectedColors,
		isCollapsibleContainerOpen,
		isPaletteListPopupOpen,
		selectedColorsTag,
		resetFormInputs,
		selectedHostingReferenceId,
	};
};
