import {
	FormEntry,
	FormSchema,
} from '@/types';

export const getSortedUniqueTableKeys = ({
	formEntries,
	formSchema,
}: {formEntries: FormEntry[], formSchema?: FormSchema[]}) => {
	const formEntriesKeys = formEntries
		.flatMap((entry) => Object.keys(entry));

	const uniqueTableKeys = [...new Set([...formEntriesKeys])];

	const sortedUniqueTableKeys = uniqueTableKeys
		.map((formEntryKey) => {
			if (formSchema) {
				const formEntrySchemaItem = formSchema.find((schemaItem) => schemaItem.name === formEntryKey);
				const formEntrySchemaItemIndex = formSchema.findIndex((schemaItem) => schemaItem.name === formEntryKey);

				return {
					formEntryKey,
					label: formEntrySchemaItem?.inputLabel || formEntryKey,
					position: formEntrySchemaItem ? formEntrySchemaItemIndex : Number.POSITIVE_INFINITY,
				};
			}

			return {
				formEntryKey,
				label: formEntryKey,
				position: Number.POSITIVE_INFINITY,
			};
		})
		.sort((a, b) => a.position - b.position)
		.map((item) => item.formEntryKey);

	return sortedUniqueTableKeys;
};
