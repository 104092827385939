<template>
	<div>
		<div class="ecommerce-no-products">
			<p class="ecommerce-no-products__text text-body-1">
				{{ $t('builder.editButton.ecommerce.noProducts') }}
			</p>
			<HostingerButton
				v-qa="'ecommerce-settings-button-addproducts'"
				button-type="outlined"
				@click="redirectToEcommerceProducts"
			>
				{{ $t('builder.editButton.ecommerce.addProducts') }}
			</HostingerButton>
		</div>
		<NpsRateFeature
			:feature-name="$t('builder.npsRateOnlineStore')"
			:type="NPS_TYPE_FEATURE_SIMPLE_STORE"
		/>
	</div>
</template>
<script>
import HostingerButton from '@/components/global/HostingerButton.vue';

import NpsRateFeature from '@/components/ui/NpsRateFeature.vue';
import { NPS_TYPE_FEATURE_SIMPLE_STORE } from '@/constants';
import {
	mapActionsGui,
	OPEN_MODAL,
	CLOSE_MODAL,
} from '@/store/builder/gui';
import { useRedirects } from '@/use/useRedirects';

import { defineComponent } from 'vue';

export default defineComponent({
	components: {
		NpsRateFeature,
		HostingerButton,
	},

	setup() {
		const { redirectToEcommerceProducts } = useRedirects();

		return {
			redirectToEcommerceProducts,
			NPS_TYPE_FEATURE_SIMPLE_STORE,
		};
	},

	methods: {
		...mapActionsGui({
			openModal: OPEN_MODAL,
			closeModal: CLOSE_MODAL,
		}),
	},
});
</script>

<style lang="scss" scoped>
.ecommerce-no-products {
	margin-bottom: 24px;
	text-align: center;

	&__text {
		margin-bottom: 8px;
	}
}
</style>
