<template>
	<div class="ai-type-select">
		<AiTypeDropdown
			:custom-options="customOptions"
			:default-options="defaultOptions"
			:selected-option="selectedOption"
			:has-ai-type="hasAiType"
			@select-option="handleOptionSelect"
			@add-new-type="handleNewTypeAddition"
			@edit-type="handleTypeEdit"
			@delete-type="handleTypeDeletion"
		/>

		<ZyroInput
			v-if="isElement"
			class="ai-type-select__input"
			:model-value="aiElementIndex"
			type="number"
			placeholder="Index"
			padding="8px"
			@update:model-value="dispatch('mergeElementData', {
				elementId: props.elementId,
				elementData: { aiData: { index: $event, }, },
			})"
		/>
	</div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from 'vuex';
import ZyroInput from '@/components/global/ZyroInput.vue';
import AiTypeDropdown from '@/components/AiTypeDropdown.vue';
import {
	AI_BLOCK_TYPES,
	AI_ELEMENT_TYPES,
} from '@/constants';
import {
	SiteBlock,
	SiteElement,
} from '@hostinger/builder-schema-validator';
import { useNotifications } from '@/use/useNotifications';

const props = defineProps({
	elementId: {
		type: String,
		default: null,
	},
	blockId: {
		type: String,
		default: null,
	},
});

interface aiTypeItem {
	name: string;
	description: string;
}

const { notify } = useNotifications();
const {
	getters,
	dispatch,
} = useStore();
const siteMeta = computed(() => getters.siteMeta);
const siteElements = computed(() => getters.siteElements);
const siteBlocks = computed(() => getters.siteBlocks);
const isElement = computed(() => props.elementId !== null);
const aiBlockTypes = computed(() => siteMeta.value.aiBlockTypes || []);
const aiElementTypes = computed(() => siteMeta.value.aiElementTypes || []);
const selectedAiElementType = computed<string>(() => siteElements.value[props.elementId].aiData?.type);
const selectedAiSectionType = computed<string>(() => siteBlocks.value[props.blockId].aiData?.type);
const aiElementIndex = computed(() => siteElements.value[props.elementId].aiData?.index);
const customOptions = computed(() => (isElement.value ? aiElementTypes.value : aiBlockTypes.value));
const hasAiType = computed(() => (isElement.value ? !!selectedAiElementType.value : !!selectedAiSectionType.value));

// filter out custom types from default options if key matching
const defaultOptions = computed(() => {
	const defaultAiTypes = isElement.value ? AI_ELEMENT_TYPES : AI_BLOCK_TYPES;

	return Object.fromEntries(Object.entries(defaultAiTypes)
		.filter(([key]) => !customOptions.value[key]));
});
const selectedOption = computed(() => (isElement.value ? selectedAiElementType.value : selectedAiSectionType.value));
const aiOptionKey = computed(() => (isElement.value ? 'aiElementTypes' : 'aiBlockTypes'));
const aiTypes = computed(() => (isElement.value ? aiElementTypes.value : aiBlockTypes.value));

const updateAiElementType = (newValue: string) => {
	const aiData = newValue ? {
		type: newValue,
	} : null;

	dispatch('mergeElementData', {
		elementId: props.elementId,
		elementData: {
			aiData,
		},
	});
};

const updateAiSectionType = (newValue: string) => {
	const aiData = newValue ? {
		type: newValue,
	} : null;

	dispatch('updateBlockData', {
		blockId: props.blockId,
		blockData: {
			aiData,
		},
		merge: true,
	});
};

const handleTypeEdit = ({
	name,
	description,
}: aiTypeItem) => {
	const allCurrentOptions = {
		...customOptions.value,
		...defaultOptions.value,
	};

	if (!allCurrentOptions[name]) {
		notify({
			message: `Type "${name}" does not exist. Create new one.`,
		});

		return;
	}

	dispatch('setWebsiteSiteMeta', {
		key: aiOptionKey.value,
		value: {
			...aiTypes.value,
			[name]: description,
		},
	});
};

const handleNewTypeAddition = ({
	name,
	description,
}: aiTypeItem) => {
	if (customOptions.value[name]) {
		notify({
			message: `Type "${name}" already exists. Choose another one`,
		});

		return;
	}

	dispatch('setWebsiteSiteMeta', {
		key: aiOptionKey.value,
		value: {
			...aiTypes.value,
			[name]: description,
		},
	});
};

const handleOptionSelect = (key: string) => (isElement.value ? updateAiElementType(key) : updateAiSectionType(key));

const handleTypeDeletion = (key: string) => {
	const isKeyUsed = Object.values<SiteElement | SiteBlock>(isElement.value ? siteElements.value : siteBlocks.value)
		.some((element) => element.aiData?.type === key);

	if (isKeyUsed) {
		notify({
			message: `Type "${key}" is used and cannot be deleted. Ensure that no elements or sections are using this type.`,
		});

		return;
	}

	const {
		[key]: _deletedType,
		...value
	} = aiTypes.value;

	dispatch('setWebsiteSiteMeta', {
		key: aiOptionKey.value,
		value,
	});
};
</script>

<style lang="scss" scoped>
.ai-type-select {
	display: flex;
	gap: 4px;
	margin-right: 8px;

	&__select {
		height: 36px;
		width: 200px;
		margin-right: 8px;
	}

	&__selected-option {
		max-width: 100%;
		overflow: hidden;
	}

	&__selected-option-title {
		font-size: 10px;
	}

	&__input {
		:deep(.zyro-input__input) {
			padding: 8px;
		}
	}
}
</style>
