<template>
	<div class="tags-list">
		<button
			v-for="(tag, index) in tags"
			:key="`tag-${index}`"
			v-qa="isSelected(tag)?'seo-keyword-tag-selected':'seo-keyword-tag'"
			class="tag"
			:class="{ 'tag--selected': isSelected(tag) }"
			:disabled="!isSelected(tag) && isMaxTagsSelected && !isSingleSelect"
			@click.prevent="toggleTag(tag)"
		>
			{{ tag[displayProperty] }}

			<Icon
				v-if="isRemoveButtonVisible"
				class="tag__remove"
				name="close"
				dimensions="16px"
				@click.prevent.stop="$emit('remove-tag', { value: tag.value })"
			/>
		</button>
	</div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import Icon from '@/components/global/Icon.vue';
import { MultiselectTag } from '@/types';

type Props = {
	tags: Array<MultiselectTag>;
	modelValue?: Array<MultiselectTag>;
	maxSelections?: number | null;
	displayProperty?: 'value' | 'name';
	isSingleSelect?: boolean;
	isRemoveButtonVisible?: boolean;
	qaPrefix?: string
};

const props = withDefaults(defineProps<Props>(), {
	displayProperty: 'value',
	isSingleSelect: false,
	modelValue: () => [],
	maxSelections: null,
	isRemoveButtonVisible: false,
	qaPrefix: 'multiselect-tag',
});

const emit = defineEmits<{
	'update:model-value': [tagArray: Array<MultiselectTag>],
	'select': [tag: MultiselectTag,],
	'deselect': [tag: MultiselectTag,],
	'remove-tag': [tag: MultiselectTag,]
}>();

const maxSelectionCount = computed(() => (props.isSingleSelect ? 1 : props.maxSelections));
const isMaxTagsSelected = computed<boolean>(() => !!maxSelectionCount.value
	&& !!props.maxSelections
	&& props.modelValue.length >= props.maxSelections);

const isSelected = (tag: MultiselectTag) => props.modelValue.some((modelTag) => modelTag.value === tag.value);

const toggleTag = (tag: MultiselectTag) => {
	if (props.isSingleSelect) {
		emit('update:model-value', [tag]);
		emit('select', tag);

		return;
	}

	if (isSelected(tag)) {
		emit('update:model-value', props.modelValue.filter((selectedTag) => selectedTag.value !== tag.value) || []);
		emit('deselect', tag);

		return;
	}

	emit('update:model-value', [
		...props.modelValue,
		tag,
	]);
	emit('select', tag);
};
</script>

<style lang="scss" scoped>
.tags-list {
	display: flex;
	flex-wrap: wrap;
	gap: 12px;
}

.tag {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 6px 16px;
	font-size: 14px;
	line-height: 1.4;
	color: $color-gray;
	cursor: pointer;
	border: 1px solid $color-gray-border;
	border-radius: 100px;
	transition: all 0.3s ease;

	&--selected {
		color: $color-primary;
		background-color: $color-primary-light;
		border-color: $color-primary;
	}

	&:hover {
		color: $color-primary;
		background-color: $color-primary-light;
	}

	&:disabled {
		color: $color-gray;
		cursor: not-allowed;
	}

	&__remove {
		margin-left: 4px;

		&:hover {
			cursor: pointer;
			color: $color-primary-dark;
		}
	}
}
</style>
