<template>
	<div class="ecommerce-tab">
		<EcommerceNoProducts v-if="!products.length" />
		<template v-else>
			<ZyroLabel class="ecommerce-tab__label">
				{{ $t('builder.editButton.ecommerce.connectButtonTo') }}
			</ZyroLabel>
			<ZyroSelect
				v-qa="'ecommerce-button-product-select'"
				class="ecommerce-tab__dropdown"
				:options="productSelectOptions"
				label-key="title"
				:model-value="selectedProduct"
				@update:model-value="updateSelectedProduct"
			>
				<template #option="{ option }">
					<div
						v-qa="`dropdown-option-${option.title}`"
						class="ecommerce-tab__dropdown-option"
					>
						<span
							v-if="option.sku"
							class="ecommerce-tab__dropdown-prefix"
						>
							{{ `SKU ${option.sku}` }}
						</span>
						{{ option.title }}
					</div>
				</template>
			</ZyroSelect>
			<template v-if="isVariantSelectVisible">
				<ZyroLabel class="ecommerce-tab__label">
					{{ $t('builder.editButton.ecommerce.productOption') }}:
				</ZyroLabel>
				<ZyroSelect
					v-qa="'ecommerce-tab-product-select'"
					class="ecommerce-tab__dropdown"
					:options="selectedProduct.variants"
					label-key="title"
					:model-value="selectedVariant"
					@update:model-value="updateSelectedVariant"
				>
					<template #option="{ option }">
						<div
							v-qa="`dropdown-option-${option.title}`"
							class="ecommerce-tab__dropdown-option"
						>
							<span
								v-if="option.sku"
								class="ecommerce-tab__dropdown-prefix"
							>
								{{ `SKU ${option.sku}` }}
							</span>
							{{ option.title }}
						</div>
					</template>
				</ZyroSelect>
			</template>
			<HostingerButton
				v-if="isProductIdValid"
				v-qa="'ecommerce-settings-button-editthisproduct'"
				button-type="outlined"
				class="ecommerce-tab__edit-button"
				@click="handleButtonClick"
			>
				{{ $t('builder.editButton.ecommerce.editProduct') }}
			</HostingerButton>
		</template>
	</div>
</template>

<script>
import HostingerButton from '@/components/global/HostingerButton.vue';
import ZyroLabel from '@/components/global/ZyroLabel.vue';
import ZyroSelect from '@/components/global/ZyroSelect.vue';

import {
	mapGetters,
	mapActions,
} from 'vuex';

import EventLogApi from '@/api/EventLogApi';
import { useI18n } from 'vue-i18n';
import { useRedirects } from '@/use/useRedirects';
import { useEcommerceAdminStore } from '@/stores';
import { defineComponent } from 'vue';
import EcommerceNoProducts from '@/components/builder-controls/edit-button/EcommerceNoProducts.vue';
import {
	ECOMMERCE_REDIRECT_PATHS,
	REDIRECT_PARAM_KEYS,
	ECOMMERCE_FUNNEL_LOCATIONS,
} from '@/constants';

export default defineComponent({
	components: {
		ZyroSelect,
		HostingerButton,
		ZyroLabel,
		EcommerceNoProducts,
	},

	setup() {
		const { redirectToEcommerceProductsEdit } = useRedirects();
		const { t } = useI18n();
		const ecommerceAdminStore = useEcommerceAdminStore();

		const DEFAULT_DROPDOWN_FIELD = {
			id: -1,
			title: t('builder.ecommerce.chooseProduct'),
		};

		return {
			DEFAULT_DROPDOWN_FIELD,
			redirectToEcommerceProductsEdit,
			setIsEcommerceAdminIframeOpen: ecommerceAdminStore.setIsEcommerceAdminIframeOpen,
		};
	},

	computed: {
		...mapGetters('ecommerce', ['products']),
		...mapGetters([
			'siteMeta',
			'currentElementSettings',
		]),
		isProductIdValid() {
			return this.selectedProduct.id !== -1;
		},
		isVariantSelectVisible() {
			return this.isProductIdValid ? !!this.selectedProduct.options.length : false;
		},
		product() {
			const { productId } = this.currentElementSettings;

			return this.products.find((product) => product.id === productId);
		},
		variant() {
			const { productVariantId } = this.currentElementSettings;

			return this.product.variants.find((variant) => variant.id === productVariantId);
		},
		productSelectOptions() {
			return this.products.map((product) => {
				const [firstVariant] = product.variants;

				return {
					id: product.id,
					title: product.title,
					defaultVariantId: firstVariant.id,
					sku: !product.options.length ? firstVariant.sku : null,
				};
			});
		},
		selectedProduct() {
			return this.product || this.DEFAULT_DROPDOWN_FIELD;
		},
		selectedVariant() {
			return this.variant || this.DEFAULT_DROPDOWN_FIELD;
		},
	},

	methods: {
		...mapActions(['mergeCurrentElementData']),
		async handleButtonClick() {
			await EventLogApi.logEvent({
				eventName: 'website_builder.ecomm_products.edit',
				eventProperties: {
					location: 'button',
				},
			});

			this.setIsEcommerceAdminIframeOpen({
				isOpen: true,
				path: ECOMMERCE_REDIRECT_PATHS.PRODUCTS_EDIT,
				params: {
					[REDIRECT_PARAM_KEYS.PRODUCT]: this.selectedProduct.id,
				},
				location: ECOMMERCE_FUNNEL_LOCATIONS.IFRAME_EDIT_PRODUCT_BUTTON,
			});
		},
		updateSelectedProduct(product) {
			this.mergeCurrentElementData({
				elementData: {
					settings: {
						productId: product.id,
						productVariantId: product.defaultVariantId,
					},
				},
			});
		},
		updateSelectedVariant(variant) {
			this.mergeCurrentElementData({
				elementData: {
					settings: {
						productVariantId: variant.id,
					},
				},
			});
		},
	},
});
</script>
<style lang="scss" scoped>
.ecommerce-tab {
	display: flex;
	flex-direction: column;

	&__edit-button {
		margin: 40px auto 26px;
	}

	&__dropdown {
		background-color: $color-light;
	}

	&__dropdown-option {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&__dropdown-prefix {
		color: $color-gray;
	}
}
</style>
