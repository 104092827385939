import {
	ref,
	computed,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useAiBuilderForm } from '@/components/ai-builder/useAiBuilderForm';
import {
	updateTemplateWithLogoSvg,
	getTemplateNavigationColor,
	getTranslatableTemplateTexts,
} from '@/utils/aiTemplateEditingHelpers';
import {
	AI_BUILDER_TEMPLATE_SECTION_TYPE_REGEXES,
	AI_BUILDER_TEMPLATE_TYPE_DEFAULT,
	AI_BUILDER_TEMPLATE_TYPE_ONLINE_STORE,
	AI_BUILDER_TEMPLATE_TYPE_PORTFOLIO,
	AI_BUILDER_CATEGORY_ID_PORTFOLIO,
	AI_BUILDER_CATEGORY_ID_STORE,
	AI_BUILDER_CATEGORY_ID_BLOG,
	AI_BUILDER_TEMPLATE_TYPE_BLOG,
} from '@/constants';

import { DEFAULT_HTML_LANG_VALUE } from '@zyro-inc/site-modules/constants';

import ALL_LOCALES from '@/i18n/locales.json';
import { HTML_LANG_VALUES } from '@/data/htmlLangValues';

import defaultFirst from '@zyro-inc/site-modules/templates/ai/defaultFirst.json';
import defaultSecond from '@zyro-inc/site-modules/templates/ai/defaultSecond.json';
import defaultThird from '@zyro-inc/site-modules/templates/ai/defaultThird.json';
import portfolioFirst from '@zyro-inc/site-modules/templates/ai/portfolioFirst.json';
import portfolioSecond from '@zyro-inc/site-modules/templates/ai/portfolioSecond.json';
import portfolioThird from '@zyro-inc/site-modules/templates/ai/portfolioThird.json';
import onlineStoreFirst from '@zyro-inc/site-modules/templates/ai/onlineStoreFirst.json';
import onlineStoreSecond from '@zyro-inc/site-modules/templates/ai/onlineStoreSecond.json';
import onlineStoreThird from '@zyro-inc/site-modules/templates/ai/onlineStoreThird.json';
import blogFirst from '@zyro-inc/site-modules/templates/ai/blogFirst.json';
import blogSecond from '@zyro-inc/site-modules/templates/ai/blogSecond.json';
import blogThird from '@zyro-inc/site-modules/templates/ai/blogThird.json';
import { mergeObjects } from '@/utils/mergeObjects';

const PREDEFINED_TEMPLATES_BY_TYPE = {
	[AI_BUILDER_TEMPLATE_TYPE_DEFAULT]: [
		defaultFirst,
		defaultSecond,
		defaultThird,
	],
	[AI_BUILDER_TEMPLATE_TYPE_PORTFOLIO]: [
		portfolioFirst,
		portfolioSecond,
		portfolioThird,
	],
	[AI_BUILDER_TEMPLATE_TYPE_ONLINE_STORE]: [
		onlineStoreFirst,
		onlineStoreSecond,
		onlineStoreThird,
	],
	[AI_BUILDER_TEMPLATE_TYPE_BLOG]: [
		blogFirst,
		blogSecond,
		blogThird,
	],
};

// Return array of template section keys e.g. ['section2', 'section3', 'section1', 'contact', 'hero', 'about', 'slogan']
export const getTemplateSectionKeys = ({ predefinedTemplates }) => predefinedTemplates
	.reduce((acc, { languages: { system: { blocks } } }) => {
		const blockKeys = Object.keys(blocks);
		const allBlockKeys = [
			...acc,
			...blockKeys,
		];

		// Some sections have elements that should be split to two sections, i.e. 'delivery-title' and 'refunds-title',
		// but since they are used in one section, we cannot write one generic section key name
		// because we rely on section keys and send them to backend in order to get the right content.
		// Thats why a section name is compound of two words separated by the dash, i.e. 'delivery-refunds'.
		// And in this step they are separated to single words so they could be sent to backend.
		const aggregatedKeys = allBlockKeys.flatMap((section) => section.split('-'));

		// filter out only changeable sections from the template
		const filteredSectionKeys = aggregatedKeys.filter(
			(section) => AI_BUILDER_TEMPLATE_SECTION_TYPE_REGEXES.some((regex) => !!section.match(regex)),
		);

		return [...new Set(filteredSectionKeys)];
	}, []);

export const useAiTemplateGenerator = () => {
	const { locale } = useI18n();
	const { businessTypeId } = useAiBuilderForm();
	const localeIso = ref(Object.values(ALL_LOCALES).find(({ code }) => code === locale)?.iso6391);
	const htmlLangCode = computed(() => HTML_LANG_VALUES.find(({ value }) => value === localeIso.value)?.value
		|| DEFAULT_HTML_LANG_VALUE.value);

	const templateType = computed(() => {
		if (businessTypeId.value === AI_BUILDER_CATEGORY_ID_PORTFOLIO) {
			return AI_BUILDER_TEMPLATE_TYPE_PORTFOLIO;
		}

		if (businessTypeId.value === AI_BUILDER_CATEGORY_ID_STORE) {
			return AI_BUILDER_TEMPLATE_TYPE_ONLINE_STORE;
		}

		if (businessTypeId.value === AI_BUILDER_CATEGORY_ID_BLOG) {
			return AI_BUILDER_TEMPLATE_TYPE_BLOG;
		}

		return AI_BUILDER_TEMPLATE_TYPE_DEFAULT;
	});

	const predefinedTemplates = computed(() => PREDEFINED_TEMPLATES_BY_TYPE[templateType.value]);

	const templateSectionKeys = computed(() => getTemplateSectionKeys({
		predefinedTemplates: predefinedTemplates.value,
	}));
	const translatableTemplateTexts = computed(() => {
		const allTranslatableTexts = predefinedTemplates.value
			.flatMap((templateData) => getTranslatableTemplateTexts({
				templateData,
			}));

		return [...new Set(allTranslatableTexts)];
	});

	const getPredefinedTemplatesWithAiData = ({
		logoSvg,
		sectionBasedGenerationSiteData,
	}) => {
		const siteDataWithMetaHtmlLanguage = mergeObjects(sectionBasedGenerationSiteData.siteData, {
			meta: {
				metaHtmlLanguage: htmlLangCode.value,
			},
		});

		if (logoSvg?.svg) {
			const siteDataWithUpdatedLogo = updateTemplateWithLogoSvg({
				logoSvg,
				color: getTemplateNavigationColor({
					template: sectionBasedGenerationSiteData.siteData,
				}),
				templateData: siteDataWithMetaHtmlLanguage,
			});

			return [siteDataWithUpdatedLogo];
		}

		return [siteDataWithMetaHtmlLanguage];
	};

	return {
		getPredefinedTemplatesWithAiData,
		templateSectionKeys,
		templateType,
		translatableTemplateTexts,
	};
};
