<script setup lang="ts">
import Icon from '@/components/global/Icon.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import { useI18n } from 'vue-i18n';
import { OPEN_MODAL } from '@/store/builder/gui';
import {
	MODAL_CREATE_QR,
	MODAL_QR_NOT_AVAILABLE,
} from '@/constants';
import { QrModalLocationType } from '@/types';
import { useStore } from 'vuex';
import { useQrModal } from '@/use/useQrModal';

type Props = {
	pageId: string;
	location: QrModalLocationType;
};

const props = withDefaults(defineProps<Props>(), {
	pageId: '',
	location: 'pages and navigation',
});

const { t } = useI18n();

const { dispatch } = useStore();
const { isQrCodeAvailable } = useQrModal();

const openModal = () => {
	dispatch(`gui/${OPEN_MODAL}`, {
		name: isQrCodeAvailable.value ? MODAL_CREATE_QR : MODAL_QR_NOT_AVAILABLE,
		settings: {
			pageId: props.pageId,
			location: props.location,
		},
	});
};

const handleCreateQrClick = () => {
	openModal();
};
</script>

<template>
	<div>
		<HostingerButton
			v-qa="'sitemenusettingspopup-btn-opencreateqrcodemodal'"
			button-type="plain"
			class="popup-button"
			@click="handleCreateQrClick"
		>
			<template #icon-left>
				<Icon
					dimensions="20px"
					name="qr_code_2"
				/>
			</template>
			<p class="text-body-2 popup-button__text">
				{{ t('builder.pageSettingsCreateQrCode') }}
			</p>
		</HostingerButton>
	</div>
</template>

<style scoped lang="scss">
.popup-button {
	width: 100%;
}
</style>
