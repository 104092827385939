<template>
	<Teleport to="body">
		<Popup
			v-if="isGeneratedTextControlsVisible || isTextBeingGenerated"
			:target-ref="targetRef"
			:portal-selector="SELECTOR_DATA_PORTAL_BUILDER_PREVIEW"
			placement="bottom-start"
			auto-update
			:flip="false"
			:offset="6"
		>
			<div class="ai-text-controls">
				<div
					v-if="isGeneratedTextControlsVisible"
					class="ai-text-controls__button-group"
				>
					<button
						class="ai-text-controls__button"
						@click="handleDiscardClick"
					>
						{{ $t('common.discard') }}
					</button>
					<button
						class="ai-text-controls__button"
						@click="handleRetryClick"
					>
						{{ $t('common.tryAgain') }}
					</button>
					<button
						class="ai-text-controls__button"
						@click="handleKeepClick"
					>
						{{ $t('common.keep') }}
					</button>
				</div>

				<div
					v-if="isTextBeingGenerated"
					class="ai-text-controls__loader"
				>
					<ZyroLoader
						size="16px"
						color="var(--color-primary)"
					/>
					<p class="ai-text-controls__loader-text">
						{{ $t('builder.AITextGeneratorLoadingText') }}
					</p>
				</div>
			</div>
		</Popup>
	</Teleport>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useAiTextGeneration } from '@/use/useAiTextGeneration';
import { useAiWriterStore } from '@/stores';
import Popup from '@/components/global/Popup.vue';
import EventLogApi from '@/api/EventLogApi';
import ZyroLoader from '@zyro-inc/site-modules/components/ZyroLoader.vue';
import { SELECTOR_DATA_PORTAL_BUILDER_PREVIEW } from '@/constants';
import { storeToRefs } from 'pinia';

type Props = {
	elementId: string;
	targetRef: HTMLElement;
}

const aiWriterStore = useAiWriterStore();
const props = defineProps<Props>();

const {
	isAiTextGeneratorPopupVisible,
	aiGeneratedText,
	generateAiText,
	discardAiGeneratedText,
	keepAiGeneratedText,
} = useAiTextGeneration({
	elementId: props.elementId,
});
const { isTextBeingGenerated } = storeToRefs(aiWriterStore);

const handleKeepClick = () => {
	keepAiGeneratedText();

	EventLogApi.logEvent({
		eventName: 'website_builder.ai_assist.keep',
	});
};

const handleRetryClick = () => {
	generateAiText({
		isTryingAgain: true,
	});

	EventLogApi.logEvent({
		eventName: 'website_builder.ai_assist.retry',
	});
};

const handleDiscardClick = () => {
	discardAiGeneratedText();

	EventLogApi.logEvent({
		eventName: 'website_builder.ai_assist.cancel',
	});
};

const isGeneratedTextControlsVisible = computed(() => !isTextBeingGenerated.value
	&& !isAiTextGeneratorPopupVisible.value
	&& aiGeneratedText.value.length);
</script>

<style lang="scss" scoped>
.ai-text-controls {
	background-color: $color-light;
	border-radius: 5px;
	box-shadow: 0 6px 14px rgba($color-dark, 10%);

	&__loader {
		display: flex;
		align-items: center;
		padding: 10px 16px;
	}

	&__loader-text {
		font-size: 14px;
		line-height: 1.42;
		color: $color-primary;
		margin-left: 8px;
	}

	&__button-group {
		border-radius: 5px;
		border: 2px solid $color-primary;
		background-color: $color-primary;
		overflow: hidden;
	}

	&__button {
		font-size: 14px;
		line-height: 1.42;
		color: $color-dark;
		padding: 8px 16px;
		transition: all 0.3s ease-in-out;

		&:first-child {
			border-right: 2px solid $color-primary;
		}

		&:last-child {
			border-left: 2px solid $color-primary;
		}

		&:hover,
		&:focus {
			background-color: $color-primary;
			color: $color-light;
			cursor: pointer;
		}
	}
}
</style>
