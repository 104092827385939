<template>
	<div class="block-edit-controls">
		<AiTypeSelect
			v-if="userStore.isAiTypeSelectionVisible"
			:block-id="blockId"
		/>
		<HostingerButton
			v-qa="'element-button-edit'"
			button-type="plain"
			theme="highlight"
			@click="handleBlockEditButtonClick"
		>
			<template
				v-if="isMobileScreen"
				#icon
			>
				<Icon name="settings" />
			</template>

			<template v-if="!isMobileScreen">
				<span class="text-body-2">
					{{ enterEditModeButtonTitle }}
				</span>
			</template>
		</HostingerButton>

		<slot />

		<template v-if="isAiImageGenerationFeatureEnabled && isHostingerBrand">
			<VerticalSeparator />
			<AiImageGenerationButton @toggle-ai-generate="toggleImageGeneratorPopup({ location: 'controls' })" />
		</template>

		<VerticalSeparator v-if="isDuplicateButtonVisible || isVisibilityButtonVisible || isDeleteButtonVisible" />

		<ControlsTooltip
			v-if="isStretchToFullWidthButtonVisible"
			:title="$t('common.stretch')"
		>
			<HostingerButton
				v-qa="'builder-section-btn-stretch'"
				button-type="plain"
				theme="highlight"
				@click="handleStretchToFullWidthClick"
			>
				<template #icon>
					<Icon
						name="width"
						:color="block.isFullWidth ? 'primary' : 'dark'"
						:class="{ 'block-edit-controls__icon--setting-enabled': block.isFullWidth, }"
					/>
				</template>
			</HostingerButton>
		</ControlsTooltip>

		<VerticalSeparator v-if="isStretchToFullWidthButtonVisible" />

		<ControlsTooltip
			v-if="isDuplicateButtonVisible"
			:title="$t('common.duplicate')"
		>
			<HostingerButton
				v-qa="'builder-sectionedit-buttonduplicate'"
				button-type="plain"
				theme="highlight"
				:title="$t('common.duplicate')"
				@click="handleDuplicateBlock"
			>
				<template #icon>
					<Icon name="content_copy" />
				</template>
			</HostingerButton>
		</ControlsTooltip>

		<ControlsTooltip
			v-if="isVisibilityButtonVisible"
			v-qa="'builder-sectionedit-visibility'"
			:title="$t('builder.editBlockButton.hideShow')"
		>
			<VisibilityControls
				class="edit-actions__button"
				:is-hidden-desktop="isBlockHiddenOnDesktop"
				:is-hidden-mobile="isBlockHiddenOnMobile"
				@set-desktop-visibility="setBlockHidden({
					isHidden: $event,
					positionKey: ELEMENT_POSITION_KEY_DESKTOP
				})"
				@set-mobile-visibility="setBlockHidden({
					isHidden: $event,
					positionKey: ELEMENT_POSITION_KEY_MOBILE
				})"
			/>
		</ControlsTooltip>

		<ControlsTooltip
			v-if="isDeleteButtonVisible"
			:title="$t('common.delete')"
		>
			<HostingerButton
				v-qa="'builder-sectionedit-buttondelete'"
				button-type="plain"
				theme="highlight"
				:title="$t('common.delete')"
				@click="handleBlockRemoval"
			>
				<template #icon>
					<Icon name="delete" />
				</template>
			</HostingerButton>
		</ControlsTooltip>

		<template v-if="isReorderingButtonVisible">
			<VerticalSeparator />

			<ControlsTooltip :title="$t('builder.editBlockButton.moveUp')">
				<HostingerButton
					v-qa="'builder-section-btn-moveup'"
					button-type="plain"
					theme="highlight"
					:title="$t('builder.editBlockButton.moveSectionUp')"
					:disabled="!isMovingBlockUpAllowed"
					@click="handleBlockReordering({ direction: 'up' })"
				>
					<template #icon>
						<Icon name="arrow_upward" />
					</template>
				</HostingerButton>
			</ControlsTooltip>
			<ControlsTooltip :title="$t('builder.editBlockButton.moveDown')">
				<HostingerButton
					v-qa="'builder-section-btn-movedown'"
					button-type="plain"
					theme="highlight"
					:title="$t('builder.editBlockButton.moveSectionDown')"
					:disabled="!isMovingBlockDownAllowed"
					@click="handleBlockReordering({ direction: 'down' })"
				>
					<template #icon>
						<Icon name="arrow_downward" />
					</template>
				</HostingerButton>
			</ControlsTooltip>
		</template>

		<ControlsTooltip
			v-if="isContextMenuButtonVisible"
			:title="$t('common.more')"
		>
			<VerticalSeparator />

			<HostingerButton
				ref="contextMenuButtonRef"
				v-qa="'builder-sectionedit-buttoncontextmenu'"
				button-type="plain"
				theme="highlight"
				:title="$t('builder.contextMenuTitle')"
				@click="setContextMenuVisibility({ isOpen: !isContextMenuWithTriggerActive })"
			>
				<template #icon>
					<Icon name="more_vert" />
				</template>
			</HostingerButton>
		</ControlsTooltip>
	</div>

	<Teleport
		v-if="isDeleteWarningVisible"
		to="body"
	>
		<SystemDialogModal
			:title="$t('builder.editBlockButton.deleteLinkedSection')"
			:primary-button-text="$t('common.cancel')"
			:secondary-button-text="$t('common.delete')"
			secondary-button-color="danger"
			@click-primary="isDeleteWarningVisible = false"
			@click-secondary="handleBlockRemoval({ forceRemove: true })"
			@close="isDeleteWarningVisible = false"
		>
			{{ $t('builder.editBlockButton.removeWarningText') }}
		</SystemDialogModal>
	</Teleport>

	<ContextMenuLayoutBlock
		v-if="isContextMenuWithTriggerActive"
		is-enabled
		:block-elements="blockElements"
		:block-id="blockId"
		:trigger-ref="contextMenuButtonRef?.$el"
		:is-mobile-copy-paste-allowed="isBlockWithElements"
		@delete-block="handleBlockRemoval"
		@close-context-menu="setContextMenuVisibility({ isOpen: false })"
	/>
</template>

<script setup>
import {
	onUnmounted,
	computed,
	ref,
} from 'vue';
import { useStore } from 'vuex';

import AiTypeSelect from '@/components/AiTypeSelect.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import ControlsTooltip from '@/components/ControlsTooltip.vue';
import VisibilityControls from '@/components/builder-controls/VisibilityControls.vue';
import SystemDialogModal from '@/components/builder-modals/modals/SystemDialogModal.vue';
import ContextMenuLayoutBlock from '@/components/context-menu/ContextMenuLayoutBlock.vue';
import VerticalSeparator from '@/components/global/VerticalSeparator.vue';

import Icon from '@/components/global/Icon.vue';

import {
	ELEMENT_POSITION_KEY_MOBILE,
	ELEMENT_POSITION_KEY_DESKTOP,
	BLOCK_TYPE_LAYOUT,
	SYSTEM_LOCALE,
} from '@zyro-inc/site-modules/constants';

import { cloneBlock } from '@/utils/siteDataUtils';
import { useHoveredBlock } from '@/use/useHoveredBlock';
import { useLayoutContextMenu } from '@/components/context-menu/useLayoutContextMenu';
import { useContextMenu } from '@/components/context-menu/useContextMenu';
import { useBlockLayout } from '@zyro-inc/site-modules/components/blocks/layout/useBlockLayout';
import { isAiImageGeneratorOpen } from '@/use/useAiImageGenerator';
import EventLogApi from '@/api/EventLogApi';

import {
	moveOneLeft,
	moveOneRight,
} from '@/utils/array';
import AiImageGenerationButton from '@/components/builder-controls/AiImageGenerationButton.vue';
import { useBuilderMode } from '@/use/useBuilderMode';
import { addBreadcrumb } from '@sentry/vue';
import { isHostingerBrand } from '@/utils/isHostingerBrand';
import { useSiteStore } from '@/stores/siteStore';
import { useUserStore } from '@/stores';

const props = defineProps({
	blockId: {
		type: String,
		required: true,
	},
	enterEditModeButtonTitle: {
		type: String,
		required: true,
	},
	targetRef: {
		type: HTMLElement,
		default: null,
	},
	isDuplicateButtonVisible: {
		type: Boolean,
		default: false,
	},
	isVisibilityButtonVisible: {
		type: Boolean,
		default: false,
	},
	isDeleteButtonVisible: {
		type: Boolean,
		default: false,
	},
	showDeleteWarning: {
		type: Boolean,
		default: false,
	},
	isReorderingButtonVisible: {
		type: Boolean,
		default: false,
	},
	isContextMenuButtonVisible: {
		type: Boolean,
		default: true,
	},
	isBlockWithElements: {
		type: Boolean,
		default: false,
	},
	isStretchToFullWidthButtonVisible: {
		type: Boolean,
		default: false,
	},
});

const {
	getters,
	state,
	dispatch,
} = useStore();

const { hoveredBlock } = useHoveredBlock();
const { selectedLayoutBlockId } = useLayoutContextMenu();
const {
	mousePositionX,
	mousePositionY,
	isContextMenuWithTriggerActive,
} = useContextMenu();
const { isAiBuilderMode } = useBuilderMode();

const contextMenuButtonRef = ref(null);
const isDeleteWarningVisible = ref(false);

const siteStore = useSiteStore();
const userStore = useUserStore();
const isMobileScreen = computed(() => state.gui.isMobileScreen);
const isCurrentPagePrivate = computed(() => getters.isCurrentPagePrivate);
const website = computed(() => siteStore.site);
const siteBlocks = computed(() => getters.siteBlocks);
const siteElements = computed(() => getters.siteElements);
const currentPage = computed(() => getters.currentPage);
const currentPageBlocks = computed(() => currentPage.value.blocks);
const blockOrderIndex = computed(() => currentPage.value.blocks.indexOf(props.blockId));
const block = computed(() => siteBlocks.value[props.blockId]);

const isDeleteButtonVisible = computed(() => props.isDeleteButtonVisible && !isMobileScreen.value);
const isVisibilityButtonVisible = computed(() => props.isVisibilityButtonVisible && !isMobileScreen.value);

const currentLocale = computed(() => state.currentLocale);
const currentPageId = computed(() => state.currentPageId);

const isBlockHiddenOnDesktop = computed(() => block.value[ELEMENT_POSITION_KEY_DESKTOP]?.isHidden ?? false);
const isBlockHiddenOnMobile = computed(() => block.value[ELEMENT_POSITION_KEY_MOBILE]?.isHidden ?? false);

const isMovingBlockUpAllowed = computed(() => blockOrderIndex.value !== 0 && blockOrderIndex.value !== -1);

const isMovingBlockDownAllowed = computed(() => blockOrderIndex.value !== currentPageBlocks.value.length - 1
	&& blockOrderIndex.value !== -1);

const isAiImageGenerationFeatureEnabled = computed(() => block.value.type === BLOCK_TYPE_LAYOUT && !isAiBuilderMode.value);

const { blockElements } = props.isBlockWithElements ? useBlockLayout({
	blockData: block,
	siteElements,
	shouldBuildResponsive: false,
}) : {};

const handleBlockEditButtonClick = () => {
	dispatch('enterBlockEditMode');
	dispatch('leaveElementEditMode');
};

const handleDuplicateBlock = () => {
	// Set current block and close editing popup
	dispatch('leaveElementEditMode');
	dispatch('leaveBlockEditMode');

	const {
		newBlock,
		newElements,
		newBlocks,
	} = cloneBlock({
		siteData: website.value,
		blockId: props.blockId,
		fromLocale: isCurrentPagePrivate.value ? SYSTEM_LOCALE : currentLocale.value,
	});

	dispatch('addBlock', {
		pageId: currentPageId.value,
		blockData: newBlock,
		blocks: newBlocks,
		elements: newElements,
		previousBlockId: props.blockId,
	});

	addBreadcrumb({
		category: 'CLICK:Duplicate section',
		data: {
			blockId: props.blockId,
			pageId: currentPageId.value,
		},
	});
};

const setBlockHidden = ({
	isHidden,
	positionKey,
}) => {
	dispatch('updateBlockData', {
		blockId: props.blockId,
		blockData: {
			[positionKey]: {
				isHidden,
			},
		},
		merge: true,
	});
};

const handleBlockRemoval = ({ forceRemove = false } = {}) => {
	if (props.showDeleteWarning && !forceRemove) {
		isDeleteWarningVisible.value = true;
	} else {
		dispatch('leaveElementEditMode');
		dispatch('leaveBlockEditMode');
		dispatch('setDefaultBlockEditTab', '');

		addBreadcrumb({
			category: 'CLICK:Remove section',
			data: {
				blockId: props.blockId,
			},
		});

		dispatch('removeBlock', {
			blockId: props.blockId,
		});
		hoveredBlock.value = null;
	}
};

const handleBlockReordering = async ({ direction }) => {
	const newBlocksOrder = direction === 'up'
		? moveOneLeft(currentPageBlocks.value, props.blockId)
		: moveOneRight(currentPageBlocks.value, props.blockId);

	dispatch('mergePageData', {
		pageId: currentPageId.value,
		pageData: {
			blocks: newBlocksOrder,
		},
	});
};

const toggleImageGeneratorPopup = ({ location } = {}) => {
	if (!isAiImageGeneratorOpen.value) {
		EventLogApi.logEvent({
			eventName: 'website_builder.ai_image_generator.enter',
			eventProperties: {
				location: `section-${location}`,
			},
		});
	}

	isAiImageGeneratorOpen.value = !isAiImageGeneratorOpen.value;
};

const setContextMenuVisibility = ({ isOpen }) => {
	selectedLayoutBlockId.value = isOpen ? props.blockId : null;
	isContextMenuWithTriggerActive.value = isOpen;
	mousePositionX.value = null;
	mousePositionY.value = null;
};

const handleStretchToFullWidthClick = () => {
	dispatch('updateBlockData', {
		blockId: props.blockId,
		blockData: {
			isFullWidth: !block.value.isFullWidth,
		},
		merge: true,
	});
};

onUnmounted(() => {
	isAiImageGeneratorOpen.value = false;
});
</script>

<style lang="scss" scoped>
.block-edit-controls {
	$desktop-icon-size: 20px;

	display: flex;
	align-items: center;
	background-color: $color-light;
	box-shadow: $box-shadow-base;
	padding: 2px;
	border-radius: $border-radius-medium;
	user-select: none;

	@media screen and (max-width: $media-mobile) {
		box-shadow: none;
		justify-content: space-evenly;
		border-radius: 0;
		padding: 8px;
		height: $builder-preview-bottom-bar-height;
		border-top: 1px solid $color-gray-border;
	}

	&__icon {
		&--setting-enabled {
			transform: scaleX(1.2);
		}
	}

	&__additional-buttons {
		display: flex;
		align-items: center;
	}

	:deep() {
		.controls-tooltip,
		.zyro-button,
		.linked-block-controls {
			@media screen and (max-width: $media-mobile) {
				flex-grow: 1;
			}
		}
	}

	:deep(.zyro-button) {
		box-shadow: none;

		@media screen and (max-width: $media-mobile) {
			padding: 8px;
		}

		@media screen and (min-width: $media-mobile) {
			.icon {
				font-size: $desktop-icon-size;

				&__dimensions {
					width: $desktop-icon-size;
					height: $desktop-icon-size;
				}
			}
		}
	}
}
</style>
