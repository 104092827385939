<script setup lang="ts">
import AiBlogGeneratorModal from '@/components/AiBlogGeneratorModal.vue';
import Icon from '@/components/global/Icon.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import Popup from '@/components/global/Popup.vue';
import BuilderSidebarActionButton from '@/components/builder-view/sidebar/BuilderSidebarActionButton.vue';
import AiImageGenerationPopup from '@/components/builder-controls/AiImageGenerationPopup.vue';
import EventLogApi from '@/api/EventLogApi';

import { watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useAiToolsStore } from '@/stores';

interface Props {
	isActive: boolean;
	title: string;
	icon: string;
}

const props = defineProps<Props>();

defineEmits<{
	'close-popup': [],
	'action-click': [],
}>();

watch(
	() => props.isActive,
	(isActive) => {
		if (!isActive) return;

		window.hj('event', 'website_builder.ai_tools.enter');
	},
);

const aiToolsStore = useAiToolsStore();

const {
	aiTools,
	isAiImageGeneratorOpen,
	isAiBlogGeneratorModalOpen,
	isBlogBeingGenerated,
} = storeToRefs(aiToolsStore);

const toggleImageGeneratorPopup = () => {
	if (!isAiImageGeneratorOpen.value) {
		EventLogApi.logEvent({
			eventName: 'website_builder.ai_image_generator.enter',
			eventProperties: {
				location: 'side_menu',
			},
		});
	}

	isAiImageGeneratorOpen.value = !isAiImageGeneratorOpen.value;
};

</script>

<template>
	<BuilderSidebarActionButton
		ref="triggerButton"
		v-qa="'builder-sidebar-btn-aitools'"
		:is-active="isActive"
		:icon="icon"
		:title="title"
		@action-click="$emit('action-click'), isAiImageGeneratorOpen = false"
	/>
	<Popup
		v-if="isActive"
		:target-ref="($refs.triggerButton as typeof BuilderSidebarActionButton)?.$el"
		auto-update
		:padding="8"
		@click-outside="$emit('close-popup')"
	>
		<ul class="sidebar-popup">
			<template
				v-for="aiTool in aiTools"
				:key="aiTool.title"
			>
				<li
					v-if="!aiTool.isHidden"
					class="sidebar-popup__list-item"
					:class="{ 'sidebar-popup__list-item--disabled': aiTool.isDisabled }"
				>
					<HostingerButton
						v-qa="`builder-sidebar-btn-aitool${aiTool.title}`"
						:title="aiTool.title"
						:disabled="aiTool.isDisabled"
						button-type="plain"
						class="sidebar-popup__action-button"
						@click.stop="aiTool.clickHandler(), $emit('close-popup')"
					>
						<div class="sidebar-popup__action-button-left text-body-2">
							<Icon
								:name="aiTool.icon"
								dimensions="32px"
								is-custom
							/>

							{{ aiTool.title }}
						</div>
						<Icon
							v-if="aiTool.isExternalLink"
							name="open_in_new"
							dimensions="20px"
						/>
					</HostingerButton>
				</li>
			</template>
		</ul>
	</Popup>

	<AiImageGenerationPopup
		v-if="isAiImageGeneratorOpen"
		should-add-image-element
		@close="toggleImageGeneratorPopup"
	/>
	<AiBlogGeneratorModal
		v-if="isAiBlogGeneratorModalOpen"
		:is-blog-being-generated="isBlogBeingGenerated"
		is-skip-button-hidden
		@close="aiToolsStore.closeAiBlogGeneratorModal"
		@generate="aiToolsStore.generateAiBlog"
	/>
</template>

<style lang="scss" scoped>
@import "./BuilderSidebarPopup";

.sidebar-popup {
	&__action-button {
		width: 100%;

		:deep(.hostinger-button__text) {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}

	&__action-button-left {
		display: flex;
		align-items: center;
		gap: 8px;
	}
}
</style>
