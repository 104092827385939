export const DEFAULT_MESSENGER_LOCALE = {
	title: 'US (en)',
	value: 'en_US',
};

export const MESSENGER_LOCALES = [
	DEFAULT_MESSENGER_LOCALE,
	{
		title: 'ES (ca)',
		value: 'ca_ES',
	},
	{
		title: 'CZ (cs)',
		value: 'cs_CZ',
	},
	{
		title: 'PH (cx)',
		value: 'cx_PH',
	},
	{
		title: 'GB (cy)',
		value: 'cy_GB',
	},
	{
		title: 'DK (da)',
		value: 'da_DK',
	},
	{
		title: 'DE (de)',
		value: 'de_DE',
	},
	{
		title: 'ES (eu)',
		value: 'eu_ES',
	},
	{
		title: 'UD (en)',
		value: 'en_UD',
	},
	{
		title: 'LA (es)',
		value: 'es_LA',
	},
	{
		title: 'ES (es)',
		value: 'es_ES',
	},
	{
		title: 'PY (gn)',
		value: 'gn_PY',
	},
	{
		title: 'FI (fi)',
		value: 'fi_FI',
	},
	{
		title: 'FR (fr)',
		value: 'fr_FR',
	},
	{
		title: 'ES (gl)',
		value: 'gl_ES',
	},
	{
		title: 'HU (hu)',
		value: 'hu_HU',
	},
	{
		title: 'IT (it)',
		value: 'it_IT',
	},
	{
		title: 'JP (ja)',
		value: 'ja_JP',
	},
	{
		title: 'KR (ko)',
		value: 'ko_KR',
	},
	{
		title: 'NO (nb)',
		value: 'nb_NO',
	},
	{
		title: 'NO (nn)',
		value: 'nn_NO',
	},
	{
		title: 'NL (nl)',
		value: 'nl_NL',
	},
	{
		title: 'NL (fy)',
		value: 'fy_NL',
	},
	{
		title: 'PL (pl)',
		value: 'pl_PL',
	},
	{
		title: 'BR (pt)',
		value: 'pt_BR',
	},
	{
		title: 'PT (pt)',
		value: 'pt_PT',
	},
	{
		title: 'RO (ro)',
		value: 'ro_RO',
	},
	{
		title: 'RU (ru)',
		value: 'ru_RU',
	},
	{
		title: 'SK (sk)',
		value: 'sk_SK',
	},
	{
		title: 'SI (sl)',
		value: 'sl_SI',
	},
	{
		title: 'SE (sv)',
		value: 'sv_SE',
	},
	{
		title: 'TH (th)',
		value: 'th_TH',
	},
	{
		title: 'TR (tr)',
		value: 'tr_TR',
	},
	{
		title: 'TR (ku)',
		value: 'ku_TR',
	},
	{
		title: 'CN (zh)',
		value: 'zh_CN',
	},
	{
		title: 'HK (zh)',
		value: 'zh_HK',
	},
	{
		title: 'TW (zh)',
		value: 'zh_TW',
	},
	{
		title: 'ZA (af)',
		value: 'af_ZA',
	},
	{
		title: 'AL (sq)',
		value: 'sq_AL',
	},
	{
		title: 'AM (hy)',
		value: 'hy_AM',
	},
	{
		title: 'AZ (az)',
		value: 'az_AZ',
	},
	{
		title: 'BY (be)',
		value: 'be_BY',
	},
	{
		title: 'IN (bn)',
		value: 'bn_IN',
	},
	{
		title: 'BA (bs)',
		value: 'bs_BA',
	},
	{
		title: 'BG (bg)',
		value: 'bg_BG',
	},
	{
		title: 'HR (hr)',
		value: 'hr_HR',
	},
	{
		title: 'BE (nl)',
		value: 'nl_BE',
	},
	{
		title: 'GB (en)',
		value: 'en_GB',
	},
	{
		title: 'EE (et)',
		value: 'et_EE',
	},
	{
		title: 'FO (fo)',
		value: 'fo_FO',
	},
	{
		title: 'CA (fr)',
		value: 'fr_CA',
	},
	{
		title: 'GE (ka)',
		value: 'ka_GE',
	},
	{
		title: 'GR (el)',
		value: 'el_GR',
	},
	{
		title: 'IN (gu)',
		value: 'gu_IN',
	},
	{
		title: 'IN (hi)',
		value: 'hi_IN',
	},
	{
		title: 'IS (is)',
		value: 'is_IS',
	},
	{
		title: 'ID (id)',
		value: 'id_ID',
	},
	{
		title: 'IE (ga)',
		value: 'ga_IE',
	},
	{
		title: 'ID (jv)',
		value: 'jv_ID',
	},
	{
		title: 'IN (kn)',
		value: 'kn_IN',
	},
	{
		title: 'KZ (kk)',
		value: 'kk_KZ',
	},
	{
		title: 'LV (lv)',
		value: 'lv_LV',
	},
	{
		title: 'LT (lt)',
		value: 'lt_LT',
	},
	{
		title: 'MK (mk)',
		value: 'mk_MK',
	},
	{
		title: 'MG (mg)',
		value: 'mg_MG',
	},
	{
		title: 'MY (ms)',
		value: 'ms_MY',
	},
	{
		title: 'MT (mt)',
		value: 'mt_MT',
	},
	{
		title: 'IN (mr)',
		value: 'mr_IN',
	},
	{
		title: 'MN (mn)',
		value: 'mn_MN',
	},
	{
		title: 'NP (ne)',
		value: 'ne_NP',
	},
	{
		title: 'IN (pa)',
		value: 'pa_IN',
	},
	{
		title: 'RS (sr)',
		value: 'sr_RS',
	},
	{
		title: 'SO (so)',
		value: 'so_SO',
	},
	{
		title: 'KE (sw)',
		value: 'sw_KE',
	},
	{
		title: 'PH (tl)',
		value: 'tl_PH',
	},
	{
		title: 'IN (ta)',
		value: 'ta_IN',
	},
	{
		title: 'IN (te)',
		value: 'te_IN',
	},
	{
		title: 'IN (ml)',
		value: 'ml_IN',
	},
	{
		title: 'UA (uk)',
		value: 'uk_UA',
	},
	{
		title: 'UZ (uz)',
		value: 'uz_UZ',
	},
	{
		title: 'VN (vi)',
		value: 'vi_VN',
	},
	{
		title: 'KH (km)',
		value: 'km_KH',
	},
	{
		title: 'TJ (tg)',
		value: 'tg_TJ',
	},
	{
		title: 'AR (ar)',
		value: 'ar_AR',
	},
	{
		title: 'IL (he)',
		value: 'he_IL',
	},
	{
		title: 'PK (ur)',
		value: 'ur_PK',
	},
	{
		title: 'IR (fa)',
		value: 'fa_IR',
	},
	{
		title: 'AF (ps)',
		value: 'ps_AF',
	},
	{
		title: 'MM (my)',
		value: 'my_MM',
	},
	{
		title: 'MM (qz)',
		value: 'qz_MM',
	},
	{
		title: 'IN (or)',
		value: 'or_IN',
	},
	{
		title: 'LK (si)',
		value: 'si_LK',
	},
	{
		title: 'RW (rw)',
		value: 'rw_RW',
	},
	{
		title: 'IQ (cb)',
		value: 'cb_IQ',
	},
	{
		title: 'NG (ha)',
		value: 'ha_NG',
	},
	{
		title: 'KS (ja)',
		value: 'ja_KS',
	},
	{
		title: 'FR (br)',
		value: 'br_FR',
	},
	{
		title: 'MA (tz)',
		value: 'tz_MA',
	},
	{
		title: 'FR (co)',
		value: 'co_FR',
	},
	{
		title: 'IN (as)',
		value: 'as_IN',
	},
	{
		title: 'NG (ff)',
		value: 'ff_NG',
	},
	{
		title: 'IT (sc)',
		value: 'sc_IT',
	},
	{
		title: 'PL (sz)',
		value: 'sz_PL',
	},
];
