<script setup lang="ts">
import ZyroModal from '@/components/global/ZyroModal.vue';
import { MODAL_WORDPRESS_EXPORT_FAIL } from '@/constants';
import { CLOSE_MODAL } from '@/store/builder/gui';
import Icon from '@/components/global/Icon.vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const { dispatch } = useStore();

const closeModal = () => {
	dispatch(`gui/${CLOSE_MODAL}`, {
		name: MODAL_WORDPRESS_EXPORT_FAIL,
	});
};
</script>

<template>
	<Teleport to="body">
		<ZyroModal
			:max-width="'376px'"
			:height="'auto'"
			position="center"
			class="wordpress-export-fail-modal"
			show-close-button
			content-padding="32px"
			@close-modal="closeModal"
		>
			<div class="wordpress-export-fail-modal__content">
				<Icon
					class="wordpress-export-fail-modal__icon"
					name="cancel"
					color="danger"
					is-filled
					dimensions="40px"
				/>
				<h2 class="wordpress-export-fail-modal__title text-heading-2">
					{{ t('builder.wordpressExportFailModalTitle') }}
				</h2>
				<p class="wordpress-export-fail-modal__description text-body-2">
					{{ t('builder.wordpressExportFailModalDescription') }}
				</p>
			</div>
		</ZyroModal>
	</Teleport>
</template>

<style lang="scss" scoped>
.wordpress-export-fail-modal {
	text-align: center;

	&__content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	&__title {
		margin: 12px 0 4px;
	}

	&__description {
		color: var(--color-gray)
	}
}
</style>
