<script setup>
import {
	DRAWER_ADD_ELEMENT,
	DRAWER_MULTI_PAGE,
	DRAWER_USER_STYLES,
	SIDEBAR_WIDTH,
	ONBOARDING_STEP_MULTI_PAGE_DRAWER,
	ONBOARDING_STEP_ADD_ELEMENTS_DRAWER,
	ONBOARDING_STEP_USER_STYLES_DRAWER,
	ONBOARDING_STEP_MOBILE_VIEW,
	ONBOARDING_STEP_PUBLISH,
} from '@/constants';
import { useStore } from 'vuex';
import {
	disableBodyScroll,
	enableBodyScroll,
} from 'body-scroll-lock';

import OnboardingPopup from '@/components/onboarding/OnboardingPopup.vue';

import {
	PUBLISH_BUTTON_SELECTOR,
	BUILDER_SIDEBAR_SELECTOR,
	MOBILE_SWITCH_BUTTON_SELECTOR,
} from '@/components/onboarding/onboardingSelectors';
import { useOnboarding } from '@/components/onboarding/useOnboarding';
import { useOverlay } from '@/use/useOverlay';
import { isHostingerBrand } from '@/utils/isHostingerBrand';

import {
	onMounted,
	computed,
	nextTick,
	ref,
} from 'vue';

const onboardingPopupRef = ref();
const popupPositionRef = ref(null);
const popupPositioning = ref(null);

const {
	getters,
	dispatch,
} = useStore();

const isMobileView = computed(() => getters['gui/isMobileMode']);

const {
	goToNextOnboardingStep,
	goToPreviousOnboardingStep,
	onboardingStepsCountToShow,
	currentOnboardingStep,
	endOnboarding,
	onboardingEndCallback,
} = useOnboarding();
const {
	setHighlightedElement,
	setHighlightedElementCoordinates,
	isHighlightedElementInteractive,
} = useOverlay();

const getElementPopperReference = (selector) => `[data-popper-reference="${selector}"]`;

const updateStep = ({
	selector,
	placement = 'right-start',
}) => {
	// Get dom elements
	const elementPopperReference = getElementPopperReference(selector);
	const target = document.querySelector(elementPopperReference);

	popupPositionRef.value = target;
	popupPositioning.value = placement;
};

onMounted(() => {
	disableBodyScroll(onboardingPopupRef.value.$el);
});

const goBackFromMobileStep = () => {
	if (isMobileView.value) {
		dispatch('gui/toggleMobileView');
	}

	goToPreviousOnboardingStep();
};

const stopOnboarding = () => {
	onboardingEndCallback.value = null;
	endOnboarding();
	enableBodyScroll(onboardingPopupRef.value.$el);
};

const showDrawerStep = async ({ drawerId }) => {
	dispatch('gui/OPEN_DRAWER', {
		id: drawerId,
	});

	// Drawer appears in the DOM only after next tick
	await nextTick();

	const elementPopperSelector = getElementPopperReference(BUILDER_SIDEBAR_SELECTOR);
	const sidebarElement = document.querySelector(elementPopperSelector);

	updateStep({
		selector: BUILDER_SIDEBAR_SELECTOR,
	});

	const {
		x,
		y,
		width,
		height,
	} = sidebarElement.getBoundingClientRect();

	// We want to highlight not only the drawer, but sidebar as well - so we adjust the X and WIDTH values
	setHighlightedElementCoordinates({
		x: x - SIDEBAR_WIDTH,
		y,
		width: width + SIDEBAR_WIDTH,
		height,
	});
	isHighlightedElementInteractive.value = true;
};

const showPublishStepPopup = () => {
	if (isMobileView.value) {
		dispatch('gui/toggleMobileView');
	}

	updateStep({
		selector: PUBLISH_BUTTON_SELECTOR,
		placement: 'bottom-start',
	});

	const elementPopperReference = getElementPopperReference(PUBLISH_BUTTON_SELECTOR);

	setHighlightedElement({
		element: document.querySelector(elementPopperReference),
	});
};

const showMobileStepPopup = async () => {
	dispatch('gui/CLOSE_DRAWER');

	await nextTick();

	updateStep({
		selector: MOBILE_SWITCH_BUTTON_SELECTOR,
		placement: 'bottom-start',
	});

	const elementPopperReference = getElementPopperReference(MOBILE_SWITCH_BUTTON_SELECTOR);

	setHighlightedElement({
		element: document.querySelector(elementPopperReference),
	});

	if (!isMobileView.value) {
		dispatch('gui/toggleMobileView');
	}
};
</script>

<template>
	<div class="onboarding">
		<OnboardingPopup
			v-if="currentOnboardingStep === ONBOARDING_STEP_MULTI_PAGE_DRAWER"
			ref="onboardingPopupRef"
			:key="ONBOARDING_STEP_MULTI_PAGE_DRAWER"
			:target-ref="popupPositionRef"
			:popup-positioning="popupPositioning"
			:current-step-number="1"
			:max-steps-number="onboardingStepsCountToShow"
			:href="!isHostingerBrand ? 'https://www.youtube.com/watch?v=mYrGIXbWpUQ' : ''"
			@click-go-back="goToPreviousOnboardingStep"
			@click-go-forward="goToNextOnboardingStep"
			@close="stopOnboarding"
			@vue:mounted="showDrawerStep({ drawerId: DRAWER_MULTI_PAGE })"
		>
			<template #title>
				{{ $t('builder.onboarding.editPages.title') }}
			</template>
			<template #subtitle>
				{{ $t('builder.onboarding.editPages.description') }}
			</template>
			<template #link>
				{{ $t('builder.onboardingAddPagesStepLinkText') }}
			</template>
		</OnboardingPopup>
		<OnboardingPopup
			v-else-if="currentOnboardingStep === ONBOARDING_STEP_ADD_ELEMENTS_DRAWER"
			ref="onboardingPopupRef"
			:key="ONBOARDING_STEP_ADD_ELEMENTS_DRAWER"
			:target-ref="popupPositionRef"
			:popup-positioning="popupPositioning"
			:current-step-number="2"
			:max-steps-number="onboardingStepsCountToShow"
			:href="!isHostingerBrand ? 'https://www.youtube.com/watch?v=xZ-331lAT5Q' : ''"
			@click-go-back="goToPreviousOnboardingStep"
			@click-go-forward="goToNextOnboardingStep"
			@close="stopOnboarding"
			@vue:mounted="showDrawerStep({ drawerId: DRAWER_ADD_ELEMENT })"
		>
			<template #title>
				{{ $t('builder.onboarding.addElements.title') }}
			</template>
			<template #subtitle>
				{{ $t('builder.onboarding.addElements.description') }}
			</template>
			<template #link>
				{{ $t('builder.onboardingAddElementStepLinkText') }}
			</template>
		</OnboardingPopup>
		<OnboardingPopup
			v-else-if="currentOnboardingStep === ONBOARDING_STEP_USER_STYLES_DRAWER"
			ref="onboardingPopupRef"
			:key="ONBOARDING_STEP_USER_STYLES_DRAWER"
			:target-ref="popupPositionRef"
			:popup-positioning="popupPositioning"
			:current-step-number="3"
			:max-steps-number="onboardingStepsCountToShow"
			:href="!isHostingerBrand ? 'https://support.zyro.com/articles/6043621-how-to-customize-a-website#h_a6dc2a367b' : ''"
			@click-go-back="goToPreviousOnboardingStep"
			@click-go-forward="goToNextOnboardingStep"
			@close="stopOnboarding"
			@vue:mounted="showDrawerStep({ drawerId: DRAWER_USER_STYLES })"
		>
			<template #title>
				{{ $t('builder.onboarding.changeStyles.title') }}
			</template>
			<template #subtitle>
				{{ $t('builder.onboarding.changeStyles.descriptionV2') }}
			</template>
			<template #link>
				{{ $t('builder.onboardingChangeStylesStepLinkText') }}
			</template>
		</OnboardingPopup>
		<OnboardingPopup
			v-else-if="currentOnboardingStep === ONBOARDING_STEP_MOBILE_VIEW"
			ref="onboardingPopupRef"
			:key="ONBOARDING_STEP_MOBILE_VIEW"
			:target-ref="popupPositionRef"
			:popup-positioning="popupPositioning"
			:current-step-number="4"
			:max-steps-number="onboardingStepsCountToShow"
			:href="!isHostingerBrand ? 'https://support.zyro.com/en/articles/4318720-how-to-edit-mobile-and-desktop-views-separately' : ''"
			@click-go-back="goBackFromMobileStep"
			@click-go-forward="goToNextOnboardingStep"
			@close="stopOnboarding"
			@vue:mounted="showMobileStepPopup"
		>
			<template #title>
				{{ $t('builder.onboarding.mobileView.title') }}
			</template>
			<template #subtitle>
				{{ $t('builder.onboarding.mobileView.description') }}
			</template>
			<template #link>
				{{ $t('builder.onboardingMobileStepLinkText') }}
			</template>
		</OnboardingPopup>
		<OnboardingPopup
			v-else-if="currentOnboardingStep === ONBOARDING_STEP_PUBLISH"
			ref="onboardingPopupRef"
			:key="ONBOARDING_STEP_PUBLISH"
			:target-ref="popupPositionRef"
			:popup-positioning="popupPositioning"
			:current-step-number="5"
			:max-steps-number="onboardingStepsCountToShow"
			:href="!isHostingerBrand ? 'https://www.youtube.com/watch?v=X4cRv7_OwRE' : ''"
			@click-go-back="goToPreviousOnboardingStep"
			@click-go-forward="goToNextOnboardingStep"
			@close="stopOnboarding"
			@vue:mounted="showPublishStepPopup"
		>
			<template #title>
				{{ $t('builder.onboarding.goLive.titleV2') }}
			</template>
			<template #subtitle>
				{{ isHostingerBrand
					? $t('builder.onboardingPublishStepHostingerText')
					: $t('builder.onboarding.goLive.description')
				}}
			</template>
			<template #link>
				{{ $t('builder.onboardingPublishStepLinkText') }}
			</template>
			<template #button-text>
				{{ $t('builder.onboardingFinish') }}
			</template>
		</OnboardingPopup>
	</div>
</template>

<style lang="scss" scoped>
.onboarding {
	pointer-events: all;
}
</style>
