<template>
	<div>
		<p class="text-bold-2 select-label">
			{{ $t('builder.onClick') }}
		</p>
		<ZyroSelect
			v-qa="'image-click-action-select'"
			label-key="title"
			:filterable="false"
			:searchable="false"
			:options="imageClickActions"
			:model-value="imageClickAction"
			@update:model-value="setImageClickAction"
		>
			<template #option="{ option }">
				<div v-qa="`dropdown-option-${option.title}`">
					{{ option.title }}
				</div>
			</template>
		</ZyroSelect>
		<p class="text-body-2 select-message">
			{{ $t('builder.onClickDescription') }}
		</p>
		<LinkSettings
			v-if="imageClickAction.value === IMAGE_CLICK_ACTION_LINK"
			:label="$t('builder.editButton.tabActionLabel')"
			:target="currentElement.target"
			:href="currentElement.href"
			:rel="currentElement.rel"
			:type="currentElement.linkType"
			:page-id="currentElement.linkedPageId"
			@update:href="setElementProperty('href', $event)"
			@update:pageId="setElementProperty('linkedPageId', $event)"
			@update:rel="setElementProperty('rel', $event)"
			@update:target="setElementProperty('target', $event)"
			@update:type="setElementProperty('linkType', $event)"
		/>
	</div>
</template>

<script setup>
import ZyroSelect from '@/components/global/ZyroSelect.vue';

import { useStore } from 'vuex';

import {
	IMAGE_CLICK_ACTION_NONE,
	IMAGE_CLICK_ACTION_LIGHTBOX,
	IMAGE_CLICK_ACTION_LINK,
} from '@zyro-inc/site-modules/constants';

import LinkSettings from '@/components/builder-controls/edit-button/LinkSettings.vue';

import {
	computed,
	ref,
} from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const {
	getters,
	dispatch,
} = useStore();
const imageClickActions = [
	{
		title: t('builder.imageClickAction.action.none'),
		value: IMAGE_CLICK_ACTION_NONE,
	},
	{
		title: t('builder.imageClickAction.action.lightbox'),
		value: IMAGE_CLICK_ACTION_LIGHTBOX,
	},
	{
		title: t('builder.imageClickAction.action.link'),
		value: IMAGE_CLICK_ACTION_LINK,
	},
];

const currentElementId = ref(getters.currentElementId);
const currentElement = computed(() => getters.currentElement);
const imageClickAction = computed(() => {
	const elementClickAction = imageClickActions.find(({ value }) => currentElement.value?.settings.clickAction === value);

	return elementClickAction || imageClickActions[0];
});

const setImageClickAction = (action) => {
	dispatch('mergeElementData', {
		elementId: currentElementId.value,
		elementData: {
			settings: {
				clickAction: action.value,
			},
		},
	});
};

const setElementProperty = (property, value) => {
	dispatch('mergeElementData', {
		elementId: currentElementId.value,
		elementData: {
			[property]: value,
		},
	});
};
</script>

<style lang="scss" scoped>
.select-label {
	margin-bottom: 8px;
}

.select-message {
	margin: 4px 0;
	color: $color-gray;
	font-size: 12px;
}
</style>
