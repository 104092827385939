import {
	nextTick,
	ref,
} from 'vue';
import { captureException } from '@sentry/vue';
import { useStore } from 'vuex';
import { generateBlog } from '@/api/AiApi';
import { useNotifications } from '@/use/useNotifications';
import { useI18n } from 'vue-i18n';
import { updateElementPositionFromDOM } from '@/components/block-layout/useLayout';
import { useDeviceElementHeight } from '@/use/useDeviceElementHeight';
import { ELEMENT_TYPE_TEXT_BOX } from '@zyro-inc/site-modules/constants';
import { getRandomArrayItem } from '@/utils/array';
import { BLOG_PLACEHOLDERS } from '@/data';
import EventLogApi from '@/api/EventLogApi';
import { getExampleBlogPostsData } from '@/use/useAddPage';

type AiBlogType = {
	toneOfVoice: string
	lengthOfContent: string
	blogDescription: string
}

export const useAiBlogGenerator = () => {
	const { notify } = useNotifications();
	const { updateElementHeightOnDevices } = useDeviceElementHeight();

	const {
		dispatch,
		getters,
		state,
	} = useStore();

	const { t } = useI18n();

	const isAiBlogGeneratorModalOpen = ref(false);
	const isBlogBeingGenerated = ref(false);

	const generateAiBlog = async ({
		toneOfVoice,
		lengthOfContent,
		blogDescription,
	}: AiBlogType): Promise<void> => {
		isBlogBeingGenerated.value = true;

		try {
			const { data } = await generateBlog({
				tone: toneOfVoice,
				length: lengthOfContent,
				description: blogDescription,
			});

			const {
				title: postTitle,
				description: postDescription,
				content: postContent,
				images,
			} = data;

			dispatch('addBlogPostPage', {
				postTitle,
				postDescription,
				postContent,
				postThumbnail: images?.[0] || getRandomArrayItem(BLOG_PLACEHOLDERS),
			});

			dispatch('gui/CLOSE_DRAWER');

			await nextTick();

			const {
				currentPage,
				currentLanguageData,
			} = getters;

			currentPage.blocks.forEach((blockId: string) => {
				currentLanguageData.blocks[blockId].components.forEach((elementId: string) => {
					updateElementPositionFromDOM({
						elementId,
						blockId,
						dispatch,
						isMobileMode: getters['gui/isMobileMode'],
					});

					// Avoids recalculating height for images - prevents from text overlapping images
					if (getters.siteElements[elementId].type !== ELEMENT_TYPE_TEXT_BOX) {
						return;
					}

					updateElementHeightOnDevices({
						elementId,
						blockId,
					});
				});
			});

			dispatch('blog/calculateReadTime', {
				pageId: state.currentPageId,
			});

			isBlogBeingGenerated.value = false;
			isAiBlogGeneratorModalOpen.value = false;
		} catch (error) {
			captureException(error, {
				tags: {
					errorType: 'AI Blog Generation Error',
				},
			});
			console.error(error);

			notify({
				message: t('builder.aiBlogGenerationError'),
			});

			isBlogBeingGenerated.value = false;
		}

		EventLogApi.logEvent({
			eventName: 'website_builder.blog_add_post.create_content',
			eventProperties: {
				tone_of_voice: toneOfVoice,
				length: lengthOfContent,
				blog_post_description: blogDescription,
			},
		});
	};

	const closeAiBlogGeneratorModal = () => {
		isAiBlogGeneratorModalOpen.value = false;
	};

	const showAiBlogGeneratorModal = () => {
		isAiBlogGeneratorModalOpen.value = true;
	};

	const hideAiBlogGeneratorModal = () => {
		isAiBlogGeneratorModalOpen.value = false;
	};

	const createNewDummyBlogPage = () => {
		const blogPostsData = getExampleBlogPostsData({
			postTitle: t('builder.blog.blogPost.title'),
			postDescription: t('builder.blog.blogPost.description'),
			postContent: t('builder.blog.blogPageElements.content'),
			postThumbnails: BLOG_PLACEHOLDERS,
		});

		dispatch('addBlog', {
			pageTitle: t('common.blog'),
			blogPostsData,
		});

		if (state.user.user?.id) {
			window.hj('identify', state.user.user?.id, {
				'builder.blog.add_blog_page': true,
			});
		}

		EventLogApi.logEvent({
			eventName: 'website_builder.start_blog.enter',
		});
	};

	return {
		isAiBlogGeneratorModalOpen,
		isBlogBeingGenerated,
		createNewDummyBlogPage,
		closeAiBlogGeneratorModal,
		showAiBlogGeneratorModal,
		hideAiBlogGeneratorModal,
		generateAiBlog,
	};
};
