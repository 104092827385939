<template>
	<div>
		<div class="element-edit-controls-bar-buttons">
			<AiTypeSelect
				v-if="userStore.isAiTypeSelectionVisible"
				:element-id="props.elementId"
			/>
			<HostingerButton
				v-qa="'element-button-edit'"
				button-type="plain"
				theme="highlight"
				@click="editElement"
			>
				<template
					v-if="isMobileScreen"
					#icon
				>
					<Icon name="settings" />
				</template>

				<template v-if="!isMobileScreen">
					<span class="text-body-2">
						{{ enterElementEditModeButtonTitle }}
					</span>
				</template>
			</HostingerButton>

			<slot />
			<template v-if="!isMainControlsDisabled">
				<VerticalSeparator />

				<ControlsTooltip :title="$t('common.duplicate')">
					<HostingerButton
						v-qa="'builder-textelementedit-buttonduplicate'"
						button-type="plain"
						theme="highlight"
						:title="$t('common.duplicate')"
						@click="duplicateSelectedElement"
					>
						<template #icon>
							<Icon name="content_copy" />
						</template>
					</HostingerButton>
					<template #shortcut>
						<span>
							{{ isMac ? '⌘D' : 'Ctrl+D' }}
						</span>
					</template>
				</ControlsTooltip>

				<ControlsTooltip
					v-if="!isMobileScreen"
					:title="$t('builder.editBlockButton.hideShow')"
				>
					<VisibilityControls
						:is-hidden-desktop="isElementHiddenOnDesktop"
						:is-hidden-mobile="isElementHiddenOnMobile"
						@set-desktop-visibility="(value) => setIsElementHidden({
							value,
							elementPositionKey: ELEMENT_POSITION_KEY_DESKTOP
						})"
						@set-mobile-visibility="(value) => setIsElementHidden({
							value,
							elementPositionKey: ELEMENT_POSITION_KEY_MOBILE
						})"
					/>
				</ControlsTooltip>

				<ControlsTooltip
					v-if="!isMobileScreen"
					:title="$t('common.delete')"
				>
					<HostingerButton
						v-qa="'builder-textelementedit-buttondelete'"
						button-type="plain"
						theme="highlight"
						:title="$t('common.delete')"
						@click="deleteElement"
					>
						<template #icon>
							<Icon name="delete" />
						</template>
					</HostingerButton>
					<template #shortcut>
						<span>
							{{ '⌫' }}
						</span>
					</template>
				</ControlsTooltip>
				<VerticalSeparator />

				<ElementPlaceSwitchControls
					v-if="isMobileScreen"
					:element-id="props.elementId"
				/>

				<VerticalSeparator v-if="isMobileScreen" />

				<ControlsTooltip :title="$t('common.more')">
					<HostingerButton
						ref="contextMenuButtonRef"
						v-qa="'builder-elementedit-buttoncontext'"
						button-type="plain"
						theme="highlight"
						:title="$t('builder.contextMenuTitle')"
						@click="setIsContextMenuOpen({ isOpen: !isContextMenuWithTriggerActive })"
					>
						<template #icon>
							<Icon name="more_vert" />
						</template>
					</HostingerButton>
				</ControlsTooltip>
			</template>
		</div>

		<ContextMenuLayoutElement
			v-if="isContextMenuWithTriggerActive"
			:trigger-ref="contextMenuButtonRef && contextMenuButtonRef.$el"
			@close-context-menu="setIsContextMenuOpen({ isOpen: false })"
		/>
		<Popup
			v-if="isMobileView && !isMainControlsDisabled"
			ref="popup"
			:target-ref="currentElementRef"
			:portal-selector="SELECTOR_DATA_PORTAL_BUILDER_PREVIEW"
			placement="right"
			auto-update
			:offset="elementRepositionOffset"
		>
			<div class="element-place-switch-controls-popup__buttons">
				<ElementPlaceSwitchControls :element-id="props.elementId" />
			</div>
		</Popup>
	</div>
</template>

<script setup>
import {
	computed,
	ref,
} from 'vue';
import { useStore } from 'vuex';

import HostingerButton from '@/components/global/HostingerButton.vue';
import VerticalSeparator from '@/components/global/VerticalSeparator.vue';
import Icon from '@/components/global/Icon.vue';
import ControlsTooltip from '@/components/ControlsTooltip.vue';
import VisibilityControls from '@/components/builder-controls/VisibilityControls.vue';
import ContextMenuLayoutElement from '@/components/context-menu/ContextMenuLayoutElement.vue';
import ElementPlaceSwitchControls from '@/components/builder-controls/ElementPlaceSwitchControls.vue';
import Popup from '@/components/global/Popup.vue';
import {
	ELEMENT_POSITION_KEY_MOBILE,
	ELEMENT_POSITION_KEY_DESKTOP,
} from '@zyro-inc/site-modules/constants';
import { MOBILE_BLOCK_WIDTH } from '@zyro-inc/site-modules/components/blocks/layout/constants';
import { SELECTOR_DATA_PORTAL_BUILDER_PREVIEW } from '@/constants';
import { useLayoutElements } from '@/use/useLayoutElements';
import { useContextMenu } from '@/components/context-menu/useContextMenu';
import { useLayoutContextMenu } from '@/components/context-menu/useLayoutContextMenu';
import { useCurrentElementRef } from '@/use/useCurrentElementRef';
import { getIsUserUsingMac } from '@/utils/getIsUserUsingMac';
import AiTypeSelect from '@/components/AiTypeSelect.vue';
import { useUserStore } from '@/stores';

const OFFSET_SWITCH_CONTROLS_DESKTOP = 16;

const props = defineProps({
	elementId: {
		type: String,
		required: true,
	},
	enterElementEditModeButtonTitle: {
		type: String,
		required: true,
	},
	isMainControlsDisabled: {
		type: Boolean,
		default: false,
	},
});

const emit = defineEmits(['element-deleted']);

const {
	state,
	dispatch,
	getters,
} = useStore();
const { currentElementRef } = useCurrentElementRef();
const { selectedLayoutElementId } = useLayoutContextMenu();
const {
	mousePositionX,
	mousePositionY,
	isContextMenuWithTriggerActive,
} = useContextMenu();
const {
	deleteSelectedElement,
	duplicateSelectedElement,
} = useLayoutElements({
	selectedElementId: props.elementId,
});
const userStore = useUserStore();

const contextMenuButtonRef = ref(null);
const isMac = getIsUserUsingMac();
const isMobileScreen = computed(() => state.gui.isMobileScreen);
const isMobileView = computed(() => state.gui.isMobileView);
const isMobileMode = computed(() => getters['gui/isMobileMode']);
const siteElements = computed(() => getters.siteElements);
const elementData = computed(() => siteElements.value[props.elementId]);
const isElementHiddenOnDesktop = computed(() => !!elementData.value?.[ELEMENT_POSITION_KEY_DESKTOP]?.isHidden);
const isElementHiddenOnMobile = computed(() => !!elementData.value?.[ELEMENT_POSITION_KEY_MOBILE]?.isHidden);
const elementRepositionOffset = computed(() => {
	if (!isMobileMode.value || !elementData.value) {
		return OFFSET_SWITCH_CONTROLS_DESKTOP;
	}

	const {
		width,
		left,
	} = elementData.value[ELEMENT_POSITION_KEY_MOBILE];

	return MOBILE_BLOCK_WIDTH - width - left;
});

const editElement = () => {
	dispatch('enterElementEditMode');
};

const deleteElement = () => {
	deleteSelectedElement();
	emit('element-deleted');
};

const setIsElementHidden = ({
	value,
	elementPositionKey,
}) => {
	dispatch('mergeElementData', {
		elementId: props.elementId,
		elementData: {
			[elementPositionKey]: {
				isHidden: value,
			},
		},
	});
};

const setIsContextMenuOpen = ({ isOpen }) => {
	selectedLayoutElementId.value = isOpen ? props.elementId : null;
	isContextMenuWithTriggerActive.value = isOpen;
	mousePositionX.value = null;
	mousePositionY.value = null;
};
</script>

<style lang="scss" scoped>
.element-edit-controls-bar-buttons {
	display: flex;
	align-items: center;
	background-color: $color-light;
	box-shadow: $box-shadow-base;
	padding: 2px;
	border-radius: $border-radius-medium;
	user-select: none;

	@media screen and (max-width: $media-mobile) {
		box-shadow: none;
		justify-content: space-evenly;
		border-radius: 0;
		height: $builder-preview-bottom-bar-height;
		border-top: 1px solid $color-gray-border;
		background-color: $color-light;
	}
}

.element-place-switch-controls-popup {
	&__buttons {
		@media screen and (max-width: $media-mobile) {
			display: none;
		}
	}
}

.element-edit-controls-bar-buttons, .element-place-switch-controls-popup__buttons {
	$desktop-icon-size: 20px;

	:deep(.zyro-button) {
			box-shadow: none;

			@media screen and (max-width: $media-mobile) {
				padding: 8px;
			}

			@media screen and (min-width: $media-mobile) {
				.icon {
					font-size: $desktop-icon-size;

					&__dimensions {
						width: $desktop-icon-size;
						height: $desktop-icon-size;
					}
				}
			}
	}
}
</style>
