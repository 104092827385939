import slugify from 'slugify';
import escapeRegExp from 'lodash.escaperegexp';
import {
	SEO_MAX_STRING_LENGTH_TITLE,
	SEO_MIN_STRING_LENGTH_TITLE,
	SEO_MIN_STRING_LENGTH_DESCRIPTION,
} from '@zyro-inc/site-modules/constants';

export const getIsSeoTitleLengthValid = ({ seoTitle }: { seoTitle: string }) => seoTitle.length <= SEO_MAX_STRING_LENGTH_TITLE
	&& seoTitle.length >= SEO_MIN_STRING_LENGTH_TITLE;

export const getIsFocusKeywordIncludedInString = ({
	focusKeyword,
	stringToCheck,
}: {
	focusKeyword: string | null;
	stringToCheck: string;
}) => {
	if (!focusKeyword) {
		return false;
	}

	const regex = new RegExp(`(^|\\W)${escapeRegExp(focusKeyword)}(\\W|$)`, 'i');

	return regex.test(stringToCheck);
};

export const getIsSeoTitleValid = ({
	seoTitle,
	focusKeyword,
}: {
	seoTitle: string;
	focusKeyword: string | null;
}) => seoTitle.length
	&& getIsSeoTitleLengthValid({
		seoTitle,
	})
	&& getIsFocusKeywordIncludedInString({
		focusKeyword,
		stringToCheck: seoTitle,
	});

export const getIsSeoDescriptionLengthValid = ({ seoDescription }
	: { seoDescription: string }) => seoDescription.length >= SEO_MIN_STRING_LENGTH_DESCRIPTION;

export const getIsSeoDescriptionValid = ({
	seoDescription,
	focusKeyword,
}: {
	seoDescription: string;
	focusKeyword: string | null;
}) => seoDescription.length
	&& getIsSeoDescriptionLengthValid({
		seoDescription,
	})
	&& getIsFocusKeywordIncludedInString({
		focusKeyword,
		stringToCheck: seoDescription,
	});

export const getFocusKeywordIncludedInPageSlug = ({
	focusKeyword,
	pageSlug,
}: {
	focusKeyword: string | null;
	pageSlug: string;
}) => (focusKeyword
	? pageSlug.toLowerCase().includes(slugify(focusKeyword.toLowerCase()))
	: false);
