import {
	ref,
	computed,
	watch,
} from 'vue';
import { useStore } from 'vuex';

import { useDrag } from '@/use/useDrag';

import {
	ELEMENT_POSITION_KEY_MOBILE,
	ELEMENT_POSITION_KEY_DESKTOP,
} from '@zyro-inc/site-modules/constants';
import { DEFAULT_MOBILE_BLOCK_HEIGHT } from '@zyro-inc/site-modules/components/blocks/layout/constants';
import {
	LAYOUT_MIN_HEIGHT_THRESHOLD,
	LAYOUT_MIN_SECTION_HEIGHT,
} from '@/constants';

const blockingElementId = ref(null);

export const blockHeightOnResize = ref(null);
export const resizedSectionId = ref(null);

export const useSectionResizing = (props, context) => {
	const {
		getters,
		dispatch,
	} = useStore();
	const siteElements = computed(() => getters.siteElements);
	const isMobileMode = computed(() => getters['gui/isMobileMode']);

	const elementPositionKey = computed(() => (isMobileMode.value ? ELEMENT_POSITION_KEY_MOBILE : ELEMENT_POSITION_KEY_DESKTOP));

	const {
		startDragging,
		dragDeltaYPosition,
		isDragging,
	} = useDrag();

	const resizeHandleDirection = ref(null);

	const blockData = computed(() => getters.siteBlocks[props.blockId]);

	const lowestElement = computed(() => {
		const blockElements = blockData.value?.components;

		if (!blockElements || !blockElements.length) {
			return null;
		}

		//  Get elements bottom positions inside block
		//  Sort elements by bottom position in descending order
		return blockElements
			.map((elementId) => {
				const {
					desktop,
					mobile,
				} = siteElements.value[elementId];

				const bottom = (isMobileMode.value && mobile)
					? mobile.top + mobile.height
					: desktop.top + desktop.height;

				return {
					bottom,
					elementId,
				};
			})
			.sort((a, b) => b.bottom - a.bottom)[0];
	});

	const lowestElementBottom = computed(() => lowestElement.value?.bottom || 0);

	const blockMinHeight = computed(() => {
		if (isMobileMode.value) {
			const defaultMobileHeight = DEFAULT_MOBILE_BLOCK_HEIGHT > lowestElementBottom.value
				? DEFAULT_MOBILE_BLOCK_HEIGHT
				: lowestElementBottom.value;

			return blockData.value.mobile?.minHeight || defaultMobileHeight;
		}

		return blockData.value.desktop.minHeight;
	});

	// Initial value is required for history
	const initialBlockMinHeight = ref(null);

	// newBlockMinHeight should only have a value when resizing
	const newBlockMinHeight = computed(() => {
		if (!isDragging.value) {
			return null;
		}

		return Math.max(
			LAYOUT_MIN_SECTION_HEIGHT,
			blockMinHeight.value + dragDeltaYPosition.value,
			lowestElementBottom.value,
		);
	});

	const isMinHeightReached = computed(() => newBlockMinHeight.value === lowestElementBottom.value + LAYOUT_MIN_HEIGHT_THRESHOLD
		|| newBlockMinHeight.value === LAYOUT_MIN_SECTION_HEIGHT);

	const resizeHandleType = computed(() => {
		if (isDragging.value && isMinHeightReached.value) {
			return 'error';
		}

		return 'default';
	});

	const saveBlockMinHeight = ({
		minHeight,
		saveToHistory,
	}) => {
		if (!minHeight) {
			return;
		}

		dispatch('updateBlockData', {
			blockId: props.blockId,
			blockData: {
				[elementPositionKey.value]: {
					// Don't allow to set section minHeight lower than lowest element bottom
					minHeight: Math.max(lowestElementBottom.value, minHeight),
				},
			},
			merge: true,
		});
		if (saveToHistory) {
			dispatch('undoRedo/createSnapshot');
		}
	};

	const stopSectionResizing = () => {
		saveBlockMinHeight({
			minHeight: newBlockMinHeight.value,
			saveToHistory: true,
		});

		context.emit('lock-hovered-block', false);
		context.emit('set-edit-control-visibility', true);
		blockHeightOnResize.value = null;
		resizedSectionId.value = null;
	};

	const startSectionResizing = () => {
		// Save initial block minHeight to save in history after resize ended
		initialBlockMinHeight.value = blockMinHeight.value;

		startDragging({
			onDragEnd: stopSectionResizing,
		});

		resizedSectionId.value = props.blockId;
		context.emit('set-edit-control-visibility', false);
		context.emit('lock-hovered-block', true);
		dispatch('updateCurrentBlockId', props.blockId);
	};

	const updateSectionMinHeight = () => {
		if (lowestElementBottom.value >= blockMinHeight.value) {
			saveBlockMinHeight({
				minHeight: lowestElementBottom.value,
				saveToHistory: false,
			});
		}
	};

	watch(newBlockMinHeight, (newHeight, oldHeight) => {
		blockHeightOnResize.value = newBlockMinHeight.value;

		resizeHandleDirection.value = isDragging.value ? Math.sign(newHeight - oldHeight) : 0;
	});

	watch(isMinHeightReached, () => {
		if (isMinHeightReached.value && lowestElement.value) {
			blockingElementId.value = lowestElement.value.elementId;
		} else {
			blockingElementId.value = null;
		}
	});

	return {
		blockingElementId,
		startSectionResizing,
		resizeHandleType,
		resizeHandleDirection,
		saveBlockMinHeight,
		blockMinHeight,
		updateSectionMinHeight,
		lowestElementBottom,
	};
};
