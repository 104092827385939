<template>
	<div class="toolbar__group">
		<EditTextButton
			:is-active="editor.isActive('bold')"
			:tooltip-text="tooltipBold"
			@click="handleTextBolding"
		>
			<Icon
				name="format_bold"
				dimensions="20px"
			/>
		</EditTextButton>
		<EditTextButton
			:is-active="editor.isActive('italic')"
			:tooltip-text="tooltipItalic"
			@click="editor.chain().focus().toggleItalic().run()"
		>
			<Icon
				name="format_italic"
				dimensions="20px"
			/>
		</EditTextButton>
		<EditTextButton
			:is-active="editor.isActive('underline')"
			:tooltip-text="tooltipUnderline"
			@click="editor.chain().focus().toggleUnderline().run()"
		>
			<Icon
				name="format_underlined"
				dimensions="20px"
			/>
		</EditTextButton>
		<EditTextButton
			v-qa="`strike-through-button`"
			:is-active="editor.isActive('strike')"
			:tooltip-text="tooltipStrike"
			@click="editor.chain().focus().toggleStrike().run()"
		>
			<Icon
				name="strikethrough_s"
				dimensions="20px"
			/>
		</EditTextButton>
	</div>
</template>

<script setup>
import Icon from '@/components/global/Icon.vue';

import EditTextButton from '@/components/builder-controls/edit-text/EditTextButton.vue';

import {
	TEXT_EDITOR_FONT_WEIGHT_BOLD,
	TEXT_EDITOR_FONT_WEIGHT_REGULAR,
} from '@/constants';

import { useTextEditor } from '@/use/text-editor/useTextEditor';
import { getIsUserUsingMac } from '@/utils/getIsUserUsingMac';

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const { editor } = useTextEditor();

const tooltipBold = computed(() => `${t('builder.editText.bold')} ${getIsUserUsingMac() ? '⌘B' : '(CTRL+B)'}`);
const tooltipItalic = computed(() => `${t('builder.editText.italic')} ${getIsUserUsingMac() ? '⌘I' : '(CTRL+I)'}`);
const tooltipUnderline = computed(() => `${t('builder.editText.underline')} ${getIsUserUsingMac() ? '⌘U' : '(CTRL+U)'}`);
const tooltipStrike = computed(() => `${t('builder.editText.strike')} ${getIsUserUsingMac() ? '⌘+Shift+S' : '(CTRL+SHIFT+S)'}`);

const handleTextBolding = () => {
	editor.value.chain().focus().toggleBold().run();

	const newFontWeight = editor.value.isActive('bold') ? TEXT_EDITOR_FONT_WEIGHT_BOLD : TEXT_EDITOR_FONT_WEIGHT_REGULAR;

	editor.value.commands.setFontWeight(newFontWeight);
};
</script>
