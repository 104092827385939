<template>
	<GridVideo
		ref="videoRef"
		v-bind="composedProps"
		:data-element-ref="props.elementId"
		class="layout-element__component layout-element__component--GridVideo"
		@dragstart.stop.prevent
	/>

	<ElementEditControls
		:target-ref="videoRef?.$el"
		:element-id="props.elementId"
		:is-element-active="isActive"
		:enter-edit-mode-button-title="$t('builder.editVideo.title')"
	>
		<template #edit-mode-popup>
			<EditVideo @close="closeElementEditPopupHandler" />
		</template>
	</ElementEditControls>
</template>

<script setup>
import { ref } from 'vue';

import GridVideo from '@zyro-inc/site-modules/components/elements/video/GridVideo.vue';
import ElementEditControls from '@/components/builder-controls/ElementEditControls.vue';
import EditVideo from '@/components/builder-controls/edit-video/EditVideo.vue';

import { useGridVideo } from '@zyro-inc/site-modules/components/elements/video/useGridVideo';
import { useElementEditPopup } from '@/use/useElementEditPopup';

const props = defineProps({
	data: {
		type: Object,
		required: true,
	},
	elementId: {
		type: String,
		required: true,
	},
	isActive: {
		type: Boolean,
		default: false,
	},
});

const videoRef = ref(null);

const { composedProps } = useGridVideo(props);

const { closeElementEditPopupHandler } = useElementEditPopup({
	elementId: props.elementId,
});
</script>
