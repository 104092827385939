<script setup>
import Icon from '@/components/global/Icon.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';

import { useStore } from 'vuex';

import { NAVIGATION_GROUP_ROOT } from '@/constants';

const props = defineProps({
	item: {
		type: Object,
		required: true,
	},
});

const { dispatch } = useStore();

const moveOutOfDropdown = () => {
	dispatch('navigation/moveItem', {
		fromId: props.item.groupId,
		toId: NAVIGATION_GROUP_ROOT,
		oldIndex: props.item.index,
		newIndex: props.item.parentIndex + 1,
	});
};

const moveToDropdown = () => {
	dispatch('navigation/moveItem', {
		fromId: NAVIGATION_GROUP_ROOT,
		toId: props.item.itemAboveId,
		oldIndex: props.item.index,
	});
};
</script>

<template>
	<!-- You need to wrap the button in a div if you want to test click event :') -->
	<div class="popup-button">
		<HostingerButton
			v-if="!item.isRootItem && !item.isHidden"
			v-qa="'sitemenusettingspopup-btn-move-out-dropdown'"
			button-type="plain"
			@click="moveOutOfDropdown"
		>
			<template #icon-left>
				<Icon
					name="subdirectory_arrow_left"
					dimensions="20px"
				/>
			</template>
			<span class="text-body-2">
				{{ $t('builder.siteMenu.moveOutOfDropdown') }}
			</span>
		</HostingerButton>
		<HostingerButton
			v-if="item.canBeMovedToDropdown"
			v-qa="'sitemenusettingspopup-btn-move-to-dropdown'"
			button-type="plain"
			@click="moveToDropdown"
		>
			<template #icon-left>
				<Icon
					name="subdirectory_arrow_right"
					dimensions="20px"
				/>
			</template>
			<span class="text-body-2">
				{{ $t('builder.siteMenu.moveToDropdown') }}
			</span>
		</HostingerButton>
	</div>
</template>

<style lang="scss" scoped>
.popup-button .hostinger-button {
	width: 100%;
}
</style>
