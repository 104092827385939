<template>
	<Popup
		v-if="triggerRef"
		ref="contextMenuPopupRef"
		:target-ref="triggerRef"
		auto-update
		placement="bottom-start"
		is-mobile-fixed-to-bottom
	>
		<div
			class="context-menu"
			:[DATA_ATTRIBUTE_SELECTOR]="DATA_ATTRIBUTE_SELECTOR_CONTEXT_MENU"
		>
			<slot />
		</div>
	</Popup>
	<div
		v-else-if="renderMenu && isEnabled"
		ref="contextMenuRef"
		class="context-menu context-menu--absolute"
		@mousedown.stop
	>
		<div :[DATA_ATTRIBUTE_SELECTOR]="DATA_ATTRIBUTE_SELECTOR_CONTEXT_MENU">
			<slot />
		</div>
	</div>
</template>

<script setup>
import {
	ref,
	watch,
	nextTick,
} from 'vue';
import { useContextMenu } from '@/components/context-menu/useContextMenu';
import { fitElementToContainer } from '@/utils/fitElementToContainer';
import { onClickOutside } from '@/utils/onClickOutside';
import {
	BUILDER_CLASS,
	DATA_ATTRIBUTE_SELECTOR,
	DATA_ATTRIBUTE_SELECTOR_CONTEXT_MENU,
} from '@zyro-inc/site-modules/constants';
import Popup from '@/components/global/Popup.vue';

const props = defineProps({
	isEnabled: {
		type: Boolean,
		default: false,
	},
	triggerRef: {
		type: Element,
		default: null,
	},
	isElement: {
		type: Boolean,
		default: false,
	},
});

const emit = defineEmits(['close-context-menu']);

const contextMenuRef = ref(null);
const contextMenuPopupRef = ref(null);
const positionTop = ref('');
const positionLeft = ref('');
const renderMenu = ref(false);

const {
	mousePosition,
	closeContextMenu,
} = useContextMenu();

const setCoordinates = () => {
	const {
		clientX,
		clientY,
	} = mousePosition?.value ?? {};

	const {
		left,
		top,
	} = fitElementToContainer(
		contextMenuRef.value,
		document.body,
		{
			left: clientX,
			top: clientY,
		},
	);

	const scrollY = props.isElement
		? document.querySelector(`div.${BUILDER_CLASS}`).scrollTop
		: document.querySelector('body').scrollTop;

	positionLeft.value = `${left}px`;
	positionTop.value = `${top + scrollY}px`;
};

watch(mousePosition, async (newPosition) => {
	// Trigger animation
	renderMenu.value = false;
	if (newPosition) {
		renderMenu.value = true;
		// Wait for popup to render so we can place it properly
		await nextTick();
		if (contextMenuRef.value) {
			setCoordinates();
		}
	}
});

onClickOutside({
	target: props.targetRef ? contextMenuPopupRef : contextMenuRef,
	preventSelector: DATA_ATTRIBUTE_SELECTOR_CONTEXT_MENU,
}, () => {
	closeContextMenu();
	emit('close-context-menu');
});
</script>

<style lang="scss" scoped>
.context-menu {
	min-width: 230px;
	z-index: $z-index-controls-popup-card;
	max-width: 400px;
	overflow: hidden;
	user-select: none;
	background: $color-light;
	border-radius: $border-radius-small;
	box-shadow: $box-shadow;

	&--absolute {
		position: absolute;
		top: v-bind(positionTop);
		left: v-bind(positionLeft);
	}

	@media screen and (max-width: $media-mobile) {
		max-width: 100%;
		max-height: 50vh;
		overflow-y: auto;
		border-radius: $border-radius-large $border-radius-large 0 0;
		box-shadow: $box-shadow-base;

		&--absolute {
			border-radius: $border-radius-large;
		}
	}
}

.fade-in {
	transform-origin: 50% -50px;

	&-enter-active,
	&-leave-active {
		transition: transform 0.25s, opacity 0.2s;
	}

	&-enter,
	&-leave-to {
		opacity: 0;
		transform: rotateX(-10deg) scaleY(0.98) scaleX(0.99);
	}
}

</style>
