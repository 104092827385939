import {
	Experiment,
	ExperimentClient,
} from '@amplitude/experiment-js-client';
import { isHostingerBrand } from '@/utils/isHostingerBrand';
import { getCookie } from '@zyro-inc/site-modules/utils/cookies';
import { COOKIE_HOSTINGER_DEVICE_ID } from '@/constants';

export const EXPERIMENT_IDS = {
	GAMIFICATION_DOMAINS: 'website-builder-add-domain-connection-step-to-gamification',
	ECOMMERCE_FEATURE_LOCK_PLACEMENT: 'website-builder-ecommerce-feature-lock-placement',
} as const;

export const EXPERIMENT_VARIANTS = {
	[EXPERIMENT_IDS.GAMIFICATION_DOMAINS]: {
		VAR_CONTROL: 'control',
		VAR_1: '1',
	},
	[EXPERIMENT_IDS.ECOMMERCE_FEATURE_LOCK_PLACEMENT]: {
		VAR_CONTROL: '0',
		VAR_1: '1',
	},
} as const;

export type ExperimentId = typeof EXPERIMENT_IDS[keyof typeof EXPERIMENT_IDS];

export const experimentsInstance: { amplitudeExperimentInstance: ExperimentClient | null, isGoRobotsUser: boolean } = {
	amplitudeExperimentInstance: null,
	isGoRobotsUser: false,
};

export const initializeExperiments = async ({
	userId,
	isGoRobotsUser,
}: { userId: string, isGoRobotsUser: boolean}) => {
	if (!isHostingerBrand) {
		return;
	}

	experimentsInstance.amplitudeExperimentInstance = Experiment.initialize(import.meta.env.VITE_AMPLITUDE_EXPERIMENTS_API_KEY);
	experimentsInstance.isGoRobotsUser = isGoRobotsUser;

	await experimentsInstance.amplitudeExperimentInstance.fetch({
		user_id: userId,
		device_id: getCookie(COOKIE_HOSTINGER_DEVICE_ID),
	});
};

type ExperimentGetter = (experimentId: ExperimentId) => string | null | undefined;

export const getExperimentVariant: ExperimentGetter = (experimentId) => {
	if (!isHostingerBrand || experimentsInstance.isGoRobotsUser) {
		return null;
	}

	const experimentVariant = experimentsInstance.amplitudeExperimentInstance?.variant(experimentId).value;

	return experimentVariant;
};

export const getIsExperimentActive = (
	experimentId: ExperimentId,
	variants?: string | string[],
	experimentVariantGetter: ExperimentGetter = getExperimentVariant,
): boolean => {
	const experimentVariant = experimentVariantGetter(experimentId);

	const customVariants = typeof variants === 'string' ? [variants] : variants;

	const preparedVariants = customVariants ?? [
		'on',
		'1',
	];

	return preparedVariants.includes(experimentVariant || '');
};
