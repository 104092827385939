import { captureException } from '@sentry/vue';

import { getCookie } from '@zyro-inc/site-modules/utils/cookies';
import {
	NPS_TYPE_BUILDER_GENERAL,
	NPS_MAX_DAYS_AFTER_LAST_SCORE,
	NPS_DATA_MAP,
	COOKIE_NPS_HIDDEN,
} from '@/constants';
import { getLastEvaluation } from '@/api/NpsApi';
// Mutations
export const SET_NPS_VISIBLE = 'SET_NPS_VISIBLE';
export const SET_NPS_QUESTION = 'SET_NPS_QUESTION';
export const UPDATE_NPS_DATA = 'UPDATE_NPS_DATA';

export default {
	namespaced: true,
	state: {
		isNpsVisible: false,
		question: null,
		npsData: {
			titleI18Key: '',
			formType: NPS_TYPE_BUILDER_GENERAL,
			maxScoreI18Key: '',
			lowestScoreI18KEy: '',
		},
	},
	mutations: {
		[SET_NPS_VISIBLE]: (state, isVisible) => {
			state.isNpsVisible = isVisible;
		},
		[SET_NPS_QUESTION]: (state, newQuestion) => {
			state.question = newQuestion;
		},
		[UPDATE_NPS_DATA]: (state, {
			formType,
			importedWebsiteUrl = '',
		}) => {
			state.npsData = {
				...NPS_DATA_MAP[formType],
				importedWebsiteUrl,
			};
		},
	},
	actions: {
		refetchNpsModalVisibilityStatus: async ({ commit }, { formType = '' } = {}) => {
			try {
				const { daysPassed } = await getLastEvaluation(NPS_TYPE_BUILDER_GENERAL);
				const hasTermAfterLastSubmissionPassed = daysPassed > NPS_MAX_DAYS_AFTER_LAST_SCORE || daysPassed === null;
				const hasTermAfterLastModalClosePassed = getCookie(COOKIE_NPS_HIDDEN) !== 'true';
				const isNpsVisible = hasTermAfterLastSubmissionPassed && hasTermAfterLastModalClosePassed;

				if (isNpsVisible) {
					commit(UPDATE_NPS_DATA, {
						formType: formType || NPS_TYPE_BUILDER_GENERAL,
					});
				}

				commit(SET_NPS_VISIBLE, isNpsVisible);
			} catch (error) {
				captureException(error);
				commit(SET_NPS_VISIBLE, false);
			}
		},
		setNpsData: ({ commit }, {
			isVisible,
			formType,
			importedWebsiteUrl = '',
			question = null,
		}) => {
			commit(UPDATE_NPS_DATA, {
				formType: formType || NPS_TYPE_BUILDER_GENERAL,
				importedWebsiteUrl,
			});

			if (question) {
				commit(SET_NPS_QUESTION, question);
			}

			commit(SET_NPS_VISIBLE, isVisible);
		},
	},
};
