<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from 'vuex';
import Icon from '@/components/global/Icon.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import { getStickyBarBlock } from '@/components/block/blocks';
import EventLogApi from '@/api/EventLogApi';
import { BLOCK_CATEGORY_STICKY_BAR } from '@/constants';
import { BLOCK_STICKY_BAR_ID } from '@zyro-inc/site-modules/constants';

const {
	getters,
	state,
	dispatch,
} = useStore();
const isMobileMode = computed(() => getters['gui/isMobileMode']);
const currentBlockId = computed(() => state.currentBlockId);
const addStickyBar = () => {
	const blockData = getStickyBarBlock();

	dispatch('addBlock', {
		blockId: BLOCK_STICKY_BAR_ID,
		blockData,
		previousBlockId: currentBlockId.value,
	});

	EventLogApi.logEvent({
		eventName: 'website_builder.add_section.added',
		eventProperties: {
			section_type: BLOCK_CATEGORY_STICKY_BAR,
		},
	});
};
</script>

<template>
	<div class="add-sticky-bar">
		<HostingerButton
			ref="addSectionButton"
			v-qa="'builder-btn-addtickybar'"
			class="add-sticky-bar__button"
			:class="{ 'add-block-button__button--mobile': isMobileMode }"
			aria-label="GG"
			size="sm"
			theme="add-block"
			@click="addStickyBar"
		>
			<template #icon-left>
				<Icon
					name="add"
					dimensions="20px"
				/>
			</template>
			Add sticky bar
		</HostingerButton>
	</div>
</template>

<style lang="scss" scoped>
.add-sticky-bar {
	position: absolute;
	display: flex;
	top: 0;
	right: 0;
	left: 50%;
	justify-content: center;
	pointer-events: none;
	transform: translateX(-50%);
	z-index: $z-index-controls-section-line;

	&__button {
		padding: 2px 8px;
		align-items: center;
		border-radius: 0 0 8px 8px;
		min-height: 20px;
	}
}
</style>
