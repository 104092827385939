<script lang="ts" setup>
import GenerateContentModal from '@/components/builder-modals/modals/-partials/GenerateContentModal.vue';

import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import { generateSection } from '@/api/AiApi';
import { useStore } from 'vuex';
import { CLOSE_MODAL } from '@/store/builder/gui';
import { useNotifications } from '@/use/useNotifications';
import EventLogApi from '@/api/EventLogApi';
import { addBreadcrumb } from '@sentry/vue';
import { useSiteTextElementPreview } from '@/use/useSiteTextElementPreview';

const { t } = useI18n();
const { recalculateWebsiteTextHeights } = useSiteTextElementPreview();
const isGeneratingSection = ref(false);

const {
	dispatch,
	state,
} = useStore();

const { notify } = useNotifications();

const exampleDescriptions = [
	{
		title: t('builder.generateSectionDescriptionExampleTitle1'),
		text: t('builder.generateSectionDescriptionExampleText1'),
	},
];

const handleSectionGeneration = async (description = '') => {
	isGeneratingSection.value = true;

	try {
		const { data } = await generateSection({
			description: description.trim(),
		});

		dispatch('addBlock', {
			pageId: state.currentPageId,
			blockData: data.section,
			elements: data.elements,
			previousBlockId: state.currentBlockId,
		});

		EventLogApi.logEvent({
			eventName: 'website_builder.add_section.create_section',
			eventProperties: {
				prompt: description,
			},
		});

		addBreadcrumb({
			category: 'CLICK:Generating section',
			data: {
				pageDescription: description,
			},
		});

		recalculateWebsiteTextHeights();

		dispatch(`gui/${CLOSE_MODAL}`);
	} catch (error) {
		notify({
			message: t('builder.aiBuilderGenerationFailed'),
		});
	}

	isGeneratingSection.value = false;
};
</script>

<template>
	<GenerateContentModal
		:is-generating="isGeneratingSection"
		:example-descriptions="exampleDescriptions"
		:text-area-placeholder="t('builder.generateSectionPlaceholder')"
		:text-area-label="t('builder.sectionDescription')"
		:generate-modal-title="t('builder.createSectionWithAi')"
		:generate-button-text="t('builder.createSection')"
		@generate-content="handleSectionGeneration"
	/>
</template>

<style lang="scss" scoped>
.action-button {
	margin-top: 24px;
	text-align: center;
}

.content {
	margin-top: 24px;

	&__textarea {
		margin: 8px 0;

		:deep(.zyro-textarea) {
			border-radius: 8px;
		}
	}

	&__examples {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		margin-top: -28px;

		@media screen and (max-width: 460px) {
			margin-top: 0;
			align-items: flex-start;
		}
	}

	&__examples-button {
		color: var(--color-primary);
		font-size: 12px;

		&:hover, &:active, &:focus {
			background-color: transparent;
		}
	}

	&__examples-list {
		margin-top: 4px;
		border-radius: 4px;
		border: 1px solid var(--color-gray-border);
		padding: 12px 16px;
		list-style: none;
		flex-direction: column;
		align-items: flex-start;
		gap: 8px;
		display: flex;
	}
}

.generate-page-modal {
	@media screen and (max-width: 460px) {
		&__title {
			margin-top: 24px;
		}

		:deep(.modal__content) {
			padding: 24px;
		}
	}
}
</style>
