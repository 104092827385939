<script setup lang="ts">
import Icon from '@/components/global/Icon.vue';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useSeo } from '@/use/useSeo';
import {
	PAGE_TYPE_ECOMMERCE_PRODUCT,
	SEO_PAGE_MIN_TEXT_LENGTH,
} from '@zyro-inc/site-modules/constants';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const {
	getters,
	state,
} = useStore();

const {
	getWordCountFromPage,
	getImagesCountWithoutAlt,
	getAllImagesFromPage,
} = useSeo();

const currentPageId = computed(() => state.currentPageId);
const pagesList = computed<Record<string, any>>(() => getters.sitePages);

const pageWordCount = computed(() => getWordCountFromPage(currentPageId.value));
const isPageTextLengthValid = computed(() => pageWordCount.value >= SEO_PAGE_MIN_TEXT_LENGTH);

const imageCountWithoutAlt = computed(() => getImagesCountWithoutAlt(currentPageId.value));
const imageCountOverall = computed(() => getAllImagesFromPage(currentPageId.value).length);
const isImageAltValid = computed(() => getImagesCountWithoutAlt(currentPageId.value) === 0);
const imagesWithAlt = computed(() => imageCountOverall.value - imageCountWithoutAlt.value);

const isPageWordCountVisible = computed(() => pagesList.value[currentPageId.value].type !== PAGE_TYPE_ECOMMERCE_PRODUCT);
const isOtherSeoTipsVisible = computed(() => (isPageWordCountVisible.value && !isPageTextLengthValid.value && !isImageAltValid.value));
</script>

<template>
	<div v-if="isOtherSeoTipsVisible">
		<p class="text-bold-2">
			{{ t('builder.seoDrawer.otherSeoTips') }}
		</p>
		<div
			v-if="isPageWordCountVisible && !isPageTextLengthValid"
			class="seo-suggestion"
		>
			<div class="seo-suggestion__icon-wrapper">
				<Icon
					class="seo-suggestion__icon"
					dimensions="20px"
					name="description"
				/>
			</div>

			<i18n-t
				class="text-body-2"
				tag="p"
				keypath="builder.seoDrawer.seoChecker.seoCheckerWordCount"
			>
				<b>{{ pageWordCount }}</b>
			</i18n-t>
		</div>

		<div
			v-if="!isImageAltValid"
			class="seo-suggestion"
		>
			<div class="seo-suggestion__icon-wrapper">
				<Icon
					class="seo-suggestion__icon"
					dimensions="20px"
					name="image"
				/>
			</div>

			<i18n-t
				class="text-body-2"
				tag="p"
				keypath="builder.seoDrawer.seoChecker.seoCheckerAltTags"
			>
				<b>{{ imagesWithAlt }}</b>
				<b>{{ imageCountOverall }}</b>
			</i18n-t>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.seo-suggestion {
	padding: 12px 16px;
	border: 1px solid $color-gray-border;
	border-radius: 8px;
	margin-top: 8px;
	display: flex;
	align-items: center;

	&__icon-wrapper {
		display: flex;
	}

	&__icon {
		color: $color-gray;
		margin-right: 8px;
	}
}
</style>
