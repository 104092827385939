<script setup lang="ts">
import {
	ref,
	computed,
	provide,
} from 'vue';
import { useStore } from 'vuex';
import EventLogApi from '@/api/EventLogApi';
import TextBoxTextEditor from '@/components/text-edit/TextBoxTextEditor.vue';
import GridTextBox from '@zyro-inc/site-modules/components/elements/text-box/GridTextBox.vue';
import ElementEditControls from '@/components/builder-controls/ElementEditControls.vue';
import EditText from '@/components/builder-controls/edit-text/EditText.vue';
import OverlayOutline from '@/components/block-layout/OverlayOutline.vue';
import { onClickOutside } from '@/utils/onClickOutside';
import { DATA_SELECTOR_ELEMENT_EDIT_TEXT } from '@/constants';
import {
	BLOCK_STICKY_BAR_ID,
	ANIMATION_TYPE_ELEMENT,
} from '@zyro-inc/site-modules/constants';
import type { UpdateAnimations } from '@/types/animationTypes';
import { useSiteEngineAnimations } from '@zyro-inc/site-modules/use/useSiteEngineAnimations';

interface Props {
  textData: {content: string, [key: string]: any};
}

const props = defineProps<Props>();
const emit = defineEmits<{
'set-active': [boolean]
}>();
const {
	dispatch,
	state,
	getters,
} = useStore();

const textPopupOptions = {
	placement: 'bottom-start',
	offset: 8,
	flip: false,
	autoUpdate: true,
};

const textBoxProviderRef = ref<HTMLElement>();
const textBoxRef = ref<HTMLElement | null>(null);
const textBoxEditorRef = ref<InstanceType<typeof TextBoxTextEditor> | null>(null);
const isActive = ref(false);
const isHovered = ref(false);

const siteLanguages = computed(() => getters.siteLanguages);
const currentLocale = computed(() => state.currentLocale);

const isTextEditMode = computed(() => state.isElementEditMode && isActive.value);

const enterEditMode = () => {
	dispatch('enterElementEditMode');
};

const leaveEditMode = () => {
	dispatch('leaveElementEditMode');
};

const updateSpaceBetweenLines = (event: MouseEvent) => {
	textBoxEditorRef.value?.updateSpaceBetweenLines(event);
};

const handleDragFinish = () => {
	textBoxEditorRef.value?.handleDragFinish();
};

const handleUpdateContent = (content: string) => {
	dispatch('updateBlockData', {
		blockId: BLOCK_STICKY_BAR_ID,
		blockData: {
			items: [
				{
					...props.textData,
					content,
				},
			],
		},
		merge: true,
	});
};

const setActive = (value: boolean) => {
	isActive.value = value;

	emit('set-active', value);
};

const { displayAnimationInEditor } = useSiteEngineAnimations();

const updateAnimations: UpdateAnimations = (animation) => {
	displayAnimationInEditor({
		animation,
		block: BLOCK_STICKY_BAR_ID,
	});

	dispatch('updateBlockData', {
		blockId: BLOCK_STICKY_BAR_ID,
		blockData: {
			animation: animation !== 'none' ? {
				name: animation,
				type: ANIMATION_TYPE_ELEMENT,
			} : null,
		},
		merge: true,
	});

	EventLogApi.logEvent({
		eventName: 'website_builder.styles_animation_style.selected',
		eventProperties: {
			animation,
			type: ANIMATION_TYPE_ELEMENT,
			location: BLOCK_STICKY_BAR_ID,
		},
	});
};

const animationType = computed(() => siteLanguages.value[currentLocale.value].blocks[BLOCK_STICKY_BAR_ID].animation?.name);

provide('updateAnimations', updateAnimations);
provide('animationType', animationType);

onClickOutside({
	target: textBoxProviderRef,
	preventSelector: DATA_SELECTOR_ELEMENT_EDIT_TEXT,
}, (event: PointerEvent) => {
	const targetBlockStickyBar = (event?.target as HTMLElement).closest('.block-sticky-bar');

	if (!targetBlockStickyBar && isActive.value) {
		leaveEditMode();
		setActive(false);

		return;
	}

	if (isTextEditMode.value) {
		leaveEditMode();

		return;
	}

	setActive(false);
});
</script>

<template>
	<div
		ref="textBoxProviderRef"
		class="block-sticky-bar-text"
		@mouseover="isHovered = true"
		@mouseout="isHovered = false"
	>
		<GridTextBox
			ref="textBoxRef"
			v-qa="'builder-gridelement-gridtextbox'"
			class="notranslate block-sticky-bar-text__text"
			text-align="flex-start"
			text-align-mobile="flex-start"
			@dragstart.prevent
			@click="setActive(true)"
			@keyup.enter="enterEditMode"
			@dblclick="enterEditMode"
			@mouseleave="handleDragFinish"
			@mousemove="updateSpaceBetweenLines"
		>
			<TextBoxTextEditor
				ref="textBoxEditorRef"
				:is-text-edit-mode="isTextEditMode"
				:content="textData.content"
				:text-box-ref="textBoxRef"
				@update:content="handleUpdateContent"
			/>
		</GridTextBox>

		<ElementEditControls
			:target-ref="textBoxProviderRef"
			element-id=""
			:non-grid-element-ref="textBoxProviderRef"
			:is-element-active="isActive"
			:enter-edit-mode-button-title="$t('builder.editText.title')"
			:edit-mode-popup-options="textPopupOptions"
			:controls-popup-options="textPopupOptions"
			is-mobile-popup-sticked-to-bottom
			is-main-controls-disabled
			:is-non-grid-element-edit-mode="isTextEditMode"
			:is-non-grid-element-controls-visible="isActive"
		>
			<template #edit-mode-popup>
				<EditText
					is-non-grid-element
					@close="leaveEditMode"
				/>
			</template>
		</ElementEditControls>
		<OverlayOutline
			v-if="isHovered || isActive"
			outline-style="solid"
			:outline-color="'var(--color-azure)'"
		/>
	</div>
</template>

<style scoped lang="scss">
.block-sticky-bar-text {
	position: relative;
	cursor: pointer;
	height: 100%;
	width: 100%;
	text-align: center;
}
</style>
