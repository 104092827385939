<template>
	<ZyroPopupCard
		type="editor"
		:title="$t('builder.editSocialIcons.popupTitle')"
		:tabs="tabs"
		:current-tab="currentTab"
		@update:current-tab="updateCurrentTabIndex"
		@close="handlePopupCardClose"
	>
		<EditSocialIconsTabLinks
			v-if="currentTabIndex === EDIT_SOCIAL_ICONS_TAB_LINKS_INDEX"
			:element-id="currentElementId"
		/>

		<EditSocialIconsTabStyle v-else-if="currentTabIndex === EDIT_SOCIAL_ICONS_TAB_STYLE_INDEX" />

		<EditSocialIconsTabLayout v-else-if="currentTabIndex === EDIT_SOCIAL_ICONS_TAB_LAYOUT_INDEX" />

		<EditTabAnimation v-else-if="currentTabIndex === EDIT_TAB_ANIMATION_INDEX" />

		<NpsRateFeature
			:feature-name="$t('builder.npsRateSocIcons')"
			:type="NPS_TYPE_FEATURE_SOCIAL_ICONS"
			:hide-border="true"
		/>
	</ZyroPopupCard>
</template>

<script setup>
import ZyroPopupCard from '@/components/global/ZyroPopupCard.vue';

import { useStore } from 'vuex';

import EditSocialIconsTabLayout from '@/components/builder-controls/edit-social-icons/EditSocialIconsTabLayout.vue';
import EditSocialIconsTabLinks from '@/components/builder-controls/edit-social-icons/EditSocialIconsTabLinks.vue';
import EditSocialIconsTabStyle from '@/components/builder-controls/edit-social-icons/EditSocialIconsTabStyle.vue';
import EditTabAnimation from '@/components/builder-controls/reusable-controls/element/EditTabAnimation.vue';

import NpsRateFeature from '@/components/ui/NpsRateFeature.vue';
import { NPS_TYPE_FEATURE_SOCIAL_ICONS } from '@/constants';

import {
	computed,
	ref,
} from 'vue';
import { useI18n } from 'vue-i18n';

const EDIT_SOCIAL_ICONS_TAB_LINKS_INDEX = 0;
const EDIT_SOCIAL_ICONS_TAB_STYLE_INDEX = 1;
const EDIT_SOCIAL_ICONS_TAB_LAYOUT_INDEX = 2;
const EDIT_TAB_ANIMATION_INDEX = 3;

const { t } = useI18n();
const tabs = [
	{
		title: t('common.links'),
	},
	{
		title: t('common.style'),
	},
	{
		title: t('common.layout'),
	},
	{
		title: t('common.animation'),
	},
];

const emit = defineEmits(['close']);

const {
	state,
	getters,
	dispatch,
} = useStore();

const currentTabIndex = ref(0);
const currentTab = computed(() => tabs[currentTabIndex.value]);
const currentElement = computed(() => getters.currentElement);
const currentElementId = computed(() => state.currentElementId);

const updateCurrentTabIndex = ({ title: newTabTitle }) => {
	currentTabIndex.value = Math.max(0, tabs.findIndex((tab) => tab.title === newTabTitle));
};

const handlePopupCardClose = () => {
	if (currentElement.value.links.length === 0) {
		dispatch('removeElement', {
			elementId: currentElementId.value,
		});

		dispatch('undoRedo/createSnapshot');
	}

	emit('close');
};
</script>
