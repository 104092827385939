<template>
	<div
		ref="builderPreviewContainerRef"
		class="builder-root"
	>
		<div class="builder-root__main">
			<MobileFrame>
				<BuilderPreview v-if="website" />
				<HAiChatbot
					v-if="isShowingAiChatbot"
					:class="{ 'ai-chatbot-raised-from-bottom-banner': isRaisedFromBottomBanner }"
					:top-position="HEADER_HEIGHT + 8"
					:conversation-history="conversationHistory"
					:is-responding="isChatbotResponding"
					:is-restarting="isChatbotRestarting"
					:is-feedback-loading="isChatbotFeedbackLoading"
					:texts="chatbotTexts"
					@initialize="chatbotInitialize"
					@respond="chatbotRespond"
					@restart="chatbotRestart"
					@submit-feedback="chatbotSubmitFeedback"
					@function-click="handleFunctionClick"
				>
					<template #custom-content>
						<AiChatbotAiTools v-if="!userStore.areFeaturesLocked" />
					</template>
				</HAiChatbot>
				<BuilderHeatmap v-if="isHeatmapOpen" />
			</MobileFrame>
		</div>

		<BuilderDrawers />

		<ContextMenuLayoutElement />
	</div>
</template>

<script setup lang="ts">
import { useStore } from 'vuex';
import { useScrollHandler } from '@/use/useScrollHandler';
import BuilderDrawers from '@/components/builder-drawers/BuilderDrawers.vue';
import BuilderHeatmap from '@/components/builder-view/BuilderHeatmap.vue';
import MobileFrame from '@/components/builder-view/MobileFrame.vue';
import BuilderPreview from '@/components/builder-view/views/BuilderPreview/BuilderPreview.vue';
import ContextMenuLayoutElement from '@/components/context-menu/ContextMenuLayoutElement.vue';
import {
	onMounted,
	watch,
	computed,
	ref,
} from 'vue';
import { useSeo } from '@/use/useSeo';
import { useGamification } from '@/use/useGamification';
import {
	GAMIFICATION_TASK_CHANGE_SEO,
	GAMIFICATION_TASK_CONNECT_DOMAIN,
	HEADER_HEIGHT,
} from '@/constants';
import { HAiChatbot } from '@hostinger/hcomponents';
import AiChatbotAiTools from '@/components/AiChatbotAiTools.vue';
import { useChatbot } from '@/use/useChatbot';
import { useUserStore } from '@/stores';
import { useRoute } from 'vue-router';
import {
	AI_BUILDER_ROUTE,
	AI_PREVIEW_ROUTE,
	PREVIEW_ROUTE,
	TEMPLATES_ROUTE,
} from '@/constants/routes';
import { useSiteStore } from '@/stores/siteStore';
import { useDomainStore } from '@/stores/domainStore';

const {
	state,
	getters,
	dispatch,
} = useStore();
const userStore = useUserStore();
const domainStore = useDomainStore();
const { completeAchievement } = useGamification();
const route = useRoute();

const {
	chatbotInitialize,
	chatbotRespond,
	conversationHistory,
	isChatbotResponding,
	chatbotRestart,
	isChatbotRestarting,
	chatbotSubmitFeedback,
	isChatbotFeedbackLoading,
	chatbotTexts,
	handleFunctionClick,
} = useChatbot();

const isRouteValidForChatbot = computed(() => ![
	PREVIEW_ROUTE,
	TEMPLATES_ROUTE,
	AI_BUILDER_ROUTE,
	AI_PREVIEW_ROUTE,
].includes(route.name as string));

const user = computed(() => state.user);
const isP2PBannerShown = computed(() => state.gui.isP2PBannerShown);
const isSetupPaymentNotificationVisible = computed(() => getters['gui/isSetupPaymentProviderNotificationVisible']);
const isRaisedFromBottomBanner = computed(() => isP2PBannerShown.value || isSetupPaymentNotificationVisible.value);
const isShowingAiChatbot = computed(() => !user.value?.user?.isAccessManager
	&& isRouteValidForChatbot.value);

const builderPreviewContainerRef = ref<null | HTMLElement>(null);

const siteStore = useSiteStore();

const isGamificationLoaded = computed(() => state.gamification.isGamificationLoaded);
const website = computed(() => siteStore.site);
const isHeatmapOpen = computed(() => state.gui.isHeatmapOpen);

const { isHomePageSeoValid } = useSeo();

watch([
	isGamificationLoaded,
	isHomePageSeoValid,
], () => {
	if (isHomePageSeoValid.value && isGamificationLoaded.value) {
		completeAchievement(GAMIFICATION_TASK_CHANGE_SEO);
	}
});
const customDomain = computed(() => domainStore.customDomain);
const isCustomDomainPreview = computed(() => getters.isCustomDomainPreview);

watch([
	customDomain,
	isCustomDomainPreview,
	isGamificationLoaded,
], () => {
	if (customDomain.value && !isCustomDomainPreview.value && isGamificationLoaded.value) {
		completeAchievement(GAMIFICATION_TASK_CONNECT_DOMAIN);
	}
}, {
	immediate: true,
});

const { initializeScrollHandler } = useScrollHandler();

onMounted(async () => {
	if (!builderPreviewContainerRef.value) {
		return;
	}

	dispatch('gui/setBuilderPreviewContainerRef', {
		builderPreviewContainerRef: builderPreviewContainerRef.value,
	});

	initializeScrollHandler(builderPreviewContainerRef.value);
});
</script>
<style lang="scss" scoped>
.builder-root {
	display: contents;
	overflow: auto;

	&__main {
		position: relative;
		flex: 1;
		height: fit-content;
		background: $color-gray-light;

		.ai-chatbot-raised-from-bottom-banner {
			bottom: 58px;
		}
	}

	@media screen and (max-width: $media-mobile) {
		margin-top: $header-height-editor;
	}
}

:deep(.chatbot-wrapper) {
	z-index: 1000;
};
</style>
