import { LAYOUT_ELEMENT_MAX_WIDTH_MOBILE } from '@/constants';

const FALLBACK_WIDTH = 300;
const FALLBACK_HEIGHT = 300;

export const fixLayoutMobilePosition = ({
	mobilePosition = {},
	desktopPosition = {},
	positionInDom,
}) => {
	const fixedMobilePosition = {
		...mobilePosition,
	};

	// Add desktop top position if mobile top is missing
	if (!mobilePosition.top && desktopPosition.top) {
		fixedMobilePosition.top = desktopPosition.top;
	}

	// Fix negative top position to 0
	if (fixedMobilePosition.top < 0 || !fixedMobilePosition.top) {
		fixedMobilePosition.top = 0;
	}

	// Add left position that equals 0 if mobile left is missing
	if (!mobilePosition.left || mobilePosition.left < 0) {
		fixedMobilePosition.left = 0;
	}

	// Add desktop width position if mobile width is missing
	// Check if width exceeds max mobile width
	if (!mobilePosition.width || mobilePosition.width < 0) {
		fixedMobilePosition.width = desktopPosition.width > LAYOUT_ELEMENT_MAX_WIDTH_MOBILE
			? LAYOUT_ELEMENT_MAX_WIDTH_MOBILE
			: desktopPosition.width;
	}

	// Add position in dom if desktop width is broken
	// Check if width exceeds max mobile width
	if ((!fixedMobilePosition.width || fixedMobilePosition.width < 0) && positionInDom) {
		fixedMobilePosition.width = positionInDom.width > LAYOUT_ELEMENT_MAX_WIDTH_MOBILE
			? LAYOUT_ELEMENT_MAX_WIDTH_MOBILE
			: positionInDom.width;
	}

	// Add desktop height position if mobile height is missing
	if (!mobilePosition.height || mobilePosition.height < 0) {
		fixedMobilePosition.height = desktopPosition.height;
	}

	// Add position in dom if desktop height is broken
	if ((!fixedMobilePosition.height || fixedMobilePosition.height < 0) && positionInDom) {
		fixedMobilePosition.height = positionInDom.height;
	}

	// fallback to generic width if all positions including dom are missing
	if (!fixedMobilePosition.width || fixedMobilePosition.width < 0) {
		fixedMobilePosition.width = FALLBACK_WIDTH;
	}

	// fallback to generic height if all positions including dom are missing
	if (!fixedMobilePosition.height || fixedMobilePosition.height < 0) {
		fixedMobilePosition.height = FALLBACK_HEIGHT;
	}

	return fixedMobilePosition;
};

export const fixLayoutDesktopPosition = ({
	desktopPosition = {},
	positionInDom = {},
}) => {
	const fixedDesktopPosition = {
		...desktopPosition,
	};

	// Fix negative top position to 0
	if (desktopPosition.top < 0 || !desktopPosition.top) {
		fixedDesktopPosition.top = 0;
	}

	// Fix negative left position to 0
	if (!desktopPosition.left || desktopPosition.left < 0) {
		fixedDesktopPosition.left = 0;
	}

	// Add width from DOM if desktop width is missing
	if ((!desktopPosition.width || desktopPosition.width < 0) && positionInDom) {
		fixedDesktopPosition.width = positionInDom.width;
	}

	// Add height from DOM if desktop height is missing
	if ((!desktopPosition.height || desktopPosition.height < 0) && positionInDom) {
		fixedDesktopPosition.height = positionInDom.height;
	}

	// Edge-cases
	// Fallback to generic width if position in dom is missing
	if (!fixedDesktopPosition.width || fixedDesktopPosition.width < 0) {
		fixedDesktopPosition.width = FALLBACK_WIDTH;
	}

	// Fallback to generic height if position in dom is missing
	if (!fixedDesktopPosition.height || fixedDesktopPosition.height < 0) {
		fixedDesktopPosition.height = FALLBACK_HEIGHT;
	}

	return fixedDesktopPosition;
};
