<script setup lang="ts">
import HostingerButton from '@/components/global/HostingerButton.vue';
import Icon from '@/components/global/Icon.vue';
import {
	AiTool,
	AiToolId,
} from '@/types';
import {
	computed,
	ref,
} from 'vue';
import { onClickOutside } from '@vueuse/core';
import { useAiToolsStore } from '@/stores';
import { storeToRefs } from 'pinia';

const modal = ref(null);
const { aiTools } = storeToRefs(useAiToolsStore());
const isAiToolsModalOpen = ref(false);

onClickOutside(modal, (event: PointerEvent) => {
	if ((event?.target as HTMLElement)?.className !== 'chatbot-modal-backdrop') {
		return;
	}

	isAiToolsModalOpen.value = false;
});

const getAiChatbotButton = () => document.querySelector<HTMLButtonElement>('[data-qa="ai-chatbot-button"]');

const aiWriterTool = computed<AiTool>(() => aiTools.value.find((tool) => tool.id === 'writer') as AiTool);

const handleToolClick = (aiTool: AiTool) => {
	isAiToolsModalOpen.value = false;

	const toolsThatShouldCloseChatbot: AiToolId[] = [
		'heatmap',
		'image',
	];

	if (toolsThatShouldCloseChatbot.includes(aiTool.id)) {
		getAiChatbotButton()?.click();
	}

	aiTool.clickHandler({
		location: 'ai_chatbot',
	});
};
</script>

<template>
	<div class="chatbot-ai-tools">
		<HostingerButton
			button-type="outlined"
			size="sm"
			@click="handleToolClick(aiWriterTool)"
		>
			{{ $t('builder.openAiWriter') }}
		</HostingerButton>
		<div class="chatbot-ai-tools__button-wrapper">
			<HostingerButton
				class="chatbot-ai-tools__more-button"
				button-type="outlined"
				size="sm"
				@click="isAiToolsModalOpen = true"
			>
				<template #icon>
					<Icon
						name="more_horiz"
						dimensions="20px"
					/>
				</template>
			</HostingerButton>
			<span class="chatbot-ai-tools__tooltip">
				{{ $t('builder.allAiTools') }}
			</span>
		</div>
	</div>

	<Transition name="fade-slow">
		<div
			v-if="isAiToolsModalOpen"
			class="chatbot-modal-backdrop"
		>
			<div class="chatbot-modal-container">
				<div
					ref="modal"
					class="chatbot-modal"
				>
					<h4 class="chatbot-modal__title text-bold-2">
						{{ $t('builder.allAiTools') }}
					</h4>

					<button
						class="chatbot-modal__close"
						@click="isAiToolsModalOpen = false"
					>
						<Icon name="close" />
					</button>

					<ul class="chatbot-modal__tools">
						<li
							v-for="tool in aiTools"
							:key="tool.id"
							class="chatbot-modal__tool text-body-2"
							@click="handleToolClick(tool)"
						>
							<div class="chatbot-modal__tool-left">
								<Icon
									:name="tool.icon"
									is-custom
									dimensions="32px"
								/>
								<p>{{ tool.title }}</p>
							</div>
							<Icon
								v-if="tool.isExternalLink"
								name="open_in_new"
								dimensions="20px"
							/>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</Transition>
</template>

<style scoped lang="scss">
.chatbot-ai-tools {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 4px;
	padding: 8px 8px 0;

	&__tooltip {
    display: none;
    position: absolute;
    text-wrap: nowrap;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 4px;
    padding: 0 8px;
    background-color: var(--gray);
    font-size: 14px;
    line-height: 1.7;
    color: var(--light);
    pointer-events: none;
    bottom: calc(100% + 4px);
	}

	&__button-wrapper {
		position: relative;

		&:hover .chatbot-ai-tools__tooltip {
			display: block;
		}
	}

	&__more-button {
		padding: 0 5px;
	}
}

.chatbot-modal-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color-dark, 0.35);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chatbot-modal-container {
  border-radius: 8px;
  background-color: var(--color-light);
  width: calc(100% - 16px);
	max-height: 60%;
	display: flex;
  flex-direction: column;
}

.chatbot-modal {
  padding: 16px 8px;
	display: flex;
  flex-direction: column;
	overflow: auto;
	position: relative;

	&__title {
		text-align: center;
		margin-bottom: 8px;
	}

	&__close {
		border-radius: 8px;
		padding: 8px;
		cursor: pointer;
		position: absolute;
		right: 8px;
		top: 8px;
		display: flex;

		&:hover {
			background-color: var(--color-gray-light);
		}
	}

	&__tools {
		overflow: auto;
	}

	&__tool-left {
		display: flex;
		align-items: center;
		gap: 8px;
	}

	&__tool {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 8px;
		cursor: pointer;
		border-radius: 8px;
		overflow: auto;

		&:hover {
			background-color: var(--color-gray-light);
		}
	}

	&__external-link-icon {
		align-self: flex-end;
	}
}

.fade-slow-enter-active,
.fade-slow-leave-active {
  transition: opacity 0.3s;
}

.fade-slow-enter,
.fade-slow-leave-to {
  opacity: 0;
}
</style>
