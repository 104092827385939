<script setup>
import Icon from '@/components/global/Icon.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import ZyroLoader from '@zyro-inc/site-modules/components/ZyroLoader.vue';
import ZyroSvgDeprecated from '@/components/global/ZyroSvgDeprecated.vue';
import ZyroFieldInput from '@/components/global/ZyroFieldInput.vue';
import ZyroImageSelector from '@/components/global/ZyroImageSelector.vue';
import { isAiImageGeneratorOpen } from '@/use/useAiImageGenerator';
import { useI18n } from 'vue-i18n';
import {
	GAMIFICATION_TASK_UPDATE_IMAGE,
	IMAGE_ORIGIN_UNSPLASH,
	IMAGE_ORIGIN_OTHER,
	IMAGE_ORIGIN_ASSETS,
} from '@/constants';
import { useGamification } from '@/use/useGamification';

import { useStore } from 'vuex';

import { createImageAltTag } from '@/api/AiApi';
import {
	onUnmounted,
	ref,
	computed,
} from 'vue';
import { getImageSrc } from '@zyro-inc/site-modules/utils/getImageSrc';
import { useNotifications } from '@/use/useNotifications';
import { captureException } from '@sentry/vue';
import { updateAiGeneratedImagePath } from '@/utils/urlValidators';
import { isHostingerBrand } from '@/utils/isHostingerBrand';

const {
	state,
	getters,
	dispatch,
} = useStore();
const { notify } = useNotifications();
const { completeAchievement } = useGamification();
const { t } = useI18n();

const isAltTagSuggestionGenerating = ref(false);
const currentElementSettings = computed(() => getters.currentElementSettings);
const currentElementStyles = computed(() => getters.currentElementStyles);
const websiteId = computed(() => state.websiteId);
const currentElementId = computed(() => state.currentElementId);
const isAiGeneratingEnabled = computed(() => ![
	IMAGE_ORIGIN_UNSPLASH,
	IMAGE_ORIGIN_OTHER,
].includes(currentElementSettings.value.origin));

const altText = computed(() => currentElementSettings.value.alt);
const objectFit = computed({
	get() {
		return currentElementStyles.value['object-fit'] || 'cover';
	},
	set(newValue) {
		dispatch('mergeCurrentElementData', {
			elementData: {
				settings: {
					styles: {
						'object-fit': newValue,
					},
				},
			},
		});
	},
});

const updateAltText = ({ altValue }) => {
	dispatch('mergeCurrentElementData', {
		elementData: {
			settings: {
				alt: altValue,
			},
		},
	});
};

const handleAltGenerateClick = async () => {
	const imageUrl = getImageSrc(currentElementSettings.value.origin, currentElementSettings.value.path, websiteId.value);

	isAltTagSuggestionGenerating.value = true;

	try {
		const { data } = await createImageAltTag({
			imageUrl,
		});

		updateAltText({
			altValue: data.caption,
		});
	} catch (error) {
		captureException(error);
		notify({
			message: t('builder.altGenerationFailed'),
		});
	} finally {
		isAltTagSuggestionGenerating.value = false;
	}
};

const setImage = ({
	origin,
	path,
	alt,
	fullResolutionWidth,
	fullResolutionHeight,
	url,
}) => {
	const canImageBeCropped = origin === IMAGE_ORIGIN_ASSETS;
	const validPath = updateAiGeneratedImagePath(url, path);

	dispatch('mergeCurrentElementData', {
		elementData: {
			fullResolutionWidth,
			fullResolutionHeight,
			settings: {
				path: validPath,
				origin,
				alt,
			},
			...(!canImageBeCropped && {
				desktop: {
					crop: null,
				},
			}),
		},
	});

	completeAchievement(GAMIFICATION_TASK_UPDATE_IMAGE);
};

const handleAiGenerateClick = () => {
	isAiImageGeneratorOpen.value = true;
};

onUnmounted(() => {
	isAiImageGeneratorOpen.value = false;
});

</script>

<template>
	<div>
		<ZyroImageSelector
			:origin="currentElementSettings.origin"
			:path="currentElementSettings.path"
			:object-fit="objectFit"
			@update="setImage"
		/>
		<div
			v-if="isHostingerBrand"
			class="generate-ai-image"
		>
			<HostingerButton
				button-type="text"
				class="generate-ai-image__button"
				size="sm"
				@click="handleAiGenerateClick"
			>
				<template #icon-left>
					<Icon
						name="auto_awesome"
						is-filled
						dimensions="20px"
					/>
				</template>
				<p class="text-body-2 z-link">
					{{ $t('builder.aiImageGenerationImageSettings') }}
				</p>
			</HostingerButton>
		</div>
		<div class="edit-image-tab-seo">
			<ZyroFieldInput
				:id="`${currentElementId}alt`"
				:model-value="altText"
				maxlength="100"
				:label="$t('builder.editImage.tabSeo.textFieldLabel')"
				@update:model-value="updateAltText({ altValue: $event })"
			>
				<template #sublabel>
					<p class="text-body-3 edit-image-tab-seo__text">
						{{ $t('builder.editImage.tabSeo.label') }}
					</p>
				</template>
				<template
					v-if="isAiGeneratingEnabled"
					#message
				>
					<button
						class="edit-image-tab-seo__suggestion-button"
						:disabled="isAltTagSuggestionGenerating"
						:title="$t('builder.suggestUsingAi')"
						@click="handleAltGenerateClick"
					>
						<Transition
							name="fade"
							mode="out-in"
						>
							<div
								v-if="isAltTagSuggestionGenerating"
								class="edit-image-tab-seo__suggestion-button-content"
							>
								<ZyroLoader
									color="var(--color-primary)"
									size="16px"
									weight="3px"
									class="edit-image-tab-seo__suggestion-icon"
								/>
								{{ $t('builder.pageSettingsSeoDrawerHeadingsButtonGenerating') }}
							</div>
							<div
								v-else
								class="edit-image-tab-seo__suggestion-button-content"
							>
								<ZyroSvgDeprecated
									name="wand"
									class="edit-image-tab-seo__suggestion-icon"
								/>
								{{ $t('builder.suggestUsingAi') }}
							</div>
						</Transition>
					</button>
				</template>
			</ZyroFieldInput>
		</div>
	</div>
</template>

<style scoped lang="scss">

.generate-ai-image {
padding-bottom: 16px;
margin-bottom: 16px;
border-bottom: 1px solid $color-gray-border;

	&__button {
		width: 100%;
		padding: 1px;

		&:hover {
			background-color: transparent;
		}
	}
}

.edit-image-tab-seo {
	:deep(.zyro-field-text__label) {
		margin: 0;
	}

	&__text {
		margin-bottom: 4px;
	}

	&__suggestion-button {
		color: $color-primary;
		margin-top: 6px;
		cursor: pointer;
	}

	&__suggestion-button-content {
		display: flex;
		align-items: center;
	}

	&__suggestion-icon {
		margin-right: 8px;
	}
}
</style>
