import { useStore } from 'vuex';
import QRCode from 'qrcode';
import { captureException } from '@sentry/vue';
import {
	ref,
	computed,
} from 'vue';
import {
	QrImageFormatType,
	QrPageOptionType,
} from '@/types';
import { HEX_TRANSPARENT_BACKGROUND } from '@zyro-inc/site-modules/constants';

const QR_CODE_IMAGE_SIZE = 500;

export const useQrModal = () => {
	const { getters } = useStore();

	const defaultLocaleData = computed(() => getters.siteLanguages[getters.defaultLocale]);

	const homepageOption = computed(() => ({
		name: defaultLocaleData.value.pages[defaultLocaleData.value.homePageId].name,
		slug: '',
		pageId: defaultLocaleData.value.homePageId,
	}));

	const isQrCodeAvailable = computed(() => !getters.isCustomDomainPreview && getters.isSitePublished);
	const siteCustomDomainUrl = computed(() => getters.siteCustomDomainUrl);

	const isBackgroundColorPickerOpen = ref(false);
	const isColorPickerOpen = ref(false);

	const isBackgroundEnabled = ref(false);
	const selectedBackgroundColor = ref('#ffffff');
	const selectedColor = ref('#000000');
	const selectedPage = ref<QrPageOptionType>(homepageOption.value);
	const selectedFormat = ref<QrImageFormatType>('PNG');

	const pageUrlPreview = computed(() => `${siteCustomDomainUrl.value}${selectedPage.value.slug}`);

	const getQrCodePng = async () => {
		let qrCode = '';

		try {
			qrCode = await QRCode.toDataURL(pageUrlPreview.value, {
				width: QR_CODE_IMAGE_SIZE,
				color: {
					dark: selectedColor.value,
					light: isBackgroundEnabled.value ? selectedBackgroundColor.value : HEX_TRANSPARENT_BACKGROUND,
				},
			});
		} catch (error) {
			captureException(error);
		}

		return qrCode;
	};

	const getQrCodeSvg = async () => {
		let qrCode = '';

		try {
			qrCode = await QRCode.toString(pageUrlPreview.value, {
				type: 'svg',
				width: QR_CODE_IMAGE_SIZE,
				color: {
					dark: selectedColor.value,
					light: isBackgroundEnabled.value ? selectedBackgroundColor.value : HEX_TRANSPARENT_BACKGROUND,
				},
			});
		} catch (error) {
			captureException(error);
		}

		return qrCode;
	};

	return {
		isBackgroundColorPickerOpen,
		isColorPickerOpen,
		isBackgroundEnabled,
		selectedBackgroundColor,
		selectedColor,
		selectedPage,
		selectedFormat,
		isQrCodeAvailable,
		pageUrlPreview,
		getQrCodePng,
		getQrCodeSvg,
	};
};
