<template>
	<div
		v-qa="'drawer-container'"
		class="drawer-container"
	>
		<div
			v-if="isInnerSidebarOpen"
			ref="appDrawerRef"
			:data-popper-reference="BUILDER_SIDEBAR_SELECTOR"
			class="drawer"
		>
			<div
				v-if="isHeaderVisible"
				class="drawer-header"
			>
				<HostingerButton
					v-if="isBackButtonVisible"
					v-qa="'builder-sidemenu-btn-back'"
					button-type="plain"
					class="drawer__back"
					:title="$t('common.back')"
					@click="handleBackButtonClick"
				>
					<template #icon>
						<Icon name="arrow_back" />
					</template>
				</HostingerButton>
				<HostingerButton
					v-if="isCloseButtonVisible"
					v-qa="'builder-sidemenu-btn-close'"
					class="drawer__close"
					button-type="plain"
					:title="$t('common.close')"
					@click="handleCloseButtonClick"
				>
					<template #icon>
						<Icon name="close" />
					</template>
				</HostingerButton>
				<slot name="header-actions" />
			</div>

			<div
				class="drawer-body"
				:class="{
					'drawer-body--fullHeight': !$slots.footer && !isHeaderVisible,
					'drawer-body--mediumHeight': !$slots.footer && isHeaderVisible
				}"
			>
				<div
					v-if="isBodyHeaderVisible || $slots.header"
					class="drawer-body__header"
				>
					<div
						v-if="headerTitle"
						class="drawer-body__header-container"
					>
						<h2
							v-if="headerTitle"
							class="drawer-body__header-title"
						>
							{{ headerTitle }}
						</h2>
						<slot name="header-title-right-slot" />
					</div>

					<p
						v-if="headerDescription"
						class="drawer-body__header-description"
					>
						{{ headerDescription }}
					</p>

					<slot name="header" />
				</div>
				<div class="drawer-body__content">
					<slot name="body" />
				</div>
			</div>

			<div class="drawer-footer">
				<slot name="footer" />
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import {
	ref,
	computed,
	onMounted,
} from 'vue';
import { useStore } from 'vuex';

import { CLOSE_SIDEBAR } from '@/store/builder/gui';
import { useSidebar } from '@/use/useSidebar';
import {
	SIDEBAR_WIDTH,
	DATA_SELECTOR_DRAWER,
} from '@/constants';
import { useOnboarding } from '@/components/onboarding/useOnboarding';
import Icon from '@/components/global/Icon.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import { BUILDER_SIDEBAR_SELECTOR } from '@/components/onboarding/onboardingSelectors';
import { useDrawerPageSettingsPopup } from '@/use/useDrawerPageSettingsPopup';

import { onClickOutside } from '@/utils/onClickOutside';

const props = defineProps({
	left: {
		type: String,
		default: `${SIDEBAR_WIDTH}px`,
	},
	width: {
		type: String,
		default: '375px',
	},
	isCloseButtonVisible: {
		type: Boolean,
		default: true,
	},
	isBackButtonVisibleOnMobile: {
		type: Boolean,
		default: true,
	},
	isBackButtonVisibleOnDesktop: {
		type: Boolean,
		default: false,
	},
	unsetElementOnOpen: {
		type: Boolean,
		default: true,
	},
	headerTitle: {
		type: String,
		default: '',
	},
	headerDescription: {
		type: String,
		default: '',
	},
});

const emits = defineEmits([
	'close',
	'back',
]);

const {
	state,
	getters,
	dispatch,
} = useStore();

const {
	isInnerSidebarOpen,
	openInnerSidebar,
	closeSidebar,
} = useSidebar();
const { isOnboardingVisible } = useOnboarding();

const isMobileScreen = computed(() => state.gui.isMobileScreen);
const currentElement = computed(() => getters.currentElement);

const isBodyHeaderVisible = computed(() => props.headerTitle || props.headerDescription);
const isEventPrevented = computed(() => state.gui.activeModalName
	|| state.nps.isNpsVisible
	|| isOnboardingVisible.value);

const appDrawerRef = ref(null);

const { activeDrawerPageSettingsPopup } = useDrawerPageSettingsPopup();

const handleCloseButtonClick = async () => {
	activeDrawerPageSettingsPopup.value = null;
	dispatch(`gui/${CLOSE_SIDEBAR}`);
	closeSidebar();

	emits('close');
};

const handleBackButtonClick = () => {
	emits('back');
};

const isHeaderVisible = computed(() => props.isCloseButtonVisible || props.isBackButtonVisibleOnMobile);

const isBackButtonVisible = computed(() => {
	if (isMobileScreen.value) {
		return props.isBackButtonVisibleOnMobile;
	}

	return props.isBackButtonVisibleOnDesktop;
});

const sidebarWidthInPx = computed(() => props.width);
const sidebarLeftInPx = computed(() => (isMobileScreen.value ? 0 : props.left));

onMounted(() => {
	openInnerSidebar();

	if (currentElement.value && props.unsetElementOnOpen) {
		dispatch('unselectCurrentElement');
	}
});

onClickOutside({
	target: appDrawerRef,
	preventSelector: DATA_SELECTOR_DRAWER,
}, (event: MouseEvent) => {
	if (isMobileScreen.value) {
		return;
	}

	// Needed to not handle v-click-outside catching of iframe blurs
	if (isEventPrevented.value || event.type === 'blur') return;
	closeSidebar();
});
</script>

<style lang="scss" scoped>
$drawer-header-height: 56px;

.drawer-container {
	position: fixed;
	border-top: 1px solid $color-gray-border;
	top: calc($header-height-editor - 2px);
	bottom: 0;
	width: v-bind(sidebarWidthInPx);
	z-index: $z-index-layout-drawer;
	left: v-bind(sidebarLeftInPx);

	@media screen and (max-width: $media-mobile) {
		border-top: none;
		width: calc(100% - $sidebar-min-gap-mobile);
		max-width: 320px;
	}
}

.drawer {
	$this: &;

	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	background-color: $color-light;

	// Show shadow only on the right side
	clip-path: inset(0 -100vw 0 0);
	box-shadow: $box-shadow;

	&__header {
		padding: 20px;
	}

	&__close {
		margin-left: auto;
		margin-right: 0;
	}
}

.drawer-header {
	height: $drawer-header-height;
	padding: 8px;
	display: flex;
	justify-content: space-between;
}

.drawer-footer {
	&:not(:empty) {
		padding: 16px;
		display: flex;
		justify-content: space-between;
		border-top: 1px solid $color-gray-border;
		margin-top: auto;
	}

	:deep() {
		@media screen and (max-width: $media-mobile) {
			.zyro-button-outline {
				&:first-child:not(:only-child) {
					margin-right: 8px;
					padding: 4px 16px;
				}
			}
		}
	}
}

.drawer-body {
	display: flex;
	flex-direction: column;
	height: calc(100% - #{$drawer-header-height});
	overflow-x: hidden;
	overflow-y: auto;

	&--mediumHeight{
		height: calc(100% - #{$drawer-header-height});
	}

	&--fullHeight {
		height: 100%;

		@media screen and (max-width: $media-mobile) {
			height: calc(100% - #{$drawer-header-height});
		}
	}

	&__header {
		padding: 0 16px 16px;
	}

	&__header-title {
		color: $color-gray-dark;
		font-size: 24px;
		font-weight: 700;
		line-height: 1.3;
		margin-bottom: 8px;
	}

	&__header-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__header-description {
		color: $color-gray;
		font-size: 14px;
		line-height: 1.7;
	}

	&__content {
		flex-grow: 1;
		padding: 16px;
	}
}
</style>
