<script setup lang="ts">
import { ref } from 'vue';
import {
	StylePresetType,
	useAiImageGenerator,
} from '@/use/useAiImageGenerator';
import Icon from '@/components/global/Icon.vue';
import HImage from '@/components/global/HImage.vue';

const emit = defineEmits<{
	'select-style': [StylePresetType];
}>();

const { IMAGE_GENERATOR_STYLES } = useAiImageGenerator();
const selectedStyleId = ref(0);

const isStyleSelected = (index: number) => selectedStyleId.value === index;

const selectStyle = (index: number) => {
	if (selectedStyleId.value === index) {
		return;
	}

	selectedStyleId.value = index;

	emit('select-style', IMAGE_GENERATOR_STYLES[index].value as StylePresetType);
};
</script>
<template>
	<div class="image-generator-styles">
		<div class="image-generator-styles__heading-container">
			<p class="text-bold-2">
				{{ $t('builder.aiImageGenerationImageStyle') }}
			</p>
		</div>
		<div class="image-generator-styles__style-list-container">
			<div class="image-generator-styles__style-list">
				<button
					v-for="({ name, file }, index) in IMAGE_GENERATOR_STYLES"
					:key="name"
					class="image-generator-styles__list-item"
					@click="selectStyle(index)"
				>
					<div class="image-generator-styles__image-container">
						<div
							v-if="isStyleSelected(index)"
							class="image-generator-styles__selected-item-overlay"
						>
							<Icon
								name="check"
								class="image-generator-styles__check-icon"
							/>
						</div>
						<HImage
							:src="file"
							:alt="name"
						/>
					</div>
					<span class="text-body-3">{{ name }}</span>
				</button>
			</div>
		</div>
	</div>
</template>
<style scoped lang="scss">
$img-size: 100px;

.image-generator-styles {
  &__heading-container {
		margin-bottom: 8px;
	}

  &__style-list-container {
		overflow-x: hidden;
	}

  &__style-list {
		width: calc(360px - 32px);
		height: 100%;
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(100px, calc($img-size + 4px)));
		overflow-x: hidden;
		padding-top: 8px;
		gap: 8px;
  }

  &__list-item {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		text-align: center;

		&:hover {
			cursor: pointer;
		}

		& img {
			width: 100%;
			height: 100%;
			background-color: $color-gray-light;
		}

		& > span {
			align-self: center;
		}
  }

	&__image-container {
		position: relative;
	}

	&__selected-item-overlay  {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: rgba(29, 30, 32, 50%);
		display:flex;
		align-items: center;
		justify-content: center;
		color: white;
		border-radius: 4px;

		& > img {
			outline: 2px solid $color-primary;
		}
	}

	@media screen and (max-width: $media-mobile) {
		&__style-list {
			width: 100%;
			grid-template-columns: repeat(auto-fill, minmax(80px, auto));
		}
	}
}
</style>
