<script lang="ts" setup>
import { ref } from 'vue';
import { CLOSE_MODAL } from '@/store/builder/gui';
import { useI18n } from 'vue-i18n';
import HostingerButton from '@/components/global/HostingerButton.vue';
import { useStore } from 'vuex';
import ZyroFieldTextArea from '@/components/global/ZyroFieldTextArea.vue';
import EventLogApi from '@/api/EventLogApi';
import Icon from '@/components/global/Icon.vue';

import ZyroModal from '@/components/global/ZyroModal.vue';

const isFeedbackSent = ref(false);
const description = ref('');

const { dispatch } = useStore();
const { t } = useI18n();

const handleSend = () => {
	EventLogApi.logEvent({
		eventName: 'website_builder.add_element.chatbot_coming_soon_send',
		eventProperties: {
			description: description.value,
		},
	});

	isFeedbackSent.value = true;

	setTimeout(() => {
		dispatch(`gui/${CLOSE_MODAL}`);
	}, 2000);
};
</script>

<template>
	<Teleport to="body">
		<ZyroModal
			class="ai-chatbot-coming-soon-modal"
			content-padding="0"
			max-width="600px"
			height="auto"
			@close-modal="dispatch(`gui/${CLOSE_MODAL}`)"
		>
			<div>
				<Transition
					name="fade"
					mode="out-in"
				>
					<div
						v-if="isFeedbackSent"
						class="text-body-2 ai-chatbot-coming-soon-modal__submitted-content"
					>
						<Icon
							name="thumb_up_off_alt"
							dimensions="40px"
							is-filled
							color="primary"
						/>
						<p
							v-qa="'builder-ai-chatbot-coming-soon-modal-submitted-text'"
							align="center"
						>
							{{ $t('builder.thankYouForYourFeedbackV2') }}
						</p>
					</div>
					<div
						v-else
						class="ai-chatbot-coming-soon-modal__content"
					>
						<h2 class="text-heading-2 ai-chatbot-coming-soon-modal__title">
							{{ t('builder.aiChatbotComingSoonModalTitle') }}
						</h2>

						<div>
							<p class="text-body-2 ai-chatbot-coming-soon-modal__description">
								{{ t('builder.aiChatbotComingSoonModalDescription') }}
							</p>
							<ZyroFieldTextArea
								v-model="description"
								class="ai-chatbot-coming-soon-modal__textarea"
								:placeholder="t('builder.aiChatbotComingSoonModalTextareaPlaceholder')"
								:min-height="120"
								:maxlength="3000"
								theme="hostinger"
								is-resizable
							/>

							<div class="ai-chatbot-coming-soon-modal__action-box">
								<HostingerButton
									v-qa="'builder-ai-chatbot-coming-soon-modal-send-button'"
									@click="handleSend"
								>
									{{ t('builder.aiChatbotComingSoonModalButtonText') }}
								</HostingerButton>
							</div>
						</div>
					</div>
				</Transition>
			</div>
		</ZyroModal>
	</Teleport>
</template>

<style lang="scss" scoped>
.ai-chatbot-coming-soon-modal {
	&__content {
		padding: 32px 40px 40px;

		@media screen and (max-width: 600px) {
			padding: 20px;
		}
	}

	&__submitted-content {
		min-height: 360px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		gap: 16px;
	}

	&__title {
		margin-bottom: 8px;
	}

	&__description {
		color: $color-gray;
		margin-bottom: 24px;
	}

	&__textarea {
		margin-bottom: 24px;
	}

	&__action-box {
		text-align: right;
	}
}
</style>
