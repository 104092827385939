<template>
	<div class="linked-block-controls">
		<ControlsTooltip
			ref="linkedBlockControls"
			:title="$t('common.hide')"
		>
			<HostingerButton
				v-qa="'builder-sectionedit-buttonlinked'"
				button-type="plain"
				theme="highlight"
				:title="$t('common.hide')"
				@click="isLinkedBlockControlsVisible = !isLinkedBlockControlsVisible"
			>
				<template #icon>
					<Icon name="visibility" />
				</template>
			</HostingerButton>
		</ControlsTooltip>

		<Popup
			v-if="isLinkedBlockControlsVisible"
			:target-ref="linkedBlockControls.$el"
			placement="bottom"
			auto-update
			@click-outside="isLinkedBlockControlsVisible = false"
		>
			<div class="linked-block-controls__toggles">
				<HostingerButton
					button-type="plain"
					theme="highlight"
					@click="handleLinkedBlockToggle({
						onAllPages: false,
						hide: isLinkedBlockHidden
					})"
				>
					<template #icon-left>
						<Icon
							:name="isLinkedBlockHidden ? 'visibility' : 'visibility_off'"
							dimensions="20px"
						/>
					</template>
					<span class="text-body-2">
						{{ isLinkedBlockHidden ? $t('builder.globalBlock.showOnSingle') : $t('builder.globalBlock.hideOnSingle') }}
					</span>
				</HostingerButton>
				<HostingerButton
					button-type="plain"
					theme="highlight"
					@click="handleLinkedBlockToggle({
						onAllPages: true,
						hide: true
					})"
				>
					<template #icon-left>
						<Icon
							name="visibility_off"
							dimensions="20px"
						/>
					</template>
					<span class="text-body-2">
						{{ $t('builder.globalBlock.hideOnAll') }}
					</span>
				</HostingerButton>
				<HostingerButton
					button-type="plain"
					theme="highlight"
					@click="handleLinkedBlockToggle({
						onAllPages: true,
						hide: false
					})"
				>
					<template #icon-left>
						<Icon
							name="visibility"
							dimensions="20px"
						/>
					</template>
					<span class="text-body-2">
						{{ $t('builder.globalBlock.showOnAll') }}
					</span>
				</HostingerButton>
			</div>
		</Popup>

		<Teleport :to="targetRef">
			<BlockHidden
				v-if="isBlockHidden"
				@button-click="handleLinkedBlockToggle({
					onAllPages: false,
					hide: true
				})"
			/>
		</Teleport>
	</div>
</template>

<script setup>
import {
	ref,
	computed,
} from 'vue';
import { useStore } from 'vuex';

import HostingerButton from '@/components/global/HostingerButton.vue';
import ControlsTooltip from '@/components/ControlsTooltip.vue';
import Icon from '@/components/global/Icon.vue';
import Popup from '@/components/global/Popup.vue';
import BlockHidden from '@/components/block/BlockHidden.vue';

import { PROPERTY_FOOTER_SLOT_IS_HIDDEN } from '@zyro-inc/site-modules/constants';

defineProps({
	targetRef: {
		type: HTMLElement,
		default: null,
	},
});

const {
	getters,
	state,
	dispatch,
} = useStore();

const currentPage = computed(() => getters.currentPage);
const currentPageId = computed(() => state.currentPageId);
const sitePages = computed(() => getters.sitePages);
const isLinkedBlockHidden = computed(() => currentPage.value[PROPERTY_FOOTER_SLOT_IS_HIDDEN]);

const isBlockHidden = computed(() => currentPage.value.footerSlotIsHidden);

const linkedBlockControls = ref(false);
const isLinkedBlockControlsVisible = ref(false);

const handleLinkedBlockToggle = ({
	onAllPages,
	hide,
}) => {
	if (onAllPages) {
		Object.keys(sitePages.value).forEach((pageId) => {
			dispatch('mergePageData', {
				pageId,
				pageData: {
					[PROPERTY_FOOTER_SLOT_IS_HIDDEN]: hide,
				},
			});
		});

		return;
	}

	const oldValue = currentPage.value[PROPERTY_FOOTER_SLOT_IS_HIDDEN];

	dispatch('mergePageData', {
		pageId: currentPageId.value,
		pageData: {
			[PROPERTY_FOOTER_SLOT_IS_HIDDEN]: !oldValue,
		},
	});
};
</script>

<style lang="scss" scoped>
.linked-block-controls {
	:deep(.controls-tooltip) {
		margin-right: 0
	}

	&__toggles {
		min-width: 200px;
		background-color: $color-light;
		border-radius: $border-radius-medium;
		padding: 8px;
		display: flex;
		flex-direction: column;
		box-shadow: $box-shadow-base;

		:deep(.zyro-button-plain){
			font-size: 14px;
			font-weight: 400;
			text-transform: none;
			letter-spacing: normal;
		}
	}
}
</style>
