<template>
	<div
		ref="saveRef"
		class="save-button"
		@mouseenter="handleMouseEnter"
	>
		<HostingerButton
			v-qa="`builder-header-btn-${!canSave ? 'saved' : 'save'}`"
			button-type="plain"
			:disabled="!canSave"
			@click="handleSaveClick"
		>
			<Icon
				v-if="isSaved"
				name="check_circle"
				dimensions="24px"
				color="success"
			/>

			<ZyroLoader
				v-else-if="isSaving"
				size="24px"
				class="save-button__icon"
				color="var(--color-primary)"
			/>

			<template v-else>
				{{ buttonLabel }}
			</template>
		</HostingerButton>

		<Popup
			v-if="isSavePopupVisible"
			:target-ref="$refs.saveRef"
			placement="bottom"
			:offset="0"
			auto-update
		>
			<div
				class="save-button__popup-container"
				@mouseenter="setIsSavePopupVisible(true)"
				@mouseleave="setIsSavePopupVisible(false)"
			>
				<div class="save-button__popup-content">
					<div>
						<div class="save-button__popup-title text-bold-2">
							{{ $t('builder.saveButtonPopupTitle') }}
						</div>
						<div class="save-button__popup-description text-body-2">
							{{ $t('builder.saveButtonPopupDescription') }}
						</div>
						<div class="save-button__popup-view-history">
							<div class="save-button__popup-title text-bold-2">
								{{ $t('builder.saveButtonPopupBackupsTitle', [isSiteVersionsLoading ? '' : numberOfBackups]) }}
							</div>
							<ZyroLoader
								v-if="isSiteVersionsLoading"
								size="24px"
							/>
							<span v-else-if="numberOfBackups">
								<p class="save-button__popup-view-history-description text-body-2">
									{{ $t('builder.versionHistoryManageMessage', [latestBackupDate]) }}
								</p>
								<HostingerButton
									class="save-button__version-link--button z-link"
									button-type="outlined"
									@click="openVersionHistory"
								>
									{{ $t('builder.versionHistoryManage') }}
								</HostingerButton>
							</span>
							<span v-else>
								<p class="save-button__popup-view-history-description text-body-2">
									{{ $t('builder.versionHistoryCreateRestoreMessage') }}
								</p>
								<a
									class="save-button__version-link text-body-2 z-link"
									target="_blank"
									:href="SUPPORT_ARTICLE_LINK_BACKUPS"
								>
									{{ $t('builder.versionHistoryHowToCreate') }}
								</a>
							</span>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	</div>
</template>

<script setup lang="ts">
import {
	onBeforeUnmount,
	onMounted,
	computed,
	ref,
	watch,
} from 'vue';

import { useStore } from 'vuex';

import { SUPPORT_ARTICLE_LINK_BACKUPS } from '@/constants';
import { VERSION_HISTORY_ROUTE } from '@/constants/routes';
import EventLogApi from '@/api/EventLogApi';

import Icon from '@/components/global/Icon.vue';
import { useHeaderPopups } from '@/use/useHeaderPopups';
import HostingerButton from '@/components/global/HostingerButton.vue';
import Popup from '@/components/global/Popup.vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import ZyroLoader from '@zyro-inc/site-modules/components/ZyroLoader.vue';
import { useSiteVersions } from '@/use/useSiteVersions';
import { getHostingerLanguageCookie } from '@/utils/getCookies';
import { BuilderLocales } from '@/types/localeTypes';
import { timeout } from '@/utils/helpers';

const SAVE_BUTTON_WIDTH_MAP: Record<BuilderLocales, number> = {
	id_ID: 82,
	de_DE: 104,
	en_GB: 60,
	es_AR: 86,
	es_CO: 86,
	es_MX: 86,
	es_ES: 86,
	fr_FR: 105,
	it_IT: 68,
	lt_LT: 84,
	nl_NL: 60,
	pt_PT: 86,
	pt_BR: 60,
	ru_RU: 94,
	tr_TR: 66,
	uk_UA: 60,
	zh_CN: 60,
	hi_IN: 60,
	ar_AR: 60,
	he_IL: 60,
	pl_PL: 60,
	vi_VN: 60,
	th_TH: 60,
	ro_RO: 60,
};

const saveButtonWith = `${SAVE_BUTTON_WIDTH_MAP[getHostingerLanguageCookie()]}px` || 'auto';

const {
	state,
	getters,
	dispatch,
} = useStore();

const {
	setIsSavePopupVisible,
	isSavePopupVisible,
} = useHeaderPopups();

const {
	loadSiteVersions,
	isSiteVersionsLoading,
	siteVersionsList,
} = useSiteVersions();
const { t } = useI18n();
const router = useRouter();

const saveRef = ref<HTMLElement | null>(null);
const isSaving = computed(() => state.saving.isSaving);
const isSaved = ref(false);
const isSitePublished = computed(() => getters.isSitePublished);
const canSave = computed(() => getters['saving/canSave']);
const buttonLabel = computed(() => (canSave.value ? t('common.save') : t('common.saved')));

const numberOfBackups = computed(() => siteVersionsList.value?.length || 0);
const latestBackupDate = computed(() => {
	const latestBackup = siteVersionsList.value?.[0];

	return latestBackup ? `(${latestBackup?.lastModified}).` : '';
});

const saveWebsite = async ({ saveWhenImpersonating = false } = {}) => {
	await dispatch('saving/saveWebsite', {
		saveWhenImpersonating,
	});
};

const handleSaveClick = () => {
	if (canSave.value) {
		dispatch('unselectCurrentElement');

		saveWebsite({
			saveWhenImpersonating: true,
		});

		EventLogApi.logEvent({
			eventName: 'website_builder.builder.saved',
		});
	}
};

const handleKeydown = (event: KeyboardEvent) => {
	if ((event.ctrlKey || event.metaKey) && event.key === 's') {
		if (canSave.value) {
			event.preventDefault();
			saveWebsite();
		}
	}
};

const openVersionHistory = async () => {
	if (!isSitePublished.value) {
		return;
	}

	await saveWebsite();
	EventLogApi.logEvent({
		eventName: 'website_builder.backups.enter',
		eventProperties: {
			create_location: 'save_tooltip',
		},
	});

	router.push({
		name: VERSION_HISTORY_ROUTE,
	});
};

const handleMouseEnter = () => {
	if (isSitePublished.value && !siteVersionsList.value) {
		loadSiteVersions();
	}

	setIsSavePopupVisible(true);
};

watch(() => isSaving.value, async (value) => {
	if (value) return;

	// For save button animation
	isSaved.value = true;
	await timeout(2000);
	isSaved.value = false;
});

onMounted(() => {
	document.addEventListener('keydown', handleKeydown, false);
});

onBeforeUnmount(() => {
	document.removeEventListener('keydown', handleKeydown);
});
</script>

<style lang="scss" scoped>
.save-button {
	width: v-bind(saveButtonWith);
	position: relative;
	margin: 0 4px;
	display: flex;
	justify-content: center;

	@media screen and (max-width: $media-mobile) {
		display: none;
	}

	&--warning {
		:deep(.zyro-button) {
			color: $color-warning-dark;
		}
	}

	&__icon-wrapper {
		display: flex;
    align-items: center;
    justify-content: center;
	}

	&__popup-container {
		padding-top: 8px;
		background-color: transparent;

	}

	&__popup-content {
		display: flex;
		flex-direction: column;
		max-width: 282px;
		padding: 16px;
		border: 1px solid $color-gray-light;
		background-color: $color-light;
		border-radius: 12px;
		box-shadow: $box-shadow;
	}

	&__popup-description {
		margin-bottom: 12px;
		color: $color-gray-dark;
	}

	&__popup-view-history {
		padding-top: 12px;
		border-top: 1px solid $color-gray-border;
	}

	&__popup-view-history-description {
		color: $color-gray-dark;
	}

	&__version-link {
		margin-top: 4px;

		&--button {
			margin-top: 12px;
		}
	}

	&__popup-title {
		margin-bottom: 8px;
	}
}

.bounce-enter-active {
  animation: bounce-in 0.3s;
}

.bounce-leave-active {
  animation: bounce-in 0.3s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.25);
  }

  100% {
    transform: scale(1);
  }
}
</style>
