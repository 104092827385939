<template>
	<ZyroPopupCard
		type="editor"
		:tabs="tabs"
		:current-tab="currentTab"
		:title="$t('builder.shapeSettings')"
		@update:current-tab="currentTab = $event"
		@close="$emit('close')"
	>
		<Component :is="currentTab.component" />
		<NpsRateFeature
			:feature-name="$t('builder.npsRateShape')"
			:type="NPS_TYPE_FEATURE_SHAPE"
		/>
	</ZyroPopupCard>
</template>
<script setup>
import ZyroPopupCard from '@/components/global/ZyroPopupCard.vue';

import EditShapeTabShape from '@/components/builder-controls/edit-shape/EditShapeTabShape.vue';
import NpsRateFeature from '@/components/ui/NpsRateFeature.vue';
import { NPS_TYPE_FEATURE_SHAPE } from '@/constants';
import EditTabAnimation from '@/components/builder-controls/reusable-controls/element/EditTabAnimation.vue';
import { useI18n } from 'vue-i18n';

import { ref } from 'vue';

defineEmits(['close']);

const { t } = useI18n();
const tabs = [
	{
		title: t('common.shape'),
		component: EditShapeTabShape,
	},
	{
		title: t('common.animation'),
		component: EditTabAnimation,
	},
];
const currentTab = ref(tabs[0]);
</script>
