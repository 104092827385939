import {
	computed,
	ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { isHostingerBrand } from '@/utils/isHostingerBrand';
import EventLogApi from '@/api/EventLogApi';
import { useStore } from 'vuex';
import {
	TOGGLE_DRAWER,
	TOGGLE_HEATMAP,
	OPEN_MODAL,
	CLOSE_MODAL,
} from '@/store/builder/gui';
import {
	DRAWER_SEO,
	AI_TOOLS,
	DRAWER_AI_WRITER,
	MODAL_UPGRADE_TO_BUSINESS_PLAN,
	MODAL_GENERATE_SECTION,
	ECOMMERCE_FUNNEL_LOCATIONS,
} from '@/constants';
import { useAiBlogGenerator } from '@/use/useAiBlogGenerator';
import { useAddPageModal } from '@/use/useAddPageModal';
import { PINIA_STORES } from '@/constants/stores';
import {
	AiTool,
	AiToolClickHandler,
} from '@/types';
import { defineStore } from 'pinia';
import { useUserStore } from '@/stores/userStore';
import { authRedirectService } from '@/services/http/authRedirectService';

export const useAiToolsStore = defineStore(PINIA_STORES.AI_TOOLS, () => {
	const { t } = useI18n();
	const userStore = useUserStore();

	const {
		dispatch,
		getters,
	} = useStore();

	const isAiImageGeneratorOpen = ref(false);
	const chatbotPrompt = ref('');

	const {
		showAiBlogGeneratorModal,
		createNewDummyBlogPage,
		isAiBlogGeneratorModalOpen,
		closeAiBlogGeneratorModal,
		generateAiBlog,
		isBlogBeingGenerated,
	} = useAiBlogGenerator();

	const { generateNewPage } = useAddPageModal();

	const hasBlog = computed(() => getters['blog/hasBlog']);

	const handleAiSEOAssistantClick = () => {
		dispatch('gui/OPEN_DRAWER', {
			id: DRAWER_SEO,
		});
	};

	const setAiToolsPrompt = (description: string | undefined) => {
		if (description) {
			chatbotPrompt.value = description;
		}
	};

	const getAiToolsPrompt = computed(() => chatbotPrompt.value);

	const clearAiToolsPrompt = () => {
		chatbotPrompt.value = '';
	};

	const handleAiImageGeneratorClick = ({
		location,
		description,
	}: AiToolClickHandler = {
		location: 'side_menu',
		description: '',
	}) => {
		if (!isAiImageGeneratorOpen.value) {
			EventLogApi.logEvent({
				eventName: 'website_builder.ai_image_generator.enter',
				eventProperties: {
					location,
				},
			});
		}

		setAiToolsPrompt(description);

		isAiImageGeneratorOpen.value = !isAiImageGeneratorOpen.value;
	};

	const handleAiWriterClick = ({
		location,
		description,
	}: AiToolClickHandler = {
		location: 'side_menu',
		description: '',
	}) => {
		EventLogApi.logEvent({
			eventName: 'website_builder.ai_assist.enter',
			eventProperties: {
				location,
			},
		});

		setAiToolsPrompt(description);

		dispatch(`gui/${TOGGLE_DRAWER}`, DRAWER_AI_WRITER);
	};

	const handleAiSectionGeneratorClick = ({
		location,
		description,
	}: AiToolClickHandler = {
		location: 'side_menu',
		description: '',
	}) => {
		EventLogApi.logEvent({
			eventName: 'website_builder.add_section.generate_section',
			eventProperties: {
				location,
			},
		});

		setAiToolsPrompt(description);

		dispatch(`gui/${CLOSE_MODAL}`);

		dispatch(`gui/${OPEN_MODAL}`, {
			name: MODAL_GENERATE_SECTION,
		});
	};

	const handleAiPageGeneratorClick = ({
		location,
		description,
	}: AiToolClickHandler = {
		location: 'side_menu',
		description: '',
	}) => {
		EventLogApi.logEvent({
			eventName: 'website_builder.add_page.generate_page',
			eventProperties: {
				location,
			},
		});

		setAiToolsPrompt(description);

		generateNewPage();
	};

	const handleAiBlogGeneratorClick = ({
		location,
		description,
	}: AiToolClickHandler = {
		location: 'side_menu',
		description: '',
	}) => {
		EventLogApi.logEvent({
			eventName: 'website_builder.blog_generator.enter',
			eventProperties: {
				location,
			},
		});

		if (!hasBlog.value) {
			createNewDummyBlogPage();
		}

		setAiToolsPrompt(description);

		showAiBlogGeneratorModal();
	};

	const handleAiHeatmapClick = ({ location }: AiToolClickHandler = {
		location: 'side_menu',
	}) => {
		if (userStore.areFeaturesLocked) {
			dispatch(`gui/${OPEN_MODAL}`, {
				name: MODAL_UPGRADE_TO_BUSINESS_PLAN,
				settings: {
					location: ECOMMERCE_FUNNEL_LOCATIONS.AI_HEATMAP_BUTTON,
				},
			});

			return;
		}

		EventLogApi.logEvent({
			eventName: 'website_builder.ai_heatmap.enter',
			eventProperties: {
				location,
			},
		});

		dispatch(`gui/${TOGGLE_HEATMAP}`);
	};

	const handleAiLogoMakerClick = ({ location }: AiToolClickHandler = {
		location: 'side_menu',
	}) => {
		EventLogApi.logEvent({
			eventName: 'logo_maker.logo_maker.enter',
			eventProperties: {
				creation_location: location,
			},
		});

		authRedirectService.redirectToLogoMaker({
			ref: 'hostinger-builder-sidebar-ai-features',
		});
	};

	const aiTools = computed<AiTool[]>(() => [
		{
			id: AI_TOOLS.IMAGE,
			title: t('builder.aiImageGenerator'),
			icon: 'ai-app-image-generator',
			isHidden: !isHostingerBrand,
			clickHandler: handleAiImageGeneratorClick,
		},
		{
			id: AI_TOOLS.WRITER,
			title: t('builder.AIWriter.title'),
			icon: 'app-ai-text-generator-hostinger',
			clickHandler: handleAiWriterClick,
		},
		{
			id: AI_TOOLS.PAGE_GENERATOR,
			isDisabled: false,
			title: t('builder.aiPageGenerator'),
			icon: 'ai-page-generator',
			clickHandler: handleAiPageGeneratorClick,
		},
		{
			id: AI_TOOLS.SECTION_GENERATOR,
			isDisabled: false,
			title: t('builder.aiSectionGenerator'),
			icon: 'ai-section-generator',
			clickHandler: handleAiSectionGeneratorClick,
		},
		{
			id: AI_TOOLS.BLOG,
			isDisabled: false,
			title: t('builder.aiBlogGenerator'),
			icon: 'ai-app-blog-generator',
			clickHandler: handleAiBlogGeneratorClick,
		},
		{
			id: AI_TOOLS.HEATMAP,
			title: t('builder.AIHeatmapLabel'),
			icon: 'app-ai-heatmap-hostinger',
			clickHandler: handleAiHeatmapClick,
		},
		{
			id: AI_TOOLS.SEO_ASSISTANT,
			title: t('builder.aiSEOAssistant'),
			icon: 'ai-app-seo-assistant',
			clickHandler: handleAiSEOAssistantClick,
		},
		{
			id: AI_TOOLS.LOGO,
			isDisabled: false,
			title: t('builder.aiLogoMaker'),
			icon: 'logo-maker',
			isExternalLink: true,
			clickHandler: handleAiLogoMakerClick,
		},
	]);

	return {
		aiTools,
		isAiImageGeneratorOpen,
		isAiBlogGeneratorModalOpen,
		closeAiBlogGeneratorModal,
		generateAiBlog,
		isBlogBeingGenerated,
		chatbotPrompt,
		setAiToolsPrompt,
		clearAiToolsPrompt,
		getAiToolsPrompt,
	};
});
