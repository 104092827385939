import { defineStore } from 'pinia';
import {
	ref,
	computed,
} from 'vue';
import { User } from '@/types';
import { PINIA_STORES } from '@/constants/stores';

export const useUserStore = defineStore(PINIA_STORES.USER, () => {
	const user = ref<User | null>(null);
	const areFeaturesLocked = ref(false);
	const isAiTypeSelectionVisible = ref(false);
	const isJsonClipboardEnabled = ref(false);

	const userHpanelId = computed<string | undefined>(() => user.value?.hPanelId);

	const setAreFeaturesLocked = (isLocked: boolean) => {
		areFeaturesLocked.value = isLocked;
	};

	const setIsAiTypeSelectionVisible = (isVisible: boolean) => {
		isAiTypeSelectionVisible.value = isVisible;
	};

	const setIsJsonClipboardEnabled = (isEnabled: boolean) => {
		isJsonClipboardEnabled.value = isEnabled;
	};

	const setUser = ({ userData }: {userData: User}) => {
		user.value = userData;
	};

	// @ts-ignore
	window.hwbToggleAiTools = () => {
		setIsAiTypeSelectionVisible(!isAiTypeSelectionVisible.value);
		setIsJsonClipboardEnabled(!isJsonClipboardEnabled.value);
	};

	return {
		user,
		userHpanelId,
		areFeaturesLocked,
		isAiTypeSelectionVisible,
		isJsonClipboardEnabled,
		setUser,
		setAreFeaturesLocked,
	};
});
