<template>
	<div
		class="resize-dots"
		:class="{
			'resize-dots--horizontal-hidden': isElementMinHeight,
			'resize-dots--vertical-hidden': isElementMinWidth,
			'resize-dots--hidden': areHandlesHidden,
			'resize-dots--only-horizontal': isOnlyHorizontalDotsVisible,
		}"
		:[DATA_ATTRIBUTE_SELECTOR]="DATA_ATTRIBUTE_SELECTOR_RESIZE_DOTS"
	>
		<div
			v-for="direction in resizeDirections"
			:key="direction"
			class="resize-dot"
			:class="[`resize-dot--${direction}`]"
			@mousedown.left.stop="$emit('start-resizing', direction)"
			@touchstart.stop="handleTouchStart($event, direction)"
		>
			<div class="resize-dot__visual-trigger" />
		</div>
	</div>
</template>
<script setup>
import {
	DATA_ATTRIBUTE_SELECTOR,
	DATA_ATTRIBUTE_SELECTOR_RESIZE_DOTS,
} from '@zyro-inc/site-modules/constants';
import { LAYOUT_ELEMENT_HANDLES_ELEMENT_MIN_SIZE } from '@/constants';
import { computed } from 'vue';
import { useTouchPosition } from '@/use/useTouchPosition';

const props = defineProps({
	elementWidth: {
		type: Number,
		required: true,
	},
	elementHeight: {
		type: Number,
		required: true,
	},
	resizeDirections: {
		type: Array,
		default: () => [],
	},
});

const emit = defineEmits(['start-resizing']);

const { handleTouchEvent } = useTouchPosition();

const isOnlyHorizontalDotsVisible = computed(() => (
	props.resizeDirections.length === 2 && props.resizeDirections.includes('left') && props.resizeDirections.includes('right')));
const isElementMinHeight = computed(() => !isOnlyHorizontalDotsVisible.value
&& props.elementHeight <= LAYOUT_ELEMENT_HANDLES_ELEMENT_MIN_SIZE);
const isElementMinWidth = computed(() => props.elementWidth <= LAYOUT_ELEMENT_HANDLES_ELEMENT_MIN_SIZE);
const areHandlesHidden = computed(() => isElementMinHeight.value && isElementMinWidth.value);

const handleTouchStart = (event, direction) => {
	// Prevents scrolling on touch devices
	event.preventDefault();

	handleTouchEvent(event);

	emit('start-resizing', direction);
};
</script>
<style lang="scss" scoped>
$dot-padding: 4px;

@media (max-width: $media-mobile) {
	$dot-padding: 8px;
}

.resize-dot {
	$this: &;
	$outline-offset: 1px;
	$size: 16px;

	position: absolute;
	grid-area: 1 / 1 / -1 / -1;
	padding: $dot-padding;
	pointer-events: all;

	&__visual-trigger {
		// Total size of the dot is 12px = 10px + 2px (outline)
		display: block;
		width: 12px;
		height: 12px;
		background: white;
		outline: 2px solid var(--outline-color, $color-azure);
	}

	&--right,
	&--left,
	&--top,
	&--bottom {
		display: flex;
		align-items: center;
		justify-content: center;

		#{$this}__visual-trigger {
			border-radius: 5px;
		}
	}

	&--right,
	&--left {
		width: $size;
		height: 100%;

		#{$this}__visual-trigger {
			height: 28px;
			width: 8px;
		}
	}

	&--top,
	&--bottom {
		width: 100%;
		height: $size;

		#{$this}__visual-trigger {
			width: 28px;
			height: 8px;
		}
	}

	&--right {
		align-self: center;
		justify-self: end;
		cursor: ew-resize;
		transform: translateX(calc(50% + #{$outline-offset}));
	}

	&--left {
		align-self: center;
		justify-self: start;
		cursor: ew-resize;
		transform: translateX(calc(-50% - #{$outline-offset}));
	}

	&--bottom {
		align-self: end;
		justify-self: center;
		cursor: ns-resize;
		transform: translateY(calc(50% + #{$outline-offset}));
	}

	&--bottom-right {
		align-self: end;
		justify-self: end;
		cursor: nwse-resize;
		transform: translate(50%, 50%);
	}

	&--bottom-left {
		align-self: end;
		justify-self: start;
		cursor: nesw-resize;
		transform: translate(-50%, 50%);
	}

	&--top {
		align-self: start;
		justify-self: center;
		cursor: ns-resize;
		transform: translateY(calc(-50% - #{$outline-offset}));
	}

	&--top-left {
		align-self: start;
		justify-self: start;
		cursor: nwse-resize;
		transform: translate(-50%, -50%);
	}

	&--top-right {
		align-self: start;
		justify-self: end;
		cursor: nesw-resize;
		transform: translate(50%, -50%);
	}
}

.resize-dots {
	$this: &;

	position: relative;
	z-index: 1;
	display: grid;
	pointer-events: none;

	&--only-horizontal {
		.resize-dot {
			&__visual-trigger {
				display: block;
			}
		}
	}

	&--horizontal-hidden .resize-dot {
		&--top {
			padding: $dot-padding $dot-padding 0;
		}

		&--bottom {
			padding: 0 $dot-padding $dot-padding;
		}

		&--right,
		&--left,
		&--top-left,
		&--top-right,
		&--bottom-left {
			.resize-dot__visual-trigger {
				display: none;
			}
		}
	}

	&--vertical-hidden {
		.resize-dot {
			&--right {
				padding: $dot-padding $dot-padding $dot-padding 0;
			}

			&--left {
				padding: $dot-padding 0 $dot-padding $dot-padding;
			}

			&--top,
			&--bottom,
			&--top-left,
			&--top-right,
			&--bottom-left {
				.resize-dot__visual-trigger {
					display: none;
				}
			}
		}
	}

	&--hidden {
		.resize-dot {
			padding: 0;
		}
	}
}
</style>
