import { isHostingerBrand } from '@/utils/isHostingerBrand';

const HOSTINGER_URL_HELP = `${import.meta.env.VITE_HPANEL_URL}/help?ref=/websites&topic=website_builder`;

export default {
	namespaced: true,
	state: () => ({
		isIntercomActive: false,
	}),
	getters: {
		isIntercomActive: (state) => state.isIntercomActive,
	},
	mutations: {
		setIntercomState(state, isActive) {
			state.isIntercomActive = isActive;
		},
	},
	actions: {
		setIntercomActive({ commit }, { isActive }) {
			commit('setIntercomState', isActive);
		},
		handleIntercomOpen() {
			if (isHostingerBrand) {
				window.open(HOSTINGER_URL_HELP, '_blank');
			} else {
				window.open('mailto:support@zyro.com', '_blank');
			}
		},
	},
};
