import {
	PROPERTY_PADDING_X,
	PROPERTY_PADDING_Y,
	PROPERTY_FONT_SIZE,
	PROPERTY_M_PADDING_X,
	PROPERTY_M_PADDING_Y,
	PROPERTY_M_FONT_SIZE,
} from '@zyro-inc/site-modules/constants/globalStyles';

import {
	BUTTON_PROPERTY_SIZE_SMALL,
	BUTTON_PROPERTY_SIZE_MEDIUM,
	BUTTON_PROPERTY_SIZE_LARGE,
} from '@/constants';

const SIZE_TO_STYLE_CONFIG_MAP = {
	[BUTTON_PROPERTY_SIZE_SMALL]: {
		padding_y: '14px',
		padding_x: '32px',
		fontSize: '14px',
		fontSizeMobile: '14px',
	},
	[BUTTON_PROPERTY_SIZE_MEDIUM]: {
		padding_y: '16px',
		padding_x: '34px',
		fontSize: '16px',
		fontSizeMobile: '16px',
	},
	[BUTTON_PROPERTY_SIZE_LARGE]: {
		padding_y: '18px',
		padding_x: '40px',
		fontSize: '16px',
		fontSizeMobile: '16px',
	},
};

export const getButtonSizes = (currentElementStyles, currentButtonType) => {
	const activePaddingX = currentElementStyles[`grid-button-${currentButtonType}-${PROPERTY_PADDING_X}`];
	const activePaddingY = currentElementStyles[`grid-button-${currentButtonType}-${PROPERTY_PADDING_Y}`];
	const activeFontSize = currentElementStyles[`grid-button-${currentButtonType}-${PROPERTY_FONT_SIZE}`];
	const activePaddingMobileX = currentElementStyles[`grid-button-${currentButtonType}-${PROPERTY_M_PADDING_X}`];
	const activePaddingMobileY = currentElementStyles[`grid-button-${currentButtonType}-${PROPERTY_M_PADDING_Y}`];
	const activeFontSizeMobile = currentElementStyles[`grid-button-${currentButtonType}-${PROPERTY_M_FONT_SIZE}`];

	const activeSizeConfig = Object.entries(SIZE_TO_STYLE_CONFIG_MAP)
		.find(([, styleProps]) => activePaddingX === styleProps.padding_x
						&& activePaddingY === styleProps.padding_y
						&& activePaddingMobileX === styleProps.padding_x
						&& activePaddingMobileY === styleProps.padding_y
						&& activeFontSizeMobile === styleProps.fontSizeMobile
						&& activeFontSize === styleProps.fontSize);

	return activeSizeConfig ? activeSizeConfig[0] : null;
};

export const finalButtonSizesObject = (newSize, currentButtonType) => {
	const targetConfig = SIZE_TO_STYLE_CONFIG_MAP[newSize];

	return {
		[`grid-button-${currentButtonType}-${PROPERTY_PADDING_Y}`]: targetConfig?.padding_y || null,
		[`grid-button-${currentButtonType}-${PROPERTY_PADDING_X}`]: targetConfig?.padding_x || null,
		[`grid-button-${currentButtonType}-${PROPERTY_FONT_SIZE}`]: targetConfig?.padding_y || null,
		[`grid-button-${currentButtonType}-${PROPERTY_M_PADDING_Y}`]: targetConfig?.paddingMobile || null,
		[`grid-button-${currentButtonType}-${PROPERTY_M_PADDING_X}`]: targetConfig?.padding_x || null,
		[`grid-button-${currentButtonType}-${PROPERTY_M_FONT_SIZE}`]: targetConfig?.fontSizeMobile || null,
	};
};
