<template>
	<div
		ref="blockLayoutContainerRef"
		class="block-layout-container"
		:class="{ 'block-layout-container--overflow-hidden': isMovingElementsBellow }"
		@mouseup="endSelection"
	>
		<BlockLayoutWrapper
			ref="blockLayoutRef"
			class="block-layout"
			:class="{ 'block-layout--ai-builder': isAiBuilderMode }"
			:data-block-ref="blockId"
			:style="layoutCSSVars"
			@mousedown.left="startSelection({
				event: $event,
				blockId
			})"
			@mousemove="updateSelection({
				event: $event,
				layoutElements
			})"
			@dragenter.prevent
			@dragover.prevent
			@dragleave.prevent
			@drop.prevent="onDrop"
		>
			<LayoutGuideLines
				v-if="isCurrentBlock"
				:is-visible="areLayoutGuideLinesVisible"
				:column-count="columnCount"
				:block-height="blockHeight"
				:desktop-block-height="data.desktop.minHeight"
				:mobile-block-height="data.mobile.minHeight"
				:snap-row-height="snapRowHeight"
				:snap-row-gap="snapRowGap"
			/>

			<!-- vertical line slices layout via X axis and vice versa -->
			<SnappingLines
				v-if="areSnappingLinesVisible"
				:element-position="renderedPosition"
				:y-points="ySnapPoints"
				:x-points="xSnapPoints"
			/>

			<HorizontalGuideline
				v-if="isMovingElementsBellow"
				:top="renderedPosition.top"
			/>

			<DragBox
				v-show="isMultiSelectAreaVisible"
				:position="multiSelectAreaRenderPosition"
				:is-area-selected="isMultiSelectActive"
				:is-dragging="isDragging"
				:data-element-ref="`${blockId}-drag-box`"
				@mousedown.left.stop="startDragBoxDragging"
			/>

			<LayoutElementProviderBuilder
				v-for="element in layoutElements"
				:key="element.elementId"
				:style="activeElementId === element.elementId ? elementCSSVars : null"
				:elements-css-vars="elementsCSSVars"
				class="block-layout__element"
				:is-active="getIsElementActive({ elementId: element.elementId })"
				:is-active-element-present="!!activeElementId"
				:element-id="element.elementId"
				:block-id="blockId"
				:element-data="element"
				:rendered-position="activeElementId === element.elementId ? renderedPosition : null"
				:is-mobile-view="isMobileMode"
				:are-controls-disabled="areControlsDisabled"
				:multi-selected-elements-ids="multiSelectedElementsIds"
				:lower-elements-ids-relative-to-active="lowerElementsIdsRelativeToActive"
				:is-blocking-resize="getIsElementBlockingResize({ elementId: element.elementId })"
				@touchstart.stop="handleTouchStart({
					event: $event,
					elementId: element.elementId,
				})"
				@touchend="handleTouchEnd"
				@mousedown.left.stop="startDraggingElement({ elementId: element.elementId })"
				@touchmove="handleTouchMove"
				@element-size-changed="handleElementSizeChange(element.elementId)"
				@contextmenu="openLayoutElementContextMenu($event, element.elementId)"
				@reset-multi-select="resetMultiSelection"
			>
				<ResizeDots
					v-if="getIsSelectedElementResizeHandlesVisible({ elementId: element.elementId })"
					:element-height="isMobileMode ? element.mobile.height : element.desktop.height"
					:element-width="isMobileMode ? element.mobile.width : element.desktop.width"
					:resize-directions="getResizeDirections(element.type)"
					@start-resizing="startResizingElement(element.elementId, $event)"
				/>
				<OverlayPill
					class="block-layout__element-pill"
					:text="$t(GRID_ELEMENT_TYPE_TRANSLATION[element.type])"
				/>
				<OverlayOutline
					:outline-style="croppedImageId === element.elementId ? 'dashed' : 'solid'"
					:outline-color="getIsElementBlockingResize({ elementId: element.elementId })
						? 'var(--color-primary)'
						: 'var(--color-azure)'
					"
					:class="{ 'overlay-outline--blocked': getIsElementBlockingResize({ elementId: element.elementId }) }"
				/>
				<SpacingHandle
					v-if="getIsSelectedElementSpacingHandleVisible({ elementId: element.elementId, })"
					v-show="isSpacingHandleVisible"
					:element-data="element"
					@mousedown.left.stop="startDraggingElement({
						elementId: element.elementId,
						shouldMoveElementsBellow: true
					})"
					@touchstart.prevent.stop="startDraggingElementOnTouchStart({
						event: $event,
						elementId: element.elementId,
						shouldMoveElementsBellow: true
					})"
				/>
			</LayoutElementProviderBuilder>

			<Teleport to="body">
				<ContextMenuLayoutBlock
					:is-enabled="blockId === selectedLayoutBlockId"
					:block-elements="blockElements"
					:block-id="blockId"
				/>
			</Teleport>
		</BlockLayoutWrapper>
	</div>
	<BlockControls
		:block-id="blockId"
		:target-ref="blockLayoutContainerRef"
		:is-first-block="isFirstBlock"
		:is-last-block="isLastBlock"
		:section-title="isBlockFooter ? $t('common.footer') : $t('common.section')"
		is-resize-handle-visible
		:is-add-block-button-visible="!isBlockFooter"
		:block-select-info-color="isBlockFooter ? 'var(--color-primary)' : 'var(--color-azure-dark)'"
		:transparent-header-height="transparentHeaderHeight"
		@set-edit-control-visibility="$emit('set-edit-control-visibility', $event)"
	/>

	<BlockEditControls
		:block-id="blockId"
		:target-ref="blockLayoutContainerRef"
		:is-duplicate-button-visible="!isBlockFooter"
		:is-visibility-button-visible="!isBlockFooter"
		is-delete-button-visible
		:show-delete-warning="isBlockFooter"
		:is-reordering-button-visible="!isBlockFooter"
		is-block-with-elements
		:enter-edit-mode-button-title="t('builder.editBlockButton.editSection')"
	>
		<template #additional-edit-buttons>
			<template v-if="isMobileAutoPositioningToggleVisible">
				<VerticalSeparator />
				<HostingerButton
					v-qa="'builder-section-btn-autoFixLayout'"
					button-type="text"
					@click="updateMobileAutoPositioning({ isMobileAutoPositioningEnabled: true })"
				>
					<span v-if="!isMobileScreen">{{ $t('builder.editBlockButton.autoFixLayout') }}</span>
					<template
						v-if="isMobileScreen"
						#icon
					>
						<Icon name="auto_fix_high" />
					</template>
					<template
						v-else
						#icon-left
					>
						<Icon name="auto_fix_high" />
					</template>
				</HostingerButton>
			</template>

			<template v-if="isBlockFooter">
				<VerticalSeparator />
				<LinkedBlockControls :target-ref="blockLayoutContainerRef" />
			</template>
		</template>
		<template #edit-mode-popup>
			<EditBlockLayoutTabs @close="closeBlockEditPopupHandler" />
		</template>
	</BlockEditControls>
</template>

<script setup>
import {
	computed,
	watch,
	ref,
} from 'vue';
import { useStore } from 'vuex';

import { useI18n } from 'vue-i18n';

import BlockLayoutWrapper from '@zyro-inc/site-modules/components/blocks/layout//BlockLayoutWrapper.vue';
import BlockControls from '@/components/block/BlockControls.vue';
import BlockEditControls from '@/components/builder-controls/BlockEditControls.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import LinkedBlockControls from '@/components/builder-controls/LinkedBlockControls.vue';
import Icon from '@/components/global/Icon.vue';
import OverlayPill from '@/components/block-grid/OverlayPill.vue';
import OverlayOutline from '@/components/block-layout/OverlayOutline.vue';
import LayoutGuideLines from '@/components/block-layout/LayoutGuideLines.vue';
import SnappingLines from '@/components/block-layout/SnappingLines.vue';
import HorizontalGuideline from '@/components/block-layout/HorizontalGuideline.vue';
import DragBox from '@/components/block-layout/DragBox.vue';
import SpacingHandle from '@/components/block-layout/SpacingHandle.vue';
import VerticalSeparator from '@/components/global/VerticalSeparator.vue';
import EditBlockLayoutTabs from '@/components/builder-controls/edit-block-layout/EditBlockLayoutTabs.vue';
import { areTouchEventsSupported } from '@/utils/areTouchEventsSupported';

import {
	GRID_ELEMENT_TYPE_TRANSLATION,
	TOUCH_MOVE_DELAY,
	TOUCH_POSITION_DELTA_TRESHOLD,
} from '@/constants';

import {
	ELEMENT_POSITION_KEY_MOBILE,
	ELEMENT_POSITION_KEY_DESKTOP,
	BLOCK_SLOT_FOOTER,
} from '@zyro-inc/site-modules/constants';

import ResizeDots from '@/components/block-layout/ResizeDots.vue';
import LayoutElementProviderBuilder from '@/components/layout-element/LayoutElementProviderBuilder.vue';

import ContextMenuLayoutBlock from '@/components/context-menu/ContextMenuLayoutBlock.vue';

import {
	useLayout,
	isViewingLayoutSettings,
} from '@/components/block-layout/useLayout';
import { useTouchPosition } from '@/use/useTouchPosition';
import { useCropImage } from '@/components/layout-element/useCropImage';
import {
	useSectionResizing,
	blockHeightOnResize,
	resizedSectionId,
} from '@/use/useSectionResizing';
import { useContextMenu } from '@/components/context-menu/useContextMenu';
import { useLayoutContextMenu } from '@/components/context-menu/useLayoutContextMenu';
import { useBuilderMode } from '@/use/useBuilderMode';
import { useHoveredBlock } from '@/use/useHoveredBlock';
import { useLayoutMobilePositioning } from '@/use/useLayoutMobilePositioning';

const props = defineProps({
	blockId: {
		type: String,
		required: true,
	},
	data: {
		type: Object,
		required: true,
	},
	components: {
		type: Object,
		default: null,
	},
	areControlsDisabled: {
		type: Boolean,
		default: false,
	},
	isFirstBlock: {
		type: Boolean,
		default: false,
	},
	isLastBlock: {
		type: Boolean,
		default: false,
	},
	transparentHeaderHeight: {
		type: Number,
		default: 0,
	},
});

const emit = defineEmits(['set-edit-control-visibility']);

const {
	state,
	getters,
	dispatch,
} = useStore();
const { t } = useI18n();
const { openContextMenu } = useContextMenu();
const { isAiBuilderMode } = useBuilderMode();

const blockId = computed(() => props.blockId);

const {
	isMobileAutoPositioningToggleVisible,
	updateMobileAutoPositioning,
} = useLayoutMobilePositioning({
	blockId,
});

const {
	activeElementId,
	elementCSSVars,
	elementsCSSVars,
	layoutElements,
	blockElements,
	layoutCSSVars,
	xSnapPoints,
	ySnapPoints,
	startDraggingElement,
	handleElementSizeChange,
	startResizingElement,
	onDrop,
	getResizeDirections,
	isElementMovedToOtherBlock,
	hasMouseMoved,
	renderedPosition,
	columnCount,
	blockLayoutRef,
	snapRowHeight,
	snapRowGap,
	shouldSnapToGuides,
	multiSelectedElementsIds,
	updateMultiSelectedElements,
	isMultiSelecting,
	startSelection,
	updateSelection,
	endSelection,
	isDragging,
	multiSelectAreaRenderPosition,
	isMultiSelectActive,
	resetMultiSelection,
	multiSelectedBlockId,
	lowerElementsIdsRelativeToActive,
	isMovingElementsBellow,
	isDraggingElement,
	sectionResizeBlockingElementId,
	resizedElementId,
	draggedElementId,
} = useLayout(props, emit);

const { blockingElementId } = useSectionResizing(props);

const { croppedImageId } = useCropImage();

const { hoveredBlockId } = useHoveredBlock();

const {
	setSelectedLayoutElementId,
	selectedLayoutBlockId,
	setSelectedLayoutBlockId,
} = useLayoutContextMenu();

const { handleTouchEvent } = useTouchPosition();

const blockLayoutContainerRef = ref(null);

// Used to delay touch event to prevent accidental drag
const touchMoveDelayTimer = ref(null);

// Used to determine delta position of touch event
const touchStartPosition = ref(null);

// Used to check if touch event is still active when startDraggingElementOnTouchStart is called
const isTouchActive = ref(false);

const isCurrentBlock = computed(() => props.blockId === state.currentBlockId);
const isBlockFooter = computed(() => props.data.slot === BLOCK_SLOT_FOOTER);

const isMobileScreen = computed(() => state.gui.isMobileScreen);
const isMobileMode = computed(() => getters['gui/isMobileMode']);

const currentElementId = computed(() => getters.currentElementId);

const elementPositionKey = computed(() => (isMobileMode.value ? ELEMENT_POSITION_KEY_MOBILE : ELEMENT_POSITION_KEY_DESKTOP));

const isEditingTextBoxElement = computed(() => getters.isEditingTextBoxElement);

const isViewingCurrentBlockLayoutSettings = computed(() => isCurrentBlock.value && isViewingLayoutSettings.value);

const isMovingElementInCurrentBlock = computed(() => hasMouseMoved.value && !isElementMovedToOtherBlock.value);

const isSpacingHandleVisible = computed(() => !isEditingTextBoxElement.value);

const areLayoutGuideLinesVisible = computed(() => shouldSnapToGuides.value
	&& (isViewingCurrentBlockLayoutSettings.value || isMovingElementInCurrentBlock.value));

const isMultiSelectAreaVisible = computed(() => (isMultiSelecting.value || isMultiSelectActive.value)
	&& multiSelectedBlockId.value === props.blockId);

const blockHeight = computed(() => (props.blockId === resizedSectionId.value
	? blockHeightOnResize.value
	: props.data[elementPositionKey.value].minHeight));

const getIsElementBlockingResize = ({ elementId }) => blockingElementId.value === elementId
	|| sectionResizeBlockingElementId.value === elementId;

const getIsSelectedElementSpacingHandleVisible = ({ elementId }) => {
	if (draggedElementId.value !== elementId) {
		return croppedImageId.value !== elementId
            && currentElementId.value === elementId
            && blockingElementId.value !== elementId
            && !isMultiSelectActive.value
            && resizedElementId.value !== elementId;
	}

	return isMovingElementsBellow.value;
};

const getIsSelectedElementResizeHandlesVisible = ({ elementId }) => croppedImageId.value !== elementId
	&& currentElementId.value === elementId
	&& blockingElementId.value !== elementId
	&& !isMultiSelectActive.value
	&& !isDraggingElement.value
	&& !isMovingElementsBellow.value
	&& !isEditingTextBoxElement.value;

const startDragBoxDragging = () => {
	startDraggingElement({
		elementId: 'multiSelectArea',
	});
	updateMultiSelectedElements({
		shouldForceUpdate: true,
		layoutElements: layoutElements.value,
	});
};

const areSnappingLinesVisible = computed(() => hasMouseMoved.value
	&& !isElementMovedToOtherBlock.value
	&& !isMovingElementsBellow.value);

const openLayoutElementContextMenu = (event, id) => {
	if (isEditingTextBoxElement.value) {
		return;
	}

	event.preventDefault();
	event.stopPropagation();

	openContextMenu(event);
	setSelectedLayoutElementId(id);
	setSelectedLayoutBlockId(null);
};

const closeBlockEditPopupHandler = () => {
	dispatch('leaveBlockEditMode');
};

const startDraggingElementOnTouchStart = ({
	event,
	elementId,
	shouldMoveElementsBellow = false,
}) => {
	if (currentElementId.value !== elementId) {
		return;
	}

	event.preventDefault();

	// Set initial touch position
	handleTouchEvent(event);

	startDraggingElement({
		elementId,
		shouldMoveElementsBellow,
	});
};

const handleTouchStart = ({
	event,
	elementId,
	shouldMoveElementsBellow = false,
}) => {
	clearTimeout(touchMoveDelayTimer.value);
	touchMoveDelayTimer.value = null;
	isTouchActive.value = true;

	touchStartPosition.value = {
		x: event.touches[0].clientX,
		y: event.touches[0].clientY,
	};

	touchMoveDelayTimer.value = setTimeout(() => {
		if (!isTouchActive.value) {
			return;
		}

		event.preventDefault();

		startDraggingElementOnTouchStart({
			event,
			elementId,
			shouldMoveElementsBellow,
		});
	}, TOUCH_MOVE_DELAY);
};

const handleTouchMove = (touchEvent) => {
	const {
		x,
		y,
	} = touchStartPosition.value;

	const {
		clientX,
		clientY,
	} = touchEvent.touches[0];

	const deltaX = clientX - x;
	const deltaY = clientY - y;

	// If swiped further than deltaTouchPositionTreshold during delay - enable scrolling
	if (Math.abs(deltaX) > TOUCH_POSITION_DELTA_TRESHOLD || Math.abs(deltaY) > TOUCH_POSITION_DELTA_TRESHOLD) {
		clearTimeout(touchMoveDelayTimer.value);
		touchMoveDelayTimer.value = null;
	}

	if (isDraggingElement.value) {
		touchEvent.preventDefault();
	}
};

const handleTouchEnd = () => {
	isTouchActive.value = false;
	clearTimeout(touchMoveDelayTimer.value);
};

const getIsElementActive = ({ elementId }) => (areTouchEventsSupported
	? activeElementId.value === elementId
	: activeElementId.value === elementId && hasMouseMoved.value);

watch(hoveredBlockId, (newHoveredBlock) => {
	if (newHoveredBlock !== props.blockId && isMultiSelecting.value) {
		resetMultiSelection();
	}
});

</script>

<style lang="scss" scoped>
.block-layout-container {
	position: relative;
	z-index: $z-index-site-engine-block-grid;

	&--overflow-hidden {
		overflow: hidden;
	}
}

.block-layout {
	position: relative;

	$this: &;

	&__element {
		position: relative;

		&:hover {
			#{$this}__element-pill {
				&#{&} {
					display: flex;
				}
			}
		}
	}

	&__element-pill {
		&#{&} {
			position: absolute;
			top: -11px;
			left: 15px;
			z-index: $z-index-layout-element-pill;
			display: none;
		}
	}

	.overlay-outline {
		&--blocked {
			// height subtraction is needed so that outline wouldn't overlap section border
			height: calc(100% - 4px);
		}
	}

	// ! Hack to kill overflows in mobile view in AI Builder.
	&--ai-builder {
			padding: 0;
	}
}
</style>
