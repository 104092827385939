import axios from '@/services/http/axios';

import { Template } from '@/types';
import { LocationQueryValue } from 'vue-router';

const TEMPLATES_API = `${import.meta.env.VITE_BACKEND_API_URL}/v3/templates`;

export const getTemplateById = async (templateId: string) => (await axios.get(`${TEMPLATES_API}/${templateId}`, {
	isPublic: true,
})).data;

export const selectTemplateById = async (
	templateId: string,
	params?: {
		domain?: LocationQueryValue | LocationQueryValue[];
		txtRecord?: LocationQueryValue | LocationQueryValue[];
		hostingReferenceId?: LocationQueryValue | LocationQueryValue[];
	},
) => {
	const { data } = await axios.get<{ currentSiteId: string; }>(`${TEMPLATES_API}/select/${templateId}`, {
		params,
	});

	return data;
};

export const getTemplatesList = async ({ locale }: {
	locale: string;
}) => {
	const { data } = await axios.get<Template[]>(TEMPLATES_API, {
		params: {
			locale,
		},
		isPublic: true,
	});

	return data;
};
