<script setup lang="ts">
import Icon from '@/components/global/Icon.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import { WWW_REDIRECT_PATHS } from '@/constants';
import {
	DATA_ATTRIBUTE_SELECTOR,
	DATA_ATTRIBUTE_SELECTOR_HEATMAP,
} from '@zyro-inc/site-modules/constants';
import { CLOSE_HEATMAP } from '@/store/builder/gui';
import { getRedirectUrlToWWW } from '@/use/useRedirects';
import { isHostingerBrand } from '@/utils/isHostingerBrand';
import {
	computed,
	ref,
} from 'vue';
import { useStore } from 'vuex';

const {
	dispatch,
	state,
} = useStore();

const { AI_HEATMAP } = WWW_REDIRECT_PATHS;

const headerRef = ref<HTMLElement | null>(null);
const moreHref = getRedirectUrlToWWW({
	path: AI_HEATMAP,
});

const isHeatmapOpen = computed(() => state.gui.isHeatmapOpen);
const headerStyle = computed<Record<string, string | undefined>>(() => {
	if (isHeatmapOpen.value || !headerRef.value) {
		return {};
	}

	const { top } = headerRef.value.getBoundingClientRect();

	return {
		top: `${top}px`,
	};
});

const closeHeatmap = () => {
	dispatch(`gui/${CLOSE_HEATMAP}`);
};
</script>

<template>
	<div
		v-if="isHeatmapOpen"
		ref="headerRef"
		class="heatmap-header"
		:[DATA_ATTRIBUTE_SELECTOR]="DATA_ATTRIBUTE_SELECTOR_HEATMAP"
		:style="headerStyle"
	>
		<div class="heatmap-header__row">
			<div class="heatmap-header__text text-body-2">
				<span>{{ $t('builder.AIHeatmapText') }}</span>
				<a
					v-if="!isHostingerBrand"
					:href="moreHref"
					target="_blank"
					class="heatmap-header__more-link"
				>
					{{ $t('builder.AIHeatmapMore') }}
				</a>
			</div>
			<div class="heatmap-header__controls">
				<HostingerButton
					v-qa="'builder-heatmap-close'"
					button-type="plain"
					class="heatmap-header__close"
					@click="closeHeatmap"
				>
					<template #icon>
						<Icon name="close" />
					</template>
				</HostingerButton>
			</div>
		</div>

		<div id="heatmap-legend" />
	</div>
</template>

<style lang="scss" scoped>
.heatmap-header {
	display: flex;
	justify-content: space-between;
	padding: 0 10px 0 16px;
	min-height: $heatmap-header-height-mobile;
	color: $color-light;
	background-color: $color-azure;
	transition: height 200ms $transition-timing-easing-standard;

	@media screen and (max-width: $media-mobile) {
		flex-direction: column;
		position: relative;
		min-height: #{$heatmap-header-height-mobile + $heatmap-controls-height-mobile};
		top: $header-height-editor;
		padding: 4px 8px  8px;
	}

	&__row {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__controls {
		display: flex;
		align-items: center;
	}

	&__close {
		margin-right: 8px;
		color: $color-light;

		@media screen and (max-width: $media-mobile) {
			margin-right: 0;
		}

		&:hover {
			color: $color-gray-dark;
		}
	}

	&__label {
		margin-right: 8px;

		@media screen and (max-width: $media-mobile) {
			white-space: nowrap;
			font-size: 14px;
		}
	}

	&__text {
		display: flex;
		gap: 8px;
		align-items: center;
		padding-right: 32px;
		text-align: center;

		@media screen and (max-width: $media-mobile) {
			display: grid;
			grid-template-columns: auto auto;
			padding-right: 0;
			line-height: 1.2;
			font-size: 12px;
			text-align: left;
		}
	}

	&__more-icon {
		width: 16px;
		height: 16px;
		vertical-align: middle;
	}

	&__more-link {
		@media screen and (max-width: $media-mobile) {
			display: none;
		}
	}
}
</style>
