<template>
	<div
		:data-element-ref="props.elementId"
		class="layout-element__component layout-element__component--GridEmbed"
	>
		<GridEmbed
			v-if="props.data.content"
			:id="props.elementId"
			ref="embedRef"
			:class="$attrs.class"
			class="grid-embed-builder"
			:srcdoc="srcdoc"
			should-render
			:height="props.data.settings?.styles.height"
			:height-mobile="props.data.settings?.styles.heightMobile"
			@iframe-height-updated="setEmbedHeight($event)"
		/>
		<div
			v-else
			ref="emptyEmbedRef"
			class="empty-state"
		>
			<p class="empty-state__title text-bold-1">
				{{ $t('builder.embed.thisElementIsEmpty') }}
			</p>
			<p class="empty-state__subtext text-body-1">
				{{ $t('builder.embed.contentWillAppear') }}
			</p>
			<HostingerButton
				v-qa="`gridembedbuilder-entercode-btn`"
				@click="enterElementEditMode"
			>
				{{ $t('common.enterCode') }}
			</HostingerButton>
		</div>

		<ElementEditControls
			:target-ref="editControlsTargetRef"
			:element-id="props.elementId"
			:is-element-active="isActive"
			:enter-edit-mode-button-title="$t('common.enterCode')"
		>
			<template #edit-mode-popup>
				<EditEmbed @close="closeElementEditPopupHandler" />
			</template>
		</ElementEditControls>
	</div>
</template>

<script setup lang="ts">
import {
	computed,
	ref,
} from 'vue';

import { useStore } from 'vuex';
import { SiteElement } from '@hostinger/builder-schema-validator';

import GridEmbed from '@zyro-inc/site-modules/components/elements/embed/GridEmbed.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import ElementEditControls from '@/components/builder-controls/ElementEditControls.vue';

import EditEmbed from '@/components/builder-controls/edit-embed/EditEmbed.vue';

import { constructSrcdoc } from '@zyro-inc/site-modules/utils/constructSrcdoc';
import { useElementEditPopup } from '@/use/useElementEditPopup';

interface Props {
	elementId: string;
	data: SiteElement;
	isActive: boolean;
}

const props = defineProps<Props>();

const {
	dispatch,
	getters,
} = useStore();

const embedRef = ref<InstanceType<typeof GridEmbed> | null>(null);
const emptyEmbedRef = ref<HTMLElement | null>(null);

const { closeElementEditPopupHandler } = useElementEditPopup({
	elementId: props.elementId,
});

const isMobileMode = computed(() => getters['gui/isMobileMode']);
const srcdoc = computed<string>(() => constructSrcdoc(props.data.content ?? ''));

const editControlsTargetRef = computed<HTMLElement | null>(() => (props.data.content ? embedRef.value?.$el : emptyEmbedRef.value));

const setEmbedHeight = (height: number) => {
	dispatch('mergeElementData', {
		elementId: props.elementId,
		elementData: {
			settings: {
				styles: {
					[isMobileMode.value ? 'heightMobile' : 'height']: height,
				},
			},
		},
	});
};

const enterElementEditMode = () => {
	dispatch('enterElementEditMode');
};

</script>

<style lang="scss" scoped>
.grid-embed-builder {
	pointer-events: none;
}

.empty-state {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	padding: 8px;
	text-align: center;
	background: $color-gray-light;

	&__title {
		margin-bottom: 8px;
	}

	&__subtext {
		margin-bottom: 24px;
		color: $color-gray;
	}
}
</style>
