<template>
	<GridMap
		ref="mapRef"
		v-bind="composedProps"
		:data-element-ref="props.elementId"
		class="no-pointer-events layout-element__component layout-element__component--GridMap"
	/>

	<ElementEditControls
		:target-ref="mapRef?.$el"
		:element-id="props.elementId"
		:is-element-active="props.isActive"
		:enter-edit-mode-button-title="$t('builder.editMap.title')"
	>
		<template #edit-mode-popup>
			<EditMap @close="closeElementEditPopupHandler" />
		</template>
	</ElementEditControls>
</template>

<script setup>
import { ref } from 'vue';

import GridMap from '@zyro-inc/site-modules/components/elements/map/GridMap.vue';
import ElementEditControls from '@/components/builder-controls/ElementEditControls.vue';
import EditMap from '@/components/builder-controls/edit-map/EditMap.vue';

import { useGridMap } from '@zyro-inc/site-modules/components/elements/map/useGridMap';
import { useElementEditPopup } from '@/use/useElementEditPopup';

const props = defineProps({
	data: {
		type: Object,
		required: true,
	},
	elementId: {
		type: String,
		required: true,
	},
	isActive: {
		type: Boolean,
		default: false,
	},
});

const mapRef = ref(null);

const { composedProps } = useGridMap(props, mapRef);
const { closeElementEditPopupHandler } = useElementEditPopup({
	elementId: props.elementId,
});
</script>

<style lang="scss" scoped>
.no-pointer-events {
	pointer-events: none;
}
</style>
