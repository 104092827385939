import { createStoreWithProducts } from '@/api/EcommerceApi';
import { PINIA_STORES } from '@/constants/stores';
import { useSiteStore } from '@/stores/siteStore';
import { updateTemplateWithAiProductsData } from '@/utils/aiTemplateEditingHelpers';
import { mergeObjects } from '@/utils/mergeObjects';
import { SiteData } from '@hostinger/builder-schema-validator';
import { EcommerceProduct } from '@zyro-inc/site-modules/types';
import { removeNullishEntries } from '@zyro-inc/site-modules/utils/object';
import { defineStore } from 'pinia';

export const useEcommerceStore = defineStore(PINIA_STORES.ECOMMERCE, () => {
	const siteStore = useSiteStore();

	/**
	 *  This function creates new store with new storeId and products.
	 *  Takes in AI generated product data, removes fake ids so proper ones can be assigned, sends cleaned up data to the backend
	 * for store creation. After store is created website data is updated with new storeId and products.
	 * @newProducts - AI generated products that are sent from site or template generation flow
	 */
	const createEcommerceStoreWithAiProducts = async ({ newProducts }:{newProducts: EcommerceProduct[]}) => {
		const cleanedUpProductsData = newProducts.map((product: EcommerceProduct) => {
			const updatedProductData = mergeObjects(product, {
				id: null,
				options: null,
				pageId: null,
				variants: [
					{
						...product.variants[0],
						prices: [
							{
								...product.variants[0].prices[0],
								currency_code: product.variants[0].prices[0].currency.code,
							},
						],
					},
				],
			});

			delete updatedProductData.variants[0].prices[0].currency;
			delete updatedProductData.variants[0].options;

			return removeNullishEntries(updatedProductData);
		});

		const {
			storeId,
			products,
		} = await createStoreWithProducts({
			siteId: siteStore.websiteId,
			products: cleanedUpProductsData,
		});

		const websiteDataWithUpdatedStoreInfo = updateTemplateWithAiProductsData({
			templateData: siteStore.site,
			storeId,
			dummyProductData: newProducts,
			storeProductData: products,
		}) as SiteData;

		siteStore.setSiteData(websiteDataWithUpdatedStoreInfo);
	};

	return {
		createEcommerceStoreWithAiProducts,
	};
});
