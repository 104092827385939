import { DEFAULT_HTML_LANG_VALUE } from '@zyro-inc/site-modules/constants';

export const HTML_LANG_VALUES = [
	DEFAULT_HTML_LANG_VALUE,
	{
		title: 'Abkhazian',
		value: 'ab',
	},
	{
		title: 'Afar',
		value: 'aa',
	},
	{
		title: 'Afrikaans',
		value: 'af',
	},
	{
		title: 'Akan',
		value: 'ak',
	},
	{
		title: 'Albanian',
		value: 'sq',
	},
	{
		title: 'Amharic',
		value: 'am',
	},
	{
		title: 'العربية',
		value: 'ar',
	},
	{
		title: 'Aragonese',
		value: 'an',
	},
	{
		title: 'Armenian',
		value: 'hy',
	},
	{
		title: 'Assamese',
		value: 'as',
	},
	{
		title: 'Avaric',
		value: 'av',
	},
	{
		title: 'Avestan',
		value: 'ae',
	},
	{
		title: 'Aymara',
		value: 'ay',
	},
	{
		title: 'Azerbaijani',
		value: 'az',
	},
	{
		title: 'Bambara',
		value: 'bm',
	},
	{
		title: 'Bashkir',
		value: 'ba',
	},
	{
		title: 'Basque',
		value: 'eu',
	},
	{
		title: 'Belarusian',
		value: 'be',
	},
	{
		title: 'Bengali (Bangla)',
		value: 'bn',
	},
	{
		title: 'Bihari',
		value: 'bh',
	},
	{
		title: 'Bislama',
		value: 'bi',
	},
	{
		title: 'Bosnian',
		value: 'bs',
	},
	{
		title: 'Breton',
		value: 'br',
	},
	{
		title: 'Bulgarian',
		value: 'bg',
	},
	{
		title: 'Burmese',
		value: 'my',
	},
	{
		title: 'Catalan',
		value: 'ca',
	},
	{
		title: 'Chamorro',
		value: 'ch',
	},
	{
		title: 'Chechen',
		value: 'ce',
	},
	{
		title: 'Chichewa, Chewa, Nyanja',
		value: 'ny',
	},
	{
		title: 'Chinese',
		value: 'zh',
	},
	{
		title: 'Chinese (Simplified)',
		value: 'zh-Hans',
	},
	{
		title: 'Chinese (Traditional) ',
		value: 'zh-Hant',
	},
	{
		title: 'Chuvash',
		value: 'cv',
	},
	{
		title: 'Cornish',
		value: 'kw',
	},
	{
		title: 'Corsican',
		value: 'co',
	},
	{
		title: 'Cree',
		value: 'cr',
	},
	{
		title: 'Croatian',
		value: 'hr',
	},
	{
		title: 'Czech',
		value: 'cs',
	},
	{
		title: 'Danish',
		value: 'da',
	},
	{
		title: 'Divehi, Dhivehi, Maldivian',
		value: 'dv',
	},
	{
		title: 'Dutch',
		value: 'nl',
	},
	{
		title: 'Dzongkha',
		value: 'dz',
	},
	{
		title: 'Esperanto',
		value: 'eo',
	},
	{
		title: 'Estonian',
		value: 'et',
	},
	{
		title: 'Ewe',
		value: 'ee',
	},
	{
		title: 'Faroese',
		value: 'fo',
	},
	{
		title: 'Fijian',
		value: 'fj',
	},
	{
		title: 'Finnish',
		value: 'fi',
	},
	{
		title: 'French',
		value: 'fr',
	},
	{
		title: 'Fula, Fulah, Pulaar, Pular',
		value: 'ff',
	},
	{
		title: 'Galician',
		value: 'gl',
	},
	{
		title: 'Gaelic (Scottish)',
		value: 'gd',
	},
	{
		title: 'Georgian',
		value: 'ka',
	},
	{
		title: 'German',
		value: 'de',
	},
	{
		title: 'Greek',
		value: 'el',
	},
	{
		title: 'Greenlandic, Kalaallisut',
		value: 'kl',
	},
	{
		title: 'Guarani',
		value: 'gn',
	},
	{
		title: 'Gujarati',
		value: 'gu',
	},
	{
		title: 'Haitian Creole',
		value: 'ht',
	},
	{
		title: 'Hausa',
		value: 'ha',
	},
	{
		title: 'Hebrew',
		value: 'he',
	},
	{
		title: 'Herero',
		value: 'hz',
	},
	{
		title: 'Hindi',
		value: 'hi',
	},
	{
		title: 'Hiri Motu',
		value: 'ho',
	},
	{
		title: 'Hungarian',
		value: 'hu',
	},
	{
		title: 'Icelandic',
		value: 'is',
	},
	{
		title: 'Ido',
		value: 'io',
	},
	{
		title: 'Igbo',
		value: 'ig',
	},
	{
		title: 'Indonesian',
		value: 'id, in',
	},
	{
		title: 'Interlingua',
		value: 'ia',
	},
	{
		title: 'Interlingue',
		value: 'ie',
	},
	{
		title: 'Inuktitut',
		value: 'iu',
	},
	{
		title: 'Inupiak',
		value: 'ik',
	},
	{
		title: 'Irish',
		value: 'ga',
	},
	{
		title: 'Italian',
		value: 'it',
	},
	{
		title: 'Japanese',
		value: 'ja',
	},
	{
		title: 'Javanese',
		value: 'jv',
	},
	{
		title: 'Kannada',
		value: 'kn',
	},
	{
		title: 'Kanuri',
		value: 'kr',
	},
	{
		title: 'Kashmiri',
		value: 'ks',
	},
	{
		title: 'Kazakh',
		value: 'kk',
	},
	{
		title: 'Khmer',
		value: 'km',
	},
	{
		title: 'Kikuyu',
		value: 'ki',
	},
	{
		title: 'Kinyarwanda (Rwanda)',
		value: 'rw',
	},
	{
		title: 'Kirundi',
		value: 'rn',
	},
	{
		title: 'Kyrgyz',
		value: 'ky',
	},
	{
		title: 'Komi',
		value: 'kv',
	},
	{
		title: 'Kongo',
		value: 'kg',
	},
	{
		title: 'Korean',
		value: 'ko',
	},
	{
		title: 'Kurdish',
		value: 'ku',
	},
	{
		title: 'Kwanyama',
		value: 'kj',
	},
	{
		title: 'Lao',
		value: 'lo',
	},
	{
		title: 'Latin',
		value: 'la',
	},
	{
		title: 'Latvian (Lettish)',
		value: 'lv',
	},
	{
		title: 'Limburgish (Limburger)',
		value: 'li',
	},
	{
		title: 'Lingala',
		value: 'ln',
	},
	{
		title: 'Lithuanian',
		value: 'lt',
	},
	{
		title: 'Luga-Katanga',
		value: 'lu',
	},
	{
		title: 'Luganda, Ganda',
		value: 'lg',
	},
	{
		title: 'Luxembourgish',
		value: 'lb',
	},
	{
		title: 'Gaelic, Manx',
		value: 'gv',
	},
	{
		title: 'Macedonian',
		value: 'mk',
	},
	{
		title: 'Malagasy',
		value: 'mg',
	},
	{
		title: 'Malay',
		value: 'ms',
	},
	{
		title: 'Malayalam',
		value: 'ml',
	},
	{
		title: 'Maltese',
		value: 'mt',
	},
	{
		title: 'Maori',
		value: 'mi',
	},
	{
		title: 'Marathi',
		value: 'mr',
	},
	{
		title: 'Marshallese',
		value: 'mh',
	},
	{
		title: 'Moldavian',
		value: 'mo',
	},
	{
		title: 'Mongolian',
		value: 'mn',
	},
	{
		title: 'Nauru',
		value: 'na',
	},
	{
		title: 'Navajo',
		value: 'nv',
	},
	{
		title: 'Ndonga',
		value: 'ng',
	},
	{
		title: 'Northern Ndebele',
		value: 'nd',
	},
	{
		title: 'Nepali',
		value: 'ne',
	},
	{
		title: 'Norwegian',
		value: 'no',
	},
	{
		title: 'Norwegian bokmål',
		value: 'nb',
	},
	{
		title: 'Norwegian nynorsk',
		value: 'nn',
	},
	{
		title: 'Nuosu, Sichuan Yi',
		value: 'ii',
	},
	{
		title: 'Occitan',
		value: 'oc',
	},
	{
		title: 'Ojibwe',
		value: 'oj',
	},
	{
		title: 'Old Church Slavonic, Old Bulgarian',
		value: 'cu',
	},
	{
		title: 'Oriya',
		value: 'or',
	},
	{
		title: 'Oromo (Afaan Oromo)',
		value: 'om',
	},
	{
		title: 'Ossetian',
		value: 'os',
	},
	{
		title: 'Pāli',
		value: 'pi',
	},
	{
		title: 'Pashto, Pushto',
		value: 'ps',
	},
	{
		title: 'Persian (Farsi)',
		value: 'fa',
	},
	{
		title: 'Polish',
		value: 'pl',
	},
	{
		title: 'Portuguese',
		value: 'pt',
	},
	{
		title: 'Punjabi (Eastern)',
		value: 'pa',
	},
	{
		title: 'Quechua',
		value: 'qu',
	},
	{
		title: 'Romansh',
		value: 'rm',
	},
	{
		title: 'Romanian',
		value: 'ro',
	},
	{
		title: 'Russian',
		value: 'ru',
	},
	{
		title: 'Sami',
		value: 'se',
	},
	{
		title: 'Samoan',
		value: 'sm',
	},
	{
		title: 'Sango',
		value: 'sg',
	},
	{
		title: 'Sanskrit',
		value: 'sa',
	},
	{
		title: 'Serbian',
		value: 'sr',
	},
	{
		title: 'Serbo-Croatian',
		value: 'sh',
	},
	{
		title: 'Sesotho',
		value: 'st',
	},
	{
		title: 'Setswana',
		value: 'tn',
	},
	{
		title: 'Shona',
		value: 'sn',
	},
	{
		title: 'Sindhi',
		value: 'sd',
	},
	{
		title: 'Sinhalese',
		value: 'si',
	},
	{
		title: 'Slovak',
		value: 'sk',
	},
	{
		title: 'Slovenian',
		value: 'sl',
	},
	{
		title: 'Somali',
		value: 'so',
	},
	{
		title: 'Southern Ndebele',
		value: 'nr',
	},
	{
		title: 'Spanish',
		value: 'es',
	},
	{
		title: 'Sundanese',
		value: 'su',
	},
	{
		title: 'Swahili (Kiswahili)',
		value: 'sw',
	},
	{
		title: 'Swati, Siswati',
		value: 'ss',
	},
	{
		title: 'Swedish',
		value: 'sv',
	},
	{
		title: 'Tagalog',
		value: 'tl',
	},
	{
		title: 'Tahitian',
		value: 'ty',
	},
	{
		title: 'Tajik',
		value: 'tg',
	},
	{
		title: 'Tamil',
		value: 'ta',
	},
	{
		title: 'Tatar',
		value: 'tt',
	},
	{
		title: 'Telugu',
		value: 'te',
	},
	{
		title: 'Thai',
		value: 'th',
	},
	{
		title: 'Tibetan',
		value: 'bo',
	},
	{
		title: 'Tigrinya',
		value: 'ti',
	},
	{
		title: 'Tonga',
		value: 'to',
	},
	{
		title: 'Tsonga',
		value: 'ts',
	},
	{
		title: 'Turkish',
		value: 'tr',
	},
	{
		title: 'Turkmen',
		value: 'tk',
	},
	{
		title: 'Twi',
		value: 'tw',
	},
	{
		title: 'Uyghur',
		value: 'ug',
	},
	{
		title: 'Ukrainian',
		value: 'uk',
	},
	{
		title: 'Urdu',
		value: 'ur',
	},
	{
		title: 'Uzbek',
		value: 'uz',
	},
	{
		title: 'Venda',
		value: 've',
	},
	{
		title: 'Vietnamese',
		value: 'vi',
	},
	{
		title: 'Volapük',
		value: 'vo',
	},
	{
		title: 'Wallon',
		value: 'wa',
	},
	{
		title: 'Welsh',
		value: 'cy',
	},
	{
		title: 'Wolof',
		value: 'wo',
	},
	{
		title: 'Western Frisian',
		value: 'fy',
	},
	{
		title: 'Xhosa',
		value: 'xh',
	},
	{
		title: 'Yiddish',
		value: 'yi, ji',
	},
	{
		title: 'Yoruba',
		value: 'yo',
	},
	{
		title: 'Zhuang, Chuang',
		value: 'za',
	},
	{
		title: 'Zulu',
		value: 'zu',
	},
];
