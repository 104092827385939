import axios from '@/services/http/axios';
import { AI_BUILDER_THEME } from '@/constants';

export const AI_API = `${import.meta.env.VITE_BACKEND_API_URL}/v1/ai`;

export const generateTemplateSite = ({
	brandName,
	brandDescription,
	templateId,
	siteId,
	domain,
	txtRecord,
	hostingReferenceId,
}) => axios.post(`${AI_API}/generate-template-site`, {
	brandName,
	websiteDescription: brandDescription,
	templateId,
	siteId,
	domain,
	txtRecord,
	hostingReferenceId,
}, {
	isRetryNeeded: true,
});

export const generateSite = ({
	brandName,
	brandDescription,
	websiteType,
	templateId,
	fonts,
	siteId,
	generateImages = true,
	isHeroImageAiGenerated = false,
	colors,
	domain,
	txtRecord,
	hostingReferenceId,
}) => axios.post(`${AI_API}/generate-site-paid`, {
	brandName,
	websiteDescription: brandDescription,
	websiteType,
	templateId,
	theme: AI_BUILDER_THEME,
	fonts,
	siteName: brandName,
	generateImages,
	isHeroImageAiGenerated,
	...(siteId && {
		siteId,
	}),
	colors,
	domain,
	txtRecord,
	hostingReferenceId,
});

export const createSeo = ({
	brandDescription,
	keywords,
}) => axios.post(`${AI_API}/seo`, {
	text: brandDescription,
	keywords,
});

export const createImageAltTag = ({ imageUrl }) => axios.post(`${AI_API}/seo/alt-tag`, {
	assetUrl: imageUrl,
});

export const generateText = ({ prompt }) => axios.post(`${AI_API}/generate-text`, {
	prompt,
});

export const getSiteMatchingColorPaletteName = ({ description }) => axios.post(`${AI_API}/color-palette`, {
	description,
});

export const generateBlog = ({
	tone,
	length,
	description,
}) => axios.post(`${AI_API}/generate-blog-post`, {
	description,
	tone,
	length,
});

export const generateImages = ({
	description,
	width,
	height,
	stylePreset,
	siteId,
	count,
}) => axios.post(`${AI_API}/generate-images`, {
	description,
	width,
	height,
	stylePreset,
	siteId,
	count,
});

export const templateSearch = ({
	input,
	locale,
}) => axios.post(`${AI_API}/template-search`, {
	input,
	locale,
});

export const generateSection = ({ description }) => axios.post(`${AI_API}/generate-section`, {
	description,
});

export const callGeneratePage = ({ pageDescription }) => axios.post(`${AI_API}/generate-page`, {
	pageDescription,
}, {
	isRetryNeeded: true,
});
