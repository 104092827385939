<template>
	<BlockEcommerceProduct
		ref="ecommerceProductRef"
		v-qa="'builder-section-zyroecommerceproduct'"
		v-bind="{
			blockId,
			blockStyle,
			blockButtonText,
			blockButtonStyle,
			blockButtonType,
			blockButtonBorderWidth,
			textColorVars,
			imageBorderRadius,
			navigationArrowsColor,
			navigationThumbnailArrowsColor,
			galleryPlacement,
			imageRatio,
			isQuantityPickerEnabled,
			currentPageType
		}"
		:product-data="product"
		:is-mobile-view="isMobileView"
		:is-cart-visible="isCartVisible"
		:translations="ecommerceTranslations"
		:class="customAnimationClass"
		:site-id="websiteId"
		:shopping-cart-items="shoppingCartItems"
		:variants-quantity="variantsQuantity"
		:[DATA_ATTRIBUTE_ANIMATION_STATE]="animationAttributeStateValue"
	/>
	<BlockControls
		:block-id="blockId"
		:target-ref="ecommerceProductRef?.$el"
		:is-first-block="isFirstBlock"
		:transparent-header-height="transparentHeaderHeight"
	/>
	<BlockEditControls
		:block-id="blockId"
		:target-ref="ecommerceProductRef?.$el"
		:is-duplicate-button-visible="!siteStore.isCurrentPageTypeEcommerceProduct"
		:is-delete-button-visible="!siteStore.isCurrentPageTypeEcommerceProduct"
		:is-context-menu-button-visible="!siteStore.isCurrentPageTypeEcommerceProduct"
		is-reordering-button-visible
		is-visibility-button-visible
		:edit-popup-options="EDIT_POPUP_OPTIONS"
		:enter-edit-mode-button-title="t('builder.editBlockButton.editSection')"
	>
		<template #additional-edit-buttons>
			<VerticalSeparator />
			<ControlsTooltip>
				<HostingerButton
					v-qa="'builder-section-btn-assignproduct'"
					button-type="plain"
					theme="highlight"
					@click="handleEditProductClick"
				>
					<span class="text-body-2">{{ productId !== -1 ? $t('builder.editProduct') : $t('builder.addProduct') }}</span>
				</HostingerButton>
			</ControlsTooltip>
		</template>

		<template #edit-mode-popup>
			<EditBlockEcommerceProductTabs
				:block-id="blockId"
				:is-product-purchasable="product.purchasable ?? true"
				@close="closeBlockEditPopupHandler"
			/>
		</template>
	</BlockEditControls>
</template>

<script setup lang="ts">
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import HostingerButton from '@/components/global/HostingerButton.vue';
import ControlsTooltip from '@/components/ControlsTooltip.vue';
import BlockEditControls from '@/components/builder-controls/BlockEditControls.vue';
import VerticalSeparator from '@/components/global/VerticalSeparator.vue';
import BlockControls from '@/components/block/BlockControls.vue';
import BlockEcommerceProduct from '@zyro-inc/site-modules/components/blocks/ecommerce/BlockEcommerceProduct.vue';
import EditBlockEcommerceProductTabs from '@/components/builder-controls/edit-block-ecommerce-product/EditBlockTabsEcommerceProduct.vue';
import { useBlockEcommerceProduct } from '@zyro-inc/site-modules/components/blocks/ecommerce/useBlockEcommerceProduct';
import { useSiteEngineAnimations } from '@zyro-inc/site-modules/use/useSiteEngineAnimations';
import { DATA_ATTRIBUTE_ANIMATION_STATE } from '@zyro-inc/site-modules/constants';
import {
	DEFAULT_EMPTY_PRODUCT_VALUE,
	DEMO_PRODUCTS,
} from '@zyro-inc/site-modules/constants/ecommerce';
import {
	computed,
	ref,
} from 'vue';
import {
	ECOMMERCE_REDIRECT_PATHS,
	REDIRECT_PARAM_KEYS,
	ECOMMERCE_FUNNEL_LOCATIONS,
} from '@/constants';
import { useEcommerceAdminStore } from '@/stores';
import { useSiteStore } from '@/stores/siteStore';

const EDIT_POPUP_OPTIONS = {
	placement: 'right-start',
	placementMobile: 'right-start',
	flip: false,
	offset: {
		mainAxis: 8,
		crossAxis: 24,
	},
	offsetMobile: {
		mainAxis: 24,
		crossAxis: 0,
	},
	padding: 8,
	autoUpdate: true,
};

const props = defineProps({
	blockId: {
		type: String,
		required: true,
	},
	data: {
		type: Object,
		default: () => ({}),
	},
	areDemoProductsShown: {
		type: Boolean,
		default: false,
	},
	ecommerceTranslations: {
		type: Object,
		default: () => ({}),
	},
	currentPageType: {
		type: String,
		default: 'default',
	},
	isFirstBlock: {
		type: Boolean,
		default: false,
	},
	transparentHeaderHeight: {
		type: Number,
		default: 0,
	},
});

const {
	state,
	getters,
	dispatch,
} = useStore();
const siteStore = useSiteStore();
const { t } = useI18n();

const {
	productId,
	blockStyle,
	blockButtonText,
	blockButtonStyle,
	blockButtonType,
	blockButtonBorderWidth,
	textColorVars,
	imageBorderRadius,
	navigationArrowsColor,
	navigationThumbnailArrowsColor,
	galleryPlacement,
	imageRatio,
	isQuantityPickerEnabled,
} = useBlockEcommerceProduct(props);
const {
	customAnimationClass,
	animationAttributeStateValue,
} = useSiteEngineAnimations({
	data: props.data,
	elementData: null,
	elementId: null,
});
const ecommerceAdminStore = useEcommerceAdminStore();

const ecommerceProductRef = ref<InstanceType<typeof BlockEcommerceProduct> | null>(null);

const websiteId = computed(() => state.websiteId);
const isMobileView = computed(() => state.gui.isMobileView);
const shoppingCartItems = computed(() => state.ecommerce?.shoppingCartItems);
const variantsQuantity = computed(() => state.ecommerce?.variantsQuantity);

const isCartVisible = computed(() => getters['ecommerce/isCartVisible']);
const products = computed(() => [
	...getters['ecommerce/products'],
	...getters['ecommerce/hiddenProducts'],
]);

const product = computed(() => {
	if (props.areDemoProductsShown) {
		return DEMO_PRODUCTS[0];
	}

	const foundProduct = products.value.find((item) => item.id === productId.value);

	return foundProduct || DEFAULT_EMPTY_PRODUCT_VALUE;
});

const handleEditProductClick = () => {
	if (productId.value !== -1) {
		ecommerceAdminStore.setIsEcommerceAdminIframeOpen({
			isOpen: true,
			path: ECOMMERCE_REDIRECT_PATHS.PRODUCTS_EDIT,
			params: {
				[REDIRECT_PARAM_KEYS.PRODUCT]: productId.value,
			},
			location: ECOMMERCE_FUNNEL_LOCATIONS.IFRAME_EDIT_PRODUCT,
		});
	} else {
		ecommerceAdminStore.setIsEcommerceAdminIframeOpen({
			isOpen: true,
			path: ECOMMERCE_REDIRECT_PATHS.PRODUCTS_ADD,
			location: ECOMMERCE_FUNNEL_LOCATIONS.IFRAME_ADD_PRODUCT,
		});
	}
};

const closeBlockEditPopupHandler = () => {
	dispatch('leaveBlockEditMode');
};
</script>
