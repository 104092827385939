<template>
	<div class="seo-drawer__navigation-content">
		<div class="seo-drawer__head">
			<div class="seo-drawer__overview">
				<div
					class="seo-drawer__page-status-block"
					:class="{ 'seo-drawer__page-status-block--selected' : isOverviewSelected }"
					@click="handleOverviewClick"
				>
					<div class="seo-drawer__page-status-block-content">
						<Icon
							name="article"
							dimensions="20px"
						/>
						<p class="seo-drawer__page-name text-body-2">
							{{ t('builder.websiteOverview') }}
						</p>
					</div>
				</div>
			</div>
			<div class="seo-drawer__controls">
				<PageSelect
					:model-value="seoStore.currentTab"
					@update:model-value="updateCurrentTab"
				/>
				<PageSearch
					:model-value="seoStore.searchValue"
					:is-searching="isSearching"
					@update:model-value="searchPage"
				/>
			</div>
		</div>
		<div
			v-for="(page, key) in seoStore.pagesToDisplay"
			:key="key"
			class="seo-drawer__page-status-block"
			:class="{ 'seo-drawer__page-status-block--selected' : !isOverviewSelected && currentPageId === key }"
			@click="selectPage(key as string)"
		>
			<div class="seo-drawer__page-status-block-content">
				<Icon
					class="seo-drawer__status-icon"
					:name="currentTabSeoStatuses[key] ? 'check_circle' : 'error'"
					dimensions="20px"
					is-filled
					:class="{ 'seo-drawer__status-icon--completed': currentTabSeoStatuses[key] }"
				/>
				<p class="seo-drawer__page-name text-body-2">
					{{ page.name }}
				</p>
			</div>
		</div>
		<div
			ref="lazyLoadingTriggerRef"
			class="lazy-loading-trigger"
		/>
	</div>
</template>

<script setup lang="ts">
import { useSeo } from '@/use/useSeo';
import Icon from '@/components/global/Icon.vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import PageSelect from '@/components/builder-drawers/drawers/partials/seoDrawer/PageSelect.vue';
import PageSearch from '@/components/builder-drawers/drawers/partials/seoDrawer/PageSearch.vue';
import { debounce } from '@zyro-inc/site-modules/utils/debounce';
import { useSeoStore } from '@/stores/seoStore';
import {
	computed,
	ref,
	onMounted,
} from 'vue';
import { SeoDrawerTab } from '@/types/seoTypes';

const SEARCH_DEBOUNCE_TIME_MS = 500;

interface Props {
	isOverviewSelected: boolean;
	selectPage: (key: string) => void;
	handleOverviewClick: () => void;
}

defineProps<Props>();

const { state } = useStore();
const { t } = useI18n();
const seoStore = useSeoStore();

const { currentTabSeoStatuses } = useSeo();
const lazyLoadingTriggerRef = ref<HTMLElement | null>(null);
const isSearching = ref(false);
const observer = ref<IntersectionObserver | null>(null);

const currentPageId = computed(() => state.currentPageId);
const updatePagesToDisplay = () => {
	const pagesToDisplay = seoStore.getCurrentTabPages();

	seoStore.setPagesToDisplay(pagesToDisplay);
};

const updateCurrentTab = (tab: SeoDrawerTab) => {
	seoStore.setCurrentTab(tab);
	seoStore.setSearchValue('');
	seoStore.setCurrentPage(1);
	updatePagesToDisplay();
};

const searchDebounce = debounce(() => {
	seoStore.setCurrentPage(1);
	updatePagesToDisplay();
	isSearching.value = false;
}, SEARCH_DEBOUNCE_TIME_MS);

const searchPage = (value: string) => {
	seoStore.setSearchValue(value);
	isSearching.value = true;

	searchDebounce();
};

const handleInterect = async (isIntersecting: boolean) => {
	if (!isIntersecting) {
		return;
	}

	if (seoStore.currentPage >= seoStore.totalPages) {
		return;
	}

	seoStore.setCurrentPage(seoStore.currentPage + 1);

	const newPagesToDisplay = {
		...seoStore.pagesToDisplay,
		...seoStore.getCurrentTabPages(),
	};

	seoStore.setPagesToDisplay(newPagesToDisplay);
};

onMounted(() => {
	updatePagesToDisplay();

	observer.value = new IntersectionObserver(([{ isIntersecting }]) => {
		handleInterect(isIntersecting);
	}, {
		threshold: 1,
	});

	if (!lazyLoadingTriggerRef.value) {
		return;
	}

	observer.value.observe(lazyLoadingTriggerRef.value);
});
</script>

<style lang="scss" scoped>
.seo-drawer {
	&__head {
		width: calc(100%);
		margin: 8px 0;
	}

	&__navigation-content {
		@media screen and (max-width: $media-mobile) {
			margin-top: -16px;
			border-top: 1px solid $color-gray-border;
		}
	}

	&__overview {
		border-bottom: 1px solid $color-gray-border;
	}

	&__controls {
		display: flex;
		flex-direction: column;
		margin-top: 8px;
		gap: 8px;
	}

	&__page-status-block {
		display: flex;
		gap: 12px;
		align-items: center;
		cursor: pointer;
		border-radius: 8px;
		margin-bottom: 8px;

		&:hover {
			background-color: $color-gray-light;
			color: $color-dark
		}

		&--selected {
			background-color: $color-primary-light;
			color: $color-primary;

			&:hover {
				background-color: $color-primary-light;
				color: $color-primary;
			}
		}
	}

	&__page-status-block-content {
		display: flex;
		align-items: center;
		gap: 8px;
		width: 100%;
		padding: 8px;
	}

	&__status-icon {
		flex-shrink: 0;
		color: $color-warning-dark;

		&--completed {
			color: $color-success;
		}
	}
}

.lazy-loading-trigger {
	position: relative;
	z-index: -1;
	height: 1px;
	margin-bottom: -1px;
	pointer-events: none;
}
</style>
