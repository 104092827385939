import {
	TIME_DENOMINATOR_MINUTE,
	TIME_DENOMINATOR_HOUR,
	TIME_DENOMINATOR_DAY,
} from '@/constants';
import { getLocaleCodeFromCookie } from '@/utils/i18n/cookieLocale';
import { DEFAULT_LOCALE } from '@/utils/i18n/supportedLocales';

export const UKR_LOCALE = 'UK';
export const GB_LOCALE = 'GB';

// Slicing ISODate returns format of YYYY-MM-dd
export const sliceTimeFromISODate = (ISODateString) => {
	if (typeof ISODateString !== 'string') {
		return null;
	}

	return ISODateString?.slice(0, 10);
};

export const getLocaleForDateFormatting = (locale) => {
	// In our translation system, UK is for UNITED KINGDOM. But for Date.toLocaleDateString it is UKRAINIAN.
	if (locale.toUpperCase() === UKR_LOCALE) {
		return GB_LOCALE;
	}

	return locale;
};

export const getFormattedLocalizedDate = (date) => new Date(date).toLocaleDateString(
	getLocaleForDateFormatting(getLocaleCodeFromCookie() || DEFAULT_LOCALE.code),
	{
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	},
);

export const getFormattedNumericDate = (date) => new Date(date).toLocaleDateString('en-US');

export const convertMsToDays = (ms) => Math.ceil(ms / TIME_DENOMINATOR_DAY);

/**
 * @desc Returns object with remainder of hours, minutes and seconds for provided milliseconds
 */
export const getTimeRemainder = (milliseconds) => {
	const hours = Math.floor((milliseconds / (TIME_DENOMINATOR_HOUR)) % 24);
	const minutes = Math.floor((milliseconds / (TIME_DENOMINATOR_MINUTE)) % 60);
	const seconds = Math.floor((milliseconds / 1000) % 60);

	return {
		hours,
		minutes,
		seconds,
	};
};

export const convertUTCStringToDate = (utcDateString) => {
	if (typeof utcDateString !== 'string') return null;

	const isFormatInvalid = /[^\d :-]/.test(utcDateString);

	if (isFormatInvalid) return null;

	const [datePart, timePart] = utcDateString.split(' ');

	const [
		year,
		month,
		day,
	] = datePart.split('-').map(Number);

	const [
		hour,
		minute,
		second,
	] = timePart.split(':').map(Number);

	const utcDate = new Date(Date.UTC(year, month - 1, day, hour, minute, second));

	return utcDate;
};

export const getHasTimePassed = (date, timeDifferenceThreshold) => {
	const providedDate = new Date(date);
	const currentDate = new Date();

	const timeDifference = currentDate.getTime() - providedDate.getTime();

	return timeDifference > timeDifferenceThreshold;
};

export const getHas7DaysPassed = (date) => {
	if (!date) {
		return false;
	}

	const sevenDaysInMs = 7 * 24 * 60 * 60 * 1000;
	const utcDate = convertUTCStringToDate(date) || date;

	return getHasTimePassed(utcDate, sevenDaysInMs);
};
