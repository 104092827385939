<template>
	<p class="text-body-2 social-icons-disclaimer">
		{{ $t('builder.editSocialIcons.tabLinks.socialIconMesssage') }}
	</p>

	<EditableItemsList
		ref="items"
		:items="editableItems"
		:placeholder="$t('builder.editSocialIcons.tabLinks.placeholder')"
		:item-placeholder-text="$t('builder.editSocialIcons.tabLinks.itemPlaceholder')"
		:validate-value="validator"
		is-editable-by-single-click
		:is-editable-by-double-click="false"
		class="social-icons-editable-list"
		@edit="editUrl"
		@update-items="updateItems"
	>
		<template #header>
			<EditableItemsAddButton
				v-qa="'edit-social-icons-popup-links-button-add'"
				:button-text="$t('builder.editSocialIcons.tabLinks.addNew')"
				:placeholder="$t('builder.editSocialIcons.tabLinks.placeholder')"
				:validate-value="validator"
				@add="addUrl"
			/>
		</template>
		<template #item-button="{ item, index, startEditingItem }">
			<HostingerButton
				v-qa="`edit-social-icons-popup-links-settings-open-${socialLinksElement.links[index].icon || 'link'}`"
				:data-popup-id="`linkEditButton-${index}`"
				button-type="plain"
				:title="$t('common.settings')"
				@click="openPopup(item, index)"
			>
				<template #icon>
					<Icon
						name="settings"
						dimensions="16px"
					/>
				</template>
			</HostingerButton>
			<Popup
				v-if="isSettingsPopupOpen && openItem === `${item.name}-${index}`"
				:target-ref="linkEditButtonPopupRef"
				padding="0"
				border-radius="16"
				:offset="settingsPopupOffset"
				placement="bottom-end"
				@click-outside="isSettingsPopupOpen = false; openItem = null"
			>
				<div class="links__link-settings">
					<HostingerButton
						v-qa="'edit-social-icons-popup-links-link-edit'"
						button-type="plain"
						@click="startEditingItem(index, item)"
					>
						<template #icon-left>
							<Icon
								name="edit"
								dimensions="16px"
							/>
						</template>
						{{ $t('common.edit') }}
					</HostingerButton>

					<HostingerButton
						v-qa="'edit-social-icons-popup-links-link-remove'"
						class="links__link-remove"
						button-type="plain"
						:title="$t('common.delete')"
						@click="removeUrl(index)"
					>
						<template #icon-left>
							<Icon
								name="delete"
								dimensions="16px"
							/>
						</template>
						{{ $t('common.delete') }}
					</HostingerButton>
				</div>
			</Popup>
		</template>
	</EditableItemsList>
</template>

<script>
import Icon from '@/components/global/Icon.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import Popup from '@/components/global/Popup.vue';

import {
	mapActions,
	useStore,
} from 'vuex';

import svgImporter from '@/utils/svgImporter';

import { availableIcons } from '@/assets/data/brandInfo';
import EditableItemsAddButton from '@/components/reusable-components/editable-items-list/-partials/EditableItemsAddButton.vue';
import EditableItemsList from '@/components/reusable-components/editable-items-list/EditableItemsList.vue';
import {
	getValidEmail,
	getValidPhoneNumber,
	getValidUrl,
} from '@/utils/urlValidators';
import { REGEX_URL_START } from '@zyro-inc/site-modules/constants/regex';
import { useGamification } from '@/use/useGamification';
import { GAMIFICATION_TASK_CHANGE_SOCIAL_ICONS } from '@/constants';
import {
	computed,
	defineComponent,
	ref,
} from 'vue';

const settingsPopupOffset = {
	x: 0,
	y: 8,
};

export default defineComponent({

	components: {
		Popup,
		Icon,
		HostingerButton,
		EditableItemsList,
		EditableItemsAddButton,
	},

	props: {
		elementId: {
			type: String,
			required: true,
		},
	},

	setup(props) {
		const { completeAchievement } = useGamification();
		const { getters } = useStore();

		const socialLinksElementId = computed(() => props.elementId);
		const socialLinksElement = computed(() => getters.siteElements[socialLinksElementId.value]);

		const error = ref('');
		const openItem = ref(null);
		const isSettingsPopupOpen = ref(false);
		const linkEditButtonPopupRef = ref(null);

		const openPopup = (item, index) => {
			isSettingsPopupOpen.value = true;
			openItem.value = `${item.name}-${index}`;

			linkEditButtonPopupRef.value = document.querySelector(`[data-popup-id="linkEditButton-${index}"]`);
		};

		return {
			openItem,
			openPopup,
			isSettingsPopupOpen,
			settingsPopupOffset,
			linkEditButtonPopupRef,
			completeAchievement,
			socialLinksElementId,
			socialLinksElement,
			error,
		};
	},

	computed: {
		editableItems() {
			return this.socialLinksElement.links.map((item) => {
				const {
					link,
					icon,
				} = item;

				return {
					originalData: {
						...item,
					},
					name: decodeURI(link),
					iconLocation: icon ? 'brands' : '',
					icon: icon ? `${icon}-brands` : 'link',
				};
			});
		},
	},

	methods: {
		...mapActions(['mergeElementData']),
		async getIconByURL(url) {
			const urlWithoutStart = url.replace(REGEX_URL_START, '');

			const icon = availableIcons.find((iconObject) => iconObject
				.identifiers.some((identifier) => {
					const pattern = new RegExp(`^([A-Za-z0-9]*?)${identifier}`, 'i');

					return pattern.test(urlWithoutStart);
				}));

			const iconSvg = await svgImporter.getSvg(icon && icon.iconName, 'brands', 'brands');

			return {
				...icon,
				svg: iconSvg,
			};
		},
		parseUrl(urlToParse) {
			const {
				url,
				isUrlValid,
			} = getValidUrl(urlToParse);

			const {
				url: emailUrl,
				isUrlValid: isEmailUrlValid,
			} = getValidEmail(urlToParse);

			const {
				url: phoneNumberUrl,
				isUrlValid: isPhoneNumberUrlValid,
			} = getValidPhoneNumber(urlToParse);

			const parsedUrl = url.includes('http') ? url : `https:${url}`;

			if (isEmailUrlValid && emailUrl) {
				return {
					url: emailUrl,
					isUrlValid: isEmailUrlValid,
				};
			}

			if (isPhoneNumberUrlValid && phoneNumberUrl) {
				return {
					url: phoneNumberUrl,
					isUrlValid: isPhoneNumberUrlValid,
				};
			}

			return {
				url: parsedUrl,
				isUrlValid,
			};
		},
		validator(urlToValidate) {
			const {
				url,
				isUrlValid,
			} = this.parseUrl(urlToValidate);

			if (!urlToValidate.trim()) {
				return {
					isValid: false,
					error: this.$t('validate.emptyValue'),
				};
			}

			const isValid = url.length > 5 && isUrlValid;

			return {
				isValid,
				error: !isValid ? this.$t('builder.editSocialIcons.error.invalidUrl') : '',
			};
		},
		updateItems(links) {
			this.mergeElementData({
				elementId: this.socialLinksElementId,
				elementData: {
					links: links.map(({ originalData }) => ({
						...originalData,
					})),
				},
			});
		},
		async addUrl(newUrl) {
			const { url } = this.parseUrl(newUrl);

			const icon = await this.getIconByURL(url);
			const links = [
				...this.socialLinksElement.links,
				{
					link: url,
					icon: icon.iconName || '',
					svg: icon.svg,
				},
			];

			this.mergeElementData({
				elementId: this.socialLinksElementId,
				elementData: {
					links,
				},
			});

			this.completeAchievement(GAMIFICATION_TASK_CHANGE_SOCIAL_ICONS);
		},
		removeUrl(index) {
			const links = [...this.socialLinksElement.links];

			links.splice(index, 1);
			this.mergeElementData({
				elementId: this.socialLinksElementId,
				elementData: {
					links,
				},
			});

			this.completeAchievement(GAMIFICATION_TASK_CHANGE_SOCIAL_ICONS);
		},
		async editUrl({
			newValue,
			index,
		}) {
			const { url } = this.parseUrl(newValue.name);
			const icon = await this.getIconByURL(url);
			const links = [...this.socialLinksElement.links];
			const currentItem = {
				...links[index],
			};

			links[index] = {
				...currentItem,
				link: url,
				icon: icon.iconName || '',
				svg: icon.svg,
			};
			this.mergeElementData({
				elementId: this.socialLinksElementId,
				elementData: {
					links,
				},
			});

			this.completeAchievement(GAMIFICATION_TASK_CHANGE_SOCIAL_ICONS);
		},
	},
});
</script>

<style lang="scss" scoped>
.social-icons-disclaimer {
	color: $color-gray;
	margin: 16px 0;
}

.social-icons-editable-list {
	margin-bottom: 16px;
}

.links {
	&__link-settings {
		display: flex;
		flex-direction: column;
		overflow: hidden;
		background: $color-light;
		border-radius: 16px;
		padding: 8px;
		box-shadow: $box-shadow;
	}
}
</style>
