<!-- @vue-ignore added for Typescript :key error for Transitions component is know issue
	https://github.com/vuejs/core/issues/8682, https://github.com/vuejs/language-tools/issues/3109 -->
<template>
	<div
		class="ai-builder-loader-wrapper"
		:class="{ 'ai-builder-loader-wrapper--fixed': isFixed }"
	>
		<StarBackground
			v-if="hasBackground"
			:is-enabled="hasBackground"
			is-fixed
			:opacity
		/>
		<Transition
			name="slide-bottom-to-top"
			mode="out-in"
			appear
		>
			<div
				v-if="currentStepId"
				class="ai-builder-loader-wrapper"
			>
				<div
					class="ai-builder-loader"
					:class="{ 'ai-builder-loader--completed': isLastStep }"
				>
					<Transition
						name="fade"
						appear
					>
						<FallingConfetti v-if="isConfettiVisible" />
					</Transition>
					<div class="ai-builder-loader__icon-wrapper">
						<!-- @vue-ignore -->
						<Transition
							:key="currentStep.icon"
							name="slide-bottom-to-top"
							appear
						>
							<ZyroSvgDeprecated
								:name="currentStep.icon"
								dimensions="40px"
								class="ai-builder-loader__icon"
							/>
						</Transition>
					</div>
					<!-- @vue-ignore -->
					<Transition
						:key="currentStep.text"
						name="slide-left"
						appear
					>
						<div class="ai-builder-loader__content-wrap">
							<div class="ai-builder-loader__content">
								<ZyroLoader
									v-if="!isLastStep"
									size="24px"
									color="var(--color-primary)"
								/>
								<p class="ai-builder-loader__text">
									{{ currentStep.text }}
								</p>
							</div>
							<!-- @vue-ignore -->
							<Transition
								:key="isPatientTextShown"
								name="slide-left"
								appear
							>
								<p
									v-if="isPatientTextShown"
									class="ai-builder-loader__patient-text"
								>
									{{ $t('builder.aiBuilderLoaderBePatient') }}
								</p>
							</Transition>
						</div>
					</Transition>
				</div>
				<Transition
					name="fade"
					appear
				>
					<div
						v-if="currentStepId"
						class="progress-bar"
					>
						<div
							class="progress-bar__bar"
							:style="{ '--percentage': `${percentage}%` }"
						/>
						<p class="progress-bar__text">
							{{ `${percentage}%` }}
						</p>
					</div>
				</Transition>
			</div>
			<slot
				v-else-if="hasLoaderFinished"
				name="loaded-content"
			/>
		</Transition>
	</div>
</template>

<script setup lang="ts">
import {
	ref,
	computed,
	watch,
} from 'vue';
import ZyroLoader from '@zyro-inc/site-modules/components/ZyroLoader.vue';
import ZyroSvgDeprecated from '@/components/global/ZyroSvgDeprecated.vue';
import FallingConfetti from '@/components/ai-builder/FallingConfetti.vue';
import { LOCAL_STORAGE_KEY_AI_BUILDER_FIRST_SITE } from '@/constants';
import StarBackground from '@/components/ai-builder/StarBackground.vue';
import {
	AiBuilderLoaderStep,
	LOADER_STEP_ID_FINISHING_TOUCHES_PATIENCE,
	LOADER_STEP_ID_WEBSITE_READY,
	TICKING_PERCENTAGE_TIMOUT,
} from '@/use/useAiBuilderLoader';

interface Props {
	isFixed: boolean;
	isLoading: boolean;
	isLoaderForceFinished: boolean;
	hasBackground: boolean;
	steps: AiBuilderLoaderStep[];
	opacity?: number;
}

const props = withDefaults(defineProps<Props>(), {
	isFixed: false,
	isLoading: false,
	isLoaderForceFinished: false,
	hasBackground: false,
	steps: () => [],
});

const percentage = ref(0);
const currentStepId = ref('');
const currentStepIndex = computed(() => props.steps.findIndex(({ id }) => id === currentStepId.value));
const currentStep = computed(() => props.steps[currentStepIndex.value]);
const isLastStep = computed(() => currentStepIndex.value === props.steps.length - 1);
const isConfettiVisible = ref(false);
const hasLoaderFinished = ref(false);
const isPatientTextShown = ref(false);

const startStepTimeout = ({ timeoutMs = currentStep.value.timeoutMs } = {}) => setTimeout(() => {
	if (isConfettiVisible.value) {
		isConfettiVisible.value = false;
		currentStepId.value = '';
		hasLoaderFinished.value = true;

		if (!localStorage.getItem(LOCAL_STORAGE_KEY_AI_BUILDER_FIRST_SITE)) {
			window.hj('event', 'website_builder.ai_builder.created');

			localStorage.setItem(LOCAL_STORAGE_KEY_AI_BUILDER_FIRST_SITE, 'true');
		}

		return;
	}

	// If "Finishing touches" step is reached, but we are still loading - keep this state.
	if (props.isLoading && currentStepId.value === LOADER_STEP_ID_FINISHING_TOUCHES_PATIENCE) {
		startStepTimeout();
		isPatientTextShown.value = true;

		return;
	}

	// Move on to the next step.
	currentStepId.value = props.steps[currentStepIndex.value + 1].id;

	// On the last step, "shoot the confetti" and reset the loader state on the next timeout
	if (currentStepId.value === LOADER_STEP_ID_WEBSITE_READY) {
		isPatientTextShown.value = false;
		isConfettiVisible.value = true;
	}

	startStepTimeout();
}, timeoutMs);

// Loader should start up only when `isLoading` prop is true.
watch(() => props.isLoading, (isLoading) => {
	if (isLoading) {
		currentStepId.value = props.steps[0].id;
		hasLoaderFinished.value = false;
		startStepTimeout();
	}
}, {
	immediate: true,
});

watch(() => props.isLoaderForceFinished, (isLoaderForceFinished) => {
	if (isLoaderForceFinished) {
		hasLoaderFinished.value = true;
	}
}, {
	immediate: true,
});

watch(() => currentStep.value?.percentage, (newPercentage) => {
	// set percentage increment to 1% per 100ms
	const interval = setInterval(() => {
		percentage.value += 1;

		if (percentage.value >= newPercentage) {
			clearInterval(interval);
		}
	}, TICKING_PERCENTAGE_TIMOUT);
}, {
	immediate: true,
});
</script>

<style lang="scss" scoped>
@import "@zyro-inc/site-modules/scss/transitions/slide";
@import "@zyro-inc/site-modules/scss/transitions/fade";

.ai-builder-loader-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	position: relative;

	&--fixed {
		position: fixed;
		top: 0;
		left: 0;
		z-index: $z-index-popup;
	}
}

.ai-builder-loader {
	$this: &;

	display: flex;
	gap: 16px;
	flex-direction: column;
	align-items: center;

	&__icon-wrapper {
		border-radius: 32px;
		background: $color-primary-light;
		width: 64px;
		height: 64px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__icon {
		color: $color-primary;
	}

	&__content-wrap {
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;

		// gap: 24px;
	}

	&__content {
		display: flex;
		gap: 8px;
	}

	&__text {
		font-weight: 700;
		font-size: 16px;
		line-height: 1.5;
		color: $color-light;

		@media screen and (max-width: $media-mobile) {
			margin-left: 8px;
			margin-bottom: 24px;
		}
	}

	&--completed {
		#{$this}__icon-wrapper {
			background: $color-success-light;
		}

		#{$this}__icon {
			color: $color-success;
		}
	}

	&__patient-text {
		position: absolute;
		white-space: nowrap;
		bottom: -44px;
		font-size: 16px;
		line-height: 1.5;
		color: $color-meteorite;
		background: $color-meteorite-dark;

		@media screen and (max-width: $media-mobile) {
			text-align: center;
			white-space: normal;
		}
	}
}

.progress-bar {
	position: fixed;
	bottom: 10%;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;

	@media screen and (max-width: $media-mobile) {
		position: absolute;
		bottom: 40px;
		flex-direction: column;
		gap: 4px;
		width: 90%;
	}

	&__bar {
		position: relative;
		width: 600px;
		height: 12px;
		background: rgba($color-primary-light, 0.1);
		border-radius: 100px;

		&::before {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			width: var(--percentage);
			content: "";
			background: $color-meteorite;
			border-radius: 100px;
			transition: width 0.3s ease;
		}

		@media screen and (max-width: $media-mobile) {
			width: 100%;
		}
	}

	&__text {
		width: 30px;
		font-size: 14px;
		font-weight: 700;
		line-height: 1.71;
		color: $color-meteorite;
	}
}
</style>
