import { computed } from 'vue';
import { useStore } from 'vuex';

import {
	ELEMENT_GRID_BUTTON_PRIMARY,
	ELEMENT_GRID_BUTTON_SECONDARY,
} from '@zyro-inc/site-modules/constants/globalStyles';

import { BUTTON_SET_LIST } from '@/data';
import { useSiteStore } from '@/stores/siteStore';

export const useButtonSetsLibrary = () => {
	const siteStore = useSiteStore();
	const { getters } = useStore();

	const currentButtonSetId = computed(() => getters.siteMeta.buttonSetId);

	const setButtonSet = (buttonSetId) => {
		siteStore.setSiteMetaData({
			key: 'buttonSetId',
			value: buttonSetId,
		});
		siteStore.setSiteStyleProperties({
			element: ELEMENT_GRID_BUTTON_PRIMARY,
			value: BUTTON_SET_LIST[buttonSetId].primary,
		});
		siteStore.setSiteStyleProperties({
			element: ELEMENT_GRID_BUTTON_SECONDARY,
			value: BUTTON_SET_LIST[buttonSetId].secondary,
		});
	};

	return {
		setButtonSet,
		currentButtonSetId,
	};
};
