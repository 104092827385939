/* eslint-disable import/no-cycle */
import { useStore } from 'vuex';

import {
	HPANEL_REDIRECTS_PATHS,
	WWW_REDIRECT_PATHS,
	ECOMMERCE_REDIRECT_PATHS,
	REDIRECT_PARAM_KEYS,
	REDIRECT_PARAM_VALUES,
	REDIRECT_TYPES,
	HOSTINGER_LOCALE_DOMAINS,
	HOSTINGER_US_LOCALE,
	MODAL_UPGRADE_TO_BUSINESS_PLAN,
	ECOMMERCE_FUNNEL_LOCATIONS,
} from '@/constants';
import { OPEN_MODAL } from '@/store/builder/gui';
import { getLocaleCodeFromCookie } from '@/utils/i18n/cookieLocale';
import { useUserStore } from '@/stores/userStore';
import { authRedirectService } from '@/services/http/authRedirectService';
import {
	getIsExperimentActive,
	EXPERIMENT_IDS,
} from '@/utils/experiments';

const {
	HOME,
	PAYMENTS,
	SINGLE_SITE_DASHBOARD_SUBSCRIPTION,
	SIGN_UP,
	SIGN_IN,
	SITES,
	SUBSCRIPTIONS,
} = WWW_REDIRECT_PATHS;
const {
	PRODUCTS,
	PRODUCTS_ADD,
	PRODUCTS_EDIT,
	PAYMENT_MANAGEMENT,
	BOOKINGS,
	SHIPPING_EDIT,
	COMPANY_INFORMATION,
} = ECOMMERCE_REDIRECT_PATHS;
const {
	LANG,
	RETURN,
	SITE_ID,
	PLANS_TO_SHOW,
	SUBSCRIPTION_ID,
	PRODUCT,
	FLOW,
	ACTIVATE_PLAN,
	REDIRECT_URL,
} = REDIRECT_PARAM_KEYS;
const {
	FLOW_UPGRADE,
	FLOW_EXTEND,
	RETURN_BUILDER,
	PLANS_TO_SHOW_NON_ECOMMERCE,
	PLANS_TO_SHOW_BUSINESS,
} = REDIRECT_PARAM_VALUES;
const { ASSIGN } = REDIRECT_TYPES;

const getRedirectUrlBase = ({
	path,
	params,
	domain,
}) => {
	const baseUrl = new URL(path, domain);

	baseUrl.search = new URLSearchParams(params).toString();

	return baseUrl.href;
};

export const getRedirectUrlToWWW = ({
	path = '',
	params = {},
}) => getRedirectUrlBase({
	path,
	params,
	domain: import.meta.env.VITE_HOMEPAGE_URL,
});

export const getRedirectUrlToBuilder = ({
	path = '',
	params = {},
}) => getRedirectUrlBase({
	path,
	params,
	domain: import.meta.env.VITE_BUILDER_URL,
});

export const getRedirectUrlToDashboard = ({
	path = SITES,
	params = {},
} = {}) => getRedirectUrlBase({
	path,
	params,
	domain: import.meta.env.VITE_DASHBOARD_URL,
});

export const getRedirectUrlToHostingerLogin = ({
	path = '',
	params = {},
} = {}) => getRedirectUrlBase({
	path,
	params: {
		redirectUrl: window.location.href,
		...params,
	},
	domain: import.meta.env.VITE_HOSTINGER_LOGIN_URL,
});

export const getRedirectUrlToHPanel = ({
	path = '',
	params = {},
} = {}) => getRedirectUrlBase({
	path,
	params: {
		redirectUrl: window.location.href,
		...params,
	},
	domain: import.meta.env.VITE_HPANEL_URL,
});

export const getRedirectUrlToHostinger = ({
	path = '',
	params = {},
	locale = '',
} = {}) => {
	const domain = HOSTINGER_LOCALE_DOMAINS[locale] ?? import.meta.env.VITE_HOSTINGER_URL;

	return getRedirectUrlBase({
		path,
		params,
		domain,
	});
};

export const getRedirectUrlToEcommerce = ({
	path = '',
	siteId = '',
	params = {},
} = {}) => {
	const pathname = [
		siteId,
		path,
	].filter((value) => value).join('/');

	const url = new URL(pathname, import.meta.env.VITE_ECOMMERCE_ADMIN_URL);
	const searchParams = {
		...params,
		[LANG]: getLocaleCodeFromCookie(),
	};

	Object.keys(searchParams).forEach((key) => url.searchParams.append(key, searchParams[key]));

	return url.href;
};

export const getRedirectLinkToUpgradePage = ({
	currentDomain,
	params = {},
} = {}) => getRedirectUrlToHPanel({
	path: `${HPANEL_REDIRECTS_PATHS.WEBSITES}/${currentDomain}/${HPANEL_REDIRECTS_PATHS.UPGRADE}`,
	params: {
		redirectLocation: 'list_view',
		isBuilderWebsite: 1,
		...params,
	},
});

export const useRedirects = () => {
	const {
		state,
		getters,
		dispatch,
	} = useStore() ?? {};
	const userStore = useUserStore();

	const isEcommerceFeatureLockPlacementEnabled = getIsExperimentActive(
		EXPERIMENT_IDS.ECOMMERCE_FEATURE_LOCK_PLACEMENT,
	);

	// Accepts a WWW page path, to which redirect should be made, and custom params object.
	// Function stringifies params, constructs the link to WWW with current locale param,
	// so that after redirect, user would be presented the same language he had set in builder.

	const saveChanges = async () => {
		if (!dispatch || !getters['saving/canSave']) {
			return;
		}

		await dispatch('saving/saveWebsite');
	};

	// Executes redirect to WWW with provided redirect type.
	const redirectToWWW = async ({
		path = HOME,
		params = {},
		redirectType = ASSIGN,
	} = {}) => {
		await saveChanges();

		const wwwUrl = getRedirectUrlToWWW({
			path,
			params,
		});

		window.location[redirectType](wwwUrl);
	};

	const redirectToDashboard = async ({
		path = SITES,
		params = {},
		redirectType = ASSIGN,
	} = {}) => {
		await saveChanges();

		const dashboardUrl = getRedirectUrlToDashboard({
			path,
			params,
		});

		window.location[redirectType](dashboardUrl);
	};

	const redirectToHPanel = async ({
		path,
		params = {},
	} = {}) => {
		await saveChanges();

		await authRedirectService.redirectToHpanel({
			path,
			params,
		});
	};

	const redirectToHPanelAddDomain = async ({
		currentDomain,
		siteId,
		params = {},
	} = {}) => {
		await saveChanges();

		authRedirectService.redirectToHpanel({
			path: `${HPANEL_REDIRECTS_PATHS.ADD_DOMAIN}/${currentDomain}`,
			params: {
				[REDIRECT_URL]: `${import.meta.env.VITE_BUILDER_URL}/${siteId}`,
				websiteType: 'builder',
				...params,
			},
		});
	};

	const redirectToHPanelDomainSettings = async ({
		currentDomain,
		siteId,
		params = {},
	} = {}) => {
		await saveChanges();

		authRedirectService.redirectToHpanel({
			path: `${HPANEL_REDIRECTS_PATHS.DOMAIN}/${currentDomain}/${HPANEL_REDIRECTS_PATHS.DOMAIN_OVERVIEW}`,
			params: {
				[REDIRECT_URL]: `${import.meta.env.VITE_BUILDER_URL}/${siteId}`,
				...params,
			},
		});
	};

	const redirectToHPanelDomainsPage = async () => {
		await saveChanges();

		authRedirectService.redirectToHpanel({
			path: `${HPANEL_REDIRECTS_PATHS.DOMAINS}`,
		});
	};

	const redirectToHPanelRegisterDomainResource = async ({
		currentDomain,
		siteId,
		params = {},
	} = {}) => {
		await saveChanges();

		authRedirectService.redirectToHpanel({
			path: `${HPANEL_REDIRECTS_PATHS.REGISTER_DOMAIN_RESOURCE}/${currentDomain}`,
			params: {
				[REDIRECT_URL]: `${import.meta.env.VITE_BUILDER_URL}/${siteId}`,
				...params,
			},
		});
	};

	const redirectToHPanelDomainDNSSettings = async ({
		currentDomain,
		siteId,
		params = {},
	} = {}) => {
		await saveChanges();

		authRedirectService.redirectToHpanel({
			path: `${HPANEL_REDIRECTS_PATHS.DOMAIN}/${currentDomain}/${HPANEL_REDIRECTS_PATHS.DOMAIN_DNS}`,
			params: {
				[REDIRECT_URL]: `${import.meta.env.VITE_BUILDER_URL}/${siteId}`,
				...params,
			},
		});
	};

	const redirectToHPanelUpgradePage = async ({
		currentDomain,
		params = {},
	} = {}) => {
		await saveChanges();

		authRedirectService.redirectToHpanel({
			path: `${HPANEL_REDIRECTS_PATHS.WEBSITES}/${currentDomain}/${HPANEL_REDIRECTS_PATHS.UPGRADE}`,
			params: {
				redirectLocation: 'list_view',
				isBuilderWebsite: 1,
				...params,
			},
		});
	};

	const redirectToHostinger = async ({
		path,
		params = {},
		redirectType = ASSIGN,
		openInNewTab = false,
		locale = HOSTINGER_US_LOCALE,
	} = {}) => {
		await saveChanges();

		const hostingerUrl = getRedirectUrlToHostinger({
			path,
			params,
			locale,
		});

		if (openInNewTab) {
			window.open(hostingerUrl);

			return;
		}

		window.location[redirectType](hostingerUrl);
	};

	const getPlansToShowInPricing = () => {
		const isStoreTypeZyro = getters['ecommerce/isStoreTypeZyro'];

		if (isStoreTypeZyro) {
			return PLANS_TO_SHOW_BUSINESS;
		}

		return PLANS_TO_SHOW_NON_ECOMMERCE;
	};

	const redirectToUpgrade = (payload = {}) => {
		redirectToDashboard({
			path: SINGLE_SITE_DASHBOARD_SUBSCRIPTION,
			params: {
				[SITE_ID]: state.websiteId,
				[FLOW]: FLOW_UPGRADE,
				...payload,
			},
		});
	};

	const redirectToExtend = (payload = {}) => {
		redirectToDashboard({
			path: SINGLE_SITE_DASHBOARD_SUBSCRIPTION,
			params: {
				[SITE_ID]: state.websiteId,
				[FLOW]: FLOW_EXTEND,
				...payload,
			},
		});
	};

	const redirectToWWWPayments = (payload = {}) => {
		const plansToShowInPricing = payload[PLANS_TO_SHOW] || getPlansToShowInPricing();

		const parameters = {
			[SITE_ID]: state.websiteId,
			[PLANS_TO_SHOW]: plansToShowInPricing,
			[ACTIVATE_PLAN]: true,
			...payload,
		};

		redirectToWWW({
			path: PAYMENTS,
			params: parameters,
		});
	};

	const redirectToWWWSignUp = ({ returnTo = RETURN_BUILDER } = {}) => {
		redirectToWWW({
			path: SIGN_UP,
			params: {
				[RETURN]: returnTo,
			},
		});
	};

	const redirectToWWWSignIn = () => {
		redirectToWWW({
			path: SIGN_IN,
			params: {
				[RETURN]: RETURN_BUILDER,
			},
		});
	};

	const redirectToSubscriptions = ({
		websiteId,
		subscriptionId,
	} = {}) => {
		const params = {
			[RETURN]: RETURN_BUILDER,
		};

		if (websiteId) {
			params[SITE_ID] = websiteId;
		}

		if (subscriptionId) {
			params[SUBSCRIPTION_ID] = subscriptionId;
		}

		return redirectToDashboard({
			path: SUBSCRIPTIONS,
			params,
		});
	};

	const redirectToEcommerce = async ({
		path = '',
		params = {},
		siteId = null,
		location = '',
	} = {}) => {
		if (userStore.areFeaturesLocked && !isEcommerceFeatureLockPlacementEnabled) {
			dispatch(`gui/${OPEN_MODAL}`, {
				name: MODAL_UPGRADE_TO_BUSINESS_PLAN,
				settings: {
					location,
				},
			});

			return;
		}

		await saveChanges();

		await authRedirectService.redirectToEcommerce({
			path,
			params,
			siteId: siteId || state?.websiteId,
		});
	};

	const redirectToEcommerceBookings = () => {
		redirectToEcommerce({
			path: BOOKINGS,
			location: ECOMMERCE_FUNNEL_LOCATIONS.BOOKINGS_REDIRECT,
		});
	};

	const redirectToEcommerceProducts = () => {
		redirectToEcommerce({
			path: PRODUCTS,
			location: ECOMMERCE_FUNNEL_LOCATIONS.PRODUCTS_REDIRECT,
		});
	};

	const redirectToEcommerceProductsAdd = () => {
		redirectToEcommerce({
			path: PRODUCTS_ADD,
			location: ECOMMERCE_FUNNEL_LOCATIONS.PRODUCTS_ADD_REDIRECT,
		});
	};

	const redirectToEcommerceProductsEdit = (productId) => {
		redirectToEcommerce({
			path: PRODUCTS_EDIT,
			params: {
				[PRODUCT]: productId,
			},
			location: ECOMMERCE_FUNNEL_LOCATIONS.PRODUCTS_EDIT_REDIRECT,
		});
	};

	const redirectToEcommercePayments = (siteId = null, params = {}) => {
		redirectToEcommerce({
			path: PAYMENT_MANAGEMENT,
			params,
			siteId,
			location: ECOMMERCE_FUNNEL_LOCATIONS.PAYMENT_MANAGEMENT_REDIRECT,
		});
	};

	const redirectToEcommerceShippingEdit = (params = {}) => {
		redirectToEcommerce({
			path: SHIPPING_EDIT,
			params,
			location: ECOMMERCE_FUNNEL_LOCATIONS.SHIPPING_EDIT_REDIRECT,
		});
	};

	const redirectToEcommerceCompanyInformation = () => {
		redirectToEcommerce({
			path: COMPANY_INFORMATION,
			location: ECOMMERCE_FUNNEL_LOCATIONS.COMPANY_INFORMATION_REDIRECT,
		});
	};

	return {
		redirectToWWW,
		redirectToUpgrade,
		redirectToExtend,
		redirectToDashboard,
		redirectToHPanel,
		redirectToHostinger,
		redirectToWWWPayments,
		redirectToWWWSignUp,
		redirectToWWWSignIn,
		redirectToSubscriptions,
		redirectToEcommerce,
		redirectToEcommerceBookings,
		redirectToEcommerceProducts,
		redirectToEcommerceProductsAdd,
		redirectToEcommerceProductsEdit,
		redirectToEcommercePayments,
		redirectToEcommerceCompanyInformation,
		redirectToEcommerceShippingEdit,
		redirectToHPanelAddDomain,
		redirectToHPanelDomainsPage,
		redirectToHPanelRegisterDomainResource,
		redirectToHPanelDomainSettings,
		redirectToHPanelDomainDNSSettings,
		redirectToHPanelUpgradePage,
		getRedirectLinkToUpgradePage,
	};
};
