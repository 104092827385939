<script setup lang="ts">
import HostingerButton from '@/components/global/HostingerButton.vue';
import ZyroModal from '@/components/global/ZyroModal.vue';
import {
	HostingerButtonTheme,
	HostingerButtonType,
} from '@/types';
import ZyroLoader from '@zyro-inc/site-modules/components/ZyroLoader.vue';
import { computed } from 'vue';

type PropType = {
	primaryButtonText?: string;
	secondaryButtonText?: string;
	primaryButtonMargin?: string;
	primaryButtonTheme?: HostingerButtonType;
	secondaryButtonColor?: HostingerButtonTheme;
	title?: string;
	maxWidth?: string;
	width?: string;
	isLoading?: boolean;
	isButtonDisabled?: boolean;
};

defineEmits<{
	close: []
	'click-primary': []
	'click-secondary': []
}>();

const props = withDefaults(defineProps<PropType>(), {
	primaryButtonText: '',
	secondaryButtonText: '',
	primaryButtonMargin: '0 16px 0 0',
	primaryButtonTheme: 'text',
	secondaryButtonColor: 'primary',
	title: '',
	maxWidth: '470px',
	width: 'auto',
	isLoading: false,
	isButtonDisabled: false,
});

const computedStyles = computed(() => ({
	'--primary-button-margin': props.primaryButtonMargin,
}));
</script>

<template>
	<ZyroModal
		:max-width="maxWidth"
		max-height="auto"
		height="auto"
		:width="width"
		class="choose-placement-modal"
		content-padding="0"
		:title="title"
		:style="computedStyles"
		@close-modal="$emit('close')"
	>
		<div
			class="choose-placement-modal__content"
			:class="{ 'choose-placement-modal__content--has-title': title }"
		>
			<slot />
		</div>
		<template #footer>
			<HostingerButton
				v-qa="'systemdialogmodal-primary-btn'"
				class="choose-placement-modal__primary-button"
				:button-type="primaryButtonTheme"
				@click="$emit('click-primary')"
			>
				{{ primaryButtonText }}
			</HostingerButton>
			<HostingerButton
				v-qa="'systemdialogmodal-seconday-btn'"
				:theme="secondaryButtonColor"
				:is-disabled="isButtonDisabled"
				@click="$emit('click-secondary')"
			>
				<ZyroLoader
					v-if="isLoading"
					size="26px"
					color="var(--color-light)"
					weight="3px"
				/>
				<template v-else>
					{{ secondaryButtonText }}
				</template>
			</HostingerButton>
		</template>
	</ZyroModal>
</template>

<style lang="scss" scoped>
.choose-placement-modal {
	&__content {
		padding: 24px;

		&--has-title {
			padding-top: 0;
		}
	}

	&__primary-button {
		margin: var(--primary-button-margin);
	}
}
</style>
