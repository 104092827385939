import { siteBlockSchema, siteBuilderCompletedStepsSchema, siteDataSchema, siteElementSchema, siteFontSchema, siteLanguageSchema, siteLanguagesSchema, siteMetaSchema, sitePageSchema, siteStylesSchema } from ".";
import { SiteBlock, SiteBuilderCompletedSteps, SiteData, SiteElement, SiteElements, SiteFont, SiteLanguage, SiteLanguages, SiteMeta, SitePage, SiteStyles } from "./schemaTypes"; 
import { z } from 'zod';

export const siteSchemaValidator = {
	validateSiteData: (data: SiteData) => {
		try {
			siteDataSchema.strict().parse(data);
		} catch (error) {
			throw new Error(error as string);
		}
	},
	validateSiteFonts: (data: SiteFont[]) => {
		try {
			data.forEach((font) => {
				siteFontSchema.strict().parse(font);
			});
		} catch (error) {
			throw new Error(error as string);
		}
	},
	validateSiteMeta: (data: SiteMeta) => {
		try {
			siteMetaSchema.strict().parse(data);
		} catch (error) {
			throw new Error(error as string);
		}
	},
	validateSiteStyles: (data: SiteStyles) => {
		try {
			siteStylesSchema.strict().parse(data);
		} catch (error) {
			throw new Error(error as string);
		}
	},
	validateSiteBuilderCompletedSteps: (data: SiteBuilderCompletedSteps) => {
		try {
			siteBuilderCompletedStepsSchema.strict().parse(data);
		} catch (error) {
			throw new Error(error as string);
		}
	},
	validateSiteLanguages: (data: SiteLanguages) => {
		try {
			const siteLanguagesSchema = z.record(siteLanguageSchema.strict());
			siteLanguagesSchema.parse(data);
		} catch (error) {
			throw new Error(error as string);
		}
	},
	validateSiteLanguage: (data: SiteLanguage) => {
		try {
			siteLanguageSchema.strict().parse(data);
		} catch (error) {
			throw new Error(error as string);
		}
	},
	validateSitePage: (data: SitePage) => {
		try {
			sitePageSchema.strict().parse(data);
		} catch (error) {
			throw new Error(error as string);
		}
	},
	validateSiteBlock: (data: SiteBlock) => {
		try {
			siteBlockSchema.strict().parse(data);
		} catch (error) {
			throw new Error(error as string);
		}
	},
	validateSiteElement: (data: SiteElement) => {
		try {
			siteElementSchema.strict().parse(data);
		} catch (error) {
			throw new Error(error as string);
		}
	},
	validateSiteElements: (data: SiteElements) => {
		try {
			const siteElementsSchema = z.record(siteElementSchema.strict());
			siteElementsSchema.parse(data);
		} catch (error) {
			throw new Error(error as string);
		}
	},
};
