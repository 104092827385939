<script setup lang="ts">
import ZyroFieldInput from '@/components/global/ZyroFieldInput.vue';
import CheckerList from '@/components/global/CheckerList.vue';

import {
	watch,
	computed,
	ref,
} from 'vue';
import { usePageSeoSettings } from '@/use/usePageSeoSettings';
import {
	getIsSeoTitleLengthValid,
	getIsFocusKeywordIncludedInString,
} from '@/utils/seoValidation';
import { useI18n } from 'vue-i18n';
import { SEO_MAX_STRING_LENGTH_TITLE } from '@zyro-inc/site-modules/constants';

const { t } = useI18n();

const {
	pageMetaTitle,
	pageMetaFocusKeyword,
	mergePageMeta,
} = usePageSeoSettings();

const titleInputValue = ref(pageMetaTitle.value ?? '');
const seoTitleLength = computed(() => titleInputValue.value.length);
const isSeoTitleEmpty = computed(() => !titleInputValue.value);
const isSeoTitleLengthValid = computed(() => getIsSeoTitleLengthValid({
	seoTitle: titleInputValue.value,
}));
const isFocusKeywordIncludedInSeoTitle = computed(() => getIsFocusKeywordIncludedInString({
	stringToCheck: titleInputValue.value,
	focusKeyword: pageMetaFocusKeyword.value,
}));

const seoTitleCheckerList = computed(() => ([
	{
		id: 'seoTitleNotEmpty',
		isCompleted: !isSeoTitleEmpty.value,
		text: {
			keyPath: 'builder.seoDrawer.seoChecker.seoCheckerTitleNotEmpty',
		},
	},
	{
		id: 'seoTitleLength',
		isCompleted: isSeoTitleLengthValid.value,
		text: {
			keyPath: 'builder.seoDrawer.seoChecker.seoCheckerTitleLength',
			value: seoTitleLength.value,
		},
	},
	{
		id: 'seoTitleFocusKeyword',
		isCompleted: isFocusKeywordIncludedInSeoTitle.value,
		text: {
			keyPath: 'builder.seoDrawer.seoChecker.seoCheckerTitleFocusKeyword',
			value: pageMetaFocusKeyword.value ? `"${pageMetaFocusKeyword.value}"` : '',
		},
	},
]));

watch(pageMetaTitle, () => {
	titleInputValue.value = pageMetaTitle.value ?? '';
});
</script>

<template>
	<ZyroFieldInput
		id="page-title"
		:model-value="titleInputValue"
		:label="t('builder.pageSettingsModal.seoTitle')"
		:maxlength="SEO_MAX_STRING_LENGTH_TITLE"
		has-max-length-error
		@update:model-value="titleInputValue = $event"
		@input-blur="mergePageMeta({ title: titleInputValue })"
	/>

	<CheckerList :checker-items="seoTitleCheckerList" />
</template>
