<template>
	<div>
		<HostingerButton
			v-for="(buttonSet, buttonSetId) in BUTTON_SET_LIST"
			:key="buttonSetId"
			class="style-container"
			:class="{ 'style-container--selected': buttonSetId === currentButtonSetId }"
			button-type="outlined"
			@click="setButtonSet(buttonSetId)"
		>
			<div class="button-style__container">
				<GridButton
					v-for="(buttonStyle, buttonType) in buttonSet"
					:key="buttonType"
					:content="buttonNames[buttonType]"
					:type="buttonType"
					:font-color="buttonStyle['font-color']"
					:background-color="buttonStyle['background-color']"
					:background-color-hover="buttonStyle['background-color-hover']"
					:border-radius="buttonStyle[PROPERTY_BORDER_RADIUS]"
					class="button-style__button"
					@click.prevent
				/>
			</div>
		</HostingerButton>
	</div>
	<NpsRateFeature
		:feature-name="$t('builder.npsRateGlobalStyles')"
		:type="NPS_TYPE_FEATURE_GLOBAL_STYLES_BUTTONS"
	/>
</template>

<script setup>
import HostingerButton from '@/components/global/HostingerButton.vue';
import { NPS_TYPE_FEATURE_GLOBAL_STYLES_BUTTONS } from '@/constants';

import GridButton from '@zyro-inc/site-modules/components/elements/button/GridButton.vue';
import { PROPERTY_BORDER_RADIUS } from '@zyro-inc/site-modules/constants/globalStyles';

import EventLogApi from '@/api/EventLogApi';
import { useButtonSetsLibrary } from '@/components/builder-drawers/drawers/partials/stylesDrawer/buttons/use/useButtonSetsLibrary';
import { BUTTON_SET_LIST } from '@/data';

import { useI18n } from 'vue-i18n';
import NpsRateFeature from '@/components/ui/NpsRateFeature.vue';

const { t } = useI18n();
const {
	setButtonSet,
	currentButtonSetId,
} = useButtonSetsLibrary();

const buttonNames = {
	primary: t('common.primary'),
	secondary: t('common.secondary'),
};

EventLogApi.logEvent({
	eventName: 'website_builder.global_styles_buttons.enter',
});
</script>

<style lang="scss" scoped>
@import "@/components/builder-drawers/drawers/partials/stylesDrawer/UserStyles";

.style-container {
	padding: 24px 0;
	margin-bottom: 16px;
	background-color: $color-gray-light;

	&--selected {
		border: 1px solid $color-azure;
	}
}

.button-style {
	&__container {
		display: flex;
		justify-content: space-evenly;
		width: 100%;
	}

	&__button {
		padding: 14px 28px;

		&:not(:last-child) {
			margin-right: 16px;
		}

		&:hover {
			color: $color-azure;
		}
	}
}
</style>
