<template>
	<div>
		<EditTextButton
			ref="editLinkButton"
			qa-selector="edittext-textlink-button"
			:is-active="editor.isActive(TEXT_EDITOR_PROPERTY_LINK_MARK) || isLinkSettingsOpen"
			:tooltip-text="$t('common.link')"
			@click.stop="getLink(); isLinkSettingsOpen = !isLinkSettingsOpen;"
		>
			<ZyroSvgDeprecated name="link" />
		</EditTextButton>
		<Popup
			v-if="isLinkSettingsOpen"
			:target-ref="$refs.editLinkButton && $refs.editLinkButton.$el"
			auto-update
			placement="bottom-start"
			:offset="4"
			:portal-selector="SELECTOR_DATA_PORTAL_BUILDER_PREVIEW"
			is-mobile-fixed-to-bottom
			@click-outside="clickOutsideHandler"
		>
			<ZyroPopupCard
				:title="$t('common.linkSettings')"
				:show-footer="true"
				max-width="360px"
				type="editor"
				@save="saveLink"
				@close="isLinkSettingsOpen = false"
			>
				<LinkSettings
					ref="linksettings"
					:target="target"
					:href="href"
					:rel="rel"
					:type="type"
					:page-id="pageId"
					@update:href="href = $event"
					@update:rel="rel = $event"
					@update:target="target = $event"
					@update:type="type = $event"
					@update:pageId="pageId = $event"
				/>
				<HostingerButton
					v-if="editor.isActive(TEXT_EDITOR_PROPERTY_LINK_MARK)"
					v-qa="`edittext-textlink-remove-button`"
					button-type="outlined"
					@click.stop="removeLink(); isLinkSettingsOpen = false"
				>
					{{ $t('common.removeLink') }}
				</HostingerButton>
			</ZyroPopupCard>
		</Popup>
	</div>
</template>

<script setup>
import Popup from '@/components/global/Popup.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import ZyroPopupCard from '@/components/global/ZyroPopupCard.vue';
import ZyroSvgDeprecated from '@/components/global/ZyroSvgDeprecated.vue';

import { useStore } from 'vuex';
import {
	ref,
	computed,
} from 'vue';
import EditTextButton from '@/components/builder-controls/edit-text/EditTextButton.vue';

import { getPagePathFromId } from '@zyro-inc/site-modules/utils/page/getPagePathFromId';
import { LINK_TYPE } from '@zyro-inc/site-modules/constants';

import LinkSettings from '@/components/builder-controls/edit-button/LinkSettings.vue';
import {
	TEXT_EDITOR_PROPERTY_LINK_MARK,
	TEXT_EDITOR_DATA_ATTRIBUTE_LINKED_PAGE_ID,
	SELECTOR_DATA_PORTAL_BUILDER_PREVIEW,
} from '@/constants';
import { useTextEditor } from '@/use/text-editor/useTextEditor';
import { useSiteStore } from '@/stores/siteStore';

const siteStore = useSiteStore();

const { state } = useStore();
const { editor } = useTextEditor();
const isLinkSettingsOpen = ref(false);
const href = ref(null);
const target = ref(null);
const rel = ref(null);
const type = ref(LINK_TYPE.PAGE);
const pageId = ref(null);
const website = computed(() => siteStore.site);
const currentLocale = computed(() => state.currentLocale);

const saveLink = () => {
	if (editor.value.view.state.selection.empty) {
		editor.value.commands.selectParentNode();
	}

	const linkHref = type.value === LINK_TYPE.PAGE
		? getPagePathFromId({
			siteData: website.value,
			pageId: pageId.value,
			locale: currentLocale.value,
		})
		: href.value;

	editor.value.chain()
		.focus()
		.extendMarkRange('link')
		.setLink({
			href: linkHref,
			target: target.value ? target.value : '',
			rel: rel.value,
			download: type.value === LINK_TYPE.DOWNLOAD || null,
			style: 'text-decoration: none;',
			[TEXT_EDITOR_DATA_ATTRIBUTE_LINKED_PAGE_ID]: pageId.value,
		})
		.setUnderline()
		.run();

	type.value = LINK_TYPE.EXTERNAL;
	isLinkSettingsOpen.value = false;
};

const getLink = () => {
	const linkAttributes = editor.value.getAttributes(TEXT_EDITOR_PROPERTY_LINK_MARK);

	target.value = linkAttributes.target;
	href.value = linkAttributes.href;
	rel.value = linkAttributes.rel;
	pageId.value = linkAttributes['data-page-id'];

	if (!href.value) {
		type.value = LINK_TYPE.PAGE;

		return;
	}

	if (href.value.includes('#')) {
		type.value = LINK_TYPE.ANCHORED_SECTION;

		return;
	}

	if (href.value.includes('tel:')) {
		type.value = LINK_TYPE.PHONE;

		return;
	}

	if (href.value.includes('mailto:')) {
		type.value = LINK_TYPE.EMAIL;

		return;
	}

	if (pageId.value) {
		type.value = LINK_TYPE.PAGE;

		return;
	}

	if (linkAttributes.download) {
		type.value = LINK_TYPE.DOWNLOAD;

		return;
	}

	type.value = LINK_TYPE.EXTERNAL;
};

const removeLink = () => {
	editor.value.chain()
		.focus()
		.extendMarkRange('link')
		.unsetLink()
		.unsetUnderline()
		.run();
};

const clickOutsideHandler = (event) => {
	if (!event.target?.closest('[role="dialog"]')) {
		isLinkSettingsOpen.value = false;
	}
};
</script>
