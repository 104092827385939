import { computed } from 'vue';
import {
	NEXT_BEST_ACTION,
	type NextBestAction,
} from '@hostinger/hdomains-status';
import EventLogApi from '@/api/EventLogApi';
import { useI18n } from 'vue-i18n';
import { useDomainStore } from '@/stores/domainStore';
import { useSiteStore } from '@/stores/siteStore';
import { useRedirects } from '@/use/useRedirects';
import {
	STATUS_THEMES,
	HOSTINGER_BUTTON_TYPES,
	HPANEL_REDIRECT_ACTIONS,
	HPANEL_REDIRECT_SECTIONS,
	HPANEL_REDIRECTS_PATHS,
} from '@/types';
import type {
	StatusThemes,
	HostingerButtonType,
} from '@/types';

export const DOMAIN_PROPAGATING_STATUS = 'domain-is-propagating';

type DomainStatusKey = NextBestAction | typeof DOMAIN_PROPAGATING_STATUS

interface DomainStatus {
	id: DomainStatusKey,
    statusText: string;
    statusTheme?: StatusThemes;
	actionButtonText: string;
	actionButtonType: HostingerButtonType;
	isPublishDomainStatusVisible: boolean;
	action: () => void;
}

export const useDomainStatus = () => {
	const domainStore = useDomainStore();
	const siteStore = useSiteStore();
	const {
		redirectToHPanel,
		redirectToHPanelAddDomain,
		redirectToHPanelDomainSettings,
		redirectToHPanelDomainsPage,
		redirectToHPanelRegisterDomainResource,
	} = useRedirects();

	const { t } = useI18n();

	const domainStatusMap: Record<DomainStatusKey, DomainStatus | null> = {
		[NEXT_BEST_ACTION.MANAGE]: {
			id: NEXT_BEST_ACTION.MANAGE,
			statusText: t('siteSettings.domain.domainConnected'),
			statusTheme: STATUS_THEMES.SUCCESS,
			actionButtonText: t('common.manageDomain'),
			actionButtonType: HOSTINGER_BUTTON_TYPES.OUTLINED,
			isPublishDomainStatusVisible: false,
			action: () => {
				if (domainStore.domainStatus?.isDomainExternal) {
					redirectToHPanelDomainsPage();

					return;
				}

				redirectToHPanelDomainSettings({
					currentDomain: domainStore.customDomain,
					siteId: siteStore.websiteId,
				});
			},
		},
		[DOMAIN_PROPAGATING_STATUS]: {
			id: DOMAIN_PROPAGATING_STATUS,
			statusText: t('siteSettings.domain.verifyingConnectionV2'),
			statusTheme: STATUS_THEMES.WARNING,
			actionButtonText: t('siteSettings.domain.refreshStatus'),
			actionButtonType: HOSTINGER_BUTTON_TYPES.OUTLINED,
			isPublishDomainStatusVisible: true,
			action: () => {
				domainStore.fetchDomainStatus();
			},
		},
		[NEXT_BEST_ACTION.FINISH_REGISTRATION]: {
			id: NEXT_BEST_ACTION.FINISH_REGISTRATION,
			statusText: t('siteSettings.domain.domainRegistrationPending'),
			statusTheme: STATUS_THEMES.WARNING,
			actionButtonText: t('siteSettings.domain.finishRegistration'),
			actionButtonType: HOSTINGER_BUTTON_TYPES.CONTAINED,
			isPublishDomainStatusVisible: true,
			action: () => redirectToHPanelRegisterDomainResource({
				currentDomain: domainStore.customDomain,
				siteId: siteStore.websiteId,
			}),
		},
		[NEXT_BEST_ACTION.CONNECT_DOMAIN_TEMPORARY]: {
			id: NEXT_BEST_ACTION.CONNECT_DOMAIN_TEMPORARY,
			statusText: t('common.connectDomain'),
			actionButtonText: t('common.connectDomain'),
			actionButtonType: HOSTINGER_BUTTON_TYPES.CONTAINED,
			isPublishDomainStatusVisible: false,
			action: () => {
				EventLogApi.logEvent({
					eventName: 'website_builder.connect_domain_manual.enter',
					eventProperties: {
						location: 'from_preview',
					},
				});

				redirectToHPanelAddDomain({
					currentDomain: domainStore.customDomain,
					siteId: siteStore.websiteId,
				});
			},
		},
		[NEXT_BEST_ACTION.CONNECT_DOMAIN]: {
			id: NEXT_BEST_ACTION.CONNECT_DOMAIN,
			statusText: t('siteSettings.domain.domainNotConnected'),
			statusTheme: STATUS_THEMES.DANGER,
			actionButtonText: t('common.connect'),
			actionButtonType: HOSTINGER_BUTTON_TYPES.CONTAINED,
			isPublishDomainStatusVisible: true,
			action: () => {
				redirectToHPanel({
					path: HPANEL_REDIRECTS_PATHS.WEBSITES,
					params: {
						section: HPANEL_REDIRECT_SECTIONS.WEBSITES,
						domain: domainStore.customDomain,
						action: HPANEL_REDIRECT_ACTIONS.CONNECT_DOMAIN,
					},
				});
			},
		},
		[NEXT_BEST_ACTION.CONNECT_DOMAIN_EXTERNAL]: {
			id: NEXT_BEST_ACTION.CONNECT_DOMAIN_EXTERNAL,
			statusText: t('siteSettings.domain.domainNotConnected'),
			statusTheme: STATUS_THEMES.DANGER,
			actionButtonText: t('common.connect'),
			actionButtonType: HOSTINGER_BUTTON_TYPES.CONTAINED,
			isPublishDomainStatusVisible: true,
			action: () => {
				redirectToHPanel({
					path: HPANEL_REDIRECTS_PATHS.WEBSITES,
					params: {
						section: HPANEL_REDIRECT_SECTIONS.WEBSITES,
						domain: domainStore.customDomain,
						action: HPANEL_REDIRECT_ACTIONS.CONNECT_DOMAIN,
					},
				});
			},
		},
		[NEXT_BEST_ACTION.TRANSFER_DOMAIN]: {
			id: NEXT_BEST_ACTION.TRANSFER_DOMAIN,
			statusText: t('siteSettings.domain.domainConnected'),
			statusTheme: STATUS_THEMES.SUCCESS,
			actionButtonText: t('common.manageDomain'),
			actionButtonType: HOSTINGER_BUTTON_TYPES.OUTLINED,
			isPublishDomainStatusVisible: false,
			action: () => {
				redirectToHPanelDomainSettings({
					currentDomain: domainStore.customDomain,
					siteId: siteStore.websiteId,
				});
			},
		},
		[NEXT_BEST_ACTION.RENEW_DOMAIN]: {
			id: NEXT_BEST_ACTION.RENEW_DOMAIN,
			statusText: t('siteSettings.domain.domainHasExpired'),
			statusTheme: STATUS_THEMES.DANGER,
			actionButtonText: t('common.renew'),
			actionButtonType: HOSTINGER_BUTTON_TYPES.CONTAINED,
			isPublishDomainStatusVisible: true,
			action: () => {
				redirectToHPanel({
					path: HPANEL_REDIRECTS_PATHS.WEBSITES,
					params: {
						section: HPANEL_REDIRECT_SECTIONS.WEBSITES,
						domain: domainStore.customDomain,
						action: HPANEL_REDIRECT_ACTIONS.RENEW_DOMAIN,
					},
				});
			},
		},
		[NEXT_BEST_ACTION.RESTORE_DOMAIN]: {
			id: NEXT_BEST_ACTION.RESTORE_DOMAIN,
			statusText: t('siteSettings.domain.domainHasExpired'),
			statusTheme: STATUS_THEMES.DANGER,
			actionButtonText: t('common.restore'),
			actionButtonType: HOSTINGER_BUTTON_TYPES.CONTAINED,
			isPublishDomainStatusVisible: true,
			action: () => {
				redirectToHPanel({
					path: HPANEL_REDIRECTS_PATHS.WEBSITES,
					params: {
						section: HPANEL_REDIRECT_SECTIONS.WEBSITES,
						domain: domainStore.customDomain,
						action: HPANEL_REDIRECT_ACTIONS.RESTORE_DOMAIN,
					},
				});
			},
		},
		[NEXT_BEST_ACTION.BUY_DOMAIN]: {
			id: NEXT_BEST_ACTION.BUY_DOMAIN,
			statusText: t('siteSettings.domain.domainIsAvailable'),
			statusTheme: STATUS_THEMES.INACTIVE,
			actionButtonText: t('common.buyDomain'),
			actionButtonType: HOSTINGER_BUTTON_TYPES.CONTAINED,
			isPublishDomainStatusVisible: true,
			action: () => {
				redirectToHPanel({
					path: HPANEL_REDIRECTS_PATHS.WEBSITES,
					params: {
						section: HPANEL_REDIRECT_SECTIONS.WEBSITES,
						domain: domainStore.customDomain,
						action: HPANEL_REDIRECT_ACTIONS.BUY_DOMAIN,
					},
				});
			},
		},
		[NEXT_BEST_ACTION.MOVE_DOMAIN]: null,
	};

	const domainStatusConfig = computed<DomainStatus | null>(() => {
		if (!domainStore.domainNextBestAction) return null;

		const statusKey = domainStore.isDomainPropagating ? DOMAIN_PROPAGATING_STATUS : domainStore.domainNextBestAction;

		return domainStatusMap[statusKey];
	});

	return {
		domainStatusConfig,
	};
};
