export const PINIA_STORES = {
	AI_WRITER: 'aiWriter',
	AI_WRITER_DRAWER: 'aiWriterDrawer',
	AI_TOOLS: 'aiTools',
	SITE: 'site',
	SEO: 'seo',
	USER: 'user',
	DOMAIN: 'domain',
	ECOMMERCE_ADMIN: 'ecommerceAdmin',
	ECOMMERCE: 'ecommerce',
	CLIPBOARD: 'clipboard',
} as const;
