<template>
	<button
		v-qa="'color-picker-btn'"
		class="color-picker-button"
		:style="computedStyles"
		:class="computedClass"
	/>
</template>

<script setup lang="ts">
import { SiteBackgroundGradient } from '@hostinger/builder-schema-validator';
import { computed } from 'vue';
import { getGradientCss } from '@zyro-inc/site-modules/utils/cssUtils';
import { DEFAULT_COLOR } from '@/constants';

interface Props {
	isGradient?: boolean;
	color?: string;
	gradient?: SiteBackgroundGradient;
	size?: 'sd' | 'md';
}

const props = withDefaults(defineProps<Props>(), {
	color: DEFAULT_COLOR,
	size: 'md',
});

const computedClass = computed(() => ({
	[`color-picker-button--${props.size}`]: props.size,
}));

const computedStyles = computed(() => {
	if (props.isGradient && props.gradient) {
		return {
			background: getGradientCss(props.gradient),
		};
	}

	return {
		backgroundColor: props.color,
	};
});
</script>

<style lang="scss" scoped>
.color-picker-button {
	display: block;
	cursor: pointer;
	border: 1px solid $color-gray-border;
	border-radius: 50%;

	&--sm {
		width: 16px;
		height: 16px;
	}

	&--md {
		width: 24px;
		height: 24px;
	}
}
</style>
