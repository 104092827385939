/* eslint-disable import/no-cycle */
// eslint-disable-next-line import/no-cycle
import { refreshAuthToken } from '@/api/AuthApi';
import { getRedirectUrlToHostingerLogin } from '@/use/useRedirects';
import { ignoreSentryErrorsForTimeout } from '@/services/errorLogger';
import { defineStore } from 'pinia';
import { ref } from 'vue';

import {
	getAuthToken,
	getBearerToken,
	setAuthToken,
} from '@/utils/auth';

export const useAuthStore = defineStore('auth', () => {
	const isAuthRedirecting = ref(false);
	const isTokenRefreshing = ref(false);
	const pendingRequests = ref<Array<() => void>>([]);

	const setIsAuthRedirecting = (value: boolean) => {
		isAuthRedirecting.value = value;
	};

	const forceLogout = () => {
		ignoreSentryErrorsForTimeout(10 * 1000);
		const logoutRedirect = getRedirectUrlToHostingerLogin();

		window.location.assign(logoutRedirect);
	};

	const addToPendingRequestsAndWaitForRefresh = () => new Promise<void>((resolve) => {
		pendingRequests.value.push(resolve);
	});

	const releasePendingRequests = () => {
		pendingRequests.value.forEach((resolve) => resolve());
		pendingRequests.value = [];
	};

	const checkIfTokenIsRefreshing = async () => {
		if (!isTokenRefreshing.value) {
			return;
		}

		await addToPendingRequestsAndWaitForRefresh();
	};

	const refreshToken = async (tokenFromRequest: string) => {
		const currentToken = getBearerToken();
		const isTokenAlreadyRefreshed = tokenFromRequest && currentToken !== tokenFromRequest;

		if (isTokenRefreshing.value || isTokenAlreadyRefreshed) {
			return;
		}

		isTokenRefreshing.value = true;

		try {
			const { data } = await refreshAuthToken();

			if (!data.token) {
				forceLogout();

				return;
			}

			setAuthToken(data.token);
			releasePendingRequests();
		} catch (error) {
			forceLogout();
		}

		isTokenRefreshing.value = false;
	};

	return {
		getAuthToken,
		getBearerToken,
		setAuthToken,
		checkIfTokenIsRefreshing,
		refreshToken,
		forceLogout,
		isAuthRedirecting,
		setIsAuthRedirecting,
	};
});
