<template>
	<div
		class="domain-preview"
		:class="`domain-preview--${domainStatusConfig && domainStatusConfig.statusTheme}`"
	>
		<div
			v-if="domainStatusConfig && domainStatusConfig.isPublishDomainStatusVisible"
			class="domain-status"
		>
			<div class="domain-status__header">
				<Icon
					name="error"
					class="domain-status__icon"
				/>
				<div class="domain-status__title text-body-2">
					{{ domainStatusConfig.statusText }}
				</div>
				<div
					v-if="domainStatusConfig.id !== DOMAIN_PROPAGATING_STATUS"
					class="domain-status__action"
				>
					<span class="domain-status__separator text-body-2">—</span>
					<HostingerButton
						class="domain-preview__button"
						:button-type="HOSTINGER_BUTTON_TYPES.PLAIN"
						@click="domainStatusConfig.action"
					>
						{{ domainStatusConfig.actionButtonText }}
					</HostingerButton>
				</div>
			</div>
			<div
				v-if="domainStatusConfig.id === DOMAIN_PROPAGATING_STATUS"
				class="domain-status__message text-body-2"
			>
				{{ $t('builder.domainPropagationMessage') }}
			</div>
		</div>
		<div
			class="domain-input"
			@click="$emit('open-site')"
		>
			<p
				id="domain"
				v-qa="'builder-publishmodal-inputfield-freedomain'"
				class="text-body-2 no-select domain-preview__text"
				data-hj-whitelist
			>
				{{ domain }}
			</p>
			<HostingerButton
				v-qa="'builder-publishmodal-btn-viewyoursite'"
				:button-type="HOSTINGER_BUTTON_TYPES.PLAIN"
				class="domain-preview__button"
				@click.stop="$emit('open-site')"
			>
				{{ $t('common.viewWebsite') }}
			</HostingerButton>
		</div>
	</div>
</template>

<script setup lang="ts">
import Icon from '@/components/global/Icon.vue';
import { HOSTINGER_BUTTON_TYPES } from '@/types';
import HostingerButton from '@/components/global/HostingerButton.vue';
import {
	useDomainStatus,
	DOMAIN_PROPAGATING_STATUS,
} from '@/use/useDomainStatus';

defineEmits(['open-site']);

type Props = {
	domain: string;
}

defineProps<Props>();

const { domainStatusConfig } = useDomainStatus();

</script>

<style lang="scss" scoped>
.domain-status {
	margin-bottom: 16px;

	&__header,
	&__action {
		justify-content: center;
		display: flex;
		align-items: center;
	}

	&__icon {
		margin-right: 8px;
		color: $color-gray;
	}

	&__separator {
		margin: 0 4px;
	}
}

.domain-preview {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 16px 8px 8px;
	background: $color-gray-light;
	border-radius: 8px;
	transition: all 0.2s;

	&--danger {
		background-color: $color-danger-light;

		.domain-status {
			&__icon {
				color: $color-danger;
			}
		}
	}

	&--warning {
		background-color: $color-warning-light;

		.domain-status {
			&__icon {
				color: $color-warning-dark;
			}
		}
	}

	&__button {
		color: $color-primary;
		padding: 0;
		min-height: auto;

		&:hover {
			background-color:transparent;
		}
	}

	&__text {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		display: block;
		margin-right: 8px;
	}

	&__message {
		margin-top: 4px;
	}
}

.domain-input {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 48px;
	padding: 8px 16px;
	margin-right: auto;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	cursor: pointer;
	background: $color-light;
	border: 1px solid $color-gray-border;
	border-radius: 4px;
	transition: all 0.3s ease;

	&__label {
		font-size: 14px;
		font-style: normal;
		font-weight: normal;
		font-stretch: normal;
		line-height: 1.43;
		color: $color-gray;
		cursor: copy;
	}
}

.no-select {
	user-select: none;
}
</style>
