<template>
	<div class="edit-block-navigation-tab-layout">
		<ZyroFieldToggle
			id="isStickyToggle"
			v-qa="`editblockheader-tablayout-issticky-${isSticky}`"
			:model-value="isSticky"
			:label="$t('builder.editBlockNavigation.tabLayout.stickyHeader')"
			@update:model-value="updateIsSticky"
		/>

		<ZyroFieldToggle
			v-if="isMobileView"
			id="isFullScreen"
			v-qa="`editblockheader-tablayout-isfullscreen-${isMobileMenuDropdownFullScreen}`"
			class="edit-block-navigation-tab-layout__full-screen-toggle"
			:model-value="isMobileMenuDropdownFullScreen"
			:label="$t('builder.editBlockNavigationTabLayoutFullScreenToggle')"
			@update:model-value="updateIsMobileMenuDropdownFullScreen"
		/>

		<ZyroSeparator />

		<ZyroFieldRange
			:model-value="elementSpacing"
			:qa="`editblockheader-tablayout-elementSpacing-${elementSpacing}`"
			min="8"
			max="64"
			:label="$t('builder.editBlockNavigation.tabLayout.elementSpacing')"
			@update:model-value="updateElementSpacing"
		/>

		<ZyroFieldRange
			:model-value="linkSpacing"
			:qa="`editblockheader-tablayout-linkspacing-${linkSpacing}`"
			min="8"
			max="64"
			:label="$t('builder.editBlockNavigation.tabLayout.linkSpacing')"
			@update:model-value="updateLinkSpacing"
		/>

		<ZyroCssShorthandControlRange
			:model-value="padding"
			:qa="`editblocknavigation-tablayout-padding-${padding.replace(/ /g, '-')}`"
			:vertical-title="$t('builder.topAndBottomSpacing')"
			class="edit-block-navigation-tab-layout__padding"
			@update:model-value="updatePadding"
		/>

		<ZyroSeparator />

		<div>
			<Icon
				v-for="{ icon, layout } in isMobileView ? MOBILE_LAYOUTS : DESKTOP_LAYOUTS"
				:key="icon"
				class="edit-block-navigation-tab-layout__layout-icon"
				:class="{ 'edit-block-navigation-tab-layout__layout-icon--active': headerLayout === layout }"
				:name="icon"
				view-box="0 0 328 56"
				is-custom
				@click="updateHeaderLayout(layout)"
			/>
		</div>

		<div v-if="isMobileView">
			<ZyroSeparator />

			<ZyroLabel class="edit-block-navigation-tab-layout__alignment-label">
				{{ $t('builder.editBlockNavigationTabLayoutMobileLinksAlignment') }}
			</ZyroLabel>
			<ZyroIconControls
				:model-value="mobileLinksAlignment"
				:icons="ICON_CONTROLS_LINKS_ALIGNMENT"
				:toggleable="false"
				@update:model-value="updateMobileLinksAlignment"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import ZyroCssShorthandControlRange from '@/components/global/ZyroCssShorthandControlRange.vue';
import ZyroFieldRange from '@/components/global/ZyroFieldRange.vue';
import ZyroFieldToggle from '@/components/global/ZyroFieldToggle.vue';
import Icon from '@/components/global/Icon.vue';
import ZyroIconControls from '@/components/global/ZyroIconControls.vue';
import ZyroLabel from '@/components/global/ZyroLabel.vue';
import ZyroSeparator from '@/components/global/ZyroSeparator.vue';
import {
	BLOCK_HEADER_LAYOUTS,
	BLOCK_HEADER_KEY_DESKTOP_LAYOUT,
	BLOCK_HEADER_KEY_MOBILE_LAYOUT,
} from '@zyro-inc/site-modules/constants';

import { useStore } from 'vuex';

import { useBuilderStyles } from '@/components/builder-controls/useBuilderStyles';

import { computed } from 'vue';

const ICON_CONTROLS_LINKS_ALIGNMENT = [
	{
		icon: 'align',
		value: 'left',
		direction: 'left',
	},
	{
		icon: 'align-middle-h',
		value: 'center',
	},
	{
		icon: 'align',
		value: 'right',
		direction: 'right',
	},
];

const MOBILE_LAYOUTS = [
	{
		icon: 'header-layout-mobile-1',
		layout: BLOCK_HEADER_LAYOUTS.MOBILE_1,
	},
	{
		icon: 'header-layout-mobile-2',
		layout: BLOCK_HEADER_LAYOUTS.MOBILE_2,
	},
	{
		icon: 'header-layout-mobile-3',
		layout: BLOCK_HEADER_LAYOUTS.MOBILE_3,
	},
];

const DESKTOP_LAYOUTS = [
	{
		icon: 'header-layout-desktop-1',
		layout: BLOCK_HEADER_LAYOUTS.DESKTOP_1,
	},
	{
		icon: 'header-layout-desktop-2',
		layout: BLOCK_HEADER_LAYOUTS.DESKTOP_2,
	},
	{
		icon: 'header-layout-desktop-3',
		layout: BLOCK_HEADER_LAYOUTS.DESKTOP_3,
	},
	{
		icon: 'header-layout-desktop-4',
		layout: BLOCK_HEADER_LAYOUTS.DESKTOP_4,
	},
	{
		icon: 'header-layout-desktop-5',
		layout: BLOCK_HEADER_LAYOUTS.DESKTOP_5,
	},
];

const {
	getStyleValue,
	getStyleKey,
} = useBuilderStyles();
const {
	state,
	getters,
	dispatch,
} = useStore();
const currentBlockId = computed(() => state.currentBlockId);
const isMobileView = computed(() => state.gui.isMobileView);
const currentBlockStyles = computed(() => getters.currentBlockStyles);
const currentBlockSettings = computed(() => getters.currentBlockSettings);

const isSticky = computed(() => currentBlockSettings.value.isSticky);
const isMobileMenuDropdownFullScreen = computed(() => currentBlockSettings.value.isMobileMenuDropdownFullScreen);
const elementSpacing = computed(() => Number.parseInt(getStyleValue('element-spacing', currentBlockStyles.value), 10));
const linkSpacing = computed(() => Number.parseInt(getStyleValue('link-spacing', currentBlockStyles.value), 10));
const headerLayout = computed(() => currentBlockSettings.value[
	isMobileView.value ? BLOCK_HEADER_KEY_MOBILE_LAYOUT : BLOCK_HEADER_KEY_DESKTOP_LAYOUT
]);
const padding = computed(() => getStyleValue('padding', currentBlockStyles.value));
const mobileLinksAlignment = computed(() => currentBlockSettings.value.mobileLinksAlignment);

const updateIsSticky = (newValue: boolean) => {
	dispatch('updateBlockData', {
		blockId: currentBlockId.value,
		blockData: {
			settings: {
				isSticky: newValue,
			},
		},
		merge: true,
	});
};

const updateIsMobileMenuDropdownFullScreen = (newValue: boolean) => {
	dispatch('updateBlockData', {
		blockId: currentBlockId.value,
		blockData: {
			settings: {
				isMobileMenuDropdownFullScreen: newValue,
			},
		},
		merge: true,
	});
};

const updateMobileLinksAlignment = (newValue: boolean) => {
	dispatch('updateBlockData', {
		blockId: currentBlockId.value,
		blockData: {
			settings: {
				mobileLinksAlignment: newValue,
			},
		},
		merge: true,
	});
};

const updateElementSpacing = (newValue: string) => {
	const key = getStyleKey('element-spacing');

	dispatch('updateBlockData', {
		blockId: currentBlockId.value,
		blockData: {
			settings: {
				styles: {
					[key]: `${newValue}px`,
				},
			},
		},
		merge: true,
	});
};

const updateLinkSpacing = (newValue: string) => {
	const key = getStyleKey('link-spacing');

	dispatch('updateBlockData', {
		blockId: currentBlockId.value,
		blockData: {
			settings: {
				styles: {
					[key]: `${newValue}px`,
				},
			},
		},
		merge: true,
	});
};

const updateHeaderLayout = (newValue: string) => {
	const key = isMobileView.value ? BLOCK_HEADER_KEY_MOBILE_LAYOUT : BLOCK_HEADER_KEY_DESKTOP_LAYOUT;

	dispatch('updateBlockData', {
		blockId: currentBlockId.value,
		blockData: {
			settings: {
				[key]: newValue,
			},
		},
		merge: true,
	});
};

const updatePadding = (newValue: string) => {
	const key = getStyleKey('padding');

	dispatch('updateBlockData', {
		blockId: currentBlockId.value,
		blockData: {
			settings: {
				styles: {
					[key]: newValue,
				},
			},
		},
		merge: true,
	});
};
</script>

<style lang="scss" scoped>
.edit-block-navigation-tab-layout {
	&__layout-icon {
		width: 328px;
		height: 56px;
		margin-top: 16px;
		border-radius: 8px;
		outline: 1px solid var(--color-gray-border);
		cursor: pointer;

		&:hover, &--active {
			outline: 2px solid var(--color-azure);
		}
	}

	&__padding {
		margin: 16px 0;
	}

	&__full-screen-toggle {
		padding-top: 0;
	}

	&__alignment-label {
		margin-top: 16px;
	}
}
</style>
