<script setup lang="ts">

import { useStore } from 'vuex';
import {
	computed,
	ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useUserStore } from '@/stores';

import Icon from '@/components/global/Icon.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import ZyroDrawer from '@/components/global/ZyroDrawer.vue';
import ZyroTabs from '@/components/global/ZyroTabs.vue';
import NpsRateFeature from '@/components/ui/NpsRateFeature.vue';

import EmptyBlogDrawer from '@/components/builder-drawers/drawers/partials/blogDrawer/EmptyBlogDrawer.vue';

import BlogDrawerItem from '@/components/builder-controls/blog-drawer/BlogDrawerItem.vue';
import { getFormattedNumericDate } from '@/utils/date';
import { useSidebar } from '@/use/useSidebar';
import { NPS_TYPE_FEATURE_BLOG } from '@/constants';

import { getRandomArrayItem } from '@/utils/array';
import { BLOG_PLACEHOLDERS } from '@/data';
import { useAiBlogGenerator } from '@/use/useAiBlogGenerator';
import AiBlogGeneratorModal from '@/components/AiBlogGeneratorModal.vue';
import EventLogApi from '@/api/EventLogApi';
import { useSiteStore } from '@/stores/siteStore';

const { t } = useI18n();
const {
	dispatch,
	state,
	getters,
} = useStore();
const { closeInnerSidebar } = useSidebar();

const siteStore = useSiteStore();

const {
	isAiBlogGeneratorModalOpen,
	isBlogBeingGenerated,
	closeAiBlogGeneratorModal,
	showAiBlogGeneratorModal,
	hideAiBlogGeneratorModal,
	generateAiBlog,
	createNewDummyBlogPage,
} = useAiBlogGenerator();

const userStore = useUserStore();

const tabs = [
	{
		id: 'all',
		title: t('common.all'),
	},
	{
		id: 'drafts',
		title: t('common.drafts'),
	},
	{
		id: 'scheduled',
		title: t('common.scheduled'),
	},
	{
		id: 'published',
		title: t('common.published'),
	},
];

const currentTab = ref(tabs[0]);

const addBlogPost = () => {
	dispatch('addBlogPostPage', {
		postTitle: t('builder.blog.blogPost.title'),
		postDescription: t('builder.blog.blogPost.description'),
		postContent: t('builder.blog.blogPageElements.content'),
		postThumbnail: getRandomArrayItem(BLOG_PLACEHOLDERS),
	});

	if (state.user.user?.id) {
		window.hj('identify', state.user.user.id, {
			'builder.blog.create_new_post': true,
		});
	}
};

const handleSkipButtonClick = () => {
	hideAiBlogGeneratorModal();
	addBlogPost();

	EventLogApi.logEvent({
		eventName: 'website_builder.blog_add_post.skip',
	});
};

const handleAddBlogPostButtonClick = () => {
	if (userStore.areFeaturesLocked) {
		addBlogPost();

		return;
	}

	showAiBlogGeneratorModal();

	EventLogApi.logEvent({
		eventName: 'website_builder.blog_add_post.enter',
		eventProperties: {
			location: 'side_menu',
		},
	});
};

const hasBlog = computed(() => getters['blog/hasBlog']);

const blogPosts = computed(() => {
	if (currentTab.value.id === tabs[0].id) {
		return getters.blogPages;
	}

	if (currentTab.value.id === tabs[1].id) {
		return getters.draftBlogPages;
	}

	if (currentTab.value.id === tabs[2].id) {
		return getters.scheduledBlogPages;
	}

	return getters.publishedBlogPages;
});

const postTypeText = ({
	isScheduled = false,
	isDraft = false,
}) => {
	if (isScheduled) {
		return t('common.scheduled');
	}

	if (isDraft) {
		return t('common.draft');
	}

	return t('common.public');
};

const sortedAndFormattedBlogPosts = computed(() => Object.fromEntries(
	Object.entries(blogPosts.value)
		// @ts-ignore
		.sort(([, postA], [, postB]) => new Date(postB.date) - new Date(postA.date))
		.map(([
			key, post]: [
			string|number, any,
		]) => [
			key,
			{
				...post,
				formattedDate: getFormattedNumericDate(post.date),
				isPublishable: post.isDraft || post.isScheduled,
				typeText: postTypeText({
					isScheduled: post.isScheduled,
					isDraft: post.isDraft,
				}),

			},
		]),
));

const publishPost = (blogPostId: string|number) => {
	dispatch('blog/toggleBlogPostVisibility', blogPostId);
};

const duplicatePost = (blogPostId: string|number) => {
	dispatch('duplicatePage', {
		siteData: siteStore.site,
		pageId: blogPostId,
	});
};

const openPostSettings = (blogPostId: string|number) => {
	dispatch('gui/OPEN_MODAL', {
		name: 'BlogPostSettingsModal',
		settings: {
			blogPostId,
		},
	});
};

const openBlogSettingsModal = () => {
	dispatch('gui/OPEN_MODAL', {
		name: 'BlogSettingsModal',
	});
};

const editPost = (pageId: string|number) => {
	if (state.currentPageId !== pageId) {
		dispatch('updateCurrentPageId', pageId);
	}
};

const removePage = (pageId: string|number) => {
	dispatch('removePage', {
		pageId,
	});
};
</script>
<template>
	<ZyroDrawer
		v-qa="'builder-sidebar-blog'"
		class="blog-drawer"
		:class="{ 'blog-drawer--has-blog': hasBlog, }"
		:header-title="hasBlog && $t('common.blog')"
		:header-description="hasBlog && $t('builder.blog.blogDrawer.subtitle')"
		@back="closeInnerSidebar"
	>
		<template #body>
			<template v-if="hasBlog">
				<div class="blog-drawer-content__tabs">
					<ZyroTabs
						:tabs="tabs"
						:current-tab="currentTab"
						@update:current-tab="currentTab = $event"
					/>
				</div>
				<div ref="items">
					<BlogDrawerItem
						v-for="(post, key) in sortedAndFormattedBlogPosts"
						:id="key.toString()"
						:key="`post-${key}`"
						v-qa="'blog-card-post'"
						:title="post.meta.title"
						:date="post.formattedDate"
						:is-publishable="post.isPublishable"
						:type-text="post.typeText"
						:is-active="state.currentPageId === key"
						@edit="editPost(key)"
						@duplicate="duplicatePost(key)"
						@delete="removePage(key)"
						@settings="openPostSettings(key)"
						@publish="publishPost(key)"
					/>
				</div>
			</template>
			<EmptyBlogDrawer
				v-else
				@add-post="createNewDummyBlogPage"
			/>
		</template>

		<template #footer>
			<div
				v-if="hasBlog"
				class="blog-drawer-content__footer"
			>
				<div class="blog-drawer-content__footer-settings">
					<HostingerButton
						v-qa="'blog-btn-addnewpost'"
						data-testId="add-post-button"
						@click="handleAddBlogPostButtonClick"
					>
						<template #icon-left>
							<Icon name="add" />
						</template>
						{{ $t('builder.blog.blockBlogListEmptyBlock.addNewPost') }}
					</HostingerButton>
					<HostingerButton
						v-qa="'blog-btn-settings'"
						button-type="plain"
						:title="$t('builder.blog.blogDrawer.openModal')"
						@click="openBlogSettingsModal"
					>
						<template #icon>
							<Icon name="settings" />
						</template>
					</HostingerButton>
				</div>
				<NpsRateFeature
					:feature-name="$t('builder.blog.blogDrawer.title')"
					:type="NPS_TYPE_FEATURE_BLOG"
				/>
			</div>
		</template>
	</ZyroDrawer>
	<AiBlogGeneratorModal
		v-if="isAiBlogGeneratorModalOpen"
		:is-blog-being-generated="isBlogBeingGenerated"
		@close="closeAiBlogGeneratorModal"
		@skip="handleSkipButtonClick"
		@generate="generateAiBlog"
	/>
</template>
<style lang="scss" scoped>

.blog-drawer {
	overflow: visible;

	&-content {
		display: flex;
		flex-direction: column;
		height: 100%;
		overflow: hidden;

		&__header {
			flex: 0;
			padding: 24px;
			margin-bottom: 16px;

			&-title {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 8px;
			}

			&-description {
				color: $color-gray;
			}
		}

		&__tabs {
			margin-left: -16px;
			margin-right: -16px;
			margin-bottom: 16px;
		}

		&__footer {
			width: 100%;
		}

		&__footer-settings {
			display: flex;
			justify-content: space-between;
			width: 100%;
			padding: 16px;
		}
	}

	// #region Override ZyroDrawer styles for Blog NpsRateFeature
	&--has-blog {
		:deep(.rate-feature) {
			width: 100%;
		}

		:deep(.drawer-footer) {
			height: 120px;
			padding: 0;
		}

		:deep(.drawer-body) {
			height: calc(100% - 176px);
		}

		:deep(.rate-feature__container) {
			width: 100%;
			padding: 16px 16px 0;
		}
	}

	// #endregion
}
</style>
