import * as Sentry from '@sentry/vue';

import { getUnusedHostingerDomain } from '@/api/HostingerIntegrationApi';
import { getUser as getUserApi } from '@/api/UsersApi';
import {
	GO_ROBOTS_EMAIL,
	GO_ROBOTS_EMAIL_FREE_PUBLISH,
	HOSTINGER_REF,
	PANEL_REGRESSION_EMAIL,
} from '@/constants';
import EventLogApi from '@/api/EventLogApi';
import { useSiteStore } from '@/stores/siteStore';
import { useUserStore } from '@/stores/userStore';

export const SET_UNUSED_HOSTINGER_DOMAIN = 'SET_UNUSED_HOSTINGER_DOMAIN';
export const SET_IS_AUTH_INITIATED = 'SET_IS_AUTH_INITIATED';
export const DECREMENT_CREDITS = 'DECREMENT_CREDITS';

export default {
	namespaced: true,
	state: {
		user: null,
		isAuthInitiated: false,
		hostingerDomain: null,
	},
	getters: {
		isZyroUser: (state) => /@(zyro|hostinger)\.com$/.test(state.user?.email),
		isGoRobotsUser: (state) => state.user?.email?.includes(GO_ROBOTS_EMAIL),
		isPanelRegressionUser: (state) => state.user?.email?.includes(PANEL_REGRESSION_EMAIL),
		isGoRobotsFreePublishUser: (state) => state.user?.email.includes(GO_ROBOTS_EMAIL_FREE_PUBLISH),
		isHostingerRef: (state) => state.user?.ref === HOSTINGER_REF,
		isUserAllowedToPublish: (state, getters, rootState, rootGetters) => {
			const isFreeUser = getters.isZyroUser || getters.isGoRobotsFreePublishUser;

			return isFreeUser || rootGetters['subscription/hasActiveSubscription'];
		},
		isAbleToConnectHostingerDomain: (state, getters) => !!(getters.isHostingerRef && state.hostingerDomain),
		getUserEmail: (state) => state.user?.email,
		userId: (state) => state.user?.id,
		userHpanelId: (state) => state.user?.hPanelId,
	},
	mutations: {
		[SET_IS_AUTH_INITIATED]: (state, payload) => {
			state.isAuthInitiated = payload;
		},
		setUser: (state, user) => {
			state.user = user;
		},
		[SET_UNUSED_HOSTINGER_DOMAIN]: (state, hostingerDomain) => {
			state.hostingerDomain = hostingerDomain;
		},
		[DECREMENT_CREDITS]: (state, amount) => {
			state.user = {
				...state.user,
				credits: state.user.credits - amount,
			};
		},
	},
	actions: {
		getUser: async ({
			state,
			commit,
			dispatch,
			getters,
		}, options) => {
			const siteStore = useSiteStore();
			const userStore = useUserStore();

			if (state.isAuthInitiated && !options?.force) {
				return;
			}

			commit(SET_IS_AUTH_INITIATED, true);

			try {
				const user = await getUserApi();

				if (!user) {
					return;
				}

				if (user.email?.includes(GO_ROBOTS_EMAIL)) {
					siteStore.setSiteMetaData({
						key: 'qa',
						value: true,
					});
					await dispatch('saving/saveWebsite', {}, {
						root: true,
					});
				}

				commit('setUser', user);
				userStore.setUser({
					userData: user,
				});

				EventLogApi.initAmplitude({
					userId: getters?.userHpanelId,
				});

				const sentryUserDetails = {
					id: user.id,
					hPanelId: getters?.userHpanelId,
					CRM: `https://crm.zyro.com/#/user/${user.id}`,
				};

				Sentry.setTags(sentryUserDetails);
				Sentry.setUser(sentryUserDetails);
			} catch (error) {
				Sentry.captureException(error);
			}
		},
		setHostingerDomain: async ({
			commit,
			getters,
		}) => {
			if (!getters.isHostingerRef) {
				return;
			}

			const hostingerDomain = await getUnusedHostingerDomain();

			commit(SET_UNUSED_HOSTINGER_DOMAIN, hostingerDomain);
		},
	},
};
