import { getCookie } from '@zyro-inc/site-modules/utils/cookies';

import {
	COOKIE_MANUAL_LOCALE,
	COOKIE_GEOIP_LOCALE,
	COOKIE_HOSTINGER_LANGUAGE,
} from '@/constants';
import { BuilderLocales } from '@/types/localeTypes';

export const getManualLocaleCookie = () => getCookie(COOKIE_MANUAL_LOCALE);

export const getGeoIPLocaleCookie = () => getCookie(COOKIE_GEOIP_LOCALE);

export const getHostingerLanguageCookie = (): BuilderLocales => getCookie(COOKIE_HOSTINGER_LANGUAGE) as BuilderLocales;
