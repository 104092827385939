<template>
	<div
		v-qa="'builder-sidemenu-gamificationtimer'"
		class="gamification-timer"
	>
		<div>
			<h4
				v-if="!areAllAchievementsCompleted"
				class="text-bold-2"
			>
				{{ $t('builder.gamificationTimerTitle') }}
			</h4>
			<p class="text-body-2">
				{{ areAllAchievementsCompleted ?
					$t('builder.gamificationTimerGiftDescription') : $t('builder.gamificationTimerDescription') }}
			</p>
		</div>
		<div class="gamification-timer__time-box text-bold-2">
			<span v-if="areAllAchievementsCompleted">
				{{ GAMIFICATION_DISCOUNT_CODE }}
			</span>
			<span v-else>
				{{ padNumber(timeRemainder.hours) }} : {{ padNumber(timeRemainder.minutes) }} : {{ padNumber(timeRemainder.seconds) }}
			</span>
		</div>
	</div>
</template>

<script setup lang="ts">
import {
	ref,
	computed,
} from 'vue';
import { useTimestamp } from '@vueuse/core';
import { useStore } from 'vuex';
import { useGamification } from '@/use/useGamification';
import {
	TIME_DENOMINATOR_DAY,
	GAMIFICATION_DISCOUNT_CODE,
} from '@/constants/index';
import { getTimeRemainder } from '@/utils/date';

const { state } = useStore();

const { areAllAchievementsCompleted } = useGamification();

const currentTimestamp = useTimestamp();
const deadline = ref(state.gamification.createdAt + TIME_DENOMINATOR_DAY);
const timeLeft = computed(() => deadline.value - currentTimestamp.value);
const timeRemainder = computed(() => getTimeRemainder(timeLeft.value));
const padNumber = (num: number) => num.toString().padStart(2, '0');
</script>

<style lang="scss" scoped>
.gamification-timer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 12px;
	padding: 16px;
	background: var(--color-primary-light);
	border-radius: 12px;

	&__time-box {
		width: 100%;
		padding: 4px 16px;
		text-align: center;
		background: var(--color-gray-dark);
		color: var(--color-light);
		border-radius: 8px;
	}
}
</style>
