import {
	computed,
	ref,
} from 'vue';
import { useStore } from 'vuex';
import {
	getElementGroups,
	getElementPositionsAfterGroupsSwitch,
	getShiftMarginsAfterElementDelete,
	getElementPositionsAfterVerticalShift,
	getElementGroupIndexByElementId,
	getElementsBelowVerticalTreshold,
	getBlockIdByElementId,
} from '@/utils/layout';
import { mergeObjects } from '@/utils/mergeObjects';
import {
	DEFAULT_SECTION_ROW_GAP,
	ELEMENT_POSITION_KEY_DESKTOP,
	ELEMENT_POSITION_KEY_MOBILE,
} from '@zyro-inc/site-modules/constants';
import { LAYOUT_MIN_SECTION_HEIGHT } from '@/constants';

export const useLayoutElements = ({ selectedElementId: selectedElementIdRaw } = {}) => {
	const {
		getters,
		dispatch,
	} = useStore();

	const selectedElementId = ref(selectedElementIdRaw);
	const siteBlocks = computed(() => getters.siteBlocks);
	const elementBlockId = computed(() => getBlockIdByElementId({
		elementId: selectedElementId.value,
		siteBlocks: siteBlocks.value,
	}));
	const blockData = computed(() => getters.siteBlocks[elementBlockId.value]);

	const siteElements = computed(() => getters.siteElements);
	const isMobileMode = computed(() => getters['gui/isMobileMode']);
	const currentElementPositionKey = computed(() => (isMobileMode.value ? ELEMENT_POSITION_KEY_MOBILE : ELEMENT_POSITION_KEY_DESKTOP));
	const isCurrentElementPositionKeyDesktop = computed(() => currentElementPositionKey.value === ELEMENT_POSITION_KEY_DESKTOP);
	// Enhance elements with bottom properties and elementId
	const blockElements = computed(() => blockData.value.components.map((componentId) => {
		const element = siteElements.value[componentId];

		return mergeObjects(element, {
			elementId: componentId,
			desktop: {
				bottom: element.desktop.top + element.desktop.height,
			},
			mobile: {
				bottom: element.mobile.top + element.mobile.height,
			},
		});
	}));

	const selectedElement = computed(() => blockElements.value.find((element) => element.elementId === selectedElementId.value));

	/**
	 * Element groups related logic.
	 * Each computed has its variation for three modes:
	 * - Desktop - will return the group related variables in desktop viewport
	 * - Mobile - will return the group related variables in mobile viewport
	 * - Automatic - will return the variable automatically according to the current viewport.
	*/
	const elementGroupsDesktop = computed(() => getElementGroups({
		elements: blockElements.value,
		elementPositionKey: ELEMENT_POSITION_KEY_DESKTOP,
	}));
	const elementGroupsMobile = computed(() => getElementGroups({
		elements: blockElements.value,
		elementPositionKey: ELEMENT_POSITION_KEY_MOBILE,
	}));
	const elementGroups = computed(() => (
		isCurrentElementPositionKeyDesktop.value ? elementGroupsDesktop.value : elementGroupsMobile.value
	));

	const selectedElementGroupIndexDesktop = computed(() => getElementGroupIndexByElementId({
		elementGroups: elementGroupsDesktop.value,
		elementId: selectedElementId.value,
	}));
	const selectedElementGroupIndexMobile = computed(() => getElementGroupIndexByElementId({
		elementGroups: elementGroupsMobile.value,
		elementId: selectedElementId.value,
	}));
	const selectedElementGroupIndex = computed(() => (
		isCurrentElementPositionKeyDesktop.value ? selectedElementGroupIndexDesktop.value : selectedElementGroupIndexMobile.value
	));

	const selectedElementGroupDesktop = computed(() => elementGroupsDesktop.value[selectedElementGroupIndexDesktop.value]);
	const selectedElementGroupMobile = computed(() => elementGroupsMobile.value[selectedElementGroupIndexMobile.value]);
	const selectedElementGroup = computed(() => (
		isCurrentElementPositionKeyDesktop.value ? selectedElementGroupDesktop.value : selectedElementGroupMobile.value
	));

	const updateElementPositions = ({
		elementPositions,
		elementPositionKey: _elementPositionKey,
	}) => elementPositions.forEach(({
		elementId,
		position,
	}) => dispatch('mergeElementData', {
		elementId,
		elementData: {
			[_elementPositionKey]: {
				...position,
			},
		},
	}));

	const switchElementGroupsPositions = ({
		higherGroup,
		lowerGroup,
	}) => {
		const elementPositionsAfterSwitch = getElementPositionsAfterGroupsSwitch({
			higherGroup,
			lowerGroup,
			elementPositionKey: currentElementPositionKey.value,
		});

		updateElementPositions({
			elementPositions: elementPositionsAfterSwitch,
			elementPositionKey: currentElementPositionKey.value,
		});

		dispatch('undoRedo/createSnapshot');
	};

	const baseElementDelete = ({
		elementPositionKey,
		selectedElementGroup: _selectedElementGroup,
	}) => {
		const {
			topMargin,
			bottomMargin,
		} = getShiftMarginsAfterElementDelete({
			elementToDeleteGroup: _selectedElementGroup,
			elementToDelete: selectedElement.value,
			elementPositionKey,
		});

		// Shift current element group elements only by the top margin
		const elementPositionsAfterShiftByTopMargin = getElementPositionsAfterVerticalShift({
			elements: _selectedElementGroup.elements.filter((element) => element.elementId !== selectedElementId.value),
			shiftMargin: -topMargin,
			elementPositionKey,
		});

		// Shift all elements below the current element group by the sum of top and bottom margin
		const fullShiftMargin = topMargin + bottomMargin;
		const elementPositionsAfterShiftByTopAndBottomMargin = getElementPositionsAfterVerticalShift({
			elements: getElementsBelowVerticalTreshold({
				elements: blockElements.value,
				verticalTreshold: selectedElement.value[elementPositionKey].bottom,
				elementPositionKey,
			}),
			shiftMargin: -fullShiftMargin,
			elementPositionKey,
		});

		updateElementPositions({
			elementPositions: elementPositionsAfterShiftByTopMargin,
			elementPositionKey,
		});
		updateElementPositions({
			elementPositions: elementPositionsAfterShiftByTopAndBottomMargin,
			elementPositionKey,
		});
		dispatch('updateBlockData', {
			blockId: elementBlockId.value,
			blockData: {
				[elementPositionKey]: {
					minHeight: Math.max(LAYOUT_MIN_SECTION_HEIGHT, blockData.value[elementPositionKey].minHeight - fullShiftMargin),
				},
			},
			merge: true,
		});
	};

	const deleteSelectedElement = () => {
		dispatch('leaveBlockEditMode');

		baseElementDelete({
			selectedElementGroup: selectedElementGroupMobile.value,
			elementPositionKey: ELEMENT_POSITION_KEY_MOBILE,
		});

		dispatch('removeElement', {
			elementId: selectedElementId.value,
		});

		dispatch('undoRedo/createSnapshot');
	};

	/**
	 * by default - places element below the selected element group.
	 *
	 * Behavior can be adjusted with options:
	 * @param {Boolean} shouldOverlap - if true, duplicate will overlap original element/element group [priority: 0]
	 * @param {Boolean} disabledElementNudge - if true, elements below the new element will not be nudged down
	 */
	const baseElementDuplicate = ({
		elementPositionKey,
		selectedElementGroup: _selectedElementGroup,
		shouldOverlap = false,
		shouldNudgeElementsBelow = false,
	}) => {
		const getNewElementTop = () => {
			// If element is duplicated in mobile mode, it should be placed below the last element group in desktop mode
			if (elementPositionKey === 'desktop' && isMobileMode.value) {
				return elementGroupsDesktop.value[elementGroupsDesktop.value.length - 1].groupBottom;
			}

			return (shouldOverlap
				? selectedElement.value[elementPositionKey].top
				: _selectedElementGroup.value.groupBottom)
				+ DEFAULT_SECTION_ROW_GAP;
		};

		const lowerElementNudgeDistance = selectedElement.value[elementPositionKey].height + DEFAULT_SECTION_ROW_GAP;

		const elementsBelow = getElementsBelowVerticalTreshold({
			elements: blockElements.value,
			elementPositionKey,
			verticalTreshold: _selectedElementGroup.value.groupBottom - 1,
		});

		const elementsBelowNudgedPositions = getElementPositionsAfterVerticalShift({
			elements: elementsBelow,
			elementPositionKey,
			shiftMargin: lowerElementNudgeDistance,
		});

		if (!shouldNudgeElementsBelow) {
			updateElementPositions({
				elementPositions: elementsBelowNudgedPositions,
				elementPositionKey,
			});
		}

		return {
			newElementTop: getNewElementTop(),
		};
	};

	const duplicateSelectedElement = () => {
		const { newElementTop: newDesktopElementTop } = baseElementDuplicate({
			selectedElementGroup: selectedElementGroupDesktop,
			elementPositionKey: ELEMENT_POSITION_KEY_DESKTOP,
			shouldNudgeElementsBelow: true,
			shouldOverlap: !isMobileMode.value,
		});

		const { newElementTop: newMobileElementTop } = baseElementDuplicate({
			selectedElementGroup: selectedElementGroupMobile,
			elementPositionKey: ELEMENT_POSITION_KEY_MOBILE,
		});

		dispatch('duplicateElement', {
			mobileTop: newMobileElementTop,
			desktopTop: newDesktopElementTop,
			elementId: selectedElementId.value,
		});

		dispatch('undoRedo/createSnapshot');
	};

	return {
		elementGroupsMobile,
		elementGroupsDesktop,
		elementGroups,
		currentElementPositionKey,
		selectedElement,
		selectedElementGroupIndex,
		selectedElementGroup,
		switchElementGroupsPositions,
		elementBlockId,
		blockData,
		deleteSelectedElement,
		duplicateSelectedElement,
	};
};
